import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./css/Custom.css";
import "react-toastify/dist/ReactToastify.css";
import { ProSidebarProvider } from "react-pro-sidebar";
import Router from "./Router";
import { theme } from "./lib/themeConstants";
import { ThemeProvider } from "styled-components";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import { store, persistor } from "./store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { createBrowserHistory } from "history";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

let history = createBrowserHistory();

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ProSidebarProvider>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <div className="App">
                <Router history={history} />
                <ToastContainer />
              </div>
            </PersistGate>
          </Provider>
        </QueryClientProvider>
      </ProSidebarProvider>
    </ThemeProvider>
  );
}

export default App;
