import React from "react";
import styled from "styled-components";
import { pxtoRem } from "../../lib/helper";
import Empty from "./Empty";

const StyledPlayer = styled.iframe`
  width: 100%;
  height: ${pxtoRem(550)};
`;

function PPTViewer({ url }) {
  return url ? (
    <StyledPlayer
      src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`}
    ></StyledPlayer>
  ) : (
    <Empty />
  );
}

export default PPTViewer;
