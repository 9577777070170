import { useQuery } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useChapters = (country_id, exam_board_id, subject_id, year_id, page) => {
  const formData = {};
  if (country_id) formData.country_id = country_id;
  if (exam_board_id) formData.exam_board_id = exam_board_id;
  if (subject_id) formData.subject_id = subject_id;
  if (year_id) formData.year_id = year_id;
  if (page) {
    formData.pagination = true;
    formData.page = page;
  }

  return useQuery(
    ["chapters", country_id, exam_board_id, subject_id, year_id, page],
    async () => {
      const { data } = await axiosPost("/api/cw/viewChapter", formData);
      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

export default useChapters;

//id:151
//country_id:3
//exam_board_id:2
//year_id:12
//subject_id:3
// pagination:true
// page:1
// per_page:50
