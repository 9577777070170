import React from "react";
import Switch from "react-switch";
import { theme } from "../../lib/themeConstants";
import styled from "styled-components";
import { pxtoRem } from "../../lib/helper";

const StyledSwitch = styled(Switch)`
  .react-switch-bg {
    height: ${pxtoRem(44)} !important;
    width: ${pxtoRem(125)} !important;
    border-radius: 30px !important;
  }
  .react-switch-handle {
    height: ${pxtoRem(42)} !important;
    width: ${pxtoRem(42)} !important;
    transform: translateX(
      ${(props) => (props.checked ? pxtoRem(83) : pxtoRem(1))}
    ) !important;
  }
  .checkedIcon {
    padding-left: ${pxtoRem(42)} !important;
  }
  .uncheckedIcon {
    padding-right: ${pxtoRem(42)} !important;
  }
`;

function Toggle({
  checked = false,
  onChange,
  offLable,
  onLable,
  disabled = false,
}) {
  return (
    <StyledSwitch
      disabled={disabled}
      className={checked ? "activeSwitch" : ""}
      onChange={(e) => onChange(!checked)}
      activeBoxShadow={`0 0 1px ${theme.colors.NAVY_BLUE}`}
      checked={checked}
      onColor={theme.SwitchColors.onColor}
      offColor={theme.SwitchColors.offColor}
      onHandleColor={theme.SwitchColors.onHandleColor}
      offHandleColor={theme.SwitchColors.offHandleColor}
      uncheckedIcon={<div className="uncheckedIcon">{offLable}</div>}
      checkedIcon={<div className="checkedIcon">{onLable}</div>}
    />
  );
}

export default Toggle;
