import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentQuestionIndex,
  setCurrentSubQuestionIndex,
  setQuestionsData,
  setStartEndIndex,
  setStartEndSubQuestionIndex,
  setTotalActiveQuestion,
  setTotalActiveSubQuestion,
} from "../../../redux-toolkit/questions";
import Breadcrumb from "../Breadcrumb";
import ContainerComponent from "../ContainerComponent";
import PageHeader from "../PageHeader";
import QuestionsNumberList from "../QuestionsNumberList";
import { Spinner } from "../Spinner";
import McqForm from "./McqForm";
import SaForm from "./SaForm";
import SaSubQueForm from "./SaSubQueForm";
import { Qtype } from "../../../lib/Constant";
import AlertPopup from "../popups/AlertPopUp";
import FillingBlankForm from "./FillingBlankForm";
import MatchingPairForm from "./MatchingPairForm";
import OneWordForm from "./OneWordForm";
const QuestionContainer = ({
  title,
  onCancel,
  numberOfDefaultItems = 10,
  Questions = [],
  onQuestionSubmit,
  onQuestionDelete,
  type = "mcq",
  isedit = false,
  chapters = null,
  breadCrumData,
  isSubQuesEdit = false,
}) => {
  const {
    questionsData,
    currentQuestionIndex,
    startEndIndex,
    startEndSubQuestionIndex,
    currentSubQuestionIndex,
    totalActiveQuestion,
    totalActiveSubQuestion,
  } = useSelector((state) => {
    return state.questions;
  });

  const dispatch = useDispatch();
  let [showSubQuestion, setShowSubQuestion] = useState(false);
  let [mcqFormState, setMcqFormState] = useState(null);
  let [fillBlankFormState, setFillBlankFormState] = useState(null);
  let [matchingPairFormState, setMatchingPairFormState] = useState(null);
  let [showModal, setShowModal] = useState(false);
  let [isQuestionDelete, setIsQuestionDelete] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const currentFormRef = useRef();

  const context = useFormContext();

  const addSubquestion = async (questionData) => {
    let data = questionsData?.map((item, index) => {
      if (index === currentQuestionIndex) {
        let currentChildren = [];
        if (item.children) {
          currentChildren = [...item.children];
          let totalactiveSubQues = 0;
          // eslint-disable-next-line
          currentChildren.map((val) => {
            if (Object.keys(val).length > 1) {
              totalactiveSubQues += 1;
            }
          });

          dispatch(setTotalActiveSubQuestion(totalactiveSubQues));
        }
        currentChildren.push({
          question_number: `${index + 1}.${currentChildren?.length + 1}`,
        });
        if (currentChildren?.length > 10) {
          dispatch(
            setStartEndSubQuestionIndex({
              start: currentChildren?.length - 10,
              end: currentChildren?.length - 1,
            })
          );
        }
        dispatch(setCurrentSubQuestionIndex(currentChildren?.length - 1));
        return {
          ...item,
          ...questionData,
          children: currentChildren,
        };
      }
      return item;
    });
    setShowSubQuestion(true);
    dispatch(setQuestionsData(data));
  };

  const handleDleteQuestion = (formData) => {
    const onDelete = () => {
      setIsQuestionDelete(true);
    };
    onQuestionDelete && onQuestionDelete(formData, onDelete);
  };

  useEffect(() => {
    setIsQuestionDelete(false);
    setCurrentEvent(null);
  }, []);

  useEffect(
    () => {
      // setShowSubQuestion(false);
      if (questionsData === null) {
        if (Questions?.length > 0) {
          if (isedit) {
            if (
              Questions.length > numberOfDefaultItems &&
              currentQuestionIndex > numberOfDefaultItems - 1
            ) {
              dispatch(
                setStartEndIndex({
                  start: currentQuestionIndex - 9,
                  end: currentQuestionIndex,
                })
              );
            }
          }
          if (isSubQuesEdit) {
            setShowSubQuestion(true);
            dispatch(
              setTotalActiveSubQuestion(
                Questions[currentQuestionIndex]?.children.length
              )
            );
            if (
              Questions[currentQuestionIndex]?.children.length >
                numberOfDefaultItems &&
              currentSubQuestionIndex > numberOfDefaultItems - 1
            ) {
              dispatch(
                setStartEndSubQuestionIndex({
                  start: currentSubQuestionIndex - 9,
                  end: currentSubQuestionIndex,
                })
              );
            }
          } else {
            setShowSubQuestion(false);
          }

          dispatch(setTotalActiveQuestion(Questions?.length));
          if (Questions.length < numberOfDefaultItems) {
            let data = Array.from({ length: numberOfDefaultItems }, (_, i) => {
              if (Questions[i]) {
                return {
                  ...Questions[i],
                };
              } else {
                !isedit &&
                  Questions[i - 1] &&
                  dispatch(setCurrentQuestionIndex(i));
                return {
                  question_number: i + 1,
                };
              }
            });
            dispatch(setQuestionsData(data));
          } else {
            let QuestionsData = [...Questions];
            if (!isedit) {
              QuestionsData?.push({
                question_number: QuestionsData?.length + 1,
              });
              dispatch(setCurrentQuestionIndex(QuestionsData?.length));
              dispatch(
                setStartEndIndex({
                  start: QuestionsData?.length - 10,
                  end: QuestionsData?.length,
                })
              );
            }
            let data = QuestionsData.map((item, i) => {
              if (Questions[i]) {
                return {
                  ...Questions[i],
                };
              } else {
                !isedit &&
                  Questions[i - 1] &&
                  dispatch(setCurrentQuestionIndex(i));
                return {
                  question_number: i + 1,
                };
              }
            });
            dispatch(setQuestionsData(data));
          }
        } else {
          let data = Array.from({ length: numberOfDefaultItems }, (_, i) => {
            return {
              question_number: i + 1,
            };
          });
          dispatch(setQuestionsData(data));
        }
      } else {
        if (Questions.length > 0) {
          // here after adding new question
          if (isSubQuesEdit) {
            setShowSubQuestion(true);
          }
          if (isQuestionDelete) {
            //condition for maintaining start end index on delete for sub quuestion
            if (
              Questions[currentQuestionIndex]?.children.length <=
              currentSubQuestionIndex
            ) {
              dispatch(setCurrentSubQuestionIndex(currentSubQuestionIndex - 1));
              if (
                Questions[currentQuestionIndex].children?.length >
                numberOfDefaultItems
              ) {
                dispatch(
                  setStartEndSubQuestionIndex({
                    start: startEndSubQuestionIndex?.end - 10,
                    end: startEndSubQuestionIndex?.end - 1,
                  })
                );
              } else {
                dispatch(setStartEndSubQuestionIndex({ start: 0, end: 9 }));
              }
            } else if (
              Questions[currentQuestionIndex]?.children.length >
              numberOfDefaultItems
            ) {
              // dispatch(setCurrentSubQuestionIndex(currentSubQuestionIndex - 1));

              if (
                !Questions[currentQuestionIndex]?.children[
                  startEndSubQuestionIndex.end
                ]
              ) {
                dispatch(
                  setStartEndSubQuestionIndex({
                    start:
                      Questions[currentQuestionIndex]?.children.length -
                      numberOfDefaultItems,
                    end: Questions[currentQuestionIndex]?.children.length - 1,
                  })
                );
              }
            } else {
              dispatch(setStartEndSubQuestionIndex({ start: 0, end: 9 }));
            }

            //condition for maintaining start end index on delete for question
            if (Questions.length <= currentQuestionIndex) {
              dispatch(setCurrentQuestionIndex(Questions.length - 1));
              if (Questions.length > numberOfDefaultItems) {
                dispatch(
                  setStartEndIndex({
                    start: Questions.length - numberOfDefaultItems,
                    end: Questions.length - 1,
                  })
                );
              } else {
                dispatch(
                  setStartEndIndex({
                    start: 0,
                    end: 9,
                  })
                );
              }
            } else if (Questions.length > numberOfDefaultItems) {
              if (!Questions[startEndIndex.end]) {
                dispatch(
                  setStartEndIndex({
                    start: startEndIndex.start - 1,
                    end: startEndIndex.end,
                  })
                );
              }
            } else {
              dispatch(
                setStartEndIndex({
                  start: 0,
                  end: 9,
                })
              );
            }

            if (Questions.length < numberOfDefaultItems) {
              let data = Array.from(
                { length: numberOfDefaultItems },
                (_, i) => {
                  if (Questions[i]) {
                    return {
                      ...Questions[i],
                    };
                  } else {
                    return {
                      question_number: i + 1,
                    };
                  }
                }
              );
              dispatch(setQuestionsData(data));
            } else {
              let data = Questions.map((item, i) => {
                if (Questions[i]) {
                  return {
                    ...Questions[i],
                  };
                } else {
                  return {
                    question_number: i + 1,
                  };
                }
              });
              dispatch(setQuestionsData(data));
            }
            dispatch(setTotalActiveQuestion(Questions.length));
          } else {
            // will come here after add question invalidate

            dispatch(setTotalActiveQuestion(Questions?.length));
            dispatch(
              setTotalActiveSubQuestion(
                Questions[currentQuestionIndex].children.length
              )
            );
            if (questionsData?.length > Questions.length) {
              if (Questions?.length > numberOfDefaultItems) {
                dispatch(
                  setStartEndIndex({
                    start: Questions?.length - numberOfDefaultItems,
                    end: Questions?.length - 1,
                  })
                );
              } else {
                dispatch(
                  setStartEndIndex({
                    start: 0,
                    end: 9,
                  })
                );
              }
            }
            if (Questions.length < numberOfDefaultItems) {
              let data = Array.from(
                { length: numberOfDefaultItems },
                (_, i) => {
                  if (Questions[i]) {
                    return {
                      ...Questions[i],
                    };
                  } else {
                    return {
                      question_number: i + 1,
                    };
                  }
                }
              );
              dispatch(setQuestionsData(data));
            } else {
              let QuestionsData = [...Questions];
              let data = QuestionsData.map((item, i) => {
                if (Questions[i]) {
                  return {
                    ...Questions[i],
                  };
                } else {
                  return {
                    question_number: i + 1,
                  };
                }
              });
              dispatch(setQuestionsData(data));
            }
          }
          setIsQuestionDelete(false);
        } else if (isQuestionDelete) {
          let data = Array.from({ length: numberOfDefaultItems }, (_, i) => {
            return {
              question_number: i + 1,
            };
          });
          dispatch(setQuestionsData(data));
        }
      }
    },
    // eslint-disable-next-line
    [Questions]
  );

  const onChange = (index) => {
    // eslint-disable-next-line
    questionsData.map((item, i) => {
      if (i === index) {
        if (item.children?.length > 0) {
          let totalactiveSubQues = 0;
          // eslint-disable-next-line
          item.children.map((val) => {
            if (Object.keys(val).length > 1) {
              totalactiveSubQues += 1;
            }
          });
          dispatch(setTotalActiveSubQuestion(totalactiveSubQues));
        } else {
          dispatch(setTotalActiveSubQuestion(0));
        }
      }
    });
    setShowSubQuestion(false);
    dispatch(setCurrentSubQuestionIndex(0));
    dispatch(setCurrentQuestionIndex(index));
  };

  const addNewQuestion = () => {
    dispatch(
      setQuestionsData([
        ...questionsData,
        {
          question_number:
            parseInt(questionsData[questionsData?.length - 1].question_number) +
            1,
        },
      ])
    );

    dispatch(
      setStartEndIndex({
        start: questionsData?.length - 9,
        end: questionsData?.length,
      })
    );
  };

  const onChangeLeft = () => {
    if (startEndIndex?.start > 0) {
      dispatch(
        setStartEndIndex({
          start: startEndIndex?.start - 1,
          end: startEndIndex?.end - 1,
        })
      );
    }
  };

  const onChangeRight = () => {
    if (
      startEndIndex?.end < questionsData?.length &&
      startEndIndex?.end + 1 !== questionsData?.length
    ) {
      dispatch(
        setStartEndIndex({
          start: startEndIndex?.start + 1,
          end: startEndIndex?.end + 1,
        })
      );
    }
  };

  const nextQuestion = () => {
    if (currentQuestionIndex < questionsData.length - 1) {
      onChange(currentQuestionIndex + 1);
      if (currentQuestionIndex + 1 > startEndIndex?.end) {
        setStartEndIndex({
          start: startEndIndex?.start + 1,
          end: startEndIndex?.end + 1,
        });
      }
    } else {
      addNewQuestion();
      dispatch(setCurrentQuestionIndex(questionsData?.length));
      dispatch(setTotalActiveQuestion(questionsData?.length));
    }
  };
  const onSubChangeLeft = () => {
    if (startEndSubQuestionIndex?.start > 0) {
      dispatch(
        setStartEndSubQuestionIndex({
          start: startEndSubQuestionIndex?.start - 1,
          end: startEndSubQuestionIndex?.end - 1,
        })
      );
    }
  };

  const onSubChangeRight = () => {
    if (
      startEndSubQuestionIndex?.end <
        questionsData[currentQuestionIndex]?.children?.length &&
      startEndSubQuestionIndex?.end + 1 !==
        questionsData[currentQuestionIndex]?.children?.length
    ) {
      dispatch(
        setStartEndSubQuestionIndex({
          start: startEndSubQuestionIndex?.start + 1,
          end: startEndSubQuestionIndex?.end + 1,
        })
      );
    }
  };

  const onSubQuestionChange = (index) => {
    const canSubmit = currentFormRef?.current?.canParentSubmit();
    setCurrentEvent(index);
    if (canSubmit) {
      setShowModal(true);
    } else {
      dispatch(setCurrentSubQuestionIndex(index));
    }
  };

  const completeSubQuestions = (formData) => {
    let data = questionsData?.map((item, index) => {
      if (index === currentQuestionIndex) {
        let children = [...item?.children];
        let nextKey = currentSubQuestionIndex + 1;
        if (children[nextKey]) {
          dispatch(setCurrentSubQuestionIndex(nextKey));
          if (nextKey > startEndSubQuestionIndex?.end) {
            dispatch(
              setStartEndSubQuestionIndex({
                start: startEndSubQuestionIndex?.start + 1,
                end: nextKey,
              })
            );
          }
        } else {
          children.push({
            question_number: `${index + 1}.${children?.length + 1}`,
          });
          if (children.length > 10) {
            dispatch(
              setStartEndSubQuestionIndex({
                start: children.length - 10,
                end: children.length - 1,
              })
            );
          }
          dispatch(setCurrentSubQuestionIndex(children.length - 1));
          dispatch(setTotalActiveSubQuestion(totalActiveSubQuestion + 1));
        }
        return {
          ...item,
          children: [...children],
        };
      }
      return { ...item };
    });
    dispatch(setQuestionsData(data));
  };

  const onSubQuestionSubmit = (formData) => {
    let onFormSuccess = (success) => {
      if (showModal) {
        setShowModal(false);
        if (typeof currentEvent === "number") {
          onChange(currentEvent);
        } else {
          dispatch(setCurrentSubQuestionIndex(currentEvent));
        }
      }
    };
    onQuestionSubmit(formData, onFormSuccess);
  };

  const onSAformSubmit = (formData) => {
    let onFormSuccess = (success) => {
      if (showModal) {
        setShowModal(false);
        if (typeof currentEvent === "number") {
          onChange(currentEvent);
        } else {
          context?.backPage(currentEvent, 1);
        }
      }
      if (success) {
        dispatch(setCurrentSubQuestionIndex(0));
      }
    };

    onQuestionSubmit(formData, onFormSuccess);
  };

  const handleBack = (e) => {
    const canSubmit = currentFormRef?.current?.canParentSubmit();
    if (canSubmit) {
      setCurrentEvent(e);
      setShowModal(true);
    } else {
      setCurrentEvent(e);
      if (typeof e === "number") {
        onChange(e);
      } else {
        context?.backPage(e, 1);
      }
    }
  };

  const confirmSaveQuestion = async () => {
    setShowModal(false);
    currentFormRef.current.onParentSubmit();
  };

  const cancelSaveQuestion = () => {
    dispatch(setTotalActiveSubQuestion(0));
    if (typeof currentEvent === "number") {
      onChange(currentEvent);
    }
    setShowModal(false);
  };

  return (
    <>
      <Spinner isLoading={false} />
      {!isedit && <PageHeader title={title}></PageHeader>}
      <ContainerComponent>
        <Breadcrumb data={breadCrumData} />
        <QuestionsNumberList
          listData={questionsData}
          onChange={handleBack}
          onSubQuestionChange={onSubQuestionChange}
          onChangeLeft={onChangeLeft}
          startEndIndex={startEndIndex}
          addNewQuestion={addNewQuestion}
          currentQuestionIndex={currentQuestionIndex}
          onChangeRight={onChangeRight}
          totalActiveQuestion={totalActiveQuestion}
          onSubChangeLeft={onSubChangeLeft}
          onSubChangeRight={onSubChangeRight}
          startEndSubQuestionIndex={startEndSubQuestionIndex}
          showSubQuestion={showSubQuestion}
          totalActiveSubQuestion={totalActiveSubQuestion}
          currentSubQuestionIndex={currentSubQuestionIndex}
          setShowSubQuestion={setShowSubQuestion}
          isSubQuesEdit={isSubQuesEdit}
        ></QuestionsNumberList>
        {type === Qtype.mcq ? (
          <>
            {questionsData && questionsData[currentQuestionIndex] && (
              <McqForm
                ref={currentFormRef}
                key={
                  questionsData[currentQuestionIndex]?.id ||
                  currentQuestionIndex
                }
                onSubmit={(formData) => {
                  onQuestionSubmit(formData, () => {
                    if (showModal) {
                      // setShowModal(false);
                      if (typeof currentEvent === "number") {
                        onChange(currentEvent);
                      } else {
                        context?.backPage(currentEvent, 1);
                      }
                    }
                  });
                }}
                defaultValues={questionsData[currentQuestionIndex]}
                nextQuestion={nextQuestion}
                onQuestionDelete={handleDleteQuestion}
                setFormState={setMcqFormState}
                formStateValues={mcqFormState}
                chapters={chapters}
                isedit={isedit}
              ></McqForm>
            )}
          </>
        ) : type === Qtype?.structured_question ? (
          <>
            {questionsData &&
            questionsData[currentQuestionIndex]?.children &&
            questionsData[currentQuestionIndex]?.children[
              currentSubQuestionIndex
            ] &&
            showSubQuestion ? (
              <>
                <SaSubQueForm
                  ref={currentFormRef}
                  key={currentQuestionIndex}
                  completeSubQuestions={completeSubQuestions}
                  onSubmit={onSubQuestionSubmit}
                  onQuestionDelete={handleDleteQuestion}
                  parentQuestion={questionsData[currentQuestionIndex]}
                  defaultValues={
                    questionsData[currentQuestionIndex]?.children[
                      currentSubQuestionIndex
                    ]
                  }
                  isedit={isedit}
                />
              </>
            ) : (
              <>
                {questionsData && questionsData[currentQuestionIndex] && (
                  <SaForm
                    ref={currentFormRef}
                    key={currentQuestionIndex}
                    addSubquestion={addSubquestion}
                    onSubmit={onSAformSubmit}
                    onQuestionDelete={handleDleteQuestion}
                    defaultValues={questionsData[currentQuestionIndex]}
                    chapters={chapters}
                    isedit={isedit}
                  />
                )}
              </>
            )}
          </>
        ) : type === Qtype.matching_pair ? (
          <>
            {questionsData && questionsData[currentQuestionIndex] && (
              <MatchingPairForm
                onSubmit={onQuestionSubmit}
                defaultValues={questionsData[currentQuestionIndex]}
                nextQuestion={nextQuestion}
                setFormState={setMatchingPairFormState}
                formStateValues={matchingPairFormState}
              ></MatchingPairForm>
            )}
          </>
        ) : type === Qtype.filling_in_the_blank ? (
          <>
            {questionsData && questionsData[currentQuestionIndex] && (
              <FillingBlankForm
                onSubmit={onQuestionSubmit}
                defaultValues={questionsData[currentQuestionIndex]}
                nextQuestion={nextQuestion}
                setFormState={setFillBlankFormState}
                formStateValues={fillBlankFormState}
              ></FillingBlankForm>
            )}
          </>
        ) : type === Qtype.one_word ? (
          <>
            {questionsData && questionsData[currentQuestionIndex] && (
              <OneWordForm
                ref={currentFormRef}
                key={
                  questionsData[currentQuestionIndex]?.id ||
                  currentQuestionIndex
                }
                onQuestionDelete={handleDleteQuestion}
                onSubmit={(formData) => {
                  onQuestionSubmit(formData, () => {
                    if (showModal) {
                      setShowModal(false);
                      if (typeof currentEvent === "number") {
                        onChange(currentEvent);
                      } else {
                        context?.backPage(currentEvent, 1);
                      }
                    }
                  });
                }}
                defaultValues={questionsData[currentQuestionIndex]}
                nextQuestion={nextQuestion}
                chapters={chapters}
                isedit={isedit}
              ></OneWordForm>
            )}
          </>
        ) : (
          <></>
        )}
        <AlertPopup
          confirmText="Yes"
          cancelText="No"
          header="Confirm"
          body="Do you want to save unsaved question?"
          onConfirm={confirmSaveQuestion}
          onCancel={cancelSaveQuestion}
          show={showModal}
        ></AlertPopup>
      </ContainerComponent>
    </>
  );
};

export default QuestionContainer;
