import { useState } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { Constant } from "../../lib/Constant";
import { pxtoRem } from "../../lib/helper";

const StyledWrapper = styled.div`
  background-color: ${(props) => props?.bgcolor};
  border: 1px solid ${(props) => props.theme.colors.LIGHT_GREY};
  border-radius: ${pxtoRem(10)};
  color: ${(props) => props.theme.colors.MEDIUM_GREY};
  font-size: ${pxtoRem(20)};
  display: flex;
  height: 40px;
  cursor: pointer;
  position: relative !important;
  border-bottom-right-radius: ${(props) => props.issortNotes && 0};
  border-bottom-left-radius: ${(props) => props.issortNotes && 0};
`;

const StyledLeft = styled.div`
  background-color: ${(props) =>
    props.iscustom
      ? props.theme.colors.NEBULA_BLUE
      : props.theme.colors.GHOST_WHITE} !important;

  color: ${(props) =>
    props.iscustom ? props.theme.colors.WHITE : props.theme.colors.TAUPE_GREY};
  padding-left: 12px;
  align-items: center;
  display: flex;
  border-radius: ${pxtoRem(8.5)};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: ${(props) => props.issortNotes && 0};
  width: 115px;
  min-width: 100px;
`;

const StyledRight = styled.div`
  padding: 0px 12px;
  /* display: flex; */
  line-height: 35px;
  align-items: center;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: auto;
`;

const StyledFileUpload = styled(Form.Control)`
  opacity: 0;
  position: absolute;
`;

const StyledClearbtn = styled.img`
  position: absolute;
  top: 30%;
  right: 2%;
`;

const StyledBox = styled.div`
  width: ${pxtoRem(60)};
  background-color: ${(props) => props.theme.colors.PURPLE};
  align-items: center;
  display: inline-flex;
  justify-content: center;
  height: 100%;
  border-radius: ${pxtoRem(8.5)};

  ${(props) =>
    !props.reverse &&
    `
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
`};

  ${(props) =>
    props.reverse &&
    `
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`};
`;

const StyledImage = styled.img`
  margin-left: ${pxtoRem(20)};
  height: ${pxtoRem(35)};
`;

const FileUpload = ({
  file = "",
  register,
  id,
  validation,
  iscustom = false,
  isForm = false,
  issortNotes = false,
  handleChange,
  onClear = null,
  icon,
  reverse = false,
  bgColor,
  ...rest
}) => {
  const [name, setName] = useState(Constant.getFileName(file));
  const isImage = false;
  return (
    <>
      <StyledWrapper bgcolor={bgColor} issortNotes={issortNotes}>
        {register ? (
          <StyledFileUpload
            type="file"
            {...register(id, validation)}
            {...rest}
            onChange={(e) => {
              setName(e?.target?.files?.[0]?.name);
              handleChange && handleChange(e);
            }}
          />
        ) : (
          <StyledFileUpload
            type="file"
            onChange={(e) => {
              setName(e?.target?.files?.[0]?.name);
            }}
          />
        )}
        {!icon && (
          <StyledLeft iscustom={iscustom} issortNotes={issortNotes}>
            {iscustom ? (isForm ? "Add image" : "Upload File") : "Choose file"}
          </StyledLeft>
        )}

        {!reverse && icon && (
          <StyledBox reverse={reverse}>
            <img alt="icon" height={28} src={icon} />
          </StyledBox>
        )}

        <StyledRight isForm={isForm}>
          {name ? (
            <>
              {isImage ? (
                <StyledImage src="https://media.istockphoto.com/id/1450576005/photo/tomato-isolated-tomato-on-white-background-perfect-retouched-tomatoe-side-view-with-clipping.jpg?b=1&s=170667a&w=0&k=20&c=FM9nYzpebaAPOevv_joWEa3TJy_L2uMA7QCAlnK2y5A=" />
              ) : (
                name
              )}
            </>
          ) : (
            "No file choosen"
          )}
          {onClear && name && (
            <StyledClearbtn
              src={Constant.Icons.close_grey}
              height={14}
              width={14}
              onClick={() => {
                onClear();
                setName(null);
              }}
              alt="clear file"
            ></StyledClearbtn>
          )}
        </StyledRight>

        {reverse && icon && (
          <StyledBox reverse={reverse}>
            <img alt="icon" height={28} src={icon} />
          </StyledBox>
        )}
      </StyledWrapper>
    </>
  );
};

export default FileUpload;
