import styled from "styled-components";
import { userProfile } from "../lib/dummyData";
import NavBar from "../components/shared/Navbar";
import SideBar from "../components/shared/SideBar";
import { useState } from "react";
import { Outlet } from "react-router";
import { pxtoRem } from "../lib/helper";

const sidebarWidth = pxtoRem(350);

export const StyledSideBarContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1030;
  width: ${sidebarWidth};
  background-color: red;
  background-color: #2a2f44;
  display: flex;
  flex-direction: column;
  overflow: none;
  margin-left: ${(props) => props.marginLeft};
  transition: margin-left 0.15s, margin-right 0.15s, box-shadow 75ms,
    transform 0.15s, width 0.15s, z-index 0s ease 0.15s;
`;
export const StyledHeaderAndContentContainer = styled.div`
  width: 100%;
  background-color: #f8f8fb;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-left: ${(props) => props.paddingLeft};
  transition: padding 0.15s;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const StyledHeaderContainer = styled.div`
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1029;
  display: flex;
  flex-wrap: wrap;
  // border: 2px solid red;
`;

export const StyledContentContainer = styled.div`
  // border: 2px solid blue;
  flex-grow: 1;
  padding: 1.5rem;
`;

const DashboardLayout = ({ children }) => {
  const [sideContainerMarginLeft, SetSideContainerMarginLeft] = useState("0");
  const [contentContainerPaddingLeft, SetContentContainerPaddingLeft] =
    useState(sidebarWidth);
  return (
    <>
      <StyledSideBarContainer marginLeft={sideContainerMarginLeft}>
        <SideBar />
      </StyledSideBarContainer>
      <StyledHeaderAndContentContainer
        paddingLeft={contentContainerPaddingLeft}
      >
        <StyledHeaderContainer>
          <NavBar
            onMenuClick={() => {
              if (contentContainerPaddingLeft === sidebarWidth) {
                SetSideContainerMarginLeft(`-${sidebarWidth}`);
                SetContentContainerPaddingLeft("0");
              } else {
                SetSideContainerMarginLeft("0");
                SetContentContainerPaddingLeft(sidebarWidth);
              }
            }}
            data={userProfile}
          />
        </StyledHeaderContainer>
        <StyledContentContainer>
          <Outlet />
          {children}
        </StyledContentContainer>
      </StyledHeaderAndContentContainer>
    </>
  );
};

export default DashboardLayout;
