import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { pxtoRem } from "../../lib/helper";
import Button from "../../components/shared/Button";
import { Constant } from "../../lib/Constant";
import { theme } from "../../lib/themeConstants";
import history from "../../lib/history";

const StyledWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.SHADE_ALICE_BLUE};
  padding: ${pxtoRem(60)} ${pxtoRem(120)};
  height: 100vh;
  font-size: ${pxtoRem(40)};
`;

const StyledHeaderRow = styled(Row)`
  margin-bottom: ${pxtoRem(40)};
`;

const StyledContentRow = styled(Row)`
  img {
    background-color: ${(props) => props.theme.colors.WHITE};
    text-align: center;
    border-radius: ${pxtoRem(20)};
    padding: 0;
    width: 100%;
    /* padding: ${pxtoRem(30)}; */
  }
`;

const CommonBox = styled.div`
  background-color: ${(props) => props.theme.colors.DARK_CYAN};
  color: ${(props) => props.theme.colors.WHITE};
  font-size: ${pxtoRem(20)};
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  text-align: center;
`;

const StyledEditBox = styled(CommonBox)`
  width: ${pxtoRem(60)};
  border-radius: 10px;
  margin-right: ${pxtoRem(16)};
`;

const StyledCloseBox = styled(CommonBox)`
  height: ${pxtoRem(61)};
  width: ${pxtoRem(155)};
  border-radius: 20px;
`;

const StyleButtonCol = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: end;
`;



const StyledStartButton = styled(Button)`
  margin-right: ${pxtoRem(100)};
  width: ${pxtoRem(130)};
  height: ${pxtoRem(51)};
`;



const InitialPage = ({ handleEdit, setInitialPage, defaultValues = null }) => {
  return (
    <StyledWrapper>
      <StyledHeaderRow>
        <StyleButtonCol>
          <StyledEditBox
            onClick={() => {
              handleEdit && handleEdit();
            }}
          >
            <img height={20} src={Constant.Icons.edit} alt="edit" />
          </StyledEditBox>
          <StyledCloseBox
            onClick={() => {
              history.back();
            }}
          >
            Close
          </StyledCloseBox>
        </StyleButtonCol>
      </StyledHeaderRow>

      <StyledContentRow>
        {defaultValues?.cover_image && (
          <img src={defaultValues?.cover_image} alt="cover" />
        )}
      </StyledContentRow>
      <Row>
        <Col className="mt-4 mb-4 d-flex justify-content-end">
          <StyledStartButton
            bgColor={theme.colors.DARK_CYAN}
            text={"Start"}
            onClick={() => {
              setInitialPage(false);
            }}
          ></StyledStartButton>
        </Col>
      </Row>
    </StyledWrapper>
  );
};

export default InitialPage;
