import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import { Constant, alphabets } from "../../../lib/Constant";
import { pxtoRem } from "../../../lib/helper";
import { theme } from "../../../lib/themeConstants";
import Checkbox from "../Checkbox";
import Dropdown from "../Dropdown";
import FileUpload from "../FileUpload";
import TextInput from "../TextInput";
import BoxImage from "./BoxImage";

const StyledLabel = styled(Form.Label)`
  font-size: ${pxtoRem(24)};
  color: ${(props) => props.theme.colors.LIGHT_GREY};
`;

const StyledErrMessage = styled(Form.Control.Feedback)`
  display: block;
  color: ${(props) => props.theme.colors.SHADE_OF_PINK_RED};
`;

const StyledActionCol = styled(Col)`
  display: flex;
  min-width: ${pxtoRem(170)};
`;

const QuestionOption = ({
  field,
  register,
  name,
  control,
  index,
  onDelete,
  errors,
  setValue,
  getValues,
  handleCheck,
}) => {
  const errorList = errors?.[name]?.[index];
  const [optionType, setOptionType] = useState(field?.type || "text");
  return (
    <Row key={field.id} className="mb-4">
      <Col xs={2}>
        <Row>
          <Col style={{ margin: "auto" }} xs={2}>
            <StyledLabel>{`${alphabets[index]?.toUpperCase()}.`}</StyledLabel>
          </Col>
          <Col>
            <Controller
              isClearable
              name={`${name}.${index}.type`}
              control={control}
              rules={{ required: "Please select a question type" }}
              render={({ field: { ref, onChange, ...rest } }) => (
                <Dropdown
                  autoFocus
                  placeholder="Select"
                  {...rest}
                  onChange={(value) => {
                    setValue(`${name}.${index}.answer`, "");
                    setOptionType(value);
                    onChange(value);
                  }}
                  options={[
                    {
                      value: "text",
                      label: "text",
                    },
                    {
                      value: "image",
                      label: "image",
                    },
                  ]}
                />
              )}
            />
          </Col>
        </Row>
      </Col>
      <Col>
        {optionType === "text" ? (
          <>
            <TextInput
              register={register}
              id={`${name}.${index}.answer`}
              validation={{ required: "This field is required" }}
            />
            {errorList?.answer?.message && (
              <StyledErrMessage>{errorList?.answer?.message}</StyledErrMessage>
            )}
          </>
        ) : (
          <>
            <TextInput
              style={{ display: "none" }}
              id={`${name}.${index}.answerImage`}
              register={register}
              validation={{ required: "Please select image" }}
            />
            <FileUpload
              icon={Constant.Icons.upload_arrow}
              accept=".JPG,.JPEG,.PNG"
              id={`${name}.${index}.answer`}
              register={register}
              bgColor={theme.colors.WHITE}
              file={field?.answerImage}
              handleChange={(e) => {
                setValue(
                  `${name}.${index}.answerImage`,
                  e?.target?.files?.[0]?.name,
                  {
                    shouldValidate: true,
                    shouldDirty: true,
                  }
                );
              }}
            />
            {errorList?.answerImage?.message && (
              <StyledErrMessage>
                {errorList?.answerImage?.message}
              </StyledErrMessage>
            )}
          </>
        )}
      </Col>

      <StyledActionCol xs={1}>
        <Controller
          isClearable
          name={`${name}.${index}.checked`}
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <Checkbox
              checked={getValues(`${name}.${index}.checked`)}
              onChange={(value) => {
                setValue(`${name}.${index}.checked`, value);
                handleCheck && handleCheck();
              }}
            />
          )}
        />
        <BoxImage
          bgColor={theme.colors.PINK}
          imgSrc={Constant.Icons.delete_bg_white}
          onClick={onDelete}
        />
      </StyledActionCol>
    </Row>
  );
};

export default QuestionOption;
