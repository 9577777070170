import { useState, useTransition } from "react";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LessonForm from "../../components/form/LessonForm";
import Button from "../../components/shared/Button";
import SuccessModal from "../../components/shared/popups/SuccessModal";
import { useCreateLesson } from "../../hooks/lesson";
import { Constant } from "../../lib/Constant";
import { theme } from "../../lib/themeConstants";
import useChapter from "../../hooks/chapter/useChapter";
import { useEffect } from "react";

const CreateLesson = () => {
  const createMutation = useCreateLesson();
  const [isModal, setIsModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isFormReset, setIsFormReset] = useState(false);
  const [, startTransition] = useTransition();
  const params = useParams();
  let { data: chapter, isFetching } = useChapter(params?.chapterId);
  // if (!location?.state?.chapter) {
  //   navigate("/chapters");
  // }

  // const chapter = location?.state?.chapter;
  // const generatedLessonNo = `${chapter?.number}.${
  //   parseInt(chapter?.lesson_count || 0) + 1
  // }`;

  // const [lessonNumber, setLessonNumber] = useState(0);
  const [defaultValue, setDefaultValue] = useState({
    number: 0,
  });

  useEffect(() => {
    if (chapter) {
      let lessonNo = `${chapter[0]?.number}.${
        parseInt(chapter[0]?.lessons_count || 0) + 1
      }`;
      // setLessonNumber(lessonNo);
      // setIsFormReset(true);
      setDefaultValue({ number: lessonNo });
      // startTransition(() => {
      //   setIsFormReset(false);
      // });
    }
  }, [chapter]);

  const handleFormSubmit = (formData) => {
    createMutation.mutate(formData, {
      onError: (res) => {
        toast.error(res?.response?.data?.message);
      },
      onSuccess: () => {
        queryClient.invalidateQueries("chapters");
        queryClient.invalidateQueries("lessons");
        queryClient.invalidateQueries("chapter");
        if (location?.state?.isFromChapter) {
          // const no = parseInt(formData.get("number").split(".")?.[1]);
          // console.log(no);
          // let lNo = "";
          // if (!isNaN(no)) {
          //   lNo = `${chapter.number}.${no + 1}`;
          // } else {
          //   console.log(chapter?.lesson_count);
          //   lNo = `${chapter.number}.${
          //     parseInt(chapter?.lessons_count || 0) + 1
          //   }`;
          // }
          // setLessonNumber(lNo);
          setIsModal(true);
        } else {
          navigate(`/chapters/${params.chapterId}/lessons`, {
            state: { chapter: chapter },
          });
        }
      },
    });
  };

  return (
    <>
      <SuccessModal
        onCancel={() => {
          setIsModal(false);
          navigate(`/chapters`);
        }}
        show={isModal}
        body="Lesson has been successfully created."
        button={
          <Button
            bgColor={theme.colors.NEBULA_BLUE}
            icon={Constant.Icons.add_circle_outline_white}
            text="Create Next Lesson"
            onClick={() => {
              setIsFormReset(true);
              // setDefaultValue({ number: lessonNumber, glossary: [] });
              setIsModal(false);
              startTransition(() => {
                setIsFormReset(false);
              });
            }}
          />
        }
      />
      <LessonForm
        handleFormSubmit={handleFormSubmit}
        title="Create new lesson"
        isLoading={createMutation.isLoading || isFetching}
        isFormReset={isFormReset}
        defaultValue={defaultValue}
        chapter={chapter && chapter[0]}
      />
    </>
  );
};

export default CreateLesson;
