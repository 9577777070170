export const Constant = {
  Icons: {
    notification: "/assets/icons/notification-bell.svg",
    burgermenu: "/assets/icons/burger-menu.svg",
    cross: "/assets/icons/cross-circle.svg",
    downarrow: "/assets/icons/down.svg",
    view_down_arrow: "/assets/icons/view-down.svg",
    close: "/assets/icons/close-icon.png",
    success: "/assets/icons/success-icon.png",
    add_circle_outline: "/assets/icons/add-circle-outline-black.svg",
    add_circle_outline_white: "/assets/icons/add-circle-outline-white.svg",
    add_circle_outline_blue: "/assets/icons/add-circle-outline-blue.svg",
    add_circle_blue_outline: "/assets/icons/add-circle-blue-outline.svg",
    upload: "/assets/icons/upload.svg",
    check_circle: "/assets/icons/check_circle.svg",
    login_icon1: "/assets/icons/login-pen.png",
    login_icon2: "/assets/icons/login.png",
    description: "/assets/icons/description.svg",
    glossary: "/assets/icons/glossary.svg",
    notes: "/assets/icons/notes.svg",
    ppt: "/assets/icons/ppt.svg",
    videoPlayer: "/assets/icons/video-player.svg",
    signIn: "/assets/icons/sign-in-alt.svg",
    colorPicker: "/assets/icons/color-picker.svg",
    delete: "/assets/icons/delete.svg",
    delete_bg_white: "/assets/icons/delete_bg_white.svg",
    delete_bg_red: "/assets/icons/delete-red.svg",
    eclipes: "/assets/icons/eclipes.svg",
    eye: "/assets/icons/eye.svg",
    check: "/assets/icons/check.svg",
    dashboard: "/assets/icons/dashboard.svg",
    subject_management: "/assets/icons/subject_management.svg",
    activity_management: "/assets/icons/activity_management.svg",
    curriculum: "/assets/icons/curriculum.svg",
    qa: "/assets/icons/qa.svg",
    upload_outline: "/assets/icons/upload-outline.svg",
    file: "/assets/icons/file.svg",
    green_file: "/assets/icons/green_file.svg",
    check_circle_black: "/assets/icons/check-circle-black.svg",
    close_grey: "/assets/icons/close-grey.svg",
    hint: "/assets/icons/hint.svg",
    upload_arrow: "/assets/icons/upload_arrow.svg",
    datepicker: "/assets/icons/datepicker.svg",
    edit: "/assets/icons/edit.svg",
    bulb: "/assets/icons/bulb.svg",
    close_white: "/assets/icons/close_white.svg",
  },
  Images: {
    profile: "/assets/images/profile.jpg",
    Brand: "/assets/images/aqa.png",
  },

  tableErrorMessages: {
    chapters: "No chapters found",
    lessons: "No lessons found",
    worksheet: "No worksheet found",
    selftest: "No selftest found",
    pastPaper: "No pastpaper found",
    challenges: "No challenge found",
  },
  SuccessMessages: {
    editChapter: "Chapter updated successfully",
    deleteChapter: "Chapter deleted successfully",
    editLesson: "Lesson updated successfully",
    deleteLesson: "Lesson deleted successfully",
  },
  getFileName: (path) => {
    const name = path?.replace(/^.*[\\/]/, "");
    if (!name || name === "null" || name === "undefined") {
      return "";
    } else {
      return name;
    }
  },
};

export const Qtype = {
  mcq: "mcq",
  one_word: "one_word",
  structured_question: "sa",
  filling_in_the_blank: "filling_in_the_blank",
  matching_pair: "matching_pair",
};

export const QuestionTypes = [
  {
    value: "mcq",
    label: "MCQ",
  },
  {
    value: "one_word",
    label: "One word",
  },
  {
    value: "sa",
    label: "Structured question",
  },
  {
    value: "filling_in_the_blank",
    label: "Filling the blank",
  },
  {
    value: "matching_pair",
    label: "Matching pair",
  },
];

export const FilteredQuestionTypes = [
  {
    value: "mcq",
    label: "MCQ",
  },
  {
    value: "one_word",
    label: "One word",
  },
  {
    value: "sa",
    label: "Structured question",
  },
];

export const PaperNo = [
  {
    value: "paper_1",
    label: "Paper 1",
  },
  {
    value: "paper_2",
    label: "paper 2",
  },
  {
    value: "paper_3",
    label: "paper 3",
  },
];

export const PaperLevel = [
  {
    value: "foundation",
    label: "Foundation",
  },
  {
    value: "higher",
    label: "Higher",
  },
];

export const Level = [
  {
    value: "KS- 1",
    label: "KS- 1",
  },
  {
    value: "KS- 2",
    label: "KS- 2",
  },
  {
    value: "KS- 3",
    label: "KS- 3",
  },
  {
    value: "GCSE",
    label: "GCSE",
  },
];

export const Season = [
  {
    value: "winter",
    label: "Winter",
  },
  {
    value: "summer",
    label: "Summer",
  },
  {
    value: "spring",
    label: "Spring",
  },
  {
    value: "autumn",
    label: "Autumn",
  },
];

export const Month = [
  {
    value: "january",
    label: "January",
  },
  {
    value: "february",
    label: "February",
  },
  {
    value: "march",
    label: "March",
  },
  {
    value: "april",
    label: "April",
  },
  {
    value: "april",
    label: "April",
  },
  {
    value: "may",
    label: "May",
  },
  {
    value: "june",
    label: "June",
  },

  {
    value: "july",
    label: "July",
  },
  {
    value: "august",
    label: "August",
  },
  {
    value: "september",
    label: "September",
  },
  {
    value: "october",
    label: "October",
  },
  {
    value: "november",
    label: "November",
  },
  {
    value: "december",
    label: "December",
  },
];

export const Week = [
  {
    value: "1",
    label: "Week 1",
  },
  {
    value: "2",
    label: "Week 2",
  },
  {
    value: "3",
    label: "Week 3",
  },
  {
    value: "autumn",
    label: "Autumn",
  },
];

export const alphabets = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];

export const challengeBoard = [
  {
    value: "ukbc",
    label: "UKBC",
  },
  {
    value: "bpho",
    label: "BPHO",
  },
  {
    value: "rsoc",
    label: "RSOC",
  },
  {
    value: "ukmt",
    lable: "UKMT",
  },
];

export const section_list = [
  {
    value: "none",
    label: "None",
  },
  {
    value: "section_1",
    label: "Section 1",
  },
  {
    value: "section_2",
    lable: "Section 2",
  },
];
