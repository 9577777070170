import { Col, Row } from "react-bootstrap";
import FormControl from "../shared/FormControl";
import TextInput from "../shared/TextInput";
import { Controller } from "react-hook-form";
import Dropdown from "../shared/Dropdown";
import InputTime from "../shared/InputTime";
import { Qtype, QuestionTypes } from "../../lib/Constant";

const SelftestForm = ({
  selftest,
  register,
  control,
  errors,
  setValue,
  isView,
  isEdit,
}) => {
  const PageControl = ({
    name,
    value,
    labelColClass = "col-4 activity-form",
  }) => {
    return (
      <FormControl labelColClass={labelColClass} label={name}>
        <TextInput disabled value={value} />
      </FormControl>
    );
  };

  return (
    <Row>
      <Col>
        <PageControl name="Country" value={selftest?.chapter?.country?.name} />
        <PageControl
          name="Exam board"
          value={selftest?.chapter?.exam_board?.name}
        />
        <PageControl name="Level" value="kb" />
        <PageControl
          name="Grade"
          value={selftest?.chapter?.years_data?.map((item) => {
            return `${item.name}  `;
          })}
        />
      </Col>
      <Col md={{ offset: 1 }}>
        <PageControl
          labelColClass="col-4"
          name="Subject"
          value={selftest?.chapter?.subject?.name}
        />

        <PageControl
          name="Chapter name"
          value={selftest?.chapter?.name}
          labelColClass="col-4"
        />
        <PageControl
          labelColClass="col-4"
          name="Lesson name"
          value={selftest?.name}
        />
        <FormControl
          labelColClass="col-4"
          label="Question type *"
          errMessage={errors?.answer_type?.message}
        >
          <Controller
            defaultValue={Qtype.mcq}
            isClearable
            name="answer_type"
            control={control}
            rules={{ required: "Please select a question type" }}
            render={({ field: { ref, ...rest } }) => (
              <Dropdown
                autoFocus
                placeholder="Select question"
                {...rest}
                onChange={(value) => {
                  setValue("answer_type", value);
                  // dispatch(resetQuestions());
                }}
                options={[{ value: "mcq", label: "MCQ" }]}
                // isDisabled={true}
                isDisabled={isView || isEdit}
              />
            )}
          />
        </FormControl>

        <FormControl
          labelColClass="col-4"
          errMessage={
            errors?.duration_hours?.message || errors?.duration_minutes?.message
          }
          label="Total duration *"
        >
          <InputTime disabled={isView} register={register} />
        </FormControl>
      </Col>
    </Row>
  );
};

export default SelftestForm;
