import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { pxtoRem } from "../../lib/helper";
import Button from "../../components/shared/Button";
import { resetQuestions } from "../../redux-toolkit/questions";
import { Constant } from "../../lib/Constant";
import { theme } from "../../lib/themeConstants";
import history from "../../lib/history";

const StyledWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.SHADE_ALICE_BLUE};
  padding: ${pxtoRem(60)} ${pxtoRem(120)};
  height: 100vh;
  font-size: ${pxtoRem(40)};
`;

const StyledHeaderRow = styled(Row)`
  margin-bottom: ${pxtoRem(40)};
`;

const StyledContentRow = styled(Row)`
  background-color: ${(props) => props.theme.colors.WHITE};
  text-align: center;
  border-radius: ${pxtoRem(20)};
  /* padding: ${pxtoRem(30)}; */
`;

const CommonBox = styled.div`
  background-color: ${(props) => props.theme.colors.DARK_CYAN};
  color: ${(props) => props.theme.colors.WHITE};
  font-size: ${pxtoRem(20)};
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  text-align: center;
`;

const StyledEditBox = styled(CommonBox)`
  width: ${pxtoRem(60)};
  border-radius: 10px;
  margin-right: ${pxtoRem(16)};
`;

const StyledCloseBox = styled(CommonBox)`
  height: ${pxtoRem(61)};
  width: ${pxtoRem(155)};
  border-radius: 20px;
`;

const StyleButtonCol = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledBrandRow = styled(Row)`
  background-color: ${(props) => props.theme.colors.SHADE_ALICE_BLUE};
  border-radius: ${pxtoRem(20)};
`;
const StyledBrandImage = styled.img`
  width: ${pxtoRem(400)};
  height: ${pxtoRem(154)};
  margin-left: ${pxtoRem(30)};
`;

const StyledSubject = styled.div`
  margin-left: ${pxtoRem(30)};
  font-size: ${pxtoRem(80)};
  width: ${pxtoRem(519)};
  height: ${pxtoRem(266)};
  text-align: left;
`;

const StyledPaperType = styled.div`
  font-size: ${pxtoRem(180)};
  margin-right: ${pxtoRem(200)};
`;

const StyledStartButton = styled(Button)`
  margin-right: ${pxtoRem(100)};
  width: ${pxtoRem(130)};
  height: ${pxtoRem(51)};
`;

const StyledLevel = styled.div`
  margin-left: ${pxtoRem(30)};
  text-align: initial;
  width: ${pxtoRem(449)};
`;

const StyledPaperNo = styled.div`
  text-align: initial;
  margin-left: ${pxtoRem(45)};
`;

const StyledTime = styled.div`
  text-align: end;
  margin-right: ${pxtoRem(30)};
`;

const StyledDivider = styled.div`
  border: ${pxtoRem(6)} solid ${(props) => props.theme.colors.BLACK};
  margin-left: ${pxtoRem(30)};
  margin-right: ${pxtoRem(20)};
`;

const StyledRow = styled(Row)`
  margin: ${pxtoRem(30)} 0px;
`;

const StyledConRow = styled(Row)`
  background-color: ${(props) => props.theme.colors.WHITE};
`;

const InitialPage = ({ handleEdit, setInitialPage }) => {
  return (
    <StyledWrapper>
      <StyledHeaderRow>
        <StyleButtonCol>
          <StyledEditBox
            onClick={() => {
              handleEdit && handleEdit();
            }}
          >
            <img height={20} src={Constant.Icons.edit} alt="edit" />
          </StyledEditBox>
          <StyledCloseBox
            onClick={() => {
              history.back();
            }}
          >
            Close
          </StyledCloseBox>
        </StyleButtonCol>
      </StyledHeaderRow>
      <StyledBrandRow>
        <Col xs={1} md={1} lg={1}>
          <StyledBrandImage src={Constant.Images.Brand} />
        </Col>
      </StyledBrandRow>
      <StyledContentRow>
        <Col>
          <StyledConRow>
            <Col className="d-flex justify-content-between">
              <StyledSubject>GCSE CHEMISTRY</StyledSubject>
              <StyledPaperType>F</StyledPaperType>
            </Col>
          </StyledConRow>
          <StyledRow>
            <Col xs={4} md={4} lg={4}>
              <StyledLevel>Foundation Tier</StyledLevel>
            </Col>
            <Col>
              <StyledPaperNo>Paper 1</StyledPaperNo>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <StyledDivider />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col xs={4} md={4} lg={4}>
              <StyledLevel>Monday 21 June 21</StyledLevel>
            </Col>
            <Col xs={2} md={2} lg={2}>
              <StyledPaperNo>Afternoon</StyledPaperNo>
            </Col>
            <Col>
              <StyledTime>Time allowed: 1 hour 45 minutes</StyledTime>
            </Col>
          </StyledRow>
        </Col>
      </StyledContentRow>
      <Row>
        <Col className="mt-4 mb-4 d-flex justify-content-end">
          <StyledStartButton
            bgColor={theme.colors.DARK_CYAN}
            text={"Start"}
            onClick={() => {
              setInitialPage(false);
            }}
          ></StyledStartButton>
        </Col>
      </Row>
    </StyledWrapper>
  );
};

export default InitialPage;
