import React from "react";
import styled from "styled-components";
import { pxtoRem } from "../../lib/helper";

const StyledContainer = styled.div`
  background-color: white;
  width: 100%;
  padding: ${pxtoRem(40)};
  /* margin-top:${pxtoRem(20)}; */
`;

function ContainerComponent({ children }) {
  return <StyledContainer>{children}</StyledContainer>;
}

export default ContainerComponent;
