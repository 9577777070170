import { useQuery } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useSubjects = () => {
  return useQuery(
    "subjects",
    async () => {
      const { data } = await axiosPost("/api/cw/viewSubjectFilter");
      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

export default useSubjects;
