import { useMutation } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useUpdateLesson = () => {
  return useMutation((lesson) => {
    return axiosPost("/api/cw/updateLesson", lesson);
  });
};

export default useUpdateLesson;
