import { useQuery } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useLesson = (id) => {
  const formData = {};
  if (id) formData.id = id;

  return useQuery(
    ["lesson", id],
    async () => {
      const { data } = await axiosPost("/api/cw/viewLesson", formData);
      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

export default useLesson;
