import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
import SelftestForm from "../../components/form/SelftestForm";
import Button from "../../components/shared/Button";
import ContainerComponent from "../../components/shared/ContainerComponent";
import PageHeader from "../../components/shared/PageHeader";
import SectionHeader from "../../components/shared/SectionHeader";
import { Spinner } from "../../components/shared/Spinner";
import QuestionContainer from "../../components/shared/question/QuestionContainer";
import {
  useCreateSelftest,
  useDeleteSelftest,
  useUpdateSelftestQuestion,
  useViewSelftest,
} from "../../hooks/selftest";
import useSelftestLesson from "../../hooks/selftest/useSelftestLesson";
import { pxtoRem } from "../../lib/helper";
import { theme } from "../../lib/themeConstants";
import { resetQuestions } from "../../redux-toolkit/questions";
import { setFormData } from "../../redux-toolkit/selftest";

const StyledBox = styled(Row)`
  width: 95%;
  padding-left: ${pxtoRem(60)};
  margin-top: ${pxtoRem(40)};
`;

const StyledButton = styled(Button)`
  margin-right: -${pxtoRem(30)};
`;

const CreateSelftest = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const formPages = 2;

  const [pageJump, setPageJump] = useState(1);
  const [newStep, setNewStep] = useState(false);
  const createMutation = useCreateSelftest();
  const updateMutation = useUpdateSelftestQuestion();
  const deleteMutation = useDeleteSelftest();
  const id = location.pathname.split("/")[3];
  const selftestLessonQuery = useSelftestLesson(id);
  const questionsQuery = useViewSelftest(id);
  const navigate = useNavigate();

  const selftest = selftestLessonQuery?.data?.[0];
  const [formStep, setFormStep] = useState(1);
  const self_test_count = useMemo(() => {
    setFormStep(selftest?.self_test_count > 0 ? 2 : 1);
    return selftest?.self_test_count || 0;
  }, [selftest]);

  const getBredCrupData = () => {
    const breadCrumData = [];
    breadCrumData.push(selftest?.chapter?.["exam_board"]?.name);
    breadCrumData.push(selftest?.chapter?.["subject"]?.name);
    if (selftest?.chapter?.years_data?.length > 0) {
      breadCrumData.push(selftest.chapter.years_data[0]?.key_stage?.[0]?.name);
      const data = selftest.chapter.years_data
        .map((item) => {
          return item.name;
        })
        .join(", ");
      breadCrumData.push(data);
    }
    breadCrumData.push(selftest?.chapter?.name);
    breadCrumData.push(selftest?.name);
    return breadCrumData;
  };

  const { formData } = useSelector((state) => {
    return state.selftest;
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitted, isDirty, isValid },
    watch,
    reset,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
  });

  const methods = {
    control,
    register,
    errors,
    handleSubmit,
    watch,
    backPage,
    getValues,
    setValue,
    isDirty,
    isValid,
  };

  function backPage(e, val) {
    const thisStep = formStep - val;
    setPageJump(val);
    setFormStep(thisStep);
    setNewStep(true);
  }

  useEffect(() => {
    if (newStep) {
      setNewStep(false);
      reset(
        {},
        {
          keepValues: true,
          keepDirty: true,
        }
      );
    }
  }, [isSubmitted, reset, newStep]);

  useEffect(() => {
    return () => {
      dispatch(resetQuestions());
      dispatch(setFormData(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!formData && questionsQuery?.data?.length > 0) {
      const obj = questionsQuery?.data;
      setValue("answer_type", obj?.question_type);
      setValue("duration_hours", obj?.duration_hours || 10);
      setValue("duration_minutes", obj?.duration_minutes || 20);
    }
  }, [questionsQuery, formData, setValue]);

  const onSubmit = async (data) => {
    getBredCrupData();
    dispatch(setFormData({ ...formData, ...data }));
    if (formStep < formPages) {
      const thisStep = formStep + pageJump;
      setValue("formStep", thisStep.toString(), { shouldDirty: true });
      setFormStep(thisStep);
      setNewStep(true);
      return false;
    }
    return false;
  };

  const onQuestionSubmit = (formData, passedFunc) => {
    let form_data = getValues();
    formData.append(
      "duration_hours",
      form_data?.duration_hours || questionsQuery?.data?.duration_hours
    );
    formData.append(
      "duration_minutes",
      form_data?.duration_minutes || questionsQuery?.data?.duration_minutes
    );

    const ans = form_data?.answer_type || questionsQuery?.data?.question_type;
    formData.append("answer_type", ans);
    formData.append("question_type", ans);
    formData.append("lesson_id", id);

    if (formData.get("id")) {
      updateMutation.mutate(formData, {
        onError: (res) => {
          toast.error(res?.response?.data?.message);
        },
        onSuccess: (res) => {
          queryClient.invalidateQueries("viewSelfTest");
          toast.success("Question updated successfuly");
          passedFunc && passedFunc();
        },
      });
    } else {
      formData.append("status", "draft");
      createMutation.mutate(formData, {
        onError: (res) => {
          toast.error(res?.response?.data?.message);
        },
        onSuccess: (res) => {
          queryClient.invalidateQueries("viewSelfTest");
          toast.success("Question added successfuly");
          passedFunc && passedFunc();
        },
      });
    }
  };

  const onQuestionDelete = (formData, onDelete) => {
    deleteMutation.mutate(formData, {
      onError: (res) => {
        toast.error(res?.response?.data?.message);
      },
      onSuccess: (res) => {
        queryClient.invalidateQueries("viewSelfTest");
        toast.success("Question deleted successfuly");
        if (questionsQuery?.data?.questions_data?.length === 1) {
          navigate("/selftest");
        } else {
          onDelete();
        }
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {formStep === 1 && (
          <>
            <Spinner
              isLoading={
                selftestLessonQuery.isLoading || createMutation.isLoading
              }
            />
            <PageHeader
              title="Selftest"
              control={
                <Button
                  onClick={() => {
                    navigate("/selftest");
                  }}
                  bgColor={theme.colors.NEBULA_BLUE}
                  text="Back"
                />
              }
            />
            <SectionHeader title="Create new selftest" />
            <ContainerComponent>
              <StyledBox style={{ marginBottom: "2rem" }}>
                <SelftestForm
                  selftest={selftest}
                  register={register}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                />
              </StyledBox>
              <Row>
                <Col style={{ textAlign: "end" }}>
                  <StyledButton
                    type="submit"
                    disabled={isDirty && isValid ? false : true}
                    bgColor={theme.colors.NEBULA_BLUE}
                    text="Next"
                  />
                </Col>
              </Row>
            </ContainerComponent>
          </>
        )}
      </form>
      {formStep === 2 && (
        <>
          <Spinner
            isLoading={
              createMutation.isLoading ||
              updateMutation.isLoading ||
              deleteMutation.isLoading
            }
          />
          {self_test_count > 0 ? (
            <>
              {questionsQuery?.data?.questions_data?.length > 0 ? (
                <QuestionContainer
                  Questions={questionsQuery?.data?.questions_data}
                  onQuestionSubmit={onQuestionSubmit}
                  onQuestionDelete={onQuestionDelete}
                  title="Selftest"
                  type={formData?.answer_type}
                  breadCrumData={getBredCrupData()}
                ></QuestionContainer>
              ) : (
                <Spinner isLoading={true} />
              )}
            </>
          ) : (
            <QuestionContainer
              Questions={questionsQuery?.data?.questions_data}
              onQuestionSubmit={onQuestionSubmit}
              onQuestionDelete={onQuestionDelete}
              title="Selftest"
              type={formData?.answer_type}
              breadCrumData={getBredCrupData()}
            ></QuestionContainer>
          )}
        </>
      )}
    </FormProvider>
  );
};

export default CreateSelftest;
