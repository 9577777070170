import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import Button from "../../components/shared/Button";
import FormCheckBox from "../../components/shared/FormCheckBox";
import FormInput from "../../components/shared/FormInput";
import useLogin from "../../hooks/auth/useLogin";
import { Constant } from "../../lib/Constant";
import { Spinner } from "../../components/shared/Spinner";
import { setUser } from "../../redux-toolkit/auth";
import { pxtoRem } from "../../lib/helper";
import { InputGroup } from "react-bootstrap";

const StyledWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.WHITE};
  display: flex;
  flex-direction: row;
  height: 100vh;
`;

const StyledLeftSection = styled.div`
  padding: ${pxtoRem(72)} 0;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`;

const StyleImageContainer = styled.div`
  position: relative;
`;

const StyledPenIcon = styled.img`
  position: absolute;
  left: 80px;
  width: 138px;
  height: 138px;
  top: -40px;
`;

const StyledLoginIcon = styled.img`
  // height: 100%;
  width: ${pxtoRem(871)};
  height: ${pxtoRem(898)};
  margin-left: -28px;
`;

const StyledRighttSection = styled.div`
  margin: auto;

  p {
    font-size: ${pxtoRem(24)};
  }
`;

const LoginWrapper = styled.div`
  margin-top: 40px;
  width: 420px;
`;

const StyledHeading = styled.h3`
  margin-top: 40px;
  font-size: ${pxtoRem(40)};
`;

const StyledRememberMe = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledForgotPassword = styled.a`
  color: ${(props) => props.theme.colors.BLUE_COLA};
  text-decoration: none;
  &:focus-visible {
    text-decoration: underline;
    outline: none;
  }
`;

const StyledText = styled.p`
  opacity: 0.5;
  font-size: ${pxtoRem(14)} !important;
`;

const StyledIcon = styled.img`
  height: ${pxtoRem(40)};
  width: ${pxtoRem(30)};
  margin-left: -30px !important;
  margin-top: 31px;
  padding-top: ${(props) => props.isCheck && "3px"};
  &:focus-visible {
    box-shadow: 0 0 0 0.25rem
      rgba(${(props) => props.theme.colors.BOX_SHADOW_BG}, 0.5);
    outline: none;
  }
`;

const StyledInputContainer = styled.div`
  flex-basis: 26.2rem;
`;

const Login = () => {
  const { mutate: login, isLoading } = useLogin();
  // const userData = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isTokenFetching, setIsTokenFetching] = useState(true);
  const user = localStorage.getItem("token");
  const [type, setType] = useState("password");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const username = watch("userName");
  const userpass = watch("password");

  useEffect(() => {
    if (user) {
      navigate("/dashboard", { replace: true });
    } else {
      setIsTokenFetching(false);
    }
  }, [user, navigate]);

  const onSubmit = (data) => {
    login(
      {
        user_name: data.userName,
        password: data.password,
      },
      {
        onError: (res) => {
          toast.error(res?.response?.data?.message);
        },
        onSuccess: (res) => {
          localStorage.setItem("token", res.data.token);
          dispatch(setUser(res.data.user));
          navigate("/dashboard");
        },
      }
    );
  };

  return (
    <>
      {isLoading || isTokenFetching ? (
        <Spinner isLoading={isLoading || isTokenFetching} />
      ) : (
        <StyledWrapper>
          <StyledLeftSection>
            <StyleImageContainer>
              <StyledPenIcon src={Constant.Icons.login_icon1} alt="12" />
              <StyledLoginIcon src={Constant.Icons.login_icon2} alt="12" />
            </StyleImageContainer>
          </StyledLeftSection>
          <StyledRighttSection>
            <StyledHeading className="mb-4 fw-bolder">
              Welcome back
            </StyledHeading>
            <p className="mb-0 login-into">Login into your account</p>
            <StyledText>Enter your username and password</StyledText>
            <LoginWrapper>
              <form onSubmit={handleSubmit(onSubmit)}>
                <InputGroup>
                  <StyledInputContainer>
                    <FormInput
                      autoFocus
                      id="userName"
                      style={{ backgroundColor: "#F2F7FF" }}
                      label="Enter username"
                      placeholder="Username"
                      register={register}
                      validation={{ required: "Please enter username" }}
                      errMessage={errors?.userName?.message}
                    />
                  </StyledInputContainer>
                  {username && (
                    <StyledIcon
                      src={Constant.Icons.check}
                      isCheck={true}
                      tabIndex={0}
                      hasError={errors["userName"]}
                      alt="username"
                    />
                  )}
                </InputGroup>
                <InputGroup>
                  <StyledInputContainer>
                    <FormInput
                      id="password"
                      style={{ backgroundColor: "#F2F7FF" }}
                      label="Password"
                      placeholder="Password"
                      register={register}
                      type={type}
                      validation={{ required: "Please enter password" }}
                      errMessage={errors?.password?.message}
                    />
                  </StyledInputContainer>

                  {userpass && (
                    <StyledIcon
                      tabIndex={0}
                      src={Constant.Icons.eye}
                      hasError={errors["password"]}
                      onClick={() => {
                        type === "password"
                          ? setType("text")
                          : setType("password");
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          type === "password"
                            ? setType("text")
                            : setType("password");
                        }
                      }}
                      alt="password"
                    />
                  )}
                </InputGroup>
                <StyledRememberMe>
                  <FormCheckBox
                    register={register}
                    id="rememberMe"
                    label="Remember me"
                  />
                  <StyledForgotPassword href="#forgotpassword">
                    Forgot password?
                  </StyledForgotPassword>
                </StyledRememberMe>

                <Button
                  className="w-100"
                  bgColor="#007B85 "
                  type="submit"
                  text="LOG IN"
                />
              </form>
            </LoginWrapper>
          </StyledRighttSection>
        </StyledWrapper>
      )}
    </>
  );
};

export default Login;
