import React, { useEffect, useState } from "react";
import PageHeader from "../../components/shared/PageHeader";
import SectionHeader from "../../components/shared/SectionHeader";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { pxtoRem } from "../../lib/helper";
import FormControl from "../../components/shared/FormControl";
import TextInput from "../../components/shared/TextInput";
import { useProfile } from "../../hooks/auth";
import { Spinner } from "../../components/shared/Spinner";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledContainer = styled.div`
  background-color: white;
  width: 100%;
  padding: ${pxtoRem(40)};
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin-bottom: ${pxtoRem(15)};
`;

function Profile() {
  const { mutate: getProfile, isLoading } = useProfile();
  const [profileData, setProfileData] = useState(null);
  const { register, setValue } = useForm();

  useEffect(() => {
    getProfile(
      {},
      {
        refetchOnWindowFocus: false,
        onError: (res) => {
          toast.error(res?.response?.data?.message);
        },
        onSuccess: (res) => {
          setProfileData(res.data);
        },
      }
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setValue("firstName", profileData?.first_name);
    setValue("lastName", profileData?.last_name);
    setValue("phone", profileData?.phone);
    setValue("email", profileData?.email);
  }, [profileData, setValue]);
  return (
    <>
      <Spinner isLoading={isLoading} />
      <MainContainer>
        <PageHeader title={"Welcome !"}></PageHeader>
        <SectionHeader title="My Details" />
        <StyledContainer>
          <form>
            <Row>
              <Col>
                <Row>
                  <Col md={3} lg={3}>
                    <FormControl isInline={false} label={"First name"}>
                      <TextInput disabled register={register} id="firstName" />
                    </FormControl>
                    <FormControl isInline={false} label={"Phone"}>
                      <TextInput disabled register={register} id="phone" />
                    </FormControl>
                  </Col>
                  <Col md={{ span: 3, offset: 3 }}>
                    <FormControl isInline={false} label={"Last name"}>
                      <TextInput disabled register={register} id="lastName" />
                    </FormControl>
                    <FormControl isInline={false} label={"Email"}>
                      <TextInput disabled register={register} id="email" />
                    </FormControl>
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
        </StyledContainer>
      </MainContainer>
    </>
  );
}

export default Profile;
