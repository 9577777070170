import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filter: {
    country: null,
    challenge_board: null,
    subject: null,
    grade: null,
    year: null,
  },
  formData: null,
};

export const challengSlice = createSlice({
  name: "challenge",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFilter, setFormData } = challengSlice.actions;

export default challengSlice.reducer;
