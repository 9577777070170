import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./redux-toolkit/auth";
import tableReducer from "./redux-toolkit/table";
import chaptersReducer from "./redux-toolkit/chapters";
import chapterPaperReducer from "./redux-toolkit/chapterPaper";
import mockTestReducer from "./redux-toolkit/mockTest";
import worksheetReducer from "./redux-toolkit/worksheet";
import selftestReducer from "./redux-toolkit/selftest";
import parentActivityReducer from "./redux-toolkit/parentActivity";
import storage from "redux-persist/lib/storage";
import questionsReducer from "./redux-toolkit/questions";
import pastPaperReducer from "./redux-toolkit/pastPaper";
import curriculum from "./redux-toolkit/curriculum";
import challengeReducer from "./redux-toolkit/challenges";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

const persistConfig = {
  key: "root",
  storage,
};

const appReducer = combineReducers({
  auth: authReducer,
  table: tableReducer,
  chapters: chaptersReducer,
  chapterPaper: chapterPaperReducer,
  mockTest: mockTestReducer,
  curriculum: curriculum,
  worksheet: worksheetReducer,
  selftest: selftestReducer,
  questions: questionsReducer,
  pastPaper: pastPaperReducer,
  challenges: challengeReducer,
  parentActivity: parentActivityReducer,
});
const rootReducer = (state, action) => {
  if (action.type === "DESTROY_SESSION") {
    state = undefined;
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export const persistor = persistStore(store);

export const resetStore = async () => {
  store.dispatch({ type: "DESTROY_SESSION" });
  await persistor.flush();
};
