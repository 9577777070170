import React from "react";
import Select, { components } from "react-select";
import styled from "styled-components";
import { Constant } from "../../lib/Constant";
import { theme } from "../../lib/themeConstants";
import { pxtoRem } from "../../lib/helper";
import DropdownCheckbox from "./DropdownCheckbox";

export const StyledAutoCompleteDropdownIndicator = styled.div`
  width: ${pxtoRem(35)};
  img {
    height: ${pxtoRem(7)};
    width: ${pxtoRem(12)};
    transform: ${(props) => props.isUpside && "rotate(-180deg)"};
    /* margin-right: ${(props) =>
      props.isUpside ? (props.isDropdown ? "24px" : "25px") : ""}; */
    filter: ${(props) =>
      props.isUpside &&
      props.isDropdown &&
      "invert(100%) sepia(100%) saturate(0%) hue-rotate(298deg) brightness(102%) contrast(102%)"};
  }
`;

const StyledCheckbox = styled(DropdownCheckbox)`
  height: ${pxtoRem(22)};
  width: ${pxtoRem(22)};
  border: 1px solid ${(props) => props.theme.DropDownColors.LIGHT_BLUE};
  border-radius: ${pxtoRem(2)};
`;

function MultipleDropdown({
  options,
  value = [],
  onChange,
  placeholder,
  ...rest
}) {
  const DropdownStyle = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      width: "auto",
      height: "inherit",
      borderRadius: pxtoRem(10),
      backgroundColor: state.selectProps.menuIsOpen
        ? `${theme.colors.DEEP_SKY_BLUE}`
        : `${theme.colors.WHITE}`,
      borderColor: state.selectProps.menuIsOpen
        ? `${theme.DropDownColors.LIGHT_BLUE}`
        : `${theme.DropDownColors.LIGHT_GREY}`,
      fontSize: pxtoRem(20),
      "&:hover": {
        ...baseStyles[":hover"],
        border:
          state.selectProps.menuIsOpen &&
          `1px solid ${theme.DropDownColors.LIGHT_BLUE}`,
      },
      zIndex: 10,
      "*": {
        boxShadow: "none !important",
      },
      boxShadow: "none",
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: pxtoRem(20),
      backgroundColor: state.isSelected
        ? `${theme.colors.DEEP_SKY_BLUE}`
        : state.backgroundColor,
      paddingLeft: "20px",
      "&:hover": {
        backgroundColor: state.isSelected
          ? `${theme.colors.DEEP_SKY_BLUE}`
          : `${theme.DropDownColors.SKY_BLUE}`,
      },
    }),
    menu: (baseStyles, state) => ({
      ...baseStyles,
      // width: "349px",
      marginTop: "-5px",
      paddingTop: "0px",
      border: `1px solid ${theme.DropDownColors.LIGHT_BLUE}`,
      borderRadius: pxtoRem(10),
      borderTopLeftRadius: "0px",
      borderTopRightRadius: "0px",
      zIndex: "100",
    }),
    input: (styles, state) => ({
      ...styles,
      marginLeft: pxtoRem(10),
      color: state.selectProps.menuIsOpen
        ? `${theme.colors.WHITE}`
        : `${theme.DropDownColors.MEDIUM_GREY}`,
    }),
    placeholder: (styles, state) => ({
      ...styles,
      marginLeft: pxtoRem(10),
      color: state.selectProps.menuIsOpen
        ? `${theme.colors.WHITE}`
        : `${theme.DropDownColors.MEDIUM_GREY}`,
    }),
    singleValue: (styles, state) => ({
      ...styles,
      marginLeft: pxtoRem(10),
      color: state.selectProps.menuIsOpen
        ? `${theme.colors.WHITE}`
        : `${theme.DropDownColors.MEDIUM_GREY}`,
    }),
  };

  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    // styles
    let bg = "transparent";
    if (isFocused) bg = theme.DropDownColors.LIGHT_BLUE;

    const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex ",
    };

    // prop assignment
    const props = {
      ...innerProps,
      style,
    };

    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <div className="w-100 d-flex justify-content-between">
          {children}
          <StyledCheckbox
            type="checkbox"
            checked={isSelected}
            onChange={innerProps.onClick}
          />
        </div>
      </components.Option>
    );
  };
  return (
    <Select
      options={options}
      isMulti
      isClearable={false}
      styles={DropdownStyle}
      placeholder={placeholder}
      isSearchable={false}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      components={{
        Option: InputOption,
        IndicatorSeparator: () => null,
        DropdownIndicator: (state) => (
          <StyledAutoCompleteDropdownIndicator
            isUpside={state.selectProps.menuIsOpen}
            isDropdown={true}
          >
            <img
              width={12}
              height={7}
              src={Constant.Icons.downarrow}
              alt="filter"
            />
          </StyledAutoCompleteDropdownIndicator>
        ),
      }}
      value={options.filter((item) => value?.includes(item.value))}
      onChange={(options) => {
        if (Array.isArray(options)) {
          onChange(options.map((opt) => opt.value));
        }
      }}
      {...rest}
      classNamePrefix="Autocomplete"
    />
  );
}

export default MultipleDropdown;
