import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router";
import Nisha from "./pages/nisha";
import BharatComponents from "./pages/bharat";
import Dashboard from "./pages/Dashboard";
import Chapters from "./pages/chapter/index";
import Lessons from "./pages/lesson/index";
import DashboardLayout from "./layout";
import Login from "./pages/auth/Login";
import AddChapter from "./pages/chapter/AddChapter";
import ViewChapter from "./pages/chapter/ViewChapter";
import Profile from "./pages/profile/index";
import CreateLesson from "./pages/lesson/CreateLesson";
import ViewLesson from "./pages/lesson/ViewLesson";
import EditLesson from "./pages/lesson/EditLesson";
import EditChapter from "./pages/chapter/EditChapter";
import NotFound from "./pages/notfound";
import Worksheet from "./pages/worksheet";
import AddWorksheet from "./pages/worksheet/AddWorksheet";
import Selftest from "./pages/selftest";
import CreateSelftest from "./pages/selftest/createSelftest";
import ChapterPaper from "./pages/chapterPaper";
import Mocktest from "./pages/mockTest";
import AddMockTest from "./pages/mockTest/AddMockTest";
import ParentActivity from "./pages/parentActivity";
import CreateParentActivity from "./pages/parentActivity/createParentActivity";
import SeasonActivity from "./pages/seasonActivity";
import CreateSeasonActivity from "./pages/seasonActivity/CreateSeasonActivity";
import PastPaper from "./pages/pastPaper";
import AddPastPaper from "./pages/pastPaper/AddPastPaper";
import ViewParentActivity from "./pages/parentActivity/ViewParentActivity";
import Challenges from "./pages/challenges";
import AddChallenge from "./pages/challenges/AddChallenge";
import ViewSelftest from "./pages/selftest/ViewSelftest";

import ViewWorksheet from "./pages/worksheet/ViewWorksheet";
import ViewMockTest from "./pages/mockTest/viewMockTest";
import ViewChapterPaper from "./pages/chapterPaper/viewChapterPaper";
import Curriculum from "./pages/curriculum";
import CreateSyllabus from "./pages/curriculum/createSyllabus";
import ViewPastPaper from "./pages/pastPaper/viewPastPaper";

const PrivateRoutes = () => {
  let auth = { token: localStorage.getItem("token") };
  return auth.token ? <Outlet /> : <Navigate to="/login" />;
};

function Router() {
  let token = localStorage.getItem("token");
  return (
    <Routes>
      <Route path="/nisha" element={<Nisha />} />
      <Route path="/bharat" element={<BharatComponents />}></Route>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route element={token ? <DashboardLayout /> : <Outlet />}>
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route element={<PrivateRoutes />}>
        <Route
          path="selftest/view/:lesson_id"
          element={<ViewSelftest />}
        ></Route>
        <Route
          path="worksheet/view/:worksheet_id"
          element={<ViewWorksheet />}
        ></Route>
        <Route
          path="mock-test/view/:lesson_id"
          element={<ViewMockTest />}
        ></Route>
        <Route
          path="chapter-paper/view/:chapter_id"
          element={<ViewChapterPaper />}
        ></Route>
        <Route path="past-paper/view/:id" element={<ViewPastPaper />}></Route>
        <Route path="dashboard" element={<DashboardLayout />}>
          <Route index element={<Dashboard />} />
        </Route>
        <Route path="chapters" element={<DashboardLayout />}>
          <Route index element={<Chapters />}></Route>
          <Route path=":chapterId/lessons" element={<Lessons />}></Route>
          <Route
            path=":chapterId/lessons/:lessonId"
            element={<EditLesson />}
          ></Route>
          <Route
            path=":chapterId/lessons/create"
            element={<CreateLesson />}
          ></Route>
          <Route
            path=":chapterId/lessons/:lessonId/show"
            element={<ViewLesson />}
          ></Route>
          <Route path="create" element={<AddChapter />} />
          <Route path=":chapterId" element={<EditChapter />}></Route>
          <Route path=":chapterId/show" element={<ViewChapter />}></Route>
        </Route>
        <Route path="profile" element={<DashboardLayout />}>
          <Route index element={<Profile />}></Route>
        </Route>
        <Route path="lessons" element={<DashboardLayout />}>
          <Route index element={<CreateLesson />}></Route>
          <Route path="create" element={<CreateLesson />} />
          <Route path="show" element={<ViewLesson />} />
          <Route path=":id" element={<EditLesson />} />
        </Route>
        <Route path="selftest" element={<DashboardLayout />}>
          <Route index element={<Selftest />}></Route>
          <Route path="create/:lessonId" element={<CreateSelftest />}></Route>
        </Route>
        <Route path="worksheet" element={<DashboardLayout />}>
          <Route index element={<Worksheet />}></Route>
          <Route path="create" element={<AddWorksheet />}></Route>
        </Route>
        <Route path="past-paper" element={<DashboardLayout />}>
          <Route index element={<PastPaper />}></Route>
          <Route path="create" element={<AddPastPaper />}></Route>
        </Route>
        <Route path="challenges" element={<DashboardLayout />}>
          <Route index element={<Challenges />}></Route>
          <Route path="create" element={<AddChallenge />}></Route>
        </Route>
        <Route path="parent-activity" element={<DashboardLayout />}>
          <Route index element={<ParentActivity />}></Route>
          <Route path="create" element={<CreateParentActivity />}></Route>
          <Route path="view" element={<ViewParentActivity />}></Route>
        </Route>
        <Route path="season-activity" element={<DashboardLayout />}>
          <Route index element={<SeasonActivity />}></Route>
          <Route path="create" element={<CreateSeasonActivity />}></Route>
        </Route>
        <Route path="chapter-paper" element={<DashboardLayout />}>
          <Route index element={<ChapterPaper />}></Route>
        </Route>
        <Route path="mock-test" element={<DashboardLayout />}>
          <Route index element={<Mocktest />}></Route>
          <Route path="create" element={<AddMockTest />} />
        </Route>
        <Route path="curriculum" element={<DashboardLayout />}>
          <Route index element={<Curriculum />} />
          <Route path="create" element={<CreateSyllabus />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default Router;
