import { useQuery } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useMockTest = (
  country_id,
  exam_board_id,
  subject_id,
  grade_id,
  test_type = "mock_test",
  page
) => {
  const formData = { test_type };
  if (country_id) formData.country_id = country_id;
  if (exam_board_id) formData.exam_board_id = exam_board_id;
  if (subject_id) formData.subject_id = subject_id;
  if (grade_id) formData.year_id = grade_id;
  if (page) {
    formData.pagination = true;
    formData.page = page;
  }

  return useQuery(
    [
      "mockTest",
      country_id,
      exam_board_id,
      subject_id,
      grade_id,
      test_type,
      page,
    ],
    async () => {
      const { data } = await axiosPost("/api/cw/viewQuestion", formData);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: Infinity,
    }
  );
};

export default useMockTest;
