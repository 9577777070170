import { Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/shared/Button";
import ColorPicker from "../../components/shared/ColorPicker";
import Dropdown from "../../components/shared/Dropdown";
import FileUpload from "../../components/shared/FileUpload";
import FormControl from "../../components/shared/FormControl";
import MultipleDropdown from "../../components/shared/MultipleDropdown";
import SectionHeader from "../../components/shared/SectionHeader";
import { Spinner } from "../../components/shared/Spinner";
import TextInput from "../../components/shared/TextInput";
import {
  useCountries,
  useExamBoards,
  useSubjects,
  useYears,
} from "../../hooks/chapter";
import { Constant } from "../../lib/Constant";
import { convertBase64, pxtoRem } from "../../lib/helper";
import { theme } from "../../lib/themeConstants";
import ContainerComponent from "../shared/ContainerComponent";
import { useEffect } from "react";

const ChapterForm = ({
  title,
  handleFormSubmit,
  isLoading,
  defaultValue = null,
  handleDelete,
}) => {
  const isEdit = defaultValue?.id ? true : false;
  const {
    control,
    getValues,
    setValue,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues: {
      ...defaultValue,
      country_id:
        defaultValue?.country_id && parseInt(defaultValue?.country_id),
      exam_board_id:
        defaultValue?.exam_board_id && parseInt(defaultValue?.exam_board_id),
      subject_id:
        defaultValue?.subject_id && parseInt(defaultValue?.subject_id),
      imageFile: isEdit && defaultValue?.image,
      year_id: defaultValue?.year_id?.map((item) => parseInt(item)) || [],
    },
    mode: "onChange",
  });

  const { data: lstCountries } = useCountries();
  const { data: lstGrades } = useYears(
    watch("country_id"),
    watch("subject_id")
  );
  const { data: lstExamBoards } = useExamBoards(watch("country_id"));
  const { data: lstSubjects } = useSubjects();
  const navigate = useNavigate();

  const StyledDeleteIcon = styled.img`
    cursor: pointer;
    width: ${pxtoRem(26)};
    height: ${pxtoRem(34)};
  `;

  useEffect(() => {
    reset({
      ...defaultValue,
      country_id:
        defaultValue?.country_id && parseInt(defaultValue?.country_id),
      exam_board_id:
        defaultValue?.exam_board_id && parseInt(defaultValue?.exam_board_id),
      subject_id:
        defaultValue?.subject_id && parseInt(defaultValue?.subject_id),
      imageFile: isEdit && defaultValue?.image,
      year_id: defaultValue?.year_id?.map((item) => parseInt(item)) || [],
    });
  }, [reset, defaultValue]);

  const onSubmit = async (data, e) => {
    const formData = new FormData();
    formData.set("country_id", data.country_id);
    formData.set("exam_board_id", data.exam_board_id);
    data.year_id.forEach((element, index) => {
      formData.append(`year_id[${index}]`, element);
    });

    formData.set("subject_id", data.subject_id);
    formData.set("number", data.number);
    formData.set("name", data.name);

    if (typeof data?.image !== "string" && data?.image?.length > 0) {
      const imageBase64 = await convertBase64(data?.image?.[0]);
      formData.append("image", imageBase64);
    }
    formData.set("color", data.color);
    handleFormSubmit(formData);
  };

  return (
    <>
      <Spinner isLoading={isLoading} />
      {!isEdit && (
        <div className="mb-2" style={{ textAlign: "end" }}>
          <Button
            onClick={() => {
              navigate("/chapters");
            }}
            bgColor={theme.colors.NEBULA_BLUE}
            text="Back"
          />
        </div>
      )}
      <SectionHeader
        title={title}
        control={
          isEdit && (
            <StyledDeleteIcon
              onClick={handleDelete}
              src={Constant.Icons.delete}
              alt="delete"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleDelete();
                }
              }}
            />
          )
        }
      />

      <ContainerComponent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row style={{ marginBottom: "5rem" }}>
            <Col>
              <FormControl
                label="Select country *"
                errMessage={errors?.country_id?.message}
              >
                <Controller
                  isClearable
                  name="country_id"
                  control={control}
                  rules={{ required: "Please select a country" }}
                  render={({ field: { ref, ...rest } }) => (
                    <Dropdown
                      autoFocus
                      placeholder="Select country"
                      {...rest}
                      options={
                        lstCountries?.map((item) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        }) || []
                      }
                      onChange={(val) => {
                        setValue("country_id", val);
                        setValue("exam_board_id", null);
                        setValue("year_id", null);
                      }}
                    />
                  )}
                />
              </FormControl>

              <FormControl
                label="Select exam board *"
                errMessage={errors?.exam_board_id?.message}
              >
                <Controller
                  isClearable
                  name="exam_board_id"
                  control={control}
                  rules={{ required: "Please Select exam board" }}
                  render={({ field: { ref, ...rest } }) => (
                    <Dropdown
                      placeholder="Select exam board"
                      {...rest}
                      options={
                        lstExamBoards?.map((item) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        }) || []
                      }
                    />
                  )}
                />
              </FormControl>

              <FormControl
                label="Select subject *"
                errMessage={errors?.subject_id?.message}
              >
                <Controller
                  isClearable
                  name="subject_id"
                  tabIndex={0}
                  rules={{ required: "Please Select subject" }}
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <Dropdown
                      placeholder="Select subject"
                      {...rest}
                      options={
                        lstSubjects?.map((item) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        }) || []
                      }
                      onChange={(val) => {
                        setValue("subject_id", val);
                        setValue("year_id", null);
                      }}
                    />
                  )}
                />
              </FormControl>

              <FormControl
                label="Select grade *"
                errMessage={errors?.year_id?.message}
              >
                <Controller
                  isClearable
                  name="year_id"
                  rules={{ required: "Please Select grade" }}
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <MultipleDropdown
                      placeholder="Select grade"
                      {...rest}
                      options={
                        lstGrades?.map((item) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        }) || []
                      }
                    />
                  )}
                />
              </FormControl>
            </Col>
            <Col md={{ offset: 1 }}>
              <FormControl
                label="Chapter number *"
                errMessage={errors?.number?.message}
              >
                <TextInput
                  id="number"
                  type="number"
                  placeholder="Chapter number"
                  register={register}
                  validation={{
                    required: "Please enter chapter number",
                    min: {
                      value: 1,
                      message: "Value can not be less than 1",
                    },
                  }}
                />
              </FormControl>
              <FormControl
                label="Chapter name *"
                errMessage={errors?.name?.message}
              >
                <TextInput
                  id="name"
                  placeholder="Chapter name"
                  register={register}
                  validation={{ required: "Please enter chapter name" }}
                />
              </FormControl>

              <FormControl
                label="Select chapter icon *"
                errMessage={errors?.imageFile?.message}
              >
                <TextInput
                  style={{ display: "none" }}
                  id="imageFile"
                  register={register}
                  validation={{ required: "Please select chapter icon" }}
                />
                <FileUpload
                  file={defaultValue?.image}
                  accept=".JPG,.JPEG,.PNG"
                  id="image"
                  register={register}
                  handleChange={(e) => {
                    setValue("imageFile", e?.target?.files?.[0]?.name, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                  }}
                />
              </FormControl>
              <FormControl
                label="Chapter color code *"
                errMessage={errors?.color?.message}
              >
                <ColorPicker
                  placeholder="select chapter color"
                  color={getValues("color")}
                  onChange={(data) => {
                    setValue("color", data, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                  }}
                  validation={{
                    required: "Please select chapter color code",
                    pattern: {
                      value: /^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/,
                      message: "Invalid color code",
                    },
                  }}
                  id="color"
                  register={register}
                />
              </FormControl>
            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: "end" }}>
              <Button
                onClick={() => {
                  if (isEdit) {
                    navigate(`/chapters/${defaultValue?.id}/show`, {
                      state: { id: defaultValue.id },
                    });
                  } else {
                    navigate("/chapters");
                  }
                }}
                className="me-3"
                color={theme.colors.LIGHT_GREY}
                bgColor={theme.colors.WHITE}
                variant="inverse"
                text="Cancel"
              />

              <Button
                type="submit"
                bgColor="#5A5EEE"
                disabled={isDirty && isValid ? false : true}
                text={isEdit ? "Update" : "Save"}
              />
            </Col>
          </Row>
        </form>
      </ContainerComponent>
    </>
  );
};

export default ChapterForm;
