import React, { useEffect } from "react";
import {
  useCountries,
  useExamBoards,
  useSubjects,
  useYears,
} from "../../hooks/chapter";
import { Spinner } from "../../components/shared/Spinner";
import PageHeader from "../../components/shared/PageHeader";
import ContainerComponent from "../../components/shared/ContainerComponent";
import { Badge, Col, Row } from "react-bootstrap";
import Autocomplete from "../../components/shared/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "../../redux-toolkit/chapterPaper";
import { setPageChange } from "../../redux-toolkit/table";
import useChapters from "../../hooks/chapter/useChapters";
import useChapterPaper from "../../hooks/chapterPaper/useChapterPaper";
import { pxtoRem } from "../../lib/helper";
import styled from "styled-components";
import Button from "../../components/shared/Button";
import { theme } from "../../lib/themeConstants";
import { useNavigate } from "react-router-dom";
import Table from "../../components/shared/Table";
import Empty from "../../components/shared/Empty";
import { chapter_paper_data } from "../../lib/dummyData";
import { toast } from "react-toastify";

const StyledChapterName = styled.div`
  margin-left: ${pxtoRem(40)};
`;

const StyledChapterNameContainer = styled.div`
  display: flex;
  padding-left: ${pxtoRem(20)};
  padding-right: ${pxtoRem(20)};
  align-items: center;
`;

const StyledRoundIcon = styled.div`
  height: ${pxtoRem(60)};
  width: ${pxtoRem(60)};
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.NEBULA_BLUE};
  opacity: 0.5;
  flex: 0 0 ${pxtoRem(60)};
`;

const StyledBadge = styled(Badge)`
  background-color: ${(props) => props.color} !important;
  display: flex;
  width: ${pxtoRem(146)};
  height: ${pxtoRem(46)};
  padding-top: ${pxtoRem(13)};
  font-size: ${pxtoRem(20)};
  border-radius: ${pxtoRem(10)};
  font-weight: 100;
`;

const StyledButton = styled(Button)`
  height: ${pxtoRem(46)};
  width: ${pxtoRem(67)};
  font-size: ${pxtoRem(20)};
  /* line-height: ${pxtoRem(18)}; */
  padding: 0;
`;

const ChapterPaper = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentPage = useSelector((state) => state.table.page);

  const { country, examboard, subject, grade, chapter } = useSelector(
    (state) => state.chapterPaper?.filter
  );

  const {
    data: chapterPapers,
    isFetching,
    error,
  } = useChapterPaper(
    country?.value,
    examboard?.value,
    subject?.value,
    grade?.value,
    chapter?.value,
    currentPage
  );

  const { data: countriesData, isFetching: countriesLoading } = useCountries();
  const { data: examboardData, isFetching: examLoading } = useExamBoards();
  const { data: subjectsData, isFetching: subjectsLoading } = useSubjects();
  const { data: gradesData, isFetching: gradesLoading } = useYears();

  useEffect(() => {
    if (error) {
      toast.error(error?.response?.data?.message);
    }
    return () => {
      dispatch(setPageChange(1));
    };
  }, [dispatch, error]);

  const formatFilterOptions = (data) => {
    let formattedData = data?.map((item) => {
      return { value: item.id, label: item.name };
    });
    return formattedData;
  };

  const onFilterChange = (key, value) => {
    let data = { country, examboard, subject, grade };
    data[key] = value;
    dispatch(setFilter(data));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Chapter name",
        accessor: "name",
        customWidth: 30,
        Cell: ({ row }) => (
          <StyledChapterNameContainer>
            <StyledRoundIcon />
            <StyledChapterName> {row?.original?.name}</StyledChapterName>
          </StyledChapterNameContainer>
        ),
      },
      {
        Header: "Subject",

        accessor: "subject",
        Cell: ({ row }) => (
          <StyledBadge
            color={
              row?.original?.subject?.color && row?.original?.subject?.color
            }
            text="light"
          >
            {row?.original?.subject?.name && row.original.subject.name}
          </StyledBadge>
        ),
      },
      {
        Header: "Grade",
        accessor: "year",
        Cell: ({ row }) => <span>{row?.original?.year?.name}</span>,
      },
      {
        Header: "Exam board",
        accessor: "exam_board",
        Cell: ({ row }) => <span>{row?.original?.exam_board?.name}</span>,
      },
      {
        Header: "Action",
        className: "text-start align-middle pr-1",
        headerClassName: "text-start align-middle pr-1 pb-3",
        Cell: ({ row }) => (
          <StyledButton
            text="View"
            onClick={() => navigate(`view/${row?.original?.id}`)}
            bgColor={theme.colors.NEBULA_BLUE}
          />
        ),
      },
    ],
    []
  );

  return (
    <>
      <Spinner
        isLoading={
          countriesLoading || examLoading || subjectsLoading || gradesLoading
        }
      />

      <PageHeader title={"Chapter Paper"} />
      <ContainerComponent>
        <Row>
          <Col>
            <Autocomplete
              options={formatFilterOptions(countriesData)}
              value={country}
              onChange={(data) => {
                // setSubject(data);
                onFilterChange("country", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Country"
              className="m-2 auto-complete"
            />
          </Col>
          <Col>
            <Autocomplete
              options={formatFilterOptions(examboardData)}
              value={examboard}
              onChange={(data) => {
                // setSubject(data);
                onFilterChange("examboard", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Examboard"
              className="m-2 auto-complete"
            />
          </Col>
          <Col>
            <Autocomplete
              options={formatFilterOptions(subjectsData)}
              value={subject}
              onChange={(data) => {
                // setSubject(data);
                onFilterChange("subject", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Subject"
              className="m-2 auto-complete"
            />
          </Col>

          <Col>
            <Autocomplete
              options={formatFilterOptions(gradesData)}
              value={grade}
              onChange={(data) => {
                // setSubject(data);
                onFilterChange("grade", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Grade"
              className="m-2 auto-complete"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {chapter_paper_data?.data?.length > 0 ? (
              <Table
                columns={columns}
                data={chapter_paper_data?.data}
                defaultPageSize={chapter_paper_data?.per_page}
                totalPages={chapter_paper_data?.last_page}
                currentPage={chapter_paper_data?.current_page}
              ></Table>
            ) : (
              !isFetching && <Empty></Empty>
            )}
          </Col>
        </Row>
      </ContainerComponent>
    </>
  );
};

export default ChapterPaper;
