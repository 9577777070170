import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import {
  ViewLayoutCloseBox,
  ViewLayoutCommonBox,
  ViewLayoutContentRow,
  ViewLayoutEditBox,
  ViewLayoutHeaderRow,
  ViewLayoutWrapper,
} from "../../Style";
import SelftestForm from "../../components/form/SelftestForm";
import Button from "../../components/shared/Button";
import { Spinner } from "../../components/shared/Spinner";
import QuestionContainer from "../../components/shared/question/QuestionContainer";
import QuestionModal from "../../components/shared/question/QuestionModal";
import {
  useCreateSelftest,
  useDeleteSelftest,
  useUpdateSelftest,
  useUpdateSelftestQuestion,
} from "../../hooks/selftest";
import useSelftestLesson from "../../hooks/selftest/useSelftestLesson";
import useViewSelftest from "../../hooks/selftest/useViewSelfTest";
import ViewDemo from "../../layout/ViewDemo";
import { Constant, Qtype } from "../../lib/Constant";
import { pxtoRem } from "../../lib/helper";
import { theme } from "../../lib/themeConstants";

const StyleButtonCol = styled(Col)`
  display: flex;
  flex-direction: row;
  text-align: end;
  justify-content: flex-end;
`;

const StyledStartBtn = styled(ViewLayoutCommonBox)`
  height: ${pxtoRem(45)};
  width: ${pxtoRem(120)};
  border-radius: 4px;
  margin-top: ${pxtoRem(60)};
  margin-bottom: ${pxtoRem(40)};
`;

const StyledContentRow = styled(Row)`
  text-align: left;
  padding-top: ${pxtoRem(100)};
  padding-left: ${pxtoRem(120)};
  padding-right: ${pxtoRem(80)};
`;

const StyledHeading = styled.h6`
  margin-left: ${pxtoRem(40)};
  margin-bottom: 0px;
`;

const StyledModelForm = styled.div`
  margin-bottom: ${pxtoRem(50)};
  padding: ${pxtoRem(20)};
  padding-left: ${pxtoRem(100)};
  padding-right: ${pxtoRem(140)};
`;

const UpdateButton = styled(Button)`
  position: absolute;
  right: ${pxtoRem(60)};
  bottom: ${pxtoRem(40)};
`;

const ViewSelftest = () => {
  const queryClient = useQueryClient();
  const createMutation = useCreateSelftest();
  const updateMutation = useUpdateSelftest();
  const deleteMutation = useDeleteSelftest();
  const updateQuestionMutation = useUpdateSelftestQuestion();
  let location = useLocation();
  const lesson_id = location.pathname.split("/")[3];
  const selftestQuery = useViewSelftest(lesson_id);
  const [show, setShow] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isEditQues, setIsEditQues] = useState(false);
  const navigate = useNavigate();

  const selftestLessonQuery = useSelftestLesson(lesson_id);
  const selftestQuestionQuery = useViewSelftest(lesson_id);
  const selftest = selftestLessonQuery?.data?.[0];
  const selftestQuestion = selftestQuestionQuery?.data;

  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    const formData = getValues();
    if (!formData?.duration_hours && selftestQuestion) {
      reset({
        duration_hours: selftestQuestion?.duration_hours,
        duration_minutes: selftestQuestion?.duration_minutes,
        answer_type: selftestQuestion?.question_type || Qtype.mcq,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selftestQuestion, getValues, setValue]);

  useEffect(() => {
    const count = parseInt(selftest?.self_test_count || 0);
    if (selftest && count === 0) {
      navigate("/selftest");
    }
  }, [selftest, navigate]);

  if (selftestQuery.isLoading) {
    return <Spinner isLoading={true} />;
  }

  const onQuestionDelete = (formData) => {
    deleteMutation.mutate(formData, {
      onError: (res) => {
        toast.error(res?.response?.data?.message);
      },
      onSuccess: () => {
        if (selftestQuery?.data?.questions_data?.length === 1) {
          navigate("/selftest");
        } else {
          queryClient.invalidateQueries("viewSelfTest");
        }
        toast.success("question deleted successfuly");
        setShow(false);
      },
    });
  };

  const onDetailSubmit = (event) => {
    const formData = new FormData();

    formData.append("answer_type", event.answer_type);
    formData.append("duration_hours", event.duration_hours);
    formData.append("duration_minutes", event.duration_minutes);
    formData.append("id", selftestQuestion.id);

    updateMutation.mutate(formData, {
      onError: (res) => {
        toast.error(res?.response?.data?.message);
      },
      onSuccess: (res) => {
        queryClient.invalidateQueries("viewSelfTest", {
          lesson_id: lesson_id,
        });
        setIsEditQues(false);
        toast.success("Details updated successfuly");
      },
    });
  };

  const onQuestionSubmit = (formData) => {
    formData.append("lesson_id", lesson_id);
    if (formData.get("id")) {
      formData.append("status", selftestQuestion?.status);
      updateQuestionMutation.mutate(formData, {
        onError: (res) => {
          toast.error(res?.response?.data?.message);
        },
        onSuccess: (res) => {
          queryClient.invalidateQueries("viewSelfTest", {
            lesson_id: lesson_id,
          });
          toast.success("question updated successfuly");
          setShow(false);
        },
      });
    } else {
      formData.append("duration_hours", selftestQuestion?.duration_hours);
      formData.append("duration_minutes", selftestQuestion?.duration_minutes);
      formData.append(
        "question_type",
        selftestQuestion?.question_type || Qtype.mcq
      );
      formData.append("status", "publish");

      createMutation.mutate(formData, {
        onError: (res) => {
          toast.error(res?.response?.data?.message);
        },
        onSuccess: (res) => {
          queryClient.invalidateQueries("viewSelfTest", {
            lesson_id: lesson_id,
          });
          setShow(false);
          toast.success("question added successfuly");
        },
      });
    }
  };

  return (
    <>
      {isView ? (
        <>
          <QuestionModal
            onCancel={() => {
              setShow(false);
            }}
            show={show}
          >
            <Spinner
              isLoading={
                updateMutation.isLoading ||
                deleteMutation.isLoading ||
                updateQuestionMutation.isLoading ||
                createMutation.isLoading
              }
            />
            <QuestionContainer
              isedit={true}
              Questions={selftestQuery?.data?.questions_data}
              onQuestionSubmit={onQuestionSubmit}
              type={Qtype.mcq}
              onQuestionDelete={onQuestionDelete}
            ></QuestionContainer>
          </QuestionModal>

          <ViewDemo
            onClose={() => {
              setIsView(false);
            }}
            type={Qtype.mcq}
            handleEdit={() => {
              setShow(true);
            }}
            list={selftestQuery?.data?.questions_data}
            defaultValues={selftest.self_test}
          />
        </>
      ) : (
        <>
          <QuestionModal
            heading={<StyledHeading>Edit fields</StyledHeading>}
            HedingHeight={85}
            onCancel={() => {
              reset();
              setIsEditQues(false);
            }}
            show={isEditQues}
          >
            <Spinner
              isLoading={
                updateMutation.isLoading ||
                deleteMutation.isLoading ||
                updateQuestionMutation.isLoading ||
                createMutation.isLoading
              }
            />
            <StyledModelForm>
              <form onSubmit={handleSubmit(onDetailSubmit)}>
                <SelftestForm
                  selftest={selftest}
                  register={register}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  isEdit={true}
                />

                <UpdateButton
                  type="Submit"
                  disabled={isValid && isDirty ? false : true}
                  bgColor={theme.colors.NEBULA_BLUE}
                  text="Update"
                />
              </form>
            </StyledModelForm>
          </QuestionModal>

          <ViewLayoutWrapper>
            <ViewLayoutHeaderRow>
              <StyleButtonCol>
                <ViewLayoutEditBox
                  onClick={() => {
                    setIsEditQues(true);
                  }}
                >
                  <img height={20} src={Constant.Icons.edit} alt="edit" />
                </ViewLayoutEditBox>
                <ViewLayoutCloseBox
                  onClick={() => {
                    navigate("/selftest");
                  }}
                >
                  Close
                </ViewLayoutCloseBox>
              </StyleButtonCol>
            </ViewLayoutHeaderRow>

            {!isEditQues && (
              <>
                <ViewLayoutContentRow>
                  <StyledContentRow>
                    <SelftestForm
                      selftest={selftest}
                      register={register}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      isView={true}
                      key="form2"
                    />
                  </StyledContentRow>
                </ViewLayoutContentRow>

                <StyleButtonCol>
                  <StyledStartBtn
                    onClick={() => {
                      setIsView(true);
                    }}
                  >
                    Start
                  </StyledStartBtn>
                </StyleButtonCol>
              </>
            )}
          </ViewLayoutWrapper>
        </>
      )}
    </>
  );
};
export default ViewSelftest;
