import { Modal } from "react-bootstrap";
import styled from "styled-components";
import Button from "../Button";
import { theme } from "../../../lib/themeConstants";
import { pxtoRem } from "../../../lib/helper";
import FileUpload from "../FileUpload";
import { useForm } from "react-hook-form";
import { Spinner } from "../Spinner";

const StyledLayer = styled.div`
  border: none;
  justify-content: ${(props) => (props.issignout ? "start" : "center")};
  color: ${(props) => props.theme.colors.SIDEMENU_GREY};
  margin-left: ${(props) => props.issignout && "110px"};
`;

const StyledHeader = styled(StyledLayer)`
  justify-content: start;
  padding: ${pxtoRem(20)} ${pxtoRem(40)};
  border-radius: 0;
  font-size: ${pxtoRem(20)};
  background-color: ${(props) => props.theme.colors.NEBULA_BLUE};
  color: ${(props) => props.theme.colors.WHITE};
`;

const StyledBody = styled(StyledLayer)`
  padding-top: ${pxtoRem(40)};
  padding-left: ${pxtoRem(40)};
  padding-right: ${pxtoRem(60)};
  justify-content: start;
  font-size: ${pxtoRem(20)};
  div {
    border-radius: 0;
  }
`;

const StyledFooter = styled(StyledLayer)`
  padding-right: ${pxtoRem(55)};
  padding-top: ${pxtoRem(40)};
  padding-bottom: ${pxtoRem(60)};
  justify-content: end;
  button {
    width: ${pxtoRem(100)};
    height: ${pxtoRem(36)};
    /* margin-right: ${pxtoRem(20)}; */
    border-radius: 4px !important;
    border: none;
    line-height: 1;
  }
`;
const StyledBodyContainer = styled.div`
  /* display: flex;
justify-content:center;
align-items: center; */
`;

const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 0;
    border: 1px solid ${(props) => props.theme.colors.DARK_GREY};
  }
`;

const StyledLable = styled.span`
  margin-top: ${pxtoRem(10)};
  margin-bottom: ${pxtoRem(10)};
  display: flex;
  text-align: center;
  align-items: center;
  img {
    height: ${pxtoRem(24)};
    width: ${pxtoRem(24)};
    margin-left: ${pxtoRem(10)};
  }
`;

const UploadFile = ({
  file,
  label = "",
  onConfirm,
  onCancel,
  show = false,
  accept = "*",
  loading = false,
}) => {
  const {
    setValue,
    register,
    handleSubmit,
    // formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues: {
      file: file,
    },
  });

  return (
    <StyledModal
      //   contentClassName="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onCancel}
    >
      <Spinner isLoading={loading} />
      <form onSubmit={handleSubmit(onConfirm)}>
        <StyledHeader as={Modal.Header}>Upload Files</StyledHeader>
        <StyledBodyContainer>
          <StyledBody as={Modal.Body}>
            {label && <StyledLable>{label}</StyledLable>}
            <FileUpload
              file={file}
              id="file"
              register={register}
              accept={accept}
              iscustom={true}
              onClear={() => {
                setValue("file", null);
              }}
            />

            {/* {file && (
              <StyledLable>
                File upload success{" "}
                <img
                  alt="file-upload-success"
                  src={Constant.Icons.check_circle_black}
                />
              </StyledLable>
            )} */}
          </StyledBody>
        </StyledBodyContainer>

        <StyledFooter as={Modal.Footer}>
          <Button
            onClick={onCancel}
            className="me-3"
            color={theme.colors.LIGHT_GREY}
            bgColor={theme.colors.WHITE}
            variant="inverse"
            text="Cancel"
          />
          <Button
            type="submit"
            bgColor={theme.colors.NEBULA_BLUE}
            text={"Save"}
          />
        </StyledFooter>
      </form>
    </StyledModal>
  );
};

export default UploadFile;
