import { useMutation } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useCreateLesson = () => {
  return useMutation((newLesson) => {
    return axiosPost("/api/cw/createLesson", newLesson);
  });
};

export default useCreateLesson;
