import React, { useEffect } from "react";
import { Badge, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Autocomplete from "../../components/shared/Autocomplete";
import Button from "../../components/shared/Button";
import PageHeader from "../../components/shared/PageHeader";
import Table from "../../components/shared/Table";
import { Constant } from "../../lib/Constant";
import ContainerComponent from "../../components/shared/ContainerComponent";
import { pxtoRem } from "../../lib/helper";
import { theme } from "../../lib/themeConstants";
import {
  useCountries,
  useExamBoards,
  useSubjects,
  useYears,
} from "../../hooks/chapter";
import {
  useChangeChallengeStatus,
  useViewAllChallenges,
} from "../../hooks/challenges";
import { Spinner } from "../../components/shared/Spinner";
import Empty from "../../components/shared/Empty";
import { useDispatch, useSelector } from "react-redux";
import { setPageChange } from "../../redux-toolkit/table";
import { setFilter } from "../../redux-toolkit/challenges";
import { challenges_data } from "../../lib/dummyData";
import Toggle from "../../components/shared/Toggle";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";

const StyledChapterName = styled.div`
  margin-left: ${pxtoRem(40)};
`;

const StyledBadge = styled(Badge)`
  background-color: ${(props) => props.color} !important;
  width: ${pxtoRem(146)};
  height: ${pxtoRem(46)};
  padding-top: ${pxtoRem(13)};
  font-size: ${pxtoRem(20)};
  border-radius: ${pxtoRem(10)};
  color: ${(props) => props.theme.colors.WHITE};
  font-weight: normal;
`;

const StyledButton = styled(Button)`
  height: ${pxtoRem(46)};
  width: ${pxtoRem(67)};
  font-size: ${pxtoRem(20)};
  /* line-height: ${pxtoRem(18)}; */
  padding: 0;
`;

function Challenges() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const currentPage = useSelector((state) => {
    return state.table.page;
  });

  const { country, challenge_board, subject, grade, year } = useSelector(
    (state) => {
      return state.challenges.filter;
    }
  );

  const {
    data: challenges_data,
    error,
    isFetching,
  } = useViewAllChallenges(
    country?.value,
    challenge_board?.value,
    subject?.value,
    grade?.value,
    year?.value,
    currentPage
  );

  const statusMutation = useChangeChallengeStatus();

  const { data: countriesData, isFetching: countriesLoading } = useCountries();
  const { data: examboardData, isFetching: examLoading } = useExamBoards();
  const { data: subjectsData, isFetching: subjectsLoading } = useSubjects();
  const { data: gradesData, isFetching: gradesLoading } = useYears();

  useEffect(() => {
    if (error) {
      toast.error(error?.response?.data?.message);
    }
    return () => {
      dispatch(setPageChange(1));
    };
  }, [dispatch, error]);

  const navigate = useNavigate();

  const formatFilterOptions = (data) => {
    let formattedData = data?.map((item) => {
      return { value: item.id, label: item.name };
    });
    return formattedData;
  };

  const onFilterChange = (key, value) => {
    let data = { country, challenge_board, subject, grade, year };
    data[key] = value;
    dispatch(setFilter(data));
  };

  const handleStatusChange = (id, value) => {};

  const columns = React.useMemo(
    () => [
      {
        Header: "Subject",
        accessor: "subject",
        Cell: ({ row }) => (
          <StyledBadge
            color={
              row?.original?.subject?.color && row?.original?.subject?.color
            }
          >
            {row?.original?.subject?.name && row.original?.subject.name}
          </StyledBadge>
        ),
      },
      {
        Header: "Challenge board",
        accessor: "challenge_board",
      },
      {
        Header: "Level",
        accessor: "level",
      },
      {
        Header: "Year",
        accessor: "year",
      },
      {
        Header: "Grade",
        accessor: "grade",
        Cell: ({ row }) => <span>{row?.original?.grade?.name}</span>,
      },
      {
        Header: "Action",
        Cell: ({ row }) => (
          <StyledButton
            text="View"
            onClick={() => navigate(`${row?.original?.id}/show`)}
            bgColor={theme.colors.NEBULA_BLUE}
          />
        ),
      },
      {
        Header: "",
        accessor: "status",
        customWidth: 17,
        Cell: ({ row }) => (
          <Toggle
            checked={row.original?.status === "publish" ? true : false}
            onChange={(status) => {
              const formData = new FormData();
              formData.set("status", status ? "publish" : "draft");
              formData.set("id", row?.original?.id);
              statusMutation.mutate(formData, {
                onError: (res) => {
                  toast.error(res?.response?.data?.message);
                },
                onSuccess: (res) => {
                  queryClient.invalidateQueries("challenges", {
                    country_id: country?.value,
                    challenge_board_id: challenge_board?.value,
                    subject_id: subject?.value,
                    grade_id: grade?.value,
                    year: year?.value,
                    page: currentPage,
                  });
                },
              });
            }}
            onLable={"Publish"}
            offLable={"Draft"}
          />
        ),
      },
    ],
    // eslint-disable-next-line
    []
  );
  return (
    <>
      <Spinner
        isLoading={
          countriesLoading || examLoading || subjectsLoading || gradesLoading
        }
      />
      <PageHeader
        title="Challenges"
        control={
          <Button
            text="Create"
            icon={Constant.Icons.add_circle_outline_white}
            bgColor={theme.colors.NEBULA_BLUE}
            onClick={() => {
              navigate("create");
            }}
          />
        }
      ></PageHeader>

      <ContainerComponent>
        <Row>
          <Col>
            <Autocomplete
              options={formatFilterOptions(countriesData)}
              value={country}
              onChange={(data) => {
                // setCountry(data);
                onFilterChange("country", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Country"
              className="m-2 auto-complete"
            />
          </Col>
          <Col>
            <Autocomplete
              options={formatFilterOptions(examboardData)}
              value={challenge_board}
              onChange={(data) => {
                // setExamboard(data);
                onFilterChange("challenge_board", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Challenge Board"
              className="m-2 auto-complete"
            />
          </Col>
          <Col>
            <Autocomplete
              options={formatFilterOptions(subjectsData)}
              value={subject}
              onChange={(data) => {
                // setSubject(data);
                onFilterChange("subject", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Subject"
              className="m-2 auto-complete"
            />
          </Col>
          <Col>
            <Autocomplete
              options={formatFilterOptions(gradesData)}
              value={grade}
              onChange={(data) => {
                // setGrade(data);
                onFilterChange("grade", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Grade"
              className="m-2 auto-complete"
            />
          </Col>
          <Col>
            <Autocomplete
              options={formatFilterOptions(gradesData)}
              value={year}
              onChange={(data) => {
                // setGrade(data);
                onFilterChange("year", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Year"
              className="m-2 auto-complete"
            />
          </Col>
        </Row>
        <Row>
          <Col className="mt-3">
            {challenges_data?.length > 0 ? (
              <Table
                data={challenges_data}
                columns={columns}
                defaultPageSize={challenges_data?.per_page}
                totalPages={challenges_data?.last_page}
                currentPage={challenges_data?.current_page}
              ></Table>
            ) : (
              !isFetching && (
                <Empty text={Constant.tableErrorMessages.challenges} />
              )
            )}
          </Col>
        </Row>
      </ContainerComponent>
    </>
  );
}

export default Challenges;
