import React from "react";
import Select from "react-select";
import { Constant } from "../../lib/Constant";
import styled from "styled-components";
import { pxtoRem } from "../../lib/helper";
import { theme } from "../../lib/themeConstants";

export const StyledAutoCompleteDropdownIndicator = styled.div`
  width: ${pxtoRem(30)};
  img {
    transform: ${(props) => props.isUpside && "rotate(-180deg)"};
  }
`;

const StyledImage = styled.img`
  height: ${pxtoRem(6)};
  width: ${pxtoRem(10)};
`;

function Autocomplete({
  options,
  value = null,
  onChange,
  placeholder,
  backgroundColor = theme.DropDownColors.Grey,
  ...rest
}) {
  const AutocompleteStyle = {
    control: (baseStyles, { data, isDisabled, isFocused, isSelected }) => ({
      ...baseStyles,
      // width: pxtoRem(264),
      backgroundColor,
      height: pxtoRem(60),
      borderRadius: pxtoRem(31),
      border: "none",
      fontSize: pxtoRem(20),
      "*": {
        boxShadow: "none !important",
      },
      boxShadow: "none",
      // color: `${theme.colors.NAVY_BLUE}`,
      // opacity: 0.5,
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: pxtoRem(20),
      backgroundColor: state.isSelected
        ? `${theme.colors.DEEP_SKY_BLUE}`
        : state.backgroundColor,
      "&:hover": {
        backgroundColor: state.isSelected
          ? `${theme.colors.DEEP_SKY_BLUE}`
          : `${theme.DropDownColors.SKY_BLUE}`,
      },
    }),
    menu: (baseStyles, state) => ({
      ...baseStyles,
      zIndex: 20,
    }),
    input: (styles) => ({
      ...styles,
      marginLeft: "1rem",
      color: `${theme.colors.GUNMENTAL}`,
      opacity: 0.5,
    }),
    placeholder: (styles) => ({
      ...styles,
      marginLeft: "1rem",
      color: `${theme.colors.GUNMENTAL}`,
      opacity: 0.5,
    }),
    singleValue: (styles) => ({
      ...styles,
      marginLeft: "1rem",
      color: `${theme.colors.GUNMENTAL}`,
      opacity: 0.5,
    }),
    clearIndicator: (styles) => ({
      ...styles,
      height: `${pxtoRem(45)}`,
      width: `${pxtoRem(45)}`,
      marginBottom: `${pxtoRem(10)}`,
    }),
  };
  return (
    <Select
      options={options}
      styles={AutocompleteStyle}
      placeholder={placeholder}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: (state) => (
          <StyledAutoCompleteDropdownIndicator
            isUpside={state.selectProps.menuIsOpen}
          >
            <StyledImage
              // width={10}
              // height={6}
              src={Constant.Icons.downarrow}
              alt="filter"
            />
          </StyledAutoCompleteDropdownIndicator>
        ),
      }}
      isClearable={true}
      value={options?.filter((item) => value?.value === item.value)}
      onChange={(data) => onChange(data)}
      {...rest}
      classNamePrefix="Autocomplete"
    />
  );
}

export default Autocomplete;
