import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { Constant } from "../../../lib/Constant";
import {
  isQuillEmpty,
  isValidImgSrc,
  isValidTextORImage,
  pxtoRem,
  removeBlank,
} from "../../../lib/helper";
import { theme } from "../../../lib/themeConstants";
import Accordion from "../Accordion";
import Button from "../Button";
import Editor from "../Editor";
import FormControl from "../FormControl";
import TextInput from "../TextInput";
import BoxImage from "./BoxImage";
import { useEffect, useState } from "react";
import _ from "lodash";
import { forwardRef } from "react";
import { useRef } from "react";
import { useImperativeHandle } from "react";
import DeleteButton from "../DeleteButton";

const StyledContainer = styled.div`
  background-color: ${(props) => props.theme.colors.QUESTION_BG};
  border: 1px solid ${(props) => props.theme.colors.DARK_GREY};
  border-radius: 5px;
  width: 100%;
  padding: ${pxtoRem(40)};
`;

const StyledLabel = styled(Form.Label)`
  margin-bottom: 0px;
  color: ${(props) => props.theme.colors.NAVY_BLUE};
`;

const StyledNextQuestionButton = styled(Button)`
  margin-right: ${pxtoRem(20)};
`;

const SaSubQueForm = forwardRef(
  (
    {
      onSubmit,
      completeSubQuestions,
      defaultValues,
      parentQuestion,
      onQuestionDelete,
      isedit,
    },
    ref
  ) => {
    const formRef = useRef();
    const {
      control,
      register,
      formState: { errors, isValid, isDirty },
      handleSubmit,
      setValue,
      getValues,
      reset,
      watch,
    } = useForm({
      mode: "onChange",
      defaultValues: defaultValues,
    });

    let allValues = getValues();
    let [showErrors, setShowErrors] = useState({});

    useEffect(() => {
      reset({
        ...defaultValues,
        mark: defaultValues?.mark ? defaultValues?.mark : null,
        question: defaultValues?.question ? defaultValues?.question : null,
        sa_question_answer: defaultValues?.sa_question_answer
          ? defaultValues?.sa_question_answer
          : null,
        marking_scheme: defaultValues?.marking_scheme
          ? defaultValues?.marking_scheme
          : null,
        knowledge: defaultValues?.knowledge ? defaultValues?.knowledge : null,
      });
      setShowErrors({});
    }, [defaultValues, reset]);

    // useEffect(() => {
    //   if (
    //     formStateValues?.isValid !== isValid ||
    //     formStateValues?.isDirty !== isDirty ||
    //     !_.isEqual(formStateValues?.allValues, allValues)
    //   ) {
    //     setFormState({ isValid, isDirty, allValues });
    //   }
    // }, [isValid, isDirty, allValues]);

    // useEffect(() => {
    //   reset({
    //     ...defaultValues,
    //     marks: defaultValues?.marks ? defaultValues?.marks : null,
    //   });
    //   setShowErrors({});
    // }, [defaultValues]);

    useImperativeHandle(ref, () => ({
      canParentSubmit: () => {
        return isValid && isDirty;
      },
      onParentSubmit: () => {
        formRef.current.dispatchEvent(
          new Event("submit", { bubbles: true, cancelable: true })
        );
      },
    }));

    const onSubQuestionSubmit = (data) => {
      setShowErrors({});
      const formData = new FormData();
      if (defaultValues?.id) {
        formData.append("id", defaultValues?.id);
        formData.append("parent_question_id", parentQuestion?.id);
        formData.append("status", defaultValues?.status);
        formData.append("mark", data?.mark);
        formData.append("question", data?.question);
        formData.append("sa_question_answer", data?.sa_question_answer);
        data?.marking_scheme &&
          formData.append("marking_scheme", data?.marking_scheme);
        data?.knowledge && formData.append("knowledge", data?.knowledge);
        onSubmit(formData);
      } else {
        let filteredData = removeBlank(data);
        for (let key of Object.keys(filteredData)) {
          formData.append(key, data[key]);
        }
        formData.append("parent_question_id", parentQuestion?.id);
        onSubmit(formData);
      }
    };

    return (
      <form key={2} ref={formRef} onSubmit={handleSubmit(onSubQuestionSubmit)}>
        <StyledContainer>
          <Row>
            <Col xs={3}>
              <FormControl
                label="Marks"
                isInline={false}
                errMessage={errors?.mark?.message}
              >
                <TextInput
                  register={register}
                  id="mark"
                  type="number"
                  validation={{
                    required: "Please enter marks",
                    min: {
                      value: 1,
                      message: "Value can not be less than 1",
                    },
                  }}
                />
              </FormControl>
            </Col>

            {defaultValues?.id && (
              <Col style={{ textAlign: "right" }}>
                <DeleteButton
                  isedit={isedit}
                  onDelete={() => {
                    if (onQuestionDelete) {
                      let formData = new FormData();
                      formData.append("id", defaultValues?.id);
                      onQuestionDelete(formData);
                    }
                  }}
                />
              </Col>
            )}
          </Row>
          <Row className="mt-2">
            <Col xs={3}>
              <FormControl
                errMessage={errors?.question_number?.message}
                label="Add Sub Question"
                isInline={false}
              >
                <TextInput
                  register={register}
                  id="question_number"
                  validation={{ required: "Please enter question number" }}
                />
              </FormControl>
            </Col>
          </Row>
          <Row className="description-container mt-2">
            <Col>
              <StyledLabel>Question?</StyledLabel>
              <Controller
                name="question"
                control={control}
                rules={{
                  required: { value: true, message: "Please enter question" },
                  validate: {
                    validImage: (v) =>
                      isValidImgSrc(v) || "Please enter image only",
                  },
                }}
                render={({ field }) => (
                  <>
                    <Editor
                      id="question"
                      {...field}
                      editorState={field.value === "" ? undefined : field.value}
                      onEditorStateChange={(text) => {
                        field.onChange(text);
                        text &&
                          text !== "" &&
                          setShowErrors({ ...showErrors, question: true });
                      }}
                      ref={field.ref}
                      errMessage={
                        showErrors?.question && errors?.question?.message
                      }
                    />
                  </>
                )}
              />
            </Col>
          </Row>
          <Row className="description-container">
            <Col>
              <StyledLabel>Answer</StyledLabel>
              <Controller
                name="sa_question_answer"
                control={control}
                rules={{
                  required: { value: true, message: "Please enter answer" },
                  validate: {
                    validImage: (v) =>
                      isValidImgSrc(v) || "Please enter image only",
                  },
                }}
                render={({ field }) => (
                  <Editor
                    id="sa_question_answer"
                    {...field}
                    editorState={field.value === "" ? undefined : field.value}
                    onEditorStateChange={(text) => {
                      field.onChange(text);
                      text &&
                        text !== "" &&
                        setShowErrors({
                          ...showErrors,
                          sa_question_answer: true,
                        });
                    }}
                    ref={field.ref}
                    errMessage={
                      showErrors?.sa_question_answer &&
                      errors?.sa_question_answer?.message
                    }
                  />
                )}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <Accordion
                eventKey="marking_scheme"
                heading="Add Marking Scheme"
                errMessage={
                  showErrors?.marking_scheme && errors?.marking_scheme?.message
                }
                defaultActiveKey={
                  defaultValues?.marking_scheme ? "marking_scheme" : ""
                }
              >
                <Controller
                  name="marking_scheme"
                  control={control}
                  rules={{
                    validate: {
                      validImage: (v) =>
                        isValidImgSrc(v) || "Please enter image only",
                    },
                  }}
                  render={({ field }) => (
                    <Editor
                      issortNotes={true}
                      id="marking_scheme"
                      {...field}
                      editorState={field.value === "" ? undefined : field.value}
                      onEditorStateChange={(text) => {
                        field.onChange(text);
                        text &&
                          text !== "" &&
                          setShowErrors({
                            ...showErrors,
                            marking_scheme: true,
                          });
                      }}
                      ref={field.ref}
                    />
                  )}
                />
              </Accordion>
            </Col>
          </Row>
          <Row>
            <Col>
              <Accordion
                eventKey="knowledge"
                heading="Add Theory"
                defaultActiveKey={defaultValues?.knowledge ? "knowledge" : ""}
              >
                <Controller
                  name="knowledge"
                  control={control}
                  render={({ field }) => (
                    <Editor
                      issortNotes={true}
                      id="knowledge"
                      {...field}
                      editorState={field.value}
                      onEditorStateChange={(text) => {
                        field.onChange(text);
                      }}
                      ref={field.ref}
                    />
                  )}
                />
              </Accordion>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col className="text-end">
              <StyledNextQuestionButton
                onClick={() =>
                  completeSubQuestions({
                    ...allValues,
                    parent_question_id: parentQuestion?.id,
                  })
                }
                disabled={!defaultValues?.id}
                variant="inverse"
                bgColor={theme.colors.WHITE}
                color={theme.colors.NEBULA_BLUE}
                text="Next question"
              />
              <Button
                type="submit"
                disabled={isDirty && isValid ? false : true}
                bgColor={theme.colors.NEBULA_BLUE}
                text={defaultValues?.id ? "Update" : "Save"}
              />
            </Col>
          </Row>
        </StyledContainer>
      </form>
    );
  }
);

export default SaSubQueForm;
