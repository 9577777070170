import { useEffect } from "react";
import { Col, Form, Nav, Row, Tab } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ReactPlayer from "react-player";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Breadcrumb from "../../components/shared/Breadcrumb";
import Button from "../../components/shared/Button";
import ContainerComponent from "../../components/shared/ContainerComponent";
import FormControl from "../../components/shared/FormControl";
import Glossary from "../../components/shared/Glossary";
import PPTViewer from "../../components/shared/PPTViewer";
import SectionHeader from "../../components/shared/SectionHeader";
import { Spinner } from "../../components/shared/Spinner";
import { TabItem, TabList } from "../../components/shared/TabList";
import TextInput from "../../components/shared/TextInput";
import useLesson from "../../hooks/lesson/useLesson";
import { pxtoRem } from "../../lib/helper";
import { theme } from "../../lib/themeConstants";
import { Constant } from "../../lib/Constant";

const StyledDescription = styled.div`
  color: ${(props) => props.theme.colors.DARK_GREY};
  font-size: ${pxtoRem(20)};
  background-color: ${(props) => props.theme.colors.GHOST_WHITE};
  padding: ${pxtoRem(20)};
  margin-bottom: ${pxtoRem(32)};
  margin-top: ${pxtoRem(4)};
`;

const StyledLabel = styled(Form.Label)`
  margin-bottom: 0px;
  color: ${(props) => props.theme.colors.NAVY_BLUE};
`;

const StyledEditLink = styled.div`
  color: ${(props) => props.theme.colors.NEBULA_BLUE};
  text-decoration: none;
  cursor: pointer;
`;

const StyledHeaderLabel = styled.div`
  font-size: ${pxtoRem(24)};
  color: ${(props) => props.theme.NOTIFICATIONLIST.DARK_BLACK};
`;

const ViewLesson = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const lessonId = location.pathname.split("/")[4];
  const chapterId = location.pathname.split("/")[2];
  const lessonQuery = useLesson(lessonId);

  const chapter = location?.state?.chapter;
  const lesson = lessonQuery?.data?.data?.[0];

  const { control, register, setValue } = useForm({
    // defaultValues: {
    //   glossary: lesson?.glossary,
    // },
  });

  useEffect(() => {
    setValue("glossary", lesson?.glossary);
    setValue("lessonNumber", lesson?.number);
    setValue("lessonName", lesson?.name);
  }, [lesson, setValue, lessonId]);

  return (
    <>
      <div className="mb-2" style={{ textAlign: "end" }}>
        <Button
          onClick={() => {
            navigate(`/chapters/${chapterId}/lessons`, {
              state: { chapter: chapter },
            });
          }}
          bgColor={theme.colors.NEBULA_BLUE}
          text="Back"
        />
      </div>
      <Spinner isLoading={lessonQuery.isLoading} />
      <SectionHeader
        title="View lesson"
        control={
          <StyledEditLink
            onClick={() => {
              navigate(`/chapters/${chapterId}/lessons/${lesson?.id}`, {
                state: { chapter: chapter },
              });
            }}
          >
            Edit
          </StyledEditLink>
        }
      />

      <ContainerComponent>
        <Breadcrumb
          data={[
            chapter?.country?.name,
            chapter?.exam_board?.name,
            chapter?.subject?.name,
            chapter?.years_data &&
              chapter?.years_data
                .map(function (item) {
                  return item.name;
                })
                .join(","),
            `${chapter?.number}. ${chapter?.name}`,
          ]}
        />
        <form>
          <Row>
            <Col className="col-4">
              <FormControl isInline={false} label="Lesson number">
                <TextInput
                  id="lessonNumber"
                  disabled
                  style={{ backgroundColor: theme.colors.GHOST_WHITE }}
                  placeholder="Lesson number"
                  // value={lesson?.number}
                  register={register}
                />
              </FormControl>
            </Col>
            <Col className="col-8">
              <FormControl isInline={false} label="Lesson name">
                <TextInput
                  id="lessonName"
                  disabled
                  style={{ backgroundColor: theme.colors.GHOST_WHITE }}
                  // value={lesson?.name}
                  register={register}
                />
              </FormControl>
            </Col>
          </Row>
          <Row>
            <Col>
              {lesson?.description ? (
                <>
                  <StyledLabel>Description</StyledLabel>
                  <StyledDescription
                    dangerouslySetInnerHTML={{ __html: lesson?.description }}
                  ></StyledDescription>
                </>
              ) : (
                <StyledDescription>
                  Description is not provided
                </StyledDescription>
              )}
            </Col>
          </Row>

          <TabList
            defaultActiveKey="video_url-tab"
            tabItems={
              <>
                <TabItem className="me-5">
                  <Nav.Link eventKey="video_url-tab">Videos</Nav.Link>
                </TabItem>
                <TabItem className="me-5">
                  <Nav.Link eventKey="presentation-tab">PPT</Nav.Link>
                </TabItem>
                <TabItem>
                  <Nav.Link eventKey="notes-tab">Sort notes</Nav.Link>
                </TabItem>
              </>
            }
          >
            <Tab.Pane eventKey="video_url-tab">
              {lesson?.video_url ? (
                <ReactPlayer width={"100%"} url={lesson?.video_url} />
              ) : (
                <StyledDescription>
                  Video link is not provided
                </StyledDescription>
              )}
            </Tab.Pane>
            <Tab.Pane eventKey="presentation-tab">
              <PPTViewer url={lesson?.presentation} />
            </Tab.Pane>
            <Tab.Pane eventKey="notes-tab">
              {lesson?.short_notes_file ? (
                <TextInput
                  disabled
                  value={
                    Constant.getFileName(lesson?.short_notes_file) ||
                    "File is not available"
                  }
                />
              ) : (
                <StyledDescription>File is not available</StyledDescription>
              )}

              {lesson?.short_notes ? (
                <StyledDescription
                  dangerouslySetInnerHTML={{ __html: lesson?.short_notes }}
                ></StyledDescription>
              ) : (
                <StyledDescription>Short note not provide</StyledDescription>
              )}
            </Tab.Pane>
          </TabList>

          <Row>
            <Col>
              {lesson?.glossary.length > 0 ? (
                <Glossary
                  showHeading={true}
                  register={register}
                  control={control}
                  name={"glossary"}
                  numberOfDefaultItems={0}
                  isFormComponent={false}
                  readOnly={true}
                />
              ) : (
                <>
                  <StyledHeaderLabel>Glossary</StyledHeaderLabel>
                  <StyledDescription>Glossary not Provide</StyledDescription>
                </>
              )}
            </Col>
          </Row>
        </form>
      </ContainerComponent>
    </>
  );
};

export default ViewLesson;
