import { useMutation } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useUpdateMockTest = () => {
  return useMutation((test) => {
    return axiosPost("/api/cw/updateQuestion", test);
  });
};

export default useUpdateMockTest;
