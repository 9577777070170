import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { pxtoRem } from "../../lib/helper";

const PageHeader = ({ title, control }) => {
  const StyledRow = styled(Row)`
    height: ${pxtoRem(60)};
    padding: ${pxtoRem(10)};
    margin-bottom: ${pxtoRem(20)};
    /* height: 3.8rem; */
    /* margin-top:${pxtoRem(10)} ; */
  `;

  const StyledCol = styled(Col)`
    /* margin: auto; */
  `;

  const StyledText = styled.h4`
    color: #707070;
    font-size: ${pxtoRem(30)};
  `;

  return (
    <StyledRow>
      <StyledCol>
        <StyledText className="">{title}</StyledText>
      </StyledCol>
      {control && <StyledCol className="text-end">{control}</StyledCol>}
    </StyledRow>
  );
};

export default PageHeader;
