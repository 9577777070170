import { Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import styled from "styled-components";
import Icon from "./Icon";
import { Link, useLocation } from "react-router-dom";
import { pxtoRem } from "../../lib/helper";

const activityManagement = [
  { name: "Selftest", path: "/selftest" },
  { name: "Worksheet", path: "/worksheet" },
  { name: "Past paper", path: "/past-paper" },
  { name: "Chapter paper", path: "/chapter-paper" },
  { name: "Mock test", path: "/mock-test" },
  { name: "Challenges", path: "/challenges" },
  { name: "Season activity", path: "/season-activity" },
  { name: "Parent activity", path: "/parent-activity" },
];

const StyledMenuItem = styled(MenuItem)`
  a.ps-menu-button {
    padding-left: 10px;
    padding-right: 10px;
  }
  .ps-menu-label {
    font-size: ${pxtoRem(20)};
  }
  cursor: pointer;
`;

const StyledSubMenu = styled(SubMenu)`
  ul {
    background-color: ${(props) => props.theme.colors.SIDEMENU_GREY};
  }

  a.ps-menu-button {
    padding-left: 10px;
    padding-right: 10px;
  }
  .ps-menu-label {
    font-size: ${pxtoRem(20)};
  }
`;

const StyledHeading = styled.h2`
  text-align: center;
  margin-top: 1.1rem;
  font-size: ${pxtoRem(36)};
`;

const SideBar = () => {
  const location = useLocation();

  const Item = ({ text, icon, component }) => {
    return (
      <StyledMenuItem
        icon={icon && <Icon name={icon} />}
        active={location.pathname.startsWith(component?.props?.to)}
        component={component}
      >
        {text}
      </StyledMenuItem>
    );
  };

  const StyledBrandContainer = styled.div`
    background-color: ${(props) => props.theme.colors.BLACK};
    color: ${(props) => props.theme.colors.WHITE};
    height: ${pxtoRem(108)};
  `;
  const StyledMenuContainer = styled.div`
    background-color: ${(props) => props.theme.colors.SIDEMENU_GREY};
    color: ${(props) => props.theme.colors.WHITE};
    padding-top: 1.5rem;
    overflow-x: hidden;
    overflow-y: auto;
  `;

  return (
    <>
      <StyledBrandContainer>
        <StyledHeading>SqillUp</StyledHeading>
      </StyledBrandContainer>
      <StyledMenuContainer>
        <Menu
          menuItemStyles={{
            button: ({ active }) => {
              return {
                opacity: active ? "1" : "0.5",
              };
            },
          }}
        >
          <Item
            text="Dashboard"
            icon="dashboard"
            component={<Link to="/dashboard" />}
          />
          <Item
            text="Subject Management"
            icon="subjectManagement"
            component={<Link to="/chapters" />}
          />

          <StyledSubMenu
            icon={<Icon name="activityManagement" />}
            label="Activity Management"
            active={
              activityManagement?.find((item) =>
                location.pathname.includes(item.path)
              )
                ? true
                : false
            }
            defaultOpen={
              activityManagement?.find((item) =>
                location.pathname.includes(item.path)
              )
                ? true
                : false
            }
          >
            {activityManagement.map((item, i) => {
              return (
                <StyledMenuItem
                  key={`submenuItem-${i}`}
                  active={location.pathname.includes(item.path) ? true : false}
                  component={<Link to={item.path} />}
                >
                  {item.name}
                </StyledMenuItem>
              );
            })}
          </StyledSubMenu>

          <Item
            text="Curriculum"
            icon="curriculum"
            component={<Link to={"/curriculum"} />}
          />
          <Item text="Q&A" icon="qa" />
        </Menu>
      </StyledMenuContainer>
    </>
  );
};

export default SideBar;
