import React, { useEffect, useState } from "react";
import QuestionNumber from "./QuestionNumber";
import { Questions } from "../../lib/dummyData";
import styled from "styled-components";
import { Constant } from "../../lib/Constant";
import { Col, Row } from "react-bootstrap";
import { pxtoRem } from "../../lib/helper";
import SubQuestionNumber from "./SubQuestionNumber";

const StyledPaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const StyledLeftIndicator = styled.img`
  transform: rotate(90deg);
  margin-top: ${pxtoRem(18)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  width: ${pxtoRem(19)};
  height: ${pxtoRem(11)};
`;
const StyledRightIndicator = styled.img`
  transform: rotate(-90deg);
  margin-top: ${pxtoRem(18)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  width: ${pxtoRem(19)};
  height: ${pxtoRem(11)};
`;

const StyledSubLeftIndicator = styled.img`
  transform: rotate(90deg);
  margin-top: ${(props) => (props.isSubQuesEdit ? pxtoRem(18) : pxtoRem(17))};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  width: ${pxtoRem(19)};
  height: ${(props) => (props.isSubQuesEdit ? pxtoRem(11) : pxtoRem(8))};
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`;
const StyledSubRightIndicator = styled.img`
  transform: rotate(-90deg);
  margin-top: ${(props) => (props.isSubQuesEdit ? pxtoRem(18) : pxtoRem(17))};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  width: ${pxtoRem(19)};
  height: ${(props) => (props.isSubQuesEdit ? pxtoRem(11) : pxtoRem(8))};
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`;

const StyledAddQuestionButton = styled.img`
  height: ${pxtoRem(45)};
  width: ${pxtoRem(45)};
  float: right;
`;

const StyledRow = styled(Row)`
  padding: ${pxtoRem(10)};
`;

function QuestionsNumberList({
  listData,
  onChange,
  onSubQuestionChange,
  onChangeLeft,
  startEndIndex,
  addNewQuestion,
  onChangeRight,
  onSubChangeLeft,
  onSubChangeRight,
  startEndSubQuestionIndex,
  currentQuestionIndex,
  currentSubQuestionIndex,
  totalActiveQuestion = 0,
  totalActiveSubQuestion = 0,
  showSubQuestion = false,
  setShowSubQuestion,
  isSubQuesEdit = false,
}) {
  return (
    <div>
      <StyledRow>
        <Col md={11}>
          <StyledPaginationContainer>
            <div>
              {!isSubQuesEdit && (
                <div className="d-flex flex-row">
                  <StyledLeftIndicator
                    onClick={() => {
                      onChangeLeft();
                    }}
                    disabled={startEndIndex?.start === 0}
                    src={Constant.Icons.downarrow}
                    alt="add question"
                  />
                  {listData?.map(
                    (item, index) =>
                      index >= startEndIndex?.start &&
                      index <= startEndIndex?.end && (
                        <QuestionNumber
                          isCompleted={index < totalActiveQuestion}
                          isCurrent={index === currentQuestionIndex}
                          isDisabled={
                            index !== currentQuestionIndex &&
                            index > totalActiveQuestion
                          }
                          index={index}
                          onChange={onChange}
                          key={index}
                          setShowSubQuestion={setShowSubQuestion}
                        >
                          {index + 1}
                        </QuestionNumber>
                      )
                  )}

                  <StyledRightIndicator
                    onClick={() => {
                      onChangeRight();
                    }}
                    disabled={startEndIndex?.end === listData?.length - 1}
                    src={Constant.Icons.downarrow}
                    width="19"
                    height="11"
                    alt="add question"
                  />
                </div>
              )}
              <div
                className={
                  isSubQuesEdit ? "d-flex flex-row" : "d-flex flex-row mt-2"
                }
              >
                <StyledSubLeftIndicator
                  onClick={() => {
                    onSubChangeLeft();
                  }}
                  isSubQuesEdit={isSubQuesEdit}
                  visible={
                    listData &&
                    showSubQuestion &&
                    listData[currentQuestionIndex]?.children?.length > 10
                  }
                  disabled={startEndSubQuestionIndex?.start === 0}
                  src={Constant.Icons.downarrow}
                  alt="add question"
                />

                {listData?.map(
                  (item, index) =>
                    index === currentQuestionIndex &&
                    showSubQuestion &&
                    item.children?.length > 0 &&
                    item.children.map(
                      (subItem, subIndex) =>
                        subIndex >= startEndSubQuestionIndex?.start &&
                        subIndex <= startEndSubQuestionIndex?.end && (
                          <>
                            <SubQuestionNumber
                              isCompleted={subIndex < totalActiveSubQuestion}
                              isCurrent={subIndex === currentSubQuestionIndex}
                              index={subIndex}
                              key={`${index + 1}.${subIndex + 1}`}
                              onChange={onSubQuestionChange}
                              isSubQuesEdit={isSubQuesEdit}
                            >
                              {index + 1}.{subIndex + 1}
                            </SubQuestionNumber>
                          </>
                        )
                    )
                )}

                <StyledSubRightIndicator
                  onClick={() => {
                    onSubChangeRight();
                  }}
                  isSubQuesEdit={isSubQuesEdit}
                  disabled={
                    listData &&
                    startEndSubQuestionIndex?.end ===
                      listData[currentQuestionIndex]?.children?.length - 1
                  }
                  visible={
                    listData &&
                    showSubQuestion &&
                    listData[currentQuestionIndex]?.children?.length > 10
                  }
                  src={Constant.Icons.downarrow}
                  width="19"
                  height="11"
                  alt="add question"
                />
              </div>
            </div>
          </StyledPaginationContainer>
        </Col>
        {!isSubQuesEdit && (
          <Col>
            <StyledAddQuestionButton
              onClick={() => {
                addNewQuestion();
              }}
              role="button"
              src={Constant.Icons.add_circle_blue_outline}
              alt="add question"
            />
          </Col>
        )}
      </StyledRow>
    </div>
  );
}

export default QuestionsNumberList;
