export const notificationList = [
  { id: 1, name: "Lephen Shaw", details: "Worksheet submitted by Lucifer." },
  {
    id: 2,
    name: "Lephen Shaw",
    details: "your child Lucifer morningstar package has been upgraded.",
  },
];

export const userProfile = {
  name: "Peterson",
  designation: "Content writer",
};

export const countries = [
  { value: "afghanistan", label: "Afghanistan" },
  { value: "aland_islands", label: "Aland Islands" },
  { value: "albania", label: "Albania" },
  { value: "algeria", label: "Algeria" },
  { value: "american_samoa", label: "American Samoa" },
];

export const BreadcrumbsData = ["US", "AQA", "Chemistry", "G3", "Lesson 1"];

export const Questions = [
  {
    id: 1,
    question: "1+1=?",
    question_number: "1",
    children: [
      {
        question: "1.1+1.1",
        question_number: "1.1",
      },
    ],
  },
  {
    id: 2,
    question: "1+1=?",
    question_number: "1",
    children: [
      {
        question: "1.1+1.1",
        question_number: "1.1",
      },
    ],
  },
  // {
  //   id: 2,
  //   question: "1+1=?",
  //   question_number: "1",
  //   children: [
  //     {
  //       question: "1.1+1.1",
  //       question_number: "1.1",
  //     },
  //   ],
  // },
  // {
  //   id: 2,
  //   question: "1+1=?",
  //   question_number: "1",
  //   children: [
  //     {
  //       question: "1.1+1.1",
  //       question_number: "1.1",
  //     },
  //   ],
  // },
  // {
  //   id: 2,
  //   question: "1+1=?",
  //   question_number: "1",
  //   children: [
  //     {
  //       question: "1.1+1.1",
  //       question_number: "1.1",
  //     },
  //   ],
  // },
  // {
  //   id: 2,
  //   question: "1+1=?",
  //   question_number: "1",
  //   children: [
  //     {
  //       question: "1.1+1.1",
  //       question_number: "1.1",
  //     },
  //   ],
  // },
  // {
  //   id: 2,
  //   question: "1+1=?",
  //   question_number: "1",
  //   children: [
  //     {
  //       question: "1.1+1.1",
  //       question_number: "1.1",
  //     },
  //   ],
  // },
  // {
  //   id: 2,
  //   question: "1+1=?",
  //   question_number: "1",
  //   children: [
  //     {
  //       question: "1.1+1.1",
  //       question_number: "1.1",
  //     },
  //   ],
  // },
  // {
  //   id: 2,
  //   question: "1+1=?",
  //   question_number: "1",
  //   children: [
  //     {
  //       question: "1.1+1.1",
  //       question_number: "1.1",
  //     },
  //   ],
  // },
  // {
  //   id: 2,
  //   question: "1+1=?",
  //   question_number: "1",
  //   children: [
  //     {
  //       question: "1.1+1.1",
  //       question_number: "1.1",
  //     },
  //   ],
  // },
  // {
  //   id: 2,
  //   question: "1+1=?",
  //   question_number: "1",
  //   children: [
  //     {
  //       question: "1.1+1.1",
  //       question_number: "1.1",
  //     },
  //   ],
  // },
  // {
  //   id: 2,
  //   question: "1+1=?",
  //   question_number: "1",
  //   children: [
  //     {
  //       question: "1.1+1.1",
  //       question_number: "1.1",
  //     },
  //   ],
  // },
  // {
  //   id: 2,
  //   question: "1+1=?",
  //   question_number: "1",
  //   children: [
  //     {
  //       question: "1.1+1.1",
  //       question_number: "1.1",
  //     },
  //   ],
  // },
  // {
  //   id: 2,
  //   question: "1+1=?",
  //   question_number: "1",
  //   children: [
  //     {
  //       question: "1.1+1.1",
  //       question_number: "1.1",
  //     },
  //   ],
  // },
  // {
  //   id: 2,
  //   question: "1+1=?",
  //   question_number: "1",
  //   children: [
  //     {
  //       question: "1.1+1.1",
  //       question_number: "1.1",
  //     },
  //   ],
  // },
  // {
  //   id: 2,
  //   question: "1+1=?",
  //   question_number: "1",
  //   children: [
  //     {
  //       question: "1.1+1.1",
  //       question_number: "1.1",
  //     },
  //   ],
  // },
  // { question: "2+2=?" },
  // {
  //   question: "3+3=?",
  // },
  // { question: "4+4=?" },
  // {
  //   question: "5+5=?",
  // },
  // { question: "6+6=?" },
  // {
  //   question: "7+7=?",
  // },
  // { question: "8+8=?" },
  // {
  //   question: "9+9=?",
  // },
  // { question: "10+10=?" },
  // {
  //   question: "11+11=?",
  // },
  // { question: "12+12=?" },
];

export const chapters_data = [
  {
    chapter_name: "01. The particle models",
    subject: "chemistry",
    grade: "G3",
    exam_board: "AQA",
  },
  {
    chapter_name: "02. The particle",
    subject: "chemistry",
    grade: "G3",
    exam_board: "AQA",
  },
  {
    chapter_name: "03. The particle",
    subject: "biology",
    grade: "G3",
    exam_board: "AQA",
  },
  {
    chapter_name: "04. Lorem Ipsum",
    subject: "chemistry",
    grade: "G3",
    exam_board: "AQA",
  },
  {
    chapter_name: "05. The particle models",
    subject: "physics",
    grade: "G3",
    exam_board: "AQA",
  },
  {
    chapter_name: "01. The particle models",
    subject: "chemistry",
    grade: "G3",
    exam_board: "AQA",
  },
  {
    chapter_name: "02. The particle",
    subject: "chemistry",
    grade: "G3",
    exam_board: "AQA",
  },
  {
    chapter_name: "03. The particle",
    subject: "biology",
    grade: "G3",
    exam_board: "AQA",
  },
  {
    chapter_name: "04. Lorem Ipsum",
    subject: "chemistry",
    grade: "G3",
    exam_board: "AQA",
  },
  {
    chapter_name: "05. The particle models",
    subject: "physics",
    grade: "G3",
    exam_board: "AQA",
  },
];

export const lesson_data = [
  {
    number: "1.1",
    lesson_name: "The particals",
    is_description: true,
    is_video: true,
    is_ppt: false,
    is_notes: false,
    is_Glossary: true,
  },
  {
    number: "1.2",
    lesson_name: "Solid,liquid,gas",
    is_description: true,
    is_video: true,
    is_ppt: false,
    is_notes: false,
    is_Glossary: true,
  },
  {
    number: "1.3",
    lesson_name: "Change of state",
    is_description: true,
    is_video: true,
    is_ppt: false,
    is_notes: false,
    is_Glossary: true,
  },
  {
    number: "1.4",
    lesson_name: "The particals",
    is_description: true,
    is_video: true,
    is_ppt: false,
    is_notes: false,
    is_Glossary: true,
  },
  {
    number: "1.5",
    lesson_name: "The particals remain",
    is_description: true,
    is_video: true,
    is_ppt: false,
    is_notes: false,
    is_Glossary: true,
  },
];

export const worksheet_data = [
  {
    id: 1,
    chapter_name: "01. The particle models",
    subject: {
      name: "Mathematics",
      color: "#F6511D",
    },
    uploadFile: false,
    grade: "Grade 3",
    exam_board: "AQA",
    status: "publish",
  },
  {
    id: 2,
    chapter_name: "02. Melting and freezing",
    subject: {
      name: "Physics",
      color: "##0072FA",
    },
    uploadFile: true,
    grade: "Grade 3",
    exam_board: "OCR",
    status: "draft",
  },
  {
    id: 3,
    chapter_name: "03. The particle",
    subject: {
      name: "Biology",
      color: "#00EB6E",
    },
    uploadFile: false,
    grade: "Grade 1",
    exam_board: "AQA",
    status: "publish",
  },
  {
    id: 4,
    chapter_name: "04. Lorem ipsum",
    subject: {
      name: "Chemistry",
      color: "#FFD800",
    },
    uploadFile: false,
    grade: "Grade 1",
    exam_board: "OCR",
    status: "draft",
  },
  {
    id: 5,
    chapter_name: "05. The particle models",
    subject: {
      name: "Chemistry",
      color: "#FFD800",
    },
    uploadFile: false,
    grade: "Grade 1",
    exam_board: "AQA",
    status: "publish",
  },
];

export const pastPaper_data = [
  {
    id: 1,
    subject: {
      name: "Mathematics",
      color: "#F6511D",
    },
    exam_board: "GCSE",
    year: "2018",
    marking_scheme: true,
    instrunction: true,
    paper_type: "Paper 1",
    status: "publish",
  },
  {
    id: 2,
    subject: {
      name: "Physics",
      color: "#F6511D",
    },
    exam_board: "GCSE",
    year: "2018",
    marking_scheme: false,
    instrunction: false,
    paper_type: "Paper 2",
    status: "draft",
  },
  {
    id: 3,
    subject: {
      name: "Chemistry",
      color: "#F6511D",
    },
    exam_board: "EDEXEL",
    year: "2017",
    marking_scheme: true,
    instrunction: true,
    paper_type: "Paper 1",
    status: "publish",
  },
];

export const challenges_data = [
  {
    id: 1,
    subject: {
      name: "Mathematics",
      color: "#F6511D",
    },
    challenge_board: "Bpho",
    level: "junior physics",
    year: "2018",
    grade: "grade 1",
    status: "publish",
  },
  {
    id: 2,
    subject: {
      name: "Physics",
      color: "#F6512D",
    },
    challenge_board: "Rsoc",
    level: "junior physics",
    year: "2018",
    grade: "grade 1",
    status: "publish",
  },
  {
    id: 3,
    subject: {
      name: "Chemistry",
      color: "#FFD800",
    },
    challenge_board: "UKBC",
    level: "47th international",
    year: "2017",
    grade: "grade 3",
    status: "draft",
  },
];

export const chapter_paper_data = {
  current_page: 1,
  last_page: 2,
  per_page: 10,
  data: [
    {
      id: 1,
      name: "01. The particle modelsra",
      subject: { id: 1, name: "Mathematics", color: "#fde17d" },
      year: { id: 34, year: "8", name: "G9" },
      exam_board: { id: 8, name: "NC" },
    },
  ],
};

export const mock_test_data = {
  current_page: 1,
  last_page: 1,
  per_page: 4,
  data: [
    {
      id: 1,
      subject: { id: 1, name: "Maths", color: "#fde17d" },
      grade: { id: 34, year: "8", name: "G9" },
      exam_board: { id: 8, name: "NC" },
      paper_type: "Mock 1",
    },
  ],
};
