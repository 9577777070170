import { Form } from "react-bootstrap";
import styled from "styled-components";
import TextInput from "./TextInput";

const StyledErrMessage = styled(Form.Control.Feedback)`
  display: block;
  font-size: 14px;
  color: #dc3545;
`;

const StyledLabel = styled(Form.Label)`
  margin-bottom: 0px;
  color: #020723;
`;

const FormInput = ({
  label,
  id,
  errMessage,
  register,
  validation,
  ...rest
}) => {
  return (
    <div className="mb-3">
      <StyledLabel>{label}</StyledLabel>
      <TextInput
        id={id}
        register={register}
        validation={validation}
        {...rest}
      />
      {errMessage && <StyledErrMessage>{errMessage}</StyledErrMessage>}
    </div>
  );
};

export default FormInput;
