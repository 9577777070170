import { useEffect, useMemo, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import styled from "styled-components";
import { Constant, Qtype } from "../../../lib/Constant";
import { pxtoRem } from "../../../lib/helper";
import { constant, forEach } from "lodash";

const StyledQuestionNo = styled.p`
  background-color: ${(props) => props.theme.colors.WHITE};
  width: ${pxtoRem(30)};
  height: ${pxtoRem(35)};
  border: 2px solid ${(props) => props.theme.colors.BLACK};
  color: ${(props) => props.theme.colors.BLACK};
  cursor: pointer;
  font-size: ${pxtoRem(20)};
  ${(props) =>
    props.isrightside &&
    `border-left:none;
    `};

  ${(props) =>
    props.isactive &&
    ` border-color:${(props) => props.theme.colors.MEDIUM_LIGHT_SHADE_CYAN};
      color: ${(props) => props.theme.colors.MEDIUM_LIGHT_SHADE_CYAN};
    `};

  ${(props) =>
    props.noborder &&
    ` border:none;
      width: ${pxtoRem(10)};
    `};
`;

const PageItem = styled.div`
  display: flex;
  margin-right: ${pxtoRem(18)};
`;

const StyledQuestionAnswer = styled.div`
  text-align: left;
`;

const StyledBulb = styled.img`
  width: ${pxtoRem(40)};
  cursor: pointer;
`;

const StyledMarks = styled.div`
  font-size: ${pxtoRem(24)};
`;

const CommonBox = styled.div`
  background-color: ${(props) => props.theme.colors.DARK_CYAN};
  color: ${(props) => props.theme.colors.WHITE};
  font-size: ${pxtoRem(20)};
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  text-align: center;
`;

const StyledCloseBox = styled(CommonBox)`
  height: ${pxtoRem(56)};
  width: ${pxtoRem(56)};
  border-radius: 10px;
`;

const StyledColRight = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
  min-height: ${pxtoRem(300)};
`;

const StyledQuesCol = styled(Col)`
  padding-top: ${pxtoRem(20)};
  padding-bottom: ${pxtoRem(20)};
  padding-left: ${pxtoRem(120)};
  text-align: left;
`;

const StyledDescriptionContainer = styled.div`
  // background-color: ${(props) => props.theme.colors.GHOST_WHITE};
  color: ${(props) => props.theme.colors.DARK_GREY};
  font-size: ${pxtoRem(25)};
  height: ${pxtoRem(480)};
  overflow: auto;
  ::-webkit-scrollbar {
    width: ${pxtoRem(20)};
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    border: ${pxtoRem(6)} solid ${(props) => props.theme.colors.WHITE};
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: ${(props) => props.theme.colors.DARK_GREY};
  }
`;

const CloseBox = styled.div`
  background-color: ${(props) => props.theme.colors.DARK_CYAN};
  color: ${(props) => props.theme.colors.WHITE};
  font-size: ${pxtoRem(20)};
  height: ${pxtoRem(56)};
  width: ${pxtoRem(56)};
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  text-align: center;
  border-radius: 6px;
  img {
    width: ${pxtoRem(20)};
  }

  position: absolute;
  right: ${pxtoRem(20)};
  top: ${pxtoRem(20)};
`;

const ModalBody = styled(Modal.Body)`
  padding: 0px !important;
  margin: 0px !important;
`;

const ScrollCol = styled(Col)`
  padding: ${pxtoRem(60)} ${pxtoRem(140)};
  min-height: ${pxtoRem(600)};
  max-height: ${pxtoRem(600)};
`;

const ViewSa = ({
  activeItem,
  activeSubIndex,
  viewSolution,
  type,
  setViewSolution,
}) => {
  const [show, setShow] = useState(false);
  const [subQuestionNumber, setSubQuestionNumber] = useState("0");
  const subItem = useMemo(() => {
    if (activeSubIndex !== null) {
      return activeItem.children[activeSubIndex];
    } else {
      return null;
    }
  }, [activeSubIndex, activeItem]);

  useEffect(() => {
    if (activeItem && activeItem?.children[activeSubIndex]?.question_number) {
      let num = activeItem?.children[activeSubIndex]?.question_number;
      let que_num = num?.split(".");
      if (que_num[0]?.length === 1) {
        num = "0" + num;
      }
      setSubQuestionNumber(num);
    }
  }, [activeItem?.children[activeSubIndex]?.question_number]);
  const Question = () => {
    return (
      <Row>
        <StyledQuesCol
          dangerouslySetInnerHTML={{ __html: activeItem?.question }}
        />
      </Row>
    );
  };

  const SubQuestion = ({}) => {
    return (
      <Row>
        <ViewQuestion />
        <Col xs={1}>
          <PageItem>
            {subQuestionNumber?.split("").map((val, i) => {
              return (
                <StyledQuestionNo
                  isrightside={subQuestionNumber[i + 1] === "." ? true : false}
                  noborder={val === "." ? true : false}
                  key={i}
                >
                  {val}
                </StyledQuestionNo>
              );
            })}
            {/* <StyledQuestionNo>0</StyledQuestionNo>
            <StyledQuestionNo isrightside={true}>1</StyledQuestionNo>
            <StyledQuestionNo noborder={true}>.</StyledQuestionNo> */}
            {/* <span>.</span> */}
            {/* <StyledQuestionNo>1</StyledQuestionNo> */}
          </PageItem>
        </Col>
        <Col xs={9}>
          <StyledQuestionAnswer
            dangerouslySetInnerHTML={{
              __html: viewSolution
                ? subItem?.sa_question_answer
                : subItem?.question,
            }}
          />
        </Col>
        <StyledColRight xs={2}>
          <StyledMarks>
            <Row style={{ display: "flex", alignItems: "center" }}>
              {`[${subItem?.mark} marks]`}
              <Col></Col>
              {viewSolution && type === Qtype.structured_question && (
                <StyledCloseBox onClick={() => setViewSolution(!viewSolution)}>
                  <img
                    style={{ width: pxtoRem(23.76), height: pxtoRem(26.27) }}
                    src={Constant.Icons.close_white}
                    alt="close"
                  />
                </StyledCloseBox>
              )}
            </Row>
          </StyledMarks>

          {!viewSolution && type === Qtype.structured_question && (
            <StyledBulb
              onClick={() => {
                setShow(true);
              }}
              src={Constant.Icons.bulb}
              alt="12"
            />
          )}
        </StyledColRight>
      </Row>
    );
  };

  const ViewQuestion = () => {
    return (
      <Modal
        centered
        show={show}
        onHide={() => {
          setShow(false);
        }}
        size="xl"
      >
        <ModalBody>
          <Row>
            <Col>
              <CloseBox
                onClick={() => {
                  setShow(false);
                }}
              >
                <img src={Constant.Icons.close_white} alt="close" />
              </CloseBox>
            </Col>
          </Row>
          <Row>
            <ScrollCol>
              <StyledDescriptionContainer
                dangerouslySetInnerHTML={{
                  __html: activeItem?.question,
                }}
              ></StyledDescriptionContainer>
            </ScrollCol>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  return activeSubIndex !== null ? <SubQuestion /> : <Question />;
};

export default ViewSa;
