import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { Constant } from "../../../lib/Constant";
import { pxtoRem } from "../../../lib/helper";
import { theme } from "../../../lib/themeConstants";
import BoxImage from "./BoxImage";

const StyledCol = styled(Col)`
  background-color: ${(props) => props.theme.colors.WHITE};
  border: 1px solid ${(props) => props.theme.colors.DARK_GREY};
  display: flex;
  align-items: center;
  padding-left: ${pxtoRem(40)};
  font-size: ${pxtoRem(20)};
  color: ${(props) => props.theme.colors.DARK_GREY};
  border-radius: 4px;
`;

const StyledRow = styled(Row)`
  margin-bottom: ${pxtoRem(8)};
`;

const FillingBlankOption = ({ word, onDelete }) => {
  return (
    <StyledRow style={{ marginLeft: "0px" }}>
      <StyledCol off xs={8}>
        {word}
      </StyledCol>

      <Col style={{ display: "flex" }} xs={4}>
        <BoxImage bgColor={theme.colors.PURPLE} imgSrc={Constant.Icons.hint} />
        <BoxImage
          bgColor={theme.colors.PINK}
          imgSrc={Constant.Icons.delete_bg_white}
          onClick={onDelete}
        />
      </Col>
    </StyledRow>
  );
};

export default FillingBlankOption;
