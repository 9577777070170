import { Col, Form, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import { Constant, alphabets } from "../../../lib/Constant";
import { pxtoRem } from "../../../lib/helper";
import { theme } from "../../../lib/themeConstants";
import Dropdown from "../Dropdown";
import FileUpload from "../FileUpload";
import TextInput from "../TextInput";
import BoxImage from "./BoxImage";
import { useState } from "react";

const StyledLabel = styled(Form.Label)`
  font-size: ${pxtoRem(24)};
  color: ${(props) => props.theme.colors.LIGHT_GREY};
`;

const StyledErrMessage = styled(Form.Control.Feedback)`
  display: block;
  color: ${(props) => props.theme.colors.SHADE_OF_PINK_RED};
`;

const MatchingPairOption = ({
  field,
  register,
  name,
  control,
  index,
  onDelete,
  errors,
  setValue,
}) => {
  const [optionType, setOptionType] = useState({
    left: "",
    right: "",
  });
  const errorList = errors?.[name]?.[index];

  const renderLeftPortion = (side = "left") => {
    return (
      <Col>
        <Row key={field.id} className="mb-4">
          <Col xs={4}>
            <Row>
              <Col style={{ margin: "auto" }} xs={2}>
                <StyledLabel>{`${alphabets[
                  index
                ]?.toUpperCase()}.`}</StyledLabel>
              </Col>
              <Col>
                <Controller
                  isClearable
                  name={`${name}.${index}.[${side}].${0}.type`}
                  control={control}
                  rules={{ required: "Please select a question type" }}
                  render={({ field: { ref, onChange, ...rest } }) => (
                    <Dropdown
                      autoFocus
                      placeholder="Select"
                      {...rest}
                      onChange={(value) => {
                        setValue(`${name}.${index}.[${side}].${0}.answer`, "");
                        setOptionType({
                          ...optionType,
                          [side]: value,
                        });
                        onChange(value);
                      }}
                      options={[
                        {
                          value: "text",
                          label: "text",
                        },
                        {
                          value: "image",
                          label: "image",
                        },
                      ]}
                    />
                  )}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={8}>
            {optionType[side] === "text" ? (
              <>
                <TextInput
                  register={register}
                  id={`${name}.${index}.[${side}].${0}.answer`}
                  validation={{ required: "This field is required" }}
                />
                {errorList?.[side]?.[0]?.answer?.message && (
                  <StyledErrMessage>
                    {errorList?.[side]?.[0]?.answer?.message}
                  </StyledErrMessage>
                )}
              </>
            ) : (
              <>
                <TextInput
                  style={{ display: "none" }}
                  id={`${name}.${index}.[${side}].${0}.answerImage`}
                  register={register}
                  validation={{ required: "Please select image" }}
                />
                <FileUpload
                  icon={Constant.Icons.upload_arrow}
                  accept=".JPG,.JPEG,.PNG"
                  id={`${name}.${index}.[${side}].${0}.answer`}
                  register={register}
                  bgColor={theme.colors.WHITE}
                  file={field?.answerImage}
                  handleChange={(e) => {
                    setValue(
                      `${name}.${index}.[${side}].${0}.answerImage`,
                      e?.target?.files?.[0]?.name,
                      {
                        shouldValidate: true,
                        shouldDirty: true,
                      }
                    );
                  }}
                />
                {errorList?.[side]?.[0]?.answerImage?.message && (
                  <StyledErrMessage>
                    {errorList?.[side]?.[0]?.answerImage?.message}
                  </StyledErrMessage>
                )}
              </>
            )}
          </Col>
        </Row>
      </Col>
    );
  };

  return (
    <Row>
      {renderLeftPortion("left")}
      {renderLeftPortion("right")}
      <Col xs={1}>
        <BoxImage
          bgColor={theme.colors.PINK}
          imgSrc={Constant.Icons.delete_bg_white}
          onClick={onDelete}
        />
      </Col>
    </Row>
  );
};

export default MatchingPairOption;
