import { SketchPicker } from "@hello-pangea/color-picker";
import React, { useRef, useState } from "react";
import { Button, Form, InputGroup, Overlay } from "react-bootstrap";
import styled from "styled-components";
import { Constant } from "../../lib/Constant";
import { pxtoRem } from "../../lib/helper";

const StyledError = styled.p`
  color: red;
`;

const StyledInputGroup = styled(InputGroup)`
  height: auto;
  width: 100%;
`;

const StyledPickerInputContainer = styled.div`
  width: auto;
  height: auto;
`;

const StyledColorPopover = styled.div`
  position: "absolute";
  width: ${pxtoRem(425)};
  height: ${pxtoRem(459)};
  box-shadow: 0px 3px 6px ${(props) => props.theme.COLORPICKER.SKY_BLUE};
  border: 1px solid ${(props) => props.theme.COLORPICKER.DEEP_BLUE};
  border-radius: ${pxtoRem(4)};
  background-color: ${(props) => props.theme.colors.WHITE};
  z-index: 3;
`;

const StyledPopoverHeader = styled.div`
  font-size: ${pxtoRem(20)};
  border-bottom: 1px solid ${(props) => props.theme.COLORPICKER.GREY};
  padding: ${pxtoRem(10)} ${pxtoRem(20)};
`;

const StyledInput = styled(Form.Control)`
  border: 1px solid ${(props) => props.theme.colors.LIGHT_GREY};
  border-radius: ${pxtoRem(10)};
  border-right-style: none;
  color: ${(props) => props.theme.colors.MEDIUM_GREY};
  font-size: ${pxtoRem(20)};

  &:focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    box-shadow: none;
    border: 1px solid ${(props) => props.theme.colors.LIGHT_GREY};
    border-right-style: none;
  }
`;

const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.WHITE};
  border: 1px solid ${(props) => props.theme.colors.LIGHT_GREY};
  border-left-style: none;
  border-radius: ${pxtoRem(10)};
  &:hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: ${(props) => props.theme.colors.WHITE};
    border: 1px solid ${(props) => props.theme.colors.LIGHT_GREY};
    border-left-style: none;
  }
  &:focus-visible {
    background-color: ${(props) => props.theme.colors.WHITE};
    box-shadow: none;
    border-color: ${(props) => props.theme.colors.LIGHT_GREY};
  }
`;

const StyledCloseIcon = styled.img`
  cursor: pointer;
  height: ${pxtoRem(20)};
  width: ${pxtoRem(20)};
`;

const StyledPickerIcon = styled.img`
  height: ${pxtoRem(42)};
  width: ${pxtoRem(42)};
  &:focus-visible {
    box-shadow: 0 0 0 0.25rem
      rgba(${(props) => props.theme.colors.BOX_SHADOW_BG}, 0.5);
    outline: none;
  }
`;

const StyledSketchPicker = styled(SketchPicker)`
  width: ${pxtoRem(390)} !important;
  height: ${pxtoRem(385)} !important;
  border: none !important;
  border-radius: 0px !important;

  & > div:first-child {
    padding-bottom: 30% !important;
    border-radius: ${pxtoRem(19)} !important;
    width: ${pxtoRem(378)} !important;
    height: ${pxtoRem(155)} !important;
    padding-left: ${pxtoRem(20)} !important;
    margin-left: ${pxtoRem(10)};
    border: 1px solid #707070;
  }

  & > .flexbox-fix:not(:last-child) {
    width: ${pxtoRem(378)} !important;
    margin-left: ${pxtoRem(10)};
    margin-top: ${pxtoRem(8)};
  }

  .hue-horizontal {
    width: ${pxtoRem(325)} !important;
    height: ${pxtoRem(15)} !important;
    border-radius: ${pxtoRem(12)};
  }

  .hue-horizontal div div {
    height: ${pxtoRem(13)} !important;
  }
  &div:last-child {
    margin-left: 0px !important;
  }

  & > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) {
    height: ${pxtoRem(15)} !important;
  }

  & > div:nth-child(2) > div:nth-child(1) > div:nth-child(2) {
    width: ${pxtoRem(325)} !important;
    height: ${pxtoRem(15)} !important;
    border-radius: ${pxtoRem(12)} !important;
  }
  & > div:nth-child(2) > div:nth-child(1) > div:nth-child(2) div div {
    height: ${pxtoRem(15)} !important;
  }

  & > div:nth-child(2) > div:nth-child(2) {
    width: ${pxtoRem(36)} !important;
    height: ${pxtoRem(36)} !important;
  }

  & > div:nth-child(2) > div:nth-child(2) > div:nth-child(2) {
    border-radius: ${pxtoRem(18)} !important;
  }

  & > .flexbox-fix input {
    border: 1px solid #a4bfbd90;
    border-radius: ${pxtoRem(4)};
    opacity: 1;
    /* color: #B4B4B4; */
    height: ${pxtoRem(34)};
    font-size: ${pxtoRem(14)};
  }

  .flexbox-fix label {
    margin-right: ${pxtoRem(15)};
    height: ${pxtoRem(26)} !important;
    font-size: ${pxtoRem(16)} !important;
  }
`;

const StyledHeader = styled.span`
  font-size: ${pxtoRem(22)};
  font-weight: bold;
`;

const CustomPopover = ({ target, show, setShow, color, handleChange }) => (
  <Overlay target={target.current} show={show} placement="left">
    {({
      placement: _placement,
      arrowProps: _arrowProps,
      show: _show,
      popper: _popper,
      hasDoneInitialMeasure: _hasDoneInitialMeasure,
      ...props
    }) => (
      <StyledColorPopover {...props}>
        <StyledPopoverHeader className="d-flex justify-content-between">
          <div className="d-flex flex-direction-row w-100 justify-content-between align-items-center">
            <StyledHeader>Color Picker</StyledHeader>
            <StyledCloseIcon
              onClick={() => setShow(false)}
              src={Constant.Icons.cross}
              alt="close"
              tabIndex={0}
            />
          </div>
        </StyledPopoverHeader>
        <StyledSketchPicker
          color={color}
          onChange={handleChange}
          onChangeComplete={handleChange}
        />
      </StyledColorPopover>
    )}
  </Overlay>
);

function ColorPicker({
  placeholder,
  color,
  onChange,
  register,
  id,
  validation,
  colorError,
}) {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const handleChange = (color) => {
    onChange(color.hex);
  };
  return (
    <StyledPickerInputContainer>
      <StyledInputGroup>
        <StyledInput
          type="text"
          placeholder={placeholder}
          {...register(id, validation)}
        />
        <StyledButton ref={target} onClick={() => setShow(!show)} tabIndex={-1}>
          <StyledPickerIcon
            src={Constant.Icons.colorPicker}
            alt="filter"
            tabIndex={0}
          />
        </StyledButton>
        <CustomPopover
          target={target}
          show={show}
          setShow={setShow}
          color={color}
          handleChange={handleChange}
        />
      </StyledInputGroup>
      {colorError?.type === "pattern" && (
        <StyledError>Please Add Proper Color Code.</StyledError>
      )}
    </StyledPickerInputContainer>
  );
}

export default ColorPicker;
