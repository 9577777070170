import React from "react";
import CurriculumForm from "../../components/form/CurriculumForm";
import { FormProvider, useForm } from "react-hook-form";

const CreateSyllabus = () => {
  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm();

  const onSubmit = () => {};

  const methods = {
    register,
    errors,
    watch,
    getValues,
    setValue,
    isDirty,
    control,
    isValid,
  };
  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit())}>
          <CurriculumForm />
        </form>
      </FormProvider>
    </>
  );
};

export default CreateSyllabus;
