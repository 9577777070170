import { Col, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Button from "../../components/shared/Button";
import Dropdown from "../../components/shared/Dropdown";
import FormControl from "../../components/shared/FormControl";
import { Spinner } from "../../components/shared/Spinner";
import {
  useCountries,
  useExamBoards,
  useSubjects,
  useYears,
  useLevels,
} from "../../hooks/chapter";
import ContainerComponent from "../shared/ContainerComponent";
import styled from "styled-components";
import { pxtoRem } from "../../lib/helper";
import { theme } from "../../lib/themeConstants";
import InputTime from "../shared/InputTime";
import PageHeader from "../shared/PageHeader";
import SectionHeader from "../shared/SectionHeader";
import { FilteredQuestionTypes } from "../../lib/Constant";
import { useChapterFilters, useViewWorksheet } from "../../hooks/worksheet";
import { useState } from "react";
import { useEffect } from "react";
import MultipleDropdown from "../shared/MultipleDropdown";
import { forEach } from "lodash";
import TextInput from "../shared/TextInput";
import FileUpload from "../shared/FileUpload";

const StyledBox = styled(Row)`
  width: ${(props) => (props.isedit !== "true" ? "95%" : "99%")};
  padding-left: ${pxtoRem(60)};
  margin-top: ${pxtoRem(40)};
`;

const StyledButton = styled(Button)`
  margin-right: -${pxtoRem(30)};
`;

const WorkSheetForm = ({
  isLoading,
  isedit = false,
  isDisabled = false,
  setBreadCrumData,
  defaultValues = null,
}) => {
  const {
    control,
    register,
    errors,
    isDirty,
    isValid,
    setValue,
    getValues,
    watch,
    reset,
  } = useFormContext();

  const [chapters, setChapters] = useState([]);
  let country_id = watch("country_id");
  let exam_board_id = watch("exam_board_id");
  let subject_id = watch("subject_id");
  let year_id = watch("year_id");
  let level_id = watch("level_id");

  const navigate = useNavigate();
  const { data: worksheets, isFetching: worksheetsLoading } =
    useViewWorksheet();
  const { data: lstCountries, isFetching: lstCountriesLoading } =
    useCountries();
  const { data: lstGrades, isFetching: lstGradesLoading } = useYears(
    watch("country_id"),
    watch("subject_id")
  );
  const { data: lstExamBoards, isFetching: lstExamBoardsLoading } =
    useExamBoards(watch("country_id"));
  const { data: lstSubjects, isFetching: lstSubjectsLoading } = useSubjects();
  const { data: levels, isFetching: levelsLoading } = useLevels(
    watch("country_id")
  );
  const { data: lstChpaters, isFetching: lstChpatersLoading } =
    useChapterFilters(
      watch("country_id"),
      watch("exam_board_id"),
      watch("subject_id"),
      watch("year_id"),
      watch("level_id"),
      true
    );

  const getBredCrupData = () => {
    const keys = [
      { exam_board_id: lstExamBoards },
      { level_id: levels },
      { subject_id: lstSubjects },
      { year_id: lstGrades },
      { chapter_id: lstChpaters },
    ];

    const data = keys.map((item) => {
      const [key] = Object.keys(item);

      if (typeof getValues(key) !== "number") {
        return getValues(key)
          .map((id) => item[key].find((value) => value.id === id && value).name)
          .join(", ");
      }

      return item[key].find((value) => value.id === getValues(key) && value)
        ?.name;
    });

    data.push(getValues("question_type"));
    setBreadCrumData && setBreadCrumData(data);
  };

  useEffect(() => {
    if (
      watch("country_id") &&
      watch("exam_board_id") &&
      watch("level_id") &&
      watch("subject_id") &&
      watch("year_id")?.length > 0
    ) {
      let chaps = lstChpaters?.filter((item) => {
        let addedWorksheet = worksheets?.find(
          (sheet) => sheet?.chapter?.id === item.id
        );

        if (!addedWorksheet) {
          return true;
        } else {
          return false;
        }
      });
      if (defaultValues?.chapter_id) {
        let defaultChapter = lstChpaters?.find(
          (chap) => chap?.id === parseInt(defaultValues?.chapter_id)
        );
        defaultChapter && chaps?.push(defaultChapter);
        setChapters(chaps);
      }
      chaps && setChapters(chaps);
    } else {
      setValue("chapter_id", null);
      setChapters([]);
    }
    // eslint-disable-next-line
  }, [country_id, exam_board_id, subject_id, year_id, level_id, lstChpaters]);

  useEffect(() => {
    let years_data = [];
    if (defaultValues?.chapter?.year_id) {
      forEach(defaultValues?.chapter?.year_id, (value) => {
        years_data.push(parseInt(value));
      });
    }

    let data = {
      country_id: defaultValues?.chapter?.country_id
        ? parseInt(defaultValues?.chapter?.country_id)
        : null,
      exam_board_id: defaultValues?.chapter?.exam_board?.id
        ? parseInt(defaultValues?.chapter?.exam_board?.id)
        : null,
      level_id: defaultValues?.chapter?.year_id
        ? defaultValues.chapter.years_data[0].key_stage[0].id
        : null,
      year_id: defaultValues?.chapter?.year_id ? years_data : [],
      subject_id: defaultValues?.chapter?.subject_id
        ? parseInt(defaultValues?.chapter?.subject_id)
        : null,
      chapter_id: defaultValues?.chapter_id
        ? parseInt(defaultValues?.chapter_id)
        : null,
      question_type: defaultValues?.question_type
        ? defaultValues?.question_type
        : null,
      duration_hours: defaultValues?.duration_hours
        ? defaultValues?.duration_hours
        : null,
      duration_minutes: defaultValues?.duration_minutes
        ? defaultValues?.duration_minutes
        : null,
      coverImageFile: isedit && defaultValues?.cover_image,
    };
    reset(data, { keepDirty: true });
    // eslint-disable-next-line
  }, [defaultValues, reset]);

  return (
    <>
      <Spinner
        isLoading={
          isLoading ||
          worksheetsLoading ||
          lstCountriesLoading ||
          lstGradesLoading ||
          lstExamBoardsLoading ||
          lstSubjectsLoading ||
          levelsLoading ||
          lstChpatersLoading
        }
      />
      {!isedit && (
        <>
          <PageHeader
            title="Worksheet"
            control={
              <Button
                text="Back"
                bgColor={theme.colors.NEBULA_BLUE}
                onClick={() => {
                  navigate("/worksheet");
                }}
              />
            }
          ></PageHeader>

          <SectionHeader title="Create new Worksheet"></SectionHeader>
        </>
      )}
      <ContainerComponent>
        {/* <form onSubmit={handleSubmit(handleFormSubmit)}> */}
        <StyledBox style={{ marginBottom: "2rem" }} isedit={isedit.toString()}>
          <Col>
            <FormControl label="Country *">
              <Controller
                isClearable
                name="country_id"
                control={control}
                rules={{ required: "Please select country" }}
                render={({ field: { ref, ...rest } }) => (
                  <Dropdown
                    autoFocus
                    placeholder="Select country"
                    {...rest}
                    options={
                      lstCountries?.map((item) => {
                        return {
                          label: item.name,
                          value: item.id,
                        };
                      }) || []
                    }
                    isClearable
                    onChange={(value) => {
                      setValue("country_id", value, { shouldValidate: true });
                      setValue("exam_board_id", null);
                      setValue("level_id", null);
                      setValue("year_id", null);
                    }}
                  />
                )}
              />
            </FormControl>

            <FormControl label="Exam board *">
              <Controller
                isClearable
                name="exam_board_id"
                control={control}
                rules={{ required: "Please select exam board" }}
                render={({ field: { ref, ...rest } }) => (
                  <Dropdown
                    placeholder="Select exam board"
                    {...rest}
                    options={
                      lstExamBoards?.map((item) => {
                        return {
                          label: item.name,
                          value: item.id,
                        };
                      }) || []
                    }
                    isClearable
                  />
                )}
              />
            </FormControl>

            <FormControl label="Level *">
              <Controller
                isClearable
                name="level_id"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <Dropdown
                    placeholder="Select level"
                    {...rest}
                    options={
                      levels?.map((item) => {
                        return {
                          label: item.name,
                          value: item.id,
                        };
                      }) || []
                    }
                    isClearable
                  />
                )}
              />
            </FormControl>
            <FormControl label="Subject *">
              <Controller
                name="subject_id"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <Dropdown
                    placeholder="Select subject"
                    {...rest}
                    options={
                      lstSubjects?.map((item) => {
                        return {
                          label: item.name,
                          value: item.id,
                        };
                      }) || []
                    }
                    isClearable
                    onChange={(value) => {
                      setValue("subject_id", value, { shouldValidate: true });
                      setValue("year_id", null);
                    }}
                  />
                )}
              />
            </FormControl>

            <FormControl label="Grade *">
              <Controller
                isClearable
                name="year_id"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <MultipleDropdown
                    placeholder="Select grade"
                    {...rest}
                    options={
                      lstGrades?.map((item) => {
                        return {
                          label: item?.name,
                          value: item?.id,
                        };
                      }) || []
                    }
                    isClearable
                  />
                )}
              />
            </FormControl>
          </Col>
          <Col md={{ offset: 1 }}>
            <FormControl
              label="Chapter *"
              errMessage={errors?.chapter_id?.message}
            >
              <Controller
                isClearable
                name="chapter_id"
                rules={{ required: "Please select chapter" }}
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <>
                    <Dropdown
                      placeholder="Select chapter"
                      {...rest}
                      options={
                        chapters?.map((item) => {
                          return {
                            label: item?.name,
                            value: item?.id,
                          };
                        }) || []
                      }
                    />
                  </>
                )}
              />
            </FormControl>

            <FormControl
              label="Cover image *"
              errMessage={errors?.cover_image?.message}
            >
              <TextInput
                style={{ display: "none" }}
                id="coverImageFile"
                register={register}
                validation={{ required: "Please select cover image" }}
              />
              <FileUpload
                isForm={true}
                iscustom={true}
                file={
                  typeof defaultValues?.cover_image === "string"
                    ? defaultValues?.cover_image
                    : defaultValues?.coverImageFile
                }
                accept=".JPG,.JPEG,.PNG"
                id="cover_image"
                register={register}
                handleChange={(e) => {
                  setValue("coverImageFile", e?.target?.files?.[0]?.name, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
              />
            </FormControl>
            <FormControl
              label="Question type *"
              errMessage={errors?.question_type?.message}
            >
              <Controller
                isClearable
                name="question_type"
                control={control}
                rules={{ required: "Please select a question type" }}
                render={({ field: { ref, ...rest } }) => (
                  <Dropdown
                    autoFocus
                    placeholder="Select question"
                    {...rest}
                    options={FilteredQuestionTypes}
                    isDisabled={isDisabled}
                  />
                )}
              />
            </FormControl>
            <FormControl label="Total duration *">
              <InputTime
                errMessage={
                  errors?.duration_hours?.message ||
                  errors?.duration_minutes?.message
                }
                register={register}
              />
            </FormControl>
          </Col>
        </StyledBox>
        <Row>
          <Col style={{ textAlign: "end" }}>
            <StyledButton
              onClick={getBredCrupData}
              type="submit"
              bgColor={theme.colors.NEBULA_BLUE}
              disabled={isDirty && isValid ? false : true}
              text={isedit ? "Update" : "Next"}
            />
          </Col>
        </Row>
        {/* </form> */}
      </ContainerComponent>
    </>
  );
};

export default WorkSheetForm;
