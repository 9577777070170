import React from "react";
import styled from "styled-components";
import { pxtoRem } from "../../lib/helper";

const StyledAvatarName = styled.div`
  &&& {
    border-radius: 50%;
    background-color: ${(props) => props.theme.AvatarColors[0]};
    color: #fff;
    text-align: center;
    width: ${pxtoRem(46)};
    height: ${pxtoRem(46)};
    font-size: ${pxtoRem(26)};
    line-height: ${pxtoRem(42)};
    margin-right: 0.625rem;
    flex: 0 0 ${pxtoRem(46)};
    margin-left: 0.625rem;
    /* font-family: Google Sans; */
  }
`;

function AvatarName({ name }) {
  let nameInitial = name.charAt(0).toUpperCase();
  return <StyledAvatarName>{nameInitial}</StyledAvatarName>;
}

export default AvatarName;
