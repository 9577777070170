import { useQuery } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useLessons = (chapter_id, page) => {
  const formData = {};
  if (chapter_id) formData.chapter_id = chapter_id;
  if (page) {
    formData.pagination = true;
    formData.page = page;
  }
  return useQuery(
    ["lessons", chapter_id, page],
    async () => {
      const { data } = await axiosPost("/api/cw/viewLesson", formData);
      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

export default useLessons;
