import { useMutation } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useStatusChange = () => {
  return useMutation((data) => {
    return axiosPost("/api/cw/selfTestStatusChange", data);
  });

};

export default useStatusChange;
