import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import ChapterForm from "../../components/form/ChapterForm";
import { Spinner } from "../../components/shared/Spinner";
import AlertPopUp from "../../components/shared/popups/AlertPopUp";
import { useDeleteChapter, useUpdateChapter } from "../../hooks/chapter";
import useChapter from "../../hooks/chapter/useChapter";
import { Constant } from "../../lib/Constant";
import { useQueryClient } from "react-query";

const EditChapter = () => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const chapterQuery = useChapter(id);
  const [isModal, setIsModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const updateMutation = useUpdateChapter();
  const deleteMutation = useDeleteChapter();
  const [formData, setFormData] = useState();
  const navigate = useNavigate();

  if (chapterQuery.isLoading) {
    return <Spinner isLoading={true} />;
  }

  const handleFormSubmit = (data) => {
    setFormData(data);
    setIsModal(true);
  };

  return (
    <>
      <AlertPopUp
        confirmText="Delete"
        cancelText="Cancel"
        onCancel={() => {
          setIsDeleteModal(false);
        }}
        show={isDeleteModal}
        isdelete={true}
        onConfirm={() => {
          setIsDeleteModal(false);
          const fData = new FormData();
          fData.set("id", id);
          deleteMutation.mutate(fData, {
            onError: (res) => {
              toast.error(res?.response?.data?.message);
            },
            onSuccess: () => {
              queryClient.invalidateQueries("chapters");
              toast.success(Constant.SuccessMessages.deleteChapter);
              navigate("/chapters");
            },
          });
        }}
        header="Are you sure you want to delete the chapter ?"
        body="If you will delete the chapter all the lesson associate with this will also be deleted"
      />
      <AlertPopUp
        onCancel={() => {
          setIsModal(false);
        }}
        onConfirm={() => {
          setIsModal(false);
          const data = formData;
          data.set("id", id);
          updateMutation.mutate(formData, {
            onError: (res) => {
              toast.error(res?.response?.data?.message);
            },
            onSuccess: () => {
              queryClient.invalidateQueries("chapters");
              toast.success(Constant.SuccessMessages.editChapter);
              navigate(`/chapters/${id}/show`, {
                state: { id: id },
              });
            },
          });
        }}
        show={isModal}
        header="Do you want to save the changes ?"
      />
      <ChapterForm
        handleFormSubmit={handleFormSubmit}
        defaultValue={chapterQuery?.data?.[0]}
        title="Edit chapter details"
        isLoading={updateMutation.isLoading || deleteMutation.isLoading}
        handleDelete={() => {
          setIsDeleteModal(true);
        }}
      />
    </>
  );
};

export default EditChapter;
