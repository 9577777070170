import ViewDemo from "../../layout/ViewDemo";
import { Qtype } from "../../lib/Constant";

const dummyData = [
  {
    question_number: 1,
    isCompleted: false,
    isCurrent: true,
    isDisabled: false,
    showSubQuestion: true,
    question: `<p> A student investigated the reactivity of metals with hydrochloric acid. 1</p>
    
    A student investigated the reactivity of metals with hydrochloric acid.
    A student investigated the reactivity of metals with hydrochloric acid.
    A student investigated the reactivity of metals with hydrochloric acid.
    A student investigated the reactivity of metals with hydrochloric acid.
    A student investigated the reactivity of metals with hydrochloric acid.
    A student investigated the reactivity of metals with hydrochloric acid.

    A student investigated the reactivity of metals with hydrochloric acid.
    A student investigated the reactivity of metals with hydrochloric acid.
    A student investigated the reactivity of metals with hydrochloric acid.
    A student investigated the reactivity of metals with hydrochloric acid.
    A student investigated the reactivity of metals with hydrochloric acid.
    A student investigated the reactivity of metals with hydrochloric acid.

    A student investigated the reactivity of metals with hydrochloric acid.
    A student investigated the reactivity of metals with hydrochloric acid.
    A student investigated the reactivity of metals with hydrochloric acid.
    A student investigated the reactivity of metals with hydrochloric acid.
    A student investigated the reactivity of metals with hydrochloric acid.
    A student investigated the reactivity of metals with hydrochloric acid.
    <b>please read carefully </b>
    <p>What is your favourit sport</p>
    <div>
    <b>cricket </b>
    </div>

    A student investigated the reactivity of metals with hydrochloric acid.
    A student investigated the reactivity of metals with hydrochloric acid.
    A student investigated the reactivity of metals with hydrochloric acid.
    A student investigated the reactivity of metals with hydrochloric acid.
    A student investigated the reactivity of metals with hydrochloric acid.
    A student investigated the reactivity of metals with hydrochloric acid.

    A student investigated the reactivity of metals with hydrochloric acid.
    A student investigated the reactivity of metals with hydrochloric acid.
    A student investigated the reactivity of metals with hydrochloric acid.
    A student investigated the reactivity of metals with hydrochloric acid.
    A student investigated the reactivity of metals with hydrochloric acid.
    A student investigated the reactivity of metals with hydrochloric acid.

    A student investigated the reactivity of metals with hydrochloric acid.
    A student investigated the reactivity of metals with hydrochloric acid.
    A student investigated the reactivity of metals with hydrochloric acid.
    A student investigated the reactivity of metals with hydrochloric acid.
    A student investigated the reactivity of metals with hydrochloric acid.
    A student investigated the reactivity of metals with hydrochloric acid.
    <b>please read carefully </b>
    <p>What is your favourit sport</p>
    <div>
    <b>cricket </b>
    </div>
    `,
    children: [
      {
        question_number: "1.1",
        isCompleted: true,
        isCurrent: false,
        isDisabled: false,
        marks: "10",
        question: "<p>sub question 1</p>",
        answer: "<p>sub question answer 1</p>",
      },
      {
        question_number: "1.2",
        isCompleted: true,
        isCurrent: false,
        isDisabled: false,
        marks: "5",
        question: "<p>sub question 2</p>",
        answer: "<p>answer 2</p>",
      },
      {
        question: "<p>sub question 3</p>",
        question_number: "1.3",
        isCompleted: false,
        isCurrent: true,
        isDisabled: false,
      },
    ],
  },
  {
    question: "<p> question 2</p>",
    question_number: 2,
    isCompleted: false,
    isCurrent: false,
    isDisabled: true,
  },
  {
    question: "<p> question 3</p>",
    question_number: 3,
    isCompleted: false,
    isCurrent: false,
    isDisabled: true,
  },
  {
    question: "<p> question 4</p>",
    question_number: 4,
    isCompleted: false,
    isCurrent: false,
    isDisabled: true,
  },
  {
    question: "<p> question 5</p>",
    question_number: 5,
    isCompleted: false,
    isCurrent: false,
    isDisabled: true,
  },
  {
    question: "<p> question 6</p>",
    question_number: 6,
    isCompleted: false,
    isCurrent: false,
    isDisabled: true,
    children: [
      {
        question_number: "1.1",
        isCompleted: true,
        isCurrent: false,
        isDisabled: false,
        marks: "10",
        question: "<p>sub question 1</p>",
        answer: "<p>sub question answer 1</p>",
      },
      {
        question_number: "1.2",
        isCompleted: true,
        isCurrent: false,
        isDisabled: false,
        marks: "5",
        question: "<p>sub question 2</p>",
        answer: "<p>answer 2</p>",
      },
      {
        question: "<p>sub question 3</p>",
        question_number: "1.3",
        isCompleted: false,
        isCurrent: true,
        isDisabled: false,
      },
    ],
  },
  {
    question: "<p> question 7</p>",
    question_number: 7,
    isCompleted: false,
    isCurrent: false,
    isDisabled: true,
  },
  {
    question: "<p> question 8</p>",
    question_number: 8,
    isCompleted: false,
    isCurrent: false,
    isDisabled: true,
  },
  {
    question: "<p> question 9</p>",
    question_number: 9,
    isCompleted: false,
    isCurrent: false,
    isDisabled: true,
  },
  {
    question: "<p> question 10</p>",
    question_number: 10,
    isCompleted: false,
    isCurrent: false,
    isDisabled: true,
    children: [
      {
        question_number: "1.1",
        isCompleted: true,
        isCurrent: false,
        isDisabled: false,
        marks: "10",
        question: "<p>sub question 1</p>",
        answer: "<p>sub question answer 1</p>",
      },
      {
        question_number: "1.2",
        isCompleted: true,
        isCurrent: false,
        isDisabled: false,
        marks: "5",
        question: "<p>sub question 2</p>",
        answer: "<p>answer 2</p>",
      },
    ],
  },
];

const ViewParentActivity = () => {
  return <ViewDemo type={Qtype.structured_question} list={dummyData} />;
};

export default ViewParentActivity;
