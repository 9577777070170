import { useQuery } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useViewPastPaperDemo = (past_paper_id) => {
  const formData = {};
  if (past_paper_id) formData.past_paper_id = past_paper_id;

  return useQuery(
    ["viewPastPaperDemo", past_paper_id],
    async () => {
      const { data } = await axiosPost(
        "/api/cw/pastPaperDemoQuestion",
        formData
      );
      return data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!past_paper_id,
    }
  );
};

export default useViewPastPaperDemo;
