import { useQuery } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useViewWorksheetDemo = (work_sheet_id) => {
  const formData = {};
  if (work_sheet_id) formData.work_sheet_id = work_sheet_id;

  return useQuery(
    ["viewWorksheetDemo", work_sheet_id],
    async () => {
      const { data } = await axiosPost(
        "/api/cw/workSheetDemoQuestion",
        formData
      );
      return data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!work_sheet_id,
    }
  );
};

export default useViewWorksheetDemo;
