import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  questionsData: null,
  currentQuestionIndex: 0,
  currentSubQuestionIndex: 0,
  startEndIndex: { start: 0, end: 9 },
  startEndSubQuestionIndex: { start: 0, end: 9 },
  totalActiveQuestion: 0,
  totalActiveSubQuestion: 0,
};

export const questionsSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    setQuestionsData: (state, action) => {
      state.questionsData = action.payload;
    },
    setCurrentQuestionIndex: (state, action) => {
      state.currentQuestionIndex = action.payload;
    },
    setCurrentSubQuestionIndex: (state, action) => {
      state.currentSubQuestionIndex = action.payload;
    },
    setStartEndIndex: (state, action) => {
      state.startEndIndex = action.payload;
    },
    setStartEndSubQuestionIndex: (state, action) => {
      state.startEndSubQuestionIndex = action.payload;
    },
    setTotalActiveQuestion: (state, action) => {
      state.totalActiveQuestion = action.payload;
    },
    setTotalActiveSubQuestion: (state, action) => {
      state.totalActiveSubQuestion = action.payload;
    },
    resetQuestions: (state, action) => {
      state.questionsData = null;
      state.currentQuestionIndex = 0;
      state.currentSubQuestionIndex = 0;
      state.startEndIndex = { start: 0, end: 9 };
      state.startEndSubQuestionIndex = { start: 0, end: 9 };
      state.totalActiveQuestion = 0;
      state.totalActiveSubQuestion = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setQuestionsData,
  setCurrentQuestionIndex,
  setCurrentSubQuestionIndex,
  setStartEndIndex,
  setStartEndSubQuestionIndex,
  resetQuestions,
  setTotalActiveQuestion,
  setTotalActiveSubQuestion,
} = questionsSlice.actions;

export default questionsSlice.reducer;
