import { Form } from "react-bootstrap";
import styled from "styled-components";
import { pxtoRem } from "../../lib/helper";

const StyledInput = styled(Form.Control)`
  border: 1px solid ${(props) => props.theme.colors.LIGHT_GREY};
  border-radius: ${pxtoRem(10)};
  color: ${(props) => props.theme.colors.MEDIUM_GREY};
  font-size: ${pxtoRem(20)};
  height: ${pxtoRem(60)};

  &:focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    box-shadow: none;
    border: 1px solid ${(props) => props.theme.colors.LIGHT_GREY};
    color: ${(props) => props.theme.colors.MEDIUM_GREY};
  }

  &:disabled {
    background-color: ${(props) => props.theme.colors.DISABLED_INPUT_BG};
    border-color: ${(props) => props.theme.colors.DISABLED_BORDER};
  }
`;

const TextInput = ({
  id,
  register,
  validation,
  allowNegative = false,
  ...rest
}) => {
  const preventMinus = (e) => {
    if (!allowNegative) {
      if (e.code === "Minus") {
        e.preventDefault();
      }
    }
  };

  const preventPasteNegative = (e) => {
    if (!allowNegative) {
      const clipboardData = e.clipboardData || window.clipboardData;
      const pastedData = parseFloat(clipboardData.getData("text"));

      if (pastedData < 0) {
        e.preventDefault();
      }
    }
  };

  const preventKeyDown = (e) => {
    const tValue = e.target.value;
    if (!allowNegative) {
      if (
        (tValue === "0" || tValue === "1" || tValue === "") &&
        e.keyCode === 40
      ) {
        e.preventDefault();
      }
    }
  };

  return (
    <>
      {register ? (
        <StyledInput
          onKeyDown={preventKeyDown}
          onPaste={preventPasteNegative}
          onKeyPress={preventMinus}
          {...register(id, validation)}
          {...rest}
        />
      ) : (
        <StyledInput
          onKeyDown={preventKeyDown}
          onPaste={preventPasteNegative}
          onKeyPress={preventMinus}
          {...rest}
        />
      )}
    </>
  );
};

export default TextInput;
