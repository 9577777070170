import React from "react";
import { Navbar as BNavbar } from "react-bootstrap";
import { Popover, OverlayTrigger, NavDropdown } from "react-bootstrap";
import { Constant } from "../../lib/Constant";
import { notificationList } from "../../lib/dummyData";
import NotificationItem from "./NotificationItem";
import Avatar from "./Avatar";
import styled from "styled-components";
import { pxtoRem } from "../../lib/helper";
import { useLogout } from "../../hooks/auth";
import { toast } from "react-toastify";
import history from "../../lib/history";
import { useState } from "react";
import AlertPopUp from "./popups/AlertPopUp";
import { resetStore } from "../../store";

const StyledNavbar = styled(BNavbar)`
  background-color: ${(props) => props.theme.colors.WHITE};
  height: ${pxtoRem(108)};
`;

const StyledCloseButton = styled.img`
  &&& {
    width: ${pxtoRem(20)};
    height: ${pxtoRem(20)};
    margin-right: ${pxtoRem(8)};
  }
`;

const StyledViewAllLink = styled.span`
  display: flex;
  justify-content: end;
  font-size: ${pxtoRem(18)};
  font-weight: bold;
  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.NEBULA_BLUE};
    padding-right: ${pxtoRem(15)};
    padding-top: ${pxtoRem(10)};
  }
`;
const NotificationCount = styled.span`
  background-color: ${(props) => props.theme.colors.DEEP_SKY_BLUE};
  color: ${(props) => props.theme.colors.WHITE};
  border-radius: 50%;
  font-size: ${pxtoRem(12)};
  font-weight: 600;
  text-align: center;
  line-height: 2;
  height: ${pxtoRem(20)};
  width: ${pxtoRem(20)};
  margin-left: -1rem;
  z-index: 1;

  position: absolute;
  top: 0;
  right: 0;
`;

const StyledAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    margin-right: ${pxtoRem(10)} !important;
  }
`;
const StyledProfileName = styled.span`
  font-weight: 600;
  font-size: ${pxtoRem(20)};
`;

const StyledProfileHello = styled.span`
  font-size: ${pxtoRem(20)};
`;

const StyledDropdownArrow = styled.div`
  padding-left: ${pxtoRem(60)};
  padding-top: ${pxtoRem(20)};
`;

const StyledProfilePosition = styled.span`
  display: flex !important;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: ${pxtoRem(16)};
  max-width: ${pxtoRem(118)};
  height: ${pxtoRem(26)};
  color: ${(props) => props.theme.colors.NAVY_BLUE};
  opacity: 0.5;
`;

const StyledNavIcons = styled.img`
  width: ${pxtoRem(33)};
  height: ${pxtoRem(33)};
  cursor: pointer;
`;

const StyledMenuNavIcon = styled.img`
  width: ${pxtoRem(28)};
  height: ${pxtoRem(28)};
  cursor: pointer;
  margin-left: ${pxtoRem(50)};
`;

const StyledUserGreet = styled.span`
  display: flex !important;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: ${pxtoRem(118)};
  height: ${pxtoRem(26)};
  font-size: ${pxtoRem(16)};
  color: ${(props) => props.theme.colors.NAVY_BLUE};
`;

const StyledProfileDropdownArrow = styled.img`
  width: ${pxtoRem(13)};
  height: ${pxtoRem(7)};
`;

const StyledProfileItemIcon = styled.img`
  flex-basis: 20px;
  height: ${pxtoRem(22)};
  width: ${pxtoRem(22)};
  margin-right: ${pxtoRem(10)};
`;

const StyledNavDropdownItem = styled(NavDropdown.Item)`
  align-items: center;
  font-size: ${pxtoRem(16)};
  padding-left: ${pxtoRem(110)};
  &:hover {
    background-color: ${(props) => props.theme.colors.WHITE};
  }
`;

const AvatarStyle = { height: `${pxtoRem(56)}`, width: `${pxtoRem(56)}` };

const StyledProfileSection = styled.div`
  margin-left: ${pxtoRem(80)};
  margin-right: ${pxtoRem(50)};

  .profile-section {
    /* background-color: pink; */
    padding: ${pxtoRem(10)};
    border: 1px solid #ffffff;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    border-bottom: none;
    width: ${pxtoRem(300)};
  }

  .dropdown-menu {
    width: ${pxtoRem(300)};
  }
`;

const NavbarDropdown = ({ data, logoutUser }) => (
  <StyledProfileSection className="profile">
    <NavDropdown
      title={
        <div className="profile-section">
          <div className="d-flex flex-row">
            <StyledAvatar>
              <Avatar
                src={Constant.Images.profile}
                style={AvatarStyle}
              ></Avatar>
            </StyledAvatar>
            <div className="d-flex flex-column justify-content-center">
              <StyledUserGreet className="d-inline-block">
                <StyledProfileHello>Hello,&nbsp;</StyledProfileHello>
                <StyledProfileName> {data.name} </StyledProfileName>
              </StyledUserGreet>
              <StyledProfilePosition>{data.designation}</StyledProfilePosition>
            </div>
            <StyledDropdownArrow className="d-flex flex-row">
              <StyledProfileDropdownArrow
                src={Constant.Icons.downarrow}
                alt="profile"
              />
            </StyledDropdownArrow>
          </div>
        </div>
      }
      id="basic-nav-dropdown"
    >
      <StyledNavDropdownItem href="/profile">
        <StyledProfileItemIcon
          src={Constant.Icons.signIn}
          className="text-end"
        />
        Profile
      </StyledNavDropdownItem>
      <NavDropdown.Divider />
      <StyledNavDropdownItem onClick={logoutUser}>
        <StyledProfileItemIcon src={Constant.Icons.signIn} />
        Sign Out
      </StyledNavDropdownItem>
    </NavDropdown>
  </StyledProfileSection>
);
const customPopover = (
  <Popover id="popover-contained">
    <Popover.Body>
      <div className="d-flex justify-content-end close-btn">
        <StyledCloseButton
          onClick={() => document.body.click()}
          src={Constant.Icons.cross}
          alt="close"
        />
      </div>
      {notificationList.map((data) => (
        <NotificationItem key={data.id} notification={data} />
      ))}
      <StyledViewAllLink>
        <a href="www.google.com">View All</a>
      </StyledViewAllLink>
    </Popover.Body>
  </Popover>
);

function NavBar({ data, onMenuClick }) {
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const { mutate: logout } = useLogout();
  const logoutUser = () => {
    setIsConfirmModal(true);
  };
  return (
    <div style={{ width: "100%" }}>
      <AlertPopUp
        confirmText="Yes"
        cancelText="Cancel"
        onCancel={() => {
          setIsConfirmModal(false);
        }}
        issignout="true"
        show={isConfirmModal}
        onConfirm={() => {
          setIsConfirmModal(false);
          logout(
            {},
            {
              onError: (res) => {
                toast.error(res?.response?.data?.message);
              },
              onSuccess: (res) => {
                localStorage.clear();
                resetStore();
                history.replace("/");
                history.go(0);
              },
            }
          );
        }}
        header="Sign Out ?"
        body="Are you sure you want to sign out."
      />
      <StyledNavbar variant="light">
        <BNavbar.Text>
          <StyledMenuNavIcon
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                onMenuClick && onMenuClick(e);
              }
            }}
            onClick={(e) => {
              onMenuClick && onMenuClick(e);
            }}
            src={Constant.Icons.burgermenu}
            className="d-inline-block align-top menu-icon"
            alt="Menu"
          />
        </BNavbar.Text>

        <BNavbar.Collapse className="justify-content-end">
          <OverlayTrigger
            tabIndex={0}
            trigger={["click", "focus"]}
            placement={"bottom"}
            rootClose={true}
            // containerPadding={10}
            overlay={customPopover}
          >
            <div className="d-flex flex-row" style={{ position: "relative" }}>
              <StyledNavIcons
                tabIndex={0}
                src={Constant.Icons.notification}
                className="d-inline-block align-top"
                alt="Notification"
              />
              <NotificationCount>12</NotificationCount>
            </div>
          </OverlayTrigger>
          <NavbarDropdown data={data} logoutUser={logoutUser} />
        </BNavbar.Collapse>
      </StyledNavbar>
    </div>
  );
}

export default NavBar;
