import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import Autocomplete from "../../components/shared/Autocomplete";
import Button from "../../components/shared/Button";
import ContainerComponent from "../../components/shared/ContainerComponent";
import Empty from "../../components/shared/Empty";
import PageHeader from "../../components/shared/PageHeader";
import { Spinner } from "../../components/shared/Spinner";
import Table from "../../components/shared/Table";
import Toggle from "../../components/shared/Toggle";
import {
  useCountries,
  useExamBoards,
  useSubjects,
  useYears,
} from "../../hooks/chapter";
import { Constant, Season } from "../../lib/Constant";
import { theme } from "../../lib/themeConstants";
import { setFilter } from "../../redux-toolkit/selftest";
import { setPageChange } from "../../redux-toolkit/table";
import { useSeasonActivitys } from "../../hooks/seasonActivity";

const StyledButton = styled(Button)`
  padding: 4px 8px;
`;

function SeasonActivity() {
  const dispatch = useDispatch();

  const currentPage = useSelector((state) => {
    return state.table.page;
  });

  const { country, examboard, subject, grade } = useSelector((state) => {
    return state.selftest.filter;
  });

  const {
    data: seasonActivitys,
    error,
    isFetching,
  } = useSeasonActivitys(
    country?.value,
    examboard?.value,
    subject?.value,
    grade?.value,
    null,
    currentPage
  );

  const { data: countriesData, isFetching: countriesLoading } = useCountries();
  const { data: examboardData, isFetching: examLoading } = useExamBoards();
  const { data: subjectsData, isFetching: subjectsLoading } = useSubjects();
  const { data: gradesData, isFetching: gradesLoading } = useYears();
  // const { data: chaptersData, isFetching: chapterLoading } = useChapters();

  useEffect(() => {
    if (error) {
      toast.error(error?.response?.data?.message);
    }
    return () => {
      dispatch(setPageChange(1));
    };
  }, [dispatch, error]);
  const navigate = useNavigate();

  const formatFilterOptions = (data) => {
    let formattedData = data?.map((item) => {
      return { value: item.id, label: item.name };
    });
    return formattedData;
  };

  const onFilterChange = (key, value) => {
    let data = { country, examboard, subject, grade };
    data[key] = value;
    dispatch(setFilter(data));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Season name",
        accessor: "number",
        customWidth: "10",
        Cell: ({ row }) => <span>{row?.original?.season_activity?.season}</span>,
      },
      {
        Header: "Week",
        accessor: "name",
        // customWidth: "40",
        Cell: ({ row }) => <span>{row?.original?.season_activity?.week}</span>,
      },
      {
        Header: "Date",
        accessor: "date",
        // customWidth: "40",
        Cell: ({ row }) => <span>{row?.original?.name}</span>,
      },
      {
        Header: "View",
        Cell: ({ row }) => (
          <StyledButton
          text="View"
          color={theme.colors.WHITE}
          onClick={() => {
            navigate(`view/${row?.original?.id}`);
          }}
          bgColor={theme.colors.NEBULA_BLUE}
        />
        ),
      },
      {
        Header: "",
        accessor: "gg",
        customWidth: "20",
        Cell: ({ row }) => (
          <Toggle
            checked={true}
            // onChange={setSwitchChecked}
            offLable={"Draft"}
            onLable={"Publish"}
          ></Toggle>
        ),
      },
    ],
    // eslint-disable-next-line
    []
  );
  return (
    <>
      <Spinner
        isLoading={
          isFetching ||
          countriesLoading ||
          examLoading ||
          subjectsLoading ||
          gradesLoading
        }
      />
      <PageHeader
        title="Season activity"
        control={
          <Button
            text="Create"
            icon={Constant.Icons.add_circle_outline_white}
            bgColor={theme.colors.NEBULA_BLUE}
            onClick={() => {
              navigate("create");
            }}
          />
        }
      ></PageHeader>

      <ContainerComponent>
        <Row>
          <Col>
            <Autocomplete
              options={formatFilterOptions(countriesData)}
              value={country}
              onChange={(data) => {
                // setCountry(data);
                onFilterChange("country", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Country"
              className="m-2 auto-complete"
            />
          </Col>
          <Col>
            <Autocomplete
              options={formatFilterOptions(examboardData)}
              value={examboard}
              onChange={(data) => {
                // setExamboard(data);
                onFilterChange("examboard", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Examboard"
              className="m-2 auto-complete"
            />
          </Col>
          <Col>
            <Autocomplete
              options={formatFilterOptions(subjectsData)}
              value={subject}
              onChange={(data) => {
                // setSubject(data);
                onFilterChange("subject", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Subject"
              className="m-2 auto-complete"
            />
          </Col>
          <Col>
            <Autocomplete
              options={formatFilterOptions(gradesData)}
              value={grade}
              onChange={(data) => {
                // setGrade(data);
                onFilterChange("grade", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Grade"
              className="m-2 auto-complete"
            />
          </Col>
          <Col>
            <Autocomplete
              options={Season}
              value={grade}
              onChange={(data) => {
                // setGrade(data);
                onFilterChange("season", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Season"
              className="m-2 auto-complete"
            />
          </Col>
        </Row>
        <Row>
          <Col className="mt-3">
            {seasonActivitys?.data?.length > 0 ? (
              <Table
                data={seasonActivitys?.data}
                columns={columns}
                defaultPageSize={seasonActivitys?.per_page}
                totalPages={seasonActivitys?.last_page}
                currentPage={seasonActivitys?.current_page}
              ></Table>
            ) : (
              !isFetching && (
                <Empty text={Constant.tableErrorMessages.chapters} />
              )
            )}
          </Col>
        </Row>
      </ContainerComponent>
    </>
  );
}

export default SeasonActivity;
