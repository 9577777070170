import { useQuery } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useChapter = (id) => {
  const formData = {};
  if (id) formData.id = id;

  return useQuery(
    ["chapter", id],
    async () => {
      const { data } = await axiosPost("/api/cw/viewChapter", formData);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  );
};

export default useChapter;

//id:151
//country_id:3
//exam_board_id:2
//year_id:12
//subject_id:3
// pagination:true
// page:1
// per_page:50
