import React from "react";
import AvatarName from "./AvatarName";
import styled from "styled-components";
import { pxtoRem } from "../../lib/helper";

const StyledNotificationDetails = styled.div`
  display: block;
  font-size: ${pxtoRem(14)};
  font-weight: bold;
  max-width: ${pxtoRem(224)};
  max-height: ${pxtoRem(32)};
  color: ${(props) => props.theme.NOTIFICATIONLIST.LIGHT_BLACK};
`;

const StyledNotificationItem = styled.div`
  &&& {
    height: ${pxtoRem(75)};
    padding-top: ${pxtoRem(12)};
    display: flex;
    .notification-data {
      width: ${pxtoRem(280)};
    }
  }
`;

const StyledUserName = styled.div`
  display: block;
  font-size: ${pxtoRem(18)};
  font-weight: bold;
  /* max-width: 87px; */
  max-height: ${pxtoRem(25)};
  color: ${(props) => props.theme.NOTIFICATIONLIST.DARK_BLACK};
`;

function NotificationItem({ notification }) {
  return (
    <StyledNotificationItem>
      <AvatarName name={notification.name} className="w-25" />
      <span className="notification-data">
        <StyledUserName>{notification.name}</StyledUserName>
        <StyledNotificationDetails>
          {notification.details}
        </StyledNotificationDetails>
      </span>
    </StyledNotificationItem>
  );
}

export default React.memo(NotificationItem);
