import { Col, Nav, Row, Tab } from "react-bootstrap";
import styled from "styled-components";

const TabItem = styled(Nav.Item)`
  width: 10rem;
  text-align: center;
  background-color: #ececec;
  border-radius: 10px;
  a {
    color: #666666;
    &:hover {
      color: #666666 !important;
    }

    &.active {
      background-color: #6d71ef !important;
      &:hover {
        color: white !important;
      }
    }
  }

  &:hover {
    filter: brightness(85%);
  }
`;

const TabList = ({ defaultActiveKey = "", tabItems, ...props }) => {
  return (
    <Row style={{ marginBottom: "20px" }}>
      <Tab.Container id="left-tabs-example" defaultActiveKey={defaultActiveKey}>
        <Row>
          <Col>
            <Nav variant="pills" className="flex-row">
              {tabItems}
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col>
            <Tab.Content style={{ paddingTop: "28px" }}>
              {props.children}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Row>
  );
};

export { TabList, TabItem };
