import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { Constant } from "../../../lib/Constant";
import {
  isValidImgSrc,
  isValidTextORImage,
  pxtoRem,
} from "../../../lib/helper";
import { theme } from "../../../lib/themeConstants";
import Button from "../Button";
import Editor from "../Editor";
import FormControl from "../FormControl";
import TextInput from "../TextInput";
import BoxImage from "./BoxImage";
import DeleteButton from "../DeleteButton";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import _ from "lodash";
import Dropdown from "../Dropdown";

const StyledContainer = styled.div`
  background-color: ${(props) => props.theme.colors.QUESTION_BG};
  border: 1px solid ${(props) => props.theme.colors.DARK_GREY};
  border-radius: 5px;
  width: 100%;
  padding: ${pxtoRem(40)};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

const StyledLabel = styled(Form.Label)`
  margin-bottom: 0px;
  color: ${(props) => props.theme.colors.NAVY_BLUE};
`;

const StyledHeader = styled.div`
  background-color: ${(props) => props.theme.colors.NEBULA_BLUE};
  color: ${(props) => props.theme.colors.WHITE};
  margin-top: ${pxtoRem(20)};
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  height: ${pxtoRem(67)};
  font-size: ${pxtoRem(24)};
  padding-left: ${pxtoRem(40)};
  padding-top: ${pxtoRem(15)};
`;

const StyledAddSubQuestionButton = styled(Button)`
  margin-top: ${pxtoRem(20)};
`;

const SaForm = forwardRef(
  (
    {
      addSubquestion,
      onSubmit,
      defaultValues,
      onQuestionDelete,
      isedit,
      chapters,
    },
    ref
  ) => {
    const {
      control,
      register,
      formState: { errors, isValid, isDirty, touchedFields },
      handleSubmit,
      setValue,
      getValues,
      reset,
    } = useForm({
      mode: "onChange",
      defaultValues: {
        question_number: defaultValues?.question_number,
        question: defaultValues?.question,
      },
    });
    const formRef = useRef();
    
    let [showErrors, setShowErrors] = useState({});

    useEffect(() => {
      reset({
        ...defaultValues,
        question_number: defaultValues?.question_number
          ? defaultValues?.question_number
          : null,
        question: defaultValues?.question ? defaultValues?.question : null,
      });
      // if (defaultValues && Object.keys(defaultValues).length > 0) {
      //   for (const key in defaultValues) {
      //     setValue(key, defaultValues[key]);
      //   }
      // }
      setShowErrors({});
    }, [defaultValues, reset]);

    // useEffect(() => {
    //   if (
    //     formStateValues?.isValid !== isValid ||
    //     formStateValues?.isDirty !== isDirty ||
    //     !_.isEqual(formStateValues?.allValues, allValues)
    //   ) {
    //     setFormState({ isValid, isDirty, allValues });
    //   }
    // }, [isValid, isDirty, allValues]);

    const onFormSubmit = (data) => {
      const formData = new FormData();
      formData.append("question", data.question);
      formData.append("question_number", data.question_number);
      data.chapter_id && formData.append("chapter_id", data.chapter_id);
      if (defaultValues?.id) {
        formData.append("id", defaultValues?.id);
      }

      onSubmit(formData);
      setShowErrors({});
    };

    useImperativeHandle(ref, () => ({
      canParentSubmit: () => {
        return isValid && isDirty;
      },
      onParentSubmit: () => {
        formRef.current.dispatchEvent(
          new Event("submit", { bubbles: true, cancelable: true })
        );
      },
    }));

    return (
      <form ref={formRef} key={2} onSubmit={handleSubmit(onFormSubmit)}>
        <Row>
          <Col>
            <StyledHeader>Create New Questions</StyledHeader>
          </Col>
        </Row>
        <StyledContainer>
          <Row>
            <Col xs={3}>
              <FormControl
                errMessage={errors?.question_number?.message}
                label="Question Number"
                isInline={false}
              >
                <TextInput
                  register={register}
                  id="question_number"
                  validation={{ required: "Please enter question number" }}
                />
              </FormControl>
            </Col>
            {chapters && (
              <Col xs={3}>
                <FormControl
                  label="chapter name"
                  errMessage={errors?.chapter_id?.message}
                  isInline={false}
                >
                  <Controller
                    isClearable
                    name="chapter_id"
                    rules={{ required: "Please Select chapter" }}
                    control={control}
                    render={({ field: { ref, ...rest } }) => (
                      <Dropdown
                        placeholder="Select chapter"
                        {...rest}
                        options={
                          chapters?.map((item) => {
                            return {
                              label: item.name,
                              value: item.id,
                            };
                          }) || []
                        }
                      />
                    )}
                  />
                </FormControl>
              </Col>
            )}
            {defaultValues?.id && (
              <Col style={{ textAlign: "right" }}>
                <DeleteButton
                  isedit={isedit}
                  onDelete={() => {
                    if (onQuestionDelete) {
                      let formData = new FormData();
                      formData.append("id", defaultValues?.id);
                      onQuestionDelete(formData);
                    }
                  }}
                />
              </Col>
            )}
          </Row>
          <Row className="description-container">
            <Col className="mt-2">
              <StyledLabel>Question?</StyledLabel>
              <Controller
                name="question"
                control={control}
                rules={{
                  required: { value: true, message: "Please enter question" },
                  validate: {
                    // validImage: (v) =>
                    //   isValidImgSrc(v) || "Please enter image only",
                    validText: (v) =>
                      isValidTextORImage(v) ||
                      "Please enter text or image only",
                  },
                }}
                render={({ field }) => (
                  <Editor
                    id="question"
                    {...field}
                    editorState={field.value}
                    onEditorStateChange={(text) => {
                      field.onChange(text);
                      text &&
                        text !== "" &&
                        setShowErrors({ ...showErrors, question: true });
                    }}
                    ref={field.ref}
                    errMessage={
                      showErrors.question && errors?.question?.message
                    }
                  />
                )}
              />

              <StyledAddSubQuestionButton
                text={"Add sub question"}
                bgColor={theme.colors.NEBULA_BLUE}
                onClick={() => {
                  // let formData = new FormData();
                  // for (let key of Object.keys(allValues)) {
                  //   formData.append(key, allValues[key]);
                  // }
                  addSubquestion();
                }}
                disabled={defaultValues?.id ? false : true}
              ></StyledAddSubQuestionButton>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col className="text-end">
              <Button
                type="submit"
                disabled={isDirty && isValid ? false : true}
                bgColor={theme.colors.NEBULA_BLUE}
                text={defaultValues?.id ? "Update" : "Save"}
              />
            </Col>
          </Row>
        </StyledContainer>
      </form>
    );
  }
);

export default SaForm;
