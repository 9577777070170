import { useMutation } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useUploadWorksheetFile = () => {
  return useMutation((data) => {
    return axiosPost("/api/cw/uploadWorkSheetFile", data);
  });
};

export default useUploadWorksheetFile;
