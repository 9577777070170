import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filter: {
    country: null,
    examboard: null,
    subject: null,
    grade: null,
    chapter:null
  },
};

export const chapterPaperSlice = createSlice({
    name: "chapterPaper",
    initialState,
    reducers: {
      setFilter: (state, action) => {
        state.filter = action.payload;
      },
    },
  });

export const {setFilter} = chapterPaperSlice.actions;

export default chapterPaperSlice.reducer;