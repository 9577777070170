import { useState } from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "../../components/shared/Spinner";
import QuestionContainer from "../../components/shared/question/QuestionContainer";
import QuestionModal from "../../components/shared/question/QuestionModal";
// import useViewSelftest from "../../hooks/selftest/useViewSelfTest";
import ViewDemo from "../../layout/ViewDemo";
import InitialPage from "./InitialPage";
import EditModal from "../../components/shared/popups/EditModal";
import WorkSheetForm from "../../components/form/WorksheetForm";
import { FormProvider, useForm } from "react-hook-form";
import {
  useAddWorksheet,
  useDeleteQuestion,
  useGetWorksheet,
  useUpdateWorksheet,
  useUpdateWorksheetQuestions,
  useViewWorksheetDemo,
} from "../../hooks/worksheet";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { convertBase64 } from "../../lib/helper";

const ViewWorkSheet = () => {
  const { worksheet_id } = useParams();
  const queryClient = useQueryClient();
  const { data: worksheet, isFetching: isWorksheetFetching } =
    useGetWorksheet(worksheet_id);
  const { data: questions, isFetching: isQuestionsFetching } =
    useViewWorksheetDemo(worksheet_id);
  const [initialPage, setInitialPage] = useState(true);
  //   const selftestQuery = useViewSelftest(lesson_id);
  const [show, setShow] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [isSubQuesEdit, setIsSubQuesEdit] = useState(false);

  const updateMutation = useUpdateWorksheet();
  const updateQueMutation = useUpdateWorksheetQuestions();
  const createMutation = useAddWorksheet();
  const deleteMutation = useDeleteQuestion();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    watch,
    reset,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
  });

  const methods = {
    control,
    register,
    errors,
    handleSubmit,
    watch,
    getValues,
    setValue,
    isDirty,
    isValid,
    reset,
  };

  const onSubmit = async (data) => {
    let formData = new FormData();
    formData.append("id", worksheet[0].id);
    formData.append("chapter_id", data.chapter_id);
    formData.append("duration_hours", data.duration_hours);
    formData.append("duration_minutes", data.duration_minutes);
    formData.append("question_type", data.question_type);

    if (
      typeof data?.cover_image !== "string" &&
      data?.cover_image?.length > 0
    ) {
      const imageBase64 = await convertBase64(data?.cover_image?.[0]);
      formData.append("cover_image", imageBase64);
    }

    updateMutation.mutate(formData, {
      onError: (res) => {
        toast.error(res?.response?.data?.message);
        setShowForm(false);
      },
      onSuccess: (res) => {
        queryClient.invalidateQueries("viewWorksheetDemo");
        queryClient.invalidateQueries("getWorksheet");
        toast.success("worksheet updated successfuly");
        setShowForm(false);
      },
    });
  };

  const onQuestionSubmit = async (data, onFormSuccess) => {
    data.append("chapter_id", worksheet[0].chapter_id);
    if (data.get("id")) {
      updateQueMutation.mutate(data, {
        onError: (res) => {
          toast.error(res?.response?.data?.message);
          onFormSuccess(false);
        },
        onSuccess: (res) => {
          queryClient.invalidateQueries("viewWorksheetDemo");
          onFormSuccess(true);
          toast.success("Question updated successfuly");
          setShow(false);
        },
      });
    } else {
      data.append("duration_hours", worksheet[0].duration_hours);
      data.append("duration_minutes", worksheet[0].duration_minutes);
      data.append("question_type", worksheet[0].question_type);
      worksheet[0].question_type === "sa" &&
        !data.get("sa_question_answer") &&
        data.append("sa_question_answer", "123");

      createMutation.mutate(data, {
        onError: (res) => {
          toast.error(res?.response?.data?.message);
          onFormSuccess(false);
        },
        onSuccess: (res) => {
          queryClient.invalidateQueries("viewWorksheetDemo");
          onFormSuccess(true);
          toast.success("Question added successfuly");
          setShow(false);
        },
      });
    }
  };

  const onQuestionDelete = (formData, onDelete) => {
    deleteMutation.mutate(formData, {
      onError: (res) => {
        toast.error(res?.response?.data?.message);
      },
      onSuccess: (res) => {
        queryClient.invalidateQueries("viewWorksheetDemo");
        toast.success("Question deleted successfuly");
        onDelete();
        setShow(false);
      },
    });
  };

  return (
    <>
      <Spinner isLoading={isWorksheetFetching || isQuestionsFetching} />
      <EditModal
        onCancel={() => {
          setShowForm(false);
        }}
        show={showForm}
        title={"Edit Worksheet"}
      >
        <Spinner isLoading={isWorksheetFetching || updateMutation?.isLoading} />
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
            <WorkSheetForm
              isedit={true}
              defaultValues={worksheet && worksheet[0]}
            />
          </form>
        </FormProvider>
      </EditModal>
      <QuestionModal
        onCancel={() => {
          setShow(false);
        }}
        show={show}
        heading={isSubQuesEdit ? "Edit sub question" : "Edit question"}
      >
        <Spinner
          isLoading={
            isWorksheetFetching ||
            createMutation?.isLoading ||
            updateQueMutation?.isLoading
          }
        />
        <FormProvider {...methods}>
          <QuestionContainer
            Questions={questions}
            type={worksheet && worksheet[0]?.question_type}
            isedit={true}
            onQuestionSubmit={onQuestionSubmit}
            onQuestionDelete={onQuestionDelete}
            isSubQuesEdit={isSubQuesEdit}
            breadCrumData={
              worksheet && [
                worksheet[0]?.chapter?.exam_board?.name,
                worksheet[0]?.chapter?.years_data[0]?.key_stage[0].name,
                worksheet[0]?.chapter?.subject?.name,
                worksheet[0]?.chapter?.years_data
                  .map(function (item) {
                    return item.name;
                  })
                  .join(","),
                worksheet[0]?.chapter?.name,
                worksheet[0].question_type,
              ]
            }
          ></QuestionContainer>
        </FormProvider>
      </QuestionModal>
      {initialPage ? (
        <InitialPage
          handleEdit={() => {
            setShowForm(true);
          }}
          setInitialPage={setInitialPage}
          defaultValues={worksheet && worksheet[0]}
        ></InitialPage>
      ) : (
        <ViewDemo
          type={worksheet && worksheet[0]?.question_type}
          handleEdit={(isSubQues) => {
            if (isSubQues) {
              setIsSubQuesEdit(true);
            } else {
              setIsSubQuesEdit(false);
            }
            setTimeout(() => {
              setShow(true);
            }, 100);
          }}
          list={questions}
          defaultValues={worksheet && worksheet[0]}
          onClose={() => {
            setInitialPage(true);
          }}
        />
      )}
    </>
  );
};
export default ViewWorkSheet;
