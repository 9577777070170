import { Constant } from "../../lib/Constant";

const Icon = ({ size = "16", name }) => {
  const getIconPath = () => {
    switch (name) {
      case "dashboard":
        return Constant.Icons.dashboard;
      case "subjectManagement":
        return Constant.Icons.subject_management;
      case "activityManagement":
        return Constant.Icons.activity_management;
      case "curriculum":
        return Constant.Icons.curriculum;
      case "qa":
        return Constant.Icons.qa;
      default:
        return "";
    }
  };

  return <img height={size} width={size} src={getIconPath()} alt={name} />;
};

export default Icon;
