import styled from "styled-components";
import { pxtoRem } from "../../../lib/helper";
import { Modal } from "react-bootstrap";
import { Constant } from "../../../lib/Constant";

const StyledTitle = styled(Modal.Title)`
  color: ${(props) => props.theme.colors.WHITE};
  font-size: ${pxtoRem(24)};
`;

const StyledModal = styled(Modal)``;
const Header = styled(Modal.Header)`
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.NEBULA_BLUE};
  height: ${(props) => pxtoRem(props.headingheight)};
  .btn-close {
    background: transparent url(${Constant.Icons.close_white}) center/
      ${pxtoRem(22)} auto no-repeat;
    margin-right: ${pxtoRem(20)};
  }
`;

const StyledBody = styled(Modal.Body)`
  // width: ${pxtoRem(1681)};
  align-items: center;
`;

const QuestionModal = ({
  heading = "Edit question",
  onCancel,
  show,
  HedingHeight = 105,
  children,
}) => {
  return (
    <StyledModal onHide={onCancel} show={show} size="xl">
      <Header headingheight={HedingHeight} closeButton>
        <StyledTitle>{heading}</StyledTitle>
      </Header>
      <StyledBody>{children}</StyledBody>
    </StyledModal>
  );
};

export default QuestionModal;
