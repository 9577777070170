import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filter: {
    country: null,
    examboard: null,
    subject: null,
    grade: null,
  },
};

export const chaptersSlice = createSlice({
  name: "chapters",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFilter } = chaptersSlice.actions;

export default chaptersSlice.reducer;
