import DatePicker from "react-date-picker";
import styled from "styled-components";
import { Constant } from "../../lib/Constant";
import { pxtoRem } from "../../lib/helper";

const StyledInput = styled(DatePicker)`
  font-size: ${pxtoRem(20)};
  color: ${(props) => props.theme.colors.MEDIUM_GREY};
  width: 100%;
  height: ${pxtoRem(55)};

  .react-date-picker__wrapper {
    border-radius: ${pxtoRem(10)} !important;
    padding: ${pxtoRem(10)};
    padding-left: ${pxtoRem(16)};
  }

  .react-date-picker__calendar.react-date-picker__calendar--open {
    width: 100% !important;
    z-index: 100;
  }

  .react-date-picker__button {
    padding: 0px;
    padding-right: ${pxtoRem(8)};
    svg {
      margin-right: ${pxtoRem(8)} !important;
      width: ${pxtoRem(17)};
    }
  }
`;

const StyledIcon = styled.img`
  width: ${pxtoRem(17)};
`;

const DateInput = ({ id, register, validation, ...rest }) => {
  return (
    <>
      {register ? (
        <StyledInput
          yearPlaceholder="yyyy"
          monthPlaceholder="mm"
          maxDetail="year"
          calendarIcon={
            <StyledIcon src={Constant.Icons.datepicker} alt="icon" />
          }
          {...register(id, validation)}
          {...rest}
        />
      ) : (
        <StyledInput
          format="MM/yyyy"
          yearPlaceholder="yyyy"
          monthPlaceholder="MM"
          maxDetail="year"
          calendarIcon={
            <StyledIcon src={Constant.Icons.datepicker} alt="icon" />
          }
          {...rest}
        />
      )}
    </>
  );
};

export default DateInput;
