import { useMemo } from "react";
import styled from "styled-components";
import { pxtoRem } from "../../../lib/helper";
import { Constant } from "../../../lib/Constant";

const StyledContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: flex-start;
`;

const StyledItem = styled.p`
  background-color: ${(props) => props.theme.colors.WHITE};
  width: ${pxtoRem(35)};
  height: ${pxtoRem(40)};
  border: 2px solid ${(props) => props.theme.colors.SILVER_ARGENT};
  color: ${(props) => props.theme.colors.SILVER_ARGENT};
  cursor: pointer;
  font-size: ${pxtoRem(24)};
  margin-bottom: ${pxtoRem(20)};
  ${(props) =>
    props.isrightside &&
    `border-left:none;
    `};

  ${(props) =>
    props.isactive &&
    ` border-color:#6ecacd;
      color: #6ecacd;
    `};
`;

const PageItem = styled.div`
  display: flex;
  margin-right: ${pxtoRem(18)};
`;

const StyledSubItem = styled.p`
  width: ${pxtoRem(42)};
  height: ${pxtoRem(26)};
  border: 1px solid
    ${(props) =>
      props?.isCompleted
        ? props.theme.colors.VIVID_CERULEAN
        : props.theme.colors.DARK_GREY};
  color: ${(props) =>
    props?.isCompleted
      ? props.theme.colors.WHITE
      : props.theme.colors.DARK_GREY};
  cursor: pointer;
  font-size: ${pxtoRem(16)};
  margin-right: ${pxtoRem(10)};
  border-radius: 5px;
  background-color: ${(props) =>
    props?.isCompleted
      ? props.theme.colors.VIVID_CERULEAN
      : props.theme.colors.WHITE};
  margin-bottom: 0px;

  ${(props) =>
    props.isactive &&
    ` color: #00A5EC;
    border: 1px solid #00A5EC;
    `};
`;

const StyledSubQuestions = styled(StyledContainer)`
  margin-top: -4px;
`;

const StyledLeftIndicator = styled.img`
  transform: rotate(90deg);
  margin-top: ${pxtoRem(15)};
  margin-right: ${pxtoRem(13)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  width: ${pxtoRem(20)};
  height: ${pxtoRem(11)};
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`;

const StyledRightIndicator = styled.img`
  transform: rotate(-90deg);
  margin-top: ${pxtoRem(15)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  width: ${pxtoRem(20)};
  height: ${pxtoRem(11)};
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`;

const StyledSubLeftIndicator = styled.img`
  transform: rotate(90deg);
  margin-top: ${pxtoRem(10.5)};
  margin-right: ${pxtoRem(13)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  width: ${pxtoRem(19)};
  height: ${pxtoRem(7)};
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`;
const StyledSubRightIndicator = styled.img`
  transform: rotate(-90deg);
  margin-top: ${pxtoRem(10.5)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  width: ${pxtoRem(19)};
  height: ${pxtoRem(7)};
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`;

const SaQuestionNumbers = ({
  activeIndex,
  activeSubIndex,
  onPageClick,
  onSubQuesClick,
  list,
  startEndIndex,
  startEndSubQuestionIndex,
  onChangeLeft,
  onChangeRight,
  onSubChangeLeft,
  onSubChangeRight,
}) => {
  const childQuestions = useMemo(() => {
    if (list && list[activeIndex]?.children) {
      return list[activeIndex]?.children;
    } else return [];
  }, [activeIndex, list]);

  return (
    <div>
      <StyledContainer>
        <StyledLeftIndicator
          onClick={() => {
            onChangeLeft();
          }}
          disabled={startEndIndex?.start === 0}
          src={Constant.Icons.view_down_arrow}
          alt="add question"
          visible={list && list?.length > 10}
        />
        {/* eslint-disable */}
        {list?.map((item, index) => {
          if (index >= startEndIndex?.start && index <= startEndIndex?.end) {
            const indexArr = Array.from(String(index + 1));
            return (
              <PageItem
                onClick={() => {
                  onPageClick(index);
                }}
                key={index}
              >
                <StyledItem isactive={activeIndex === index}>
                  {indexArr?.length > 1 ? indexArr[0] : "0"}
                </StyledItem>
                <StyledItem isactive={activeIndex === index} isrightside={true}>
                  {indexArr?.[1] || indexArr?.[0]}
                </StyledItem>
              </PageItem>
            );
          }
        })}

        <StyledRightIndicator
          onClick={() => {
            onChangeRight();
          }}
          disabled={startEndIndex?.end === list?.length - 1}
          src={Constant.Icons.view_down_arrow}
          width="19"
          height="11"
          alt="add question"
          visible={list && list?.length > 10}
        />
      </StyledContainer>

      {childQuestions?.length > 0 && (
        <StyledSubQuestions>
          <StyledSubLeftIndicator
            onClick={() => {
              onSubChangeLeft();
            }}
            visible={list && list[activeIndex]?.children?.length > 10}
            disabled={startEndSubQuestionIndex?.start === 0}
            src={Constant.Icons.view_down_arrow}
            alt="add question"
          />

          {childQuestions.map(
            (item, subIndex) =>
              subIndex >= startEndSubQuestionIndex?.start &&
              subIndex <= startEndSubQuestionIndex?.end && (
                <StyledSubItem
                  isactive={activeSubIndex === subIndex}
                  isCompleted={subIndex < activeSubIndex}
                  onClick={() => {
                    onSubQuesClick(subIndex);
                  }}
                  key={subIndex}
                >
                  {`${activeIndex + 1}.${subIndex + 1}`}
                </StyledSubItem>
              )
          )}
          <StyledSubRightIndicator
            onClick={() => {
              onSubChangeRight();
            }}
            disabled={
              list &&
              startEndSubQuestionIndex?.end ===
                list[activeIndex]?.children?.length - 1
            }
            visible={list && list[activeIndex]?.children?.length > 10}
            src={Constant.Icons.view_down_arrow}
            width="19"
            height="11"
            alt="add question"
          />
        </StyledSubQuestions>
      )}
    </div>
  );
};

export default SaQuestionNumbers;
