import React from "react";
import styled from "styled-components";

const AvatarImage = styled.img`
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  border: 0.18rem;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.NEBULA_BLUE};
  object-fit: cover;
`;

function Avatar(props) {
  return <AvatarImage src={props.src} alt="Avatar" {...props} />;
}

export default Avatar;
