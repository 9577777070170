import React from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { pxtoRem } from "../../../lib/helper";
import ReactPlayer from "react-player";
import { useForm } from "react-hook-form";
import Glossary from "../Glossary";
import { useEffect } from "react";
import PPTViewer from "../PPTViewer";

const StyledTitle = styled(Modal.Title)`
  color: ${(props) => props.theme.colors.NEBULA_BLUE};
  font-size: ${pxtoRem(25)};
  font-weight: 600;
`;

const StyledDescriptionContainer = styled.div`
  background-color: ${(props) => props.theme.colors.GHOST_WHITE};
  color: ${(props) => props.theme.colors.DARK_GREY};
  font-size: ${pxtoRem(25)};
  margin: ${pxtoRem(30)};
  padding: ${pxtoRem(15)};
`;

const StyledGlossaryContainer = styled.div`
  width: 80%;
  margin: 20px auto;
`;

function ViewLessonData({ show = false, onCancel, heading, content }) {
  const { control, register, setValue } = useForm({
    defaultValues: {
      glossary: content,
    },
  });

  useEffect(() => {
    if (heading === "Glossary") setValue("glossary", content);
    // eslint-disable-next-line
  }, [content, heading]);
  return (
    <Modal centered show={show} onHide={() => onCancel(false)} size="xl">
      <Modal.Header closeButton>
        <StyledTitle>{heading}</StyledTitle>
      </Modal.Header>
      <Modal.Body>
        {heading === "Description" || heading === "Notes" ? (
          <StyledDescriptionContainer
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          ></StyledDescriptionContainer>
        ) : heading === "Video" ? (
          <ReactPlayer width={"100%"} url={content} controls={true} />
        ) : heading === "Glossary" ? (
          <form>
            <StyledGlossaryContainer>
              <Glossary
                register={register}
                control={control}
                name={"glossary"}
                isFormComponent={false}
                // numberOfDefaultItems={0}
                readOnly={true}
              />
            </StyledGlossaryContainer>
          </form>
        ) : heading === "PPT" ? (
          <PPTViewer url={content} />
        ) : (
          <></>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ViewLessonData;
