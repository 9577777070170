import { useMutation } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useCreateMockTest = () => {
  return useMutation((newTest) => {
    return axiosPost("/api/cw/createQuestion", newTest);
  });
};

export default useCreateMockTest;
