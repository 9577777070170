import React from "react";
import history from "../../lib/history";
import { Button } from "react-bootstrap";

function NotFound() {
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${
        localStorage.getItem("token") ? `h-100` : `vh-100`
      }`}
    >
      <div className="text-center">
        <h1 className="display-1 fw-bold">404</h1>
        <h3> Page not found.</h3>
        <p className="lead">The page you’re looking for doesn’t exist.</p>
        <Button className="btn btn-primary" onClick={() => history.go(-1)}>
          Go Back
        </Button>
      </div>
    </div>
  );
}

export default NotFound;
