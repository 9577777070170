import { Col, Row } from "react-bootstrap";
import { Controller, useForm, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Button from "../../components/shared/Button";
import Dropdown from "../../components/shared/Dropdown";
import FormControl from "../../components/shared/FormControl";
import MultipleDropdown from "../../components/shared/MultipleDropdown";
import { Spinner } from "../../components/shared/Spinner";
import {
  useCountries,
  useExamBoards,
  useSubjects,
  useYears,
  useChapters,
} from "../../hooks/chapter";
import ContainerComponent from "../shared/ContainerComponent";
import styled from "styled-components";
import { pxtoRem, removeBlank } from "../../lib/helper";
import { theme } from "../../lib/themeConstants";
import InputTime from "../shared/InputTime";
import PageHeader from "../shared/PageHeader";
import SectionHeader from "../shared/SectionHeader";
import {
  Constant,
  PaperLevel,
  PaperNo,
  QuestionTypes,
  challengeBoard,
  section_list,
} from "../../lib/Constant";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetQuestions } from "../../redux-toolkit/questions";
import TextInput from "../shared/TextInput";
import FileUpload from "../shared/FileUpload";
import DateInput from "../shared/DateInput";

const StyledBox = styled(Row)`
  width: 95%;
  padding-left: ${pxtoRem(60)};
  margin-top: ${pxtoRem(40)};
`;

const StyledButton = styled(Button)`
  margin-right: -${pxtoRem(30)};
`;

const ChallengeForm = ({ isLoading, defaultValues }) => {
  const dispatch = useDispatch();
  const { control, register, errors, isDirty, isValid, setValue, getValues } =
    useFormContext();

  const navigate = useNavigate();
  const { data: lstCountries } = useCountries();
  const { data: lstGrades } = useYears();
  const { data: lstSubjects } = useSubjects();

  return (
    <>
      <Spinner isLoading={isLoading} />
      <PageHeader
        title="Challenges"
        control={
          <Button
            text="Back"
            bgColor={theme.colors.NEBULA_BLUE}
            onClick={() => {
              navigate("/challenges");
            }}
          />
        }
      ></PageHeader>

      <SectionHeader title="Create new challenge"></SectionHeader>
      <ContainerComponent>
        {/* <form onSubmit={handleSubmit(handleFormSubmit)}> */}
        <StyledBox style={{ marginBottom: "2rem" }}>
          <Col>
            <FormControl
              label="country *"
              errMessage={errors?.country_id?.message}
            >
              <Controller
                isClearable
                name="country_id"
                control={control}
                rules={{ required: "Please select a country" }}
                render={({ field: { ref, ...rest } }) => (
                  <Dropdown
                    autoFocus
                    placeholder="Select country"
                    {...rest}
                    options={
                      lstCountries?.map((item) => {
                        return {
                          label: item.name,
                          value: item.id,
                        };
                      }) || []
                    }
                  />
                )}
              />
            </FormControl>

            <FormControl
              label="challenge board *"
              errMessage={errors?.challenge_board?.message}
            >
              <Controller
                isClearable
                name="challenge_board_id"
                control={control}
                rules={{ required: "Please Select challenge board" }}
                render={({ field: { ref, ...rest } }) => (
                  <Dropdown
                    placeholder="Select challenge board"
                    {...rest}
                    options={challengeBoard}
                  />
                )}
              />
            </FormControl>

            <FormControl label="Level *" errMessage={errors?.level_id?.message}>
              <Controller
                isClearable
                name="level_id"
                rules={{ required: "Please Select level" }}
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <Dropdown
                    placeholder="Select level"
                    {...rest}
                    options={
                      lstGrades?.map((item) => {
                        return {
                          label: item.name,
                          value: item.id,
                        };
                      }) || []
                    }
                  />
                )}
              />
            </FormControl>

            <FormControl
              label="section *"
              errMessage={errors?.subject_id?.message}
            >
              <Controller
                isClearable
                name="section_id"
                rules={{ required: "Please Select section" }}
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <Dropdown
                    placeholder="Select section"
                    {...rest}
                    options={section_list}
                  />
                )}
              />
            </FormControl>

            <FormControl
              label="Select grade *"
              errMessage={errors?.year_id?.message}
            >
              <Controller
                isClearable
                name="grade_id"
                rules={{ required: "Please Select grade" }}
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <Dropdown
                    placeholder="Select grade"
                    {...rest}
                    options={
                      lstGrades?.map((item) => {
                        return {
                          label: item.name,
                          value: item.id,
                        };
                      }) || []
                    }
                  />
                )}
              />
            </FormControl>
          </Col>
          <Col md={{ offset: 1 }}>
            <FormControl
              label="subject *"
              errMessage={errors?.subject_id?.message}
            >
              <Controller
                isClearable
                name="subject_id"
                rules={{ required: "Please Select subject" }}
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <Dropdown
                    placeholder="Select subject"
                    {...rest}
                    options={
                      lstSubjects?.map((item) => {
                        return {
                          label: item.name,
                          value: item.id,
                        };
                      }) || []
                    }
                  />
                )}
              />
            </FormControl>
            <FormControl
              label="year *"
              errMessage={errors?.past_paper_year?.message}
            >
              <Controller
                isClearable
                name="past_paper_year"
                control={control}
                rules={{ required: "Please add a year" }}
                render={({ field: { ref, ...rest } }) => (
                  <DateInput
                    className="react-calender"
                    autoFocus
                    placeholder="Select question"
                    {...rest}
                  />
                )}
              />
            </FormControl>

            <FormControl
              label="Instruction *"
              errMessage={errors?.instruction?.message}
            >
              <TextInput
                style={{ display: "none" }}
                id="instructionFile"
                register={register}
                validation={{ required: "Please select instruction" }}
              />
              <FileUpload
                isForm={true}
                iscustom={true}
                file={
                  typeof defaultValues?.instruction === "string"
                    ? defaultValues?.instruction
                    : defaultValues?.instructionFile
                }
                accept=".JPG,.JPEG,.PNG"
                id="instruction"
                register={register}
                handleChange={(e) => {
                  setValue("instructionFile", e?.target?.files?.[0]?.name, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
              />
            </FormControl>

            <FormControl
              label="Question type *"
              errMessage={errors?.answer_type?.message}
            >
              <Controller
                isClearable
                name="answer_type"
                control={control}
                rules={{ required: "Please select a question type" }}
                render={({ field: { ref, ...rest } }) => (
                  <Dropdown
                    autoFocus
                    placeholder="Select question"
                    {...rest}
                    options={QuestionTypes}
                    onChange={(value) => {
                      setValue("answer_type", value);
                      dispatch(resetQuestions());
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl label="Total duration *">
              <InputTime
                errMessage={
                  errors?.duration_hours?.message ||
                  errors?.duration_minutes?.message
                }
                register={register}
              />
            </FormControl>
          </Col>
        </StyledBox>
        <Row>
          <Col style={{ textAlign: "end" }}>
            <StyledButton
              type="submit"
              bgColor={theme.colors.NEBULA_BLUE}
              disabled={isDirty && isValid ? false : true}
              text={"Next"}
            />
          </Col>
        </Row>
        {/* </form> */}
      </ContainerComponent>
    </>
  );
};

export default ChallengeForm;
