import styled from "styled-components";
import { pxtoRem } from "./lib/helper";
import { Row } from "react-bootstrap";

const ViewLayoutWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.SHADE_ALICE_BLUE};
  padding: ${pxtoRem(60)} ${pxtoRem(120)};
  height: 100vh;
`;

const ViewLayoutCommonBox = styled.div`
  background-color: ${(props) => props.theme.colors.DARK_CYAN};
  color: ${(props) => props.theme.colors.WHITE};
  font-size: ${pxtoRem(20)};
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  text-align: center;
`;

const ViewLayoutEditBox = styled(ViewLayoutCommonBox)`
  width: ${pxtoRem(60)};
  height: ${pxtoRem(60)};
  border-radius: 10px;
  margin-right: ${pxtoRem(24)};
`;

const ViewLayoutCloseBox = styled(ViewLayoutCommonBox)`
  height: ${pxtoRem(61)};
  width: ${pxtoRem(155)};
  border-radius: 20px;
`;

const ViewLayoutContentRow = styled(Row)`
  background-color: ${(props) => props.theme.colors.WHITE};
  text-align: center;
  border-radius: 20px;
  padding: ${pxtoRem(30)};
  
`;

const ViewLayoutHeaderRow = styled(Row)`
  margin-bottom: ${pxtoRem(40)};
  align-items: center;
  display: flex;
`;

export {
  ViewLayoutWrapper,
  ViewLayoutEditBox,
  ViewLayoutCloseBox,
  ViewLayoutCommonBox,
  ViewLayoutContentRow,
  ViewLayoutHeaderRow
};
