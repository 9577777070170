import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/shared/Button";
import ContainerComponent from "../../components/shared/ContainerComponent";
import FormControl from "../../components/shared/FormControl";
import SectionHeader from "../../components/shared/SectionHeader";
import { Spinner } from "../../components/shared/Spinner";
import TextInput from "../../components/shared/TextInput";
import useChapter from "../../hooks/chapter/useChapter";
import { theme } from "../../lib/themeConstants";

const StyledEditLink = styled.div`
  color: ${(props) => props.theme.colors.NEBULA_BLUE};
  text-decoration: none;
  cursor: pointer;
`;

const ViewChapter = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const chapterId = location.pathname.split("/")[2];
  const chapterQuery = useChapter(chapterId);

  if (chapterQuery.isLoading) {
    return <Spinner isLoading={true} />;
  }

  const chapter = chapterQuery?.data?.[0];
  const PageControl = ({ name, value }) => {
    return (
      <FormControl label={name}>
        <TextInput disabled value={value} />
      </FormControl>
    );
  };

  return (
    <>
      <Spinner isLoading={chapterQuery.isLoading} />
      <div className="mb-2" style={{ textAlign: "end" }}>
        <Button
          onClick={() => {
            navigate("/chapters");
          }}
          bgColor={theme.colors.NEBULA_BLUE}
          text="Back"
        />
      </div>

      <SectionHeader
        title="View Chapter"
        control={
          <StyledEditLink
            onClick={() => {
              navigate(`/chapters/${chapterId}`);
            }}
          >
            Edit
          </StyledEditLink>
        }
      />

      <ContainerComponent>
        <Row>
          <Col>
            <PageControl name="Country" value={chapter?.country?.name} />
            <PageControl name="Exam board" value={chapter?.exam_board?.name} />
            <PageControl
              name="Grade"
              value={chapter?.years_data?.map((item) => {
                return `${item.name}  `;
              })}
            />
            <PageControl name="Subject" value={chapter?.subject?.name} />
          </Col>
          <Col md={{ offset: 1 }}>
            <PageControl name="Chapter number" value={chapter?.number} />
            <PageControl name="Chapter name" value={chapter?.name} />
            <PageControl
              name="Chapter icon"
              value={chapter?.image?.replace(/^.*[\\/]/, "")}
            />
            <PageControl name="Chapter color code" value={chapter?.color} />
          </Col>
        </Row>
      </ContainerComponent>
    </>
  );
};

export default ViewChapter;
