import { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import {
  Controller,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import styled from "styled-components";
import { pxtoRem } from "../../../lib/helper";
import { theme } from "../../../lib/themeConstants";
import Accordion from "../Accordion";
import Button from "../Button";
import Editor from "../Editor";
import FormControl from "../FormControl";
import TextInput from "../TextInput";
import DeleteButton from "./DeleteButton";
import FillingBlankOption from "./FillingBlankOption";
import _ from "lodash";

const StyledContainer = styled.div`
  margin-top: ${pxtoRem(20)};
  background-color: ${(props) => props.theme.colors.BODY_BG};
  border: 1px solid ${(props) => props.theme.colors.DARK_GREY};
  border-radius: 4px;
  width: 100%;
  padding: ${pxtoRem(40)};
`;

const StyledLabel = styled(Form.Label)`
  margin-bottom: 0px;
  color: ${(props) => props.theme.colors.LIGHT_GREY};
  display: block;
  margin-bottom: ${pxtoRem(40)};
`;

const StyledGapButton = styled(Button)`
  border-radius: 0px;
`;

const StyledNote = styled.span`
  font-size: ${pxtoRem(20)};
  opacity: 0.5;
`;

const FillingBlankForm = ({
  defaultValues,
  handleFormSubmit,
  nextQuestion,
  onQuestionDelete,
  onSubmit,
  formStateValues,
  setFormState,
}) => {
  const [editor, getEditor] = useState(null);
  const [editorState, setEditorState] = useState(null);
  const {
    register,
    control,
    getValues,
    formState: { errors, isValid, isDirty },
  } = useForm();

  const allValues = getValues();

  const {
    append,
    // remove,
    fields: gaps,
  } = useFieldArray({
    control,
    name: "gaps",
  });

  useEffect(() => {
    if (
      formStateValues?.isValid !== isValid ||
      formStateValues?.isDirty !== isDirty ||
      !_.isEqual(formStateValues?.allValues, allValues)
    ) {
      setFormState({ isValid, isDirty, allValues });
    }
  }, [isValid, isDirty, allValues]);

  const createGap = () => {
    editor.model.change((writer) => {
      const range = editor.model.document.selection.getFirstRange();
      for (const item of range.getItems()) {
        append({ value: item.data });
        let text = writer.createText(item.data, {
          fontBackgroundColor: theme.colors.SHADE_OF_BLUE,
        });
        editor.model.insertContent(text, range);
      }
    });
  };

  // const removeGap = () => {
  //   editor.model.change((writer) => {
  //     const range = editor.model.document.selection.getFirstRange();
  //     for (const item of range.getItems()) {
  //       let text = writer.createText(item.data);
  //       console.log(range);
  //       editor.model.insertContent(text, range);
  //     }
  //   });
  // };

  return (
    <StyledContainer>
      <Row>
        <Col xs={3}>
          <FormControl errMessage={errors?.mark?.message}>
            <TextInput
              placeholder="Mark"
              type="number"
              register={register}
              id="mark"
              validation={{
                required: "This field is required",
                min: {
                  value: 1,
                  message: "Value can not be less than 1",
                },
              }}
            />
          </FormControl>
        </Col>
        <Col style={{ textAlign: "right" }}>
          <DeleteButton />
        </Col>
      </Row>
      <Row className="description-container">
        <Col>
          <StyledLabel>Question?</StyledLabel>
          <StyledGapButton
            onClick={createGap}
            bgColor={theme.colors.NEBULA_BLUE}
            text="Create Gap"
          />
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <Editor
                isTopRadius={false}
                getEditor={getEditor}
                editorState={editorState}
                onEditorStateChange={setEditorState}
              />
            )}
          />
          <StyledNote>
            Note: to Create gap select the word , then click on the create gap
            button.
          </StyledNote>
        </Col>
      </Row>

      {gaps?.length > 0 && (
        <Row className="mb-4">
          {gaps?.map((item, index) => {
            return <FillingBlankOption word={`${index + 1}. ${item.value}`} />;
          })}
        </Row>
      )}

      <Accordion eventKey="0" heading="Add Solution">
        <Controller
          name="soution"
          control={control}
          render={({ field }) => (
            <Editor
              {...field}
              editorState={field.value}
              onEditorStateChange={(text) => {
                field.onChange(text);
              }}
            />
          )}
        />
      </Accordion>

      <Accordion eventKey="0" heading="Add Theory">
        <Controller
          name="theory"
          control={control}
          render={({ field }) => (
            <Editor
              id="theory"
              {...field}
              editorState={field.value}
              onEditorStateChange={(text) => {
                field.onChange(text);
              }}
            />
          )}
        />
      </Accordion>
      <Row className="mt-4">
        <Col xs={11} className="text-end pr-0">
          <Button
            variant="inverse"
            bgColor={theme.colors.WHITE}
            color={theme.colors.NEBULA_BLUE}
            text="Next question"
          />
        </Col>
        <Col className="text-end">
          <Button bgColor={theme.colors.NEBULA_BLUE} text="Save" />
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default FillingBlankForm;
