import { useMutation } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useLogout = () => {
  return useMutation(() => {
    return axiosPost("/api/cw/logout");
  });
};

export default useLogout;
