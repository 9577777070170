import { Button as BButton } from "react-bootstrap";
import styled from "styled-components";
import { pxtoRem } from "../../lib/helper";

const StyledButton = styled(BButton)`
  font-size: ${pxtoRem(20)};
  ${(props) =>
    props.color &&
    `color:${props.color};
    `};

  ${(props) =>
    props.bgcolor &&
    `
    border-color:${props.bgcolor};
    background-color:${props.bgcolor};
    &:hover,&:first-child:active {
      background-color:${props.bgcolor};
      border-color:${props.bgcolor};
      color:${props.color};
      filter: brightness(85%);
      }

      &:focus-visible{
        background-color:${props.bgcolor};
        border-color:${props.bgcolor};
      }
  `};

  ${(props) =>
    props.isinverse &&
    `
    border-color:${props.color};
    &:hover {
      background-color:${props.bghover};
      border-color:${props.bghover};
      color:${props.color};
      filter: brightness(85%);
    }
    &:focus-visible{
      border-color:${props.color};
      }
     
`};
`;

const StyledIcon = styled.img`
  height: ${pxtoRem(30)};
  ${(props) =>
    props.reverse &&
    `margin-left: ${pxtoRem(8)};
`};

  ${(props) =>
    !props.reverse &&
    `margin-right: ${pxtoRem(8)};
`};
`;

const Button = ({
  text,
  icon,
  reverse = false,
  bgColor,
  bgHover,
  color,
  ...rest
}) => {
  return (
    <StyledButton
      isinverse={rest?.variant === "inverse" ? 1 : 0}
      color={color}
      bghover={bgHover}
      bgcolor={bgColor}
      {...rest}
    >
      {icon && !reverse && <StyledIcon reverse={reverse} src={icon} />}
      {text}
      {icon && reverse && <StyledIcon reverse={reverse} src={icon} />}
    </StyledButton>
  );
};

export default Button;
