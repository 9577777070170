import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { pxtoRem } from "../../lib/helper";
const StyledFormItem = styled(Form.Control)`
  border-radius: 4px;
  height: inherit;
  border: 1px solid ${(props) => props.theme.colors.DARK_GREY};
  color: ${(props) => props.theme.colors.DARK_GREY};
  font-size: ${pxtoRem(20)};
  width: 100%;
  height: ${(props) => (props.isFormComponent ? pxtoRem(83) : pxtoRem(108))};
  text-align: center;
  resize: none;
  background-color: ${(props) => props.theme.colors.WHITE};

  &:focus,
  &:focus-visible,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    box-shadow: none;
    border: 1px solid ${(props) => props.theme.colors.DARK_GREY};
    color: ${(props) => props.theme.colors.DARK_GREY};
    outline: none;
  }

  ::-webkit-scrollbar {
    width: ${pxtoRem(8)};
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    border: 2px solid ${(props) => props.theme.colors.WHITE};
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: ${(props) => props.theme.colors.DARK_GREY};
  }
`;

const StyledErrMessage = styled(Form.Control.Feedback)`
  margin-top: -8px;
  margin-bottom: ${pxtoRem(12)};
  display: block;
  color: ${(props) => props.theme.colors.SHADE_OF_PINK_RED};
`;

function GlossaryItem({
  name,
  field,
  index,
  register,
  disabled,
  isFormComponent,
  errors,
}) {
  const errorList = errors?.[name]?.[index];
  return (
    <Row key={field.id}>
      <Col md={2}>
        <StyledFormItem
          {...register(`${name}.${index}.word`, {
            required: "This field is required",
          })}
          as="textarea"
          isFormComponent={isFormComponent}
          disabled={disabled}
        ></StyledFormItem>
        {errorList?.word?.message && (
          <StyledErrMessage>{errorList?.word?.message}</StyledErrMessage>
        )}
      </Col>
      <Col>
        <StyledFormItem
          {...register(`${name}.${index}.description`, {
            required: "This field is required",
          })}
          as="textarea"
          isFormComponent={isFormComponent}
          disabled={disabled}
        ></StyledFormItem>
        {errorList?.description?.message && (
          <StyledErrMessage>{errorList?.description?.message}</StyledErrMessage>
        )}
      </Col>
      <Col>
        <StyledFormItem
          {...register(`${name}.${index}.example`, {
            required: "This field is required",
          })}
          as="textarea"
          isFormComponent={isFormComponent}
          disabled={disabled}
        ></StyledFormItem>
        {errorList?.example?.message && (
          <StyledErrMessage>{errorList?.example?.message}</StyledErrMessage>
        )}
      </Col>
    </Row>
  );
}

export default GlossaryItem;
