import React from "react";
import styled from "styled-components";
import { pxtoRem } from "../../lib/helper";

const StyledQuestionNumberContainer = styled.div`
  height: ${(props) => (props?.isSubQuesEdit ? pxtoRem(45) : pxtoRem(22))};
  width: ${(props) => (props?.isSubQuesEdit ? pxtoRem(45) : pxtoRem(42))};
  border-radius: ${pxtoRem(4)};
  margin-right: ${(props) =>
    props?.isSubQuesEdit ? pxtoRem(15) : pxtoRem(16.5)};
  margin-left: ${(props) =>
    props?.isSubQuesEdit ? pxtoRem(15) : pxtoRem(16.5)};
  margin-top: ${(props) => (props?.isSubQuesEdit ? pxtoRem(0) : pxtoRem(10))};
  margin-bottom: ${(props) =>
    props?.isSubQuesEdit ? pxtoRem(15) : pxtoRem(30)};
  align-items: center;
  cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};
  border: 1px solid ${(props) => props.theme.colors.DARK_GREY};
  border-color: ${(props) =>
    props.isCurrent || props.isCompleted
      ? props.theme.colors.NEBULA_BLUE
      : props.theme.colors.DARK_GREY};
  color: ${(props) =>
    props.isCurrent
      ? props.theme.colors.NEBULA_BLUE
      : props.isCompleted
      ? props.theme.colors.WHITE
      : props.theme.colors.DARK_GREY};
  background-color: ${(props) =>
    props.isCurrent
      ? props.theme.colors.WHITE
      : props.isCompleted
      ? props.theme.colors.MEDIUM_SLATE_BLUE
      : props.theme.colors.WHITE};
  font-size: ${pxtoRem(18)};
  display: flex;
  justify-content: center;
  text-align: center;
`;

function SubQuestionNumber({
  children,
  isCurrent = false,
  isCompleted = true,
  onChange,
  index,
  isSubQuesEdit,
}) {
  return (
    <StyledQuestionNumberContainer
      isCurrent={isCurrent}
      isCompleted={isCompleted}
      onClick={() => {
        onChange(index);
      }}
      key={children}
      isSubQuesEdit={isSubQuesEdit}
    >
      {children}
    </StyledQuestionNumberContainer>
  );
}

export default SubQuestionNumber;
