import { useState } from "react";
import { ListGroup, ListGroupItem, Modal } from "react-bootstrap";
import styled from "styled-components";
import { Constant } from "../../lib/Constant";
import Button from "./Button";
import FileUpload from "./FileUpload";

const StyledLayer = styled.div`
  border: none;
  justify-content: center;
`;

const StyledHeader = styled(StyledLayer)`
  color: #fafafa;
  background-color: #5a5eee;
  display: inline-block;
  font-size: 16px;
`;

const StayledIcon = styled.img`
  cursor: pointer;
`;

const StyledBody = styled(StyledLayer)`
  padding-top: 0px;
  margin-top: 20px;
`;

const StyledItem = styled(ListGroupItem)`
  border: none;
  padding-left: 0px;
  padding-bottom: 0px;
  color: #707070;
`;

const StyledFooter = styled(Modal.Footer)`
  border: none;
  padding-right: 24px;
  margin-top: 24px;
`;

const UploadPopUp = ({ show, onCancel }) => {
  return (
    <Modal show={show} onHide={onCancel}>
      <StyledHeader as={Modal.Header}>Upload Files</StyledHeader>
      <StyledBody as={Modal.Body}>
        <ListGroup>
          <StyledItem>Making scheme</StyledItem>
          <StyledItem>
            <FileUpload />
          </StyledItem>
          <StyledItem>
            File upload success{" "}
            <img src={Constant.Icons.check_circle} height={12} alt="check" />{" "}
          </StyledItem>
        </ListGroup>
      </StyledBody>

      <StyledFooter>
        <Button
          variant="inverse"
          bgHover="white"
          color="#6C6C6C"
          text="Cancel"
          onClick={onCancel}
        />
        <Button text="Save" />
      </StyledFooter>
    </Modal>
  );
};
const FileUploadModal = ({ icon = Constant.Icons.upload }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <StayledIcon
        onClick={() => {
          setShow(true);
        }}
        src={icon}
        alt="upload"
      />
      <UploadPopUp
        show={show}
        onCancel={() => {
          setShow(false);
        }}
      />
    </>
  );
};

export default FileUploadModal;
