import React, { useEffect } from "react";
import { Badge, Col, OverlayTrigger, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Autocomplete from "../../components/shared/Autocomplete";
import Button from "../../components/shared/Button";
import PageHeader from "../../components/shared/PageHeader";
import Table from "../../components/shared/Table";
import { Constant } from "../../lib/Constant";
import ContainerComponent from "../../components/shared/ContainerComponent";
import { pxtoRem } from "../../lib/helper";
import { theme } from "../../lib/themeConstants";
import useChapters from "../../hooks/chapter/useChapters";
import {
  useCountries,
  useExamBoards,
  useSubjects,
  useYears,
} from "../../hooks/chapter";
import { Spinner } from "../../components/shared/Spinner";
import Empty from "../../components/shared/Empty";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setPageChange } from "../../redux-toolkit/table";
import { setFilter } from "../../redux-toolkit/chapters";

const StyledRoundIcon = styled.div`
  height: ${pxtoRem(60)};
  width: ${pxtoRem(60)};
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.NEBULA_BLUE};
  opacity: 0.5;
  flex: 0 0 ${pxtoRem(60)};
`;

const StyledChapterNameContainer = styled.div`
  display: flex;
  padding-left: ${pxtoRem(20)};
  padding-right: ${pxtoRem(20)};
  align-items: center;
`;

const StyledChapterName = styled.div`
  margin-left: ${pxtoRem(40)};
`;

const StyledBadge = styled(Badge)`
  background-color: ${(props) => props.color} !important;
  width: ${pxtoRem(146)};
  height: ${pxtoRem(46)};
  padding-top: ${pxtoRem(13)};
  font-size: ${pxtoRem(20)};
  border-radius: ${pxtoRem(10)};
`;

const StyledButton = styled(Button)`
  height: ${pxtoRem(46)};
  width: ${pxtoRem(67)};
  font-size: ${pxtoRem(20)};
  /* line-height: ${pxtoRem(18)}; */
  padding: 0;
`;

const StyledViewLessonLink = styled.div`
  background-color: ${(props) => props.theme.colors.WHITE};
  width: ${pxtoRem(109)};
  height: ${pxtoRem(64)};
  box-shadow: 0px 3px 6px ${(props) => props.theme.colors.LIGHT_GREY};
  border-radius: ${pxtoRem(0)};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  a {
    font-size: ${pxtoRem(16)};
    text-decoration: none;
    color: ${(props) => props.theme.colors.DEEP_SKY_BLUE};
  }
`;

const StyledPopoverIcon = styled.img`
  margin-left: ${pxtoRem(5)};
  width: ${pxtoRem(25)};
  /* height: ${pxtoRem(50)}; */
  visibility: ${(props) => !props.show && "hidden"};
  cursor: pointer;
`;

const StyledCreateLessonButton = styled(StyledButton)`
  width: ${pxtoRem(185)};
`;

const StyledCreateLessonDiv = styled.div`
  min-width: ${pxtoRem(230)};
`;
function Chapters() {
  const dispatch = useDispatch();

  const currentPage = useSelector((state) => {
    return state.table.page;
  });

  const { country, examboard, subject, grade } = useSelector((state) => {
    return state.chapters.filter;
  });

  const {
    data: chapters,
    error,
    isFetching,
  } = useChapters(
    country?.value,
    examboard?.value,
    subject?.value,
    grade?.value,
    currentPage
  );

  const { data: countriesData, isFetching: countriesLoading } = useCountries();
  const { data: examboardData, isFetching: examLoading } = useExamBoards();
  const { data: subjectsData, isFetching: subjectsLoading } = useSubjects();
  const { data: gradesData, isFetching: gradesLoading } = useYears();

  useEffect(() => {
    if (error) {
      toast.error(error?.response?.data?.message);
    }
    return () => {
      dispatch(setPageChange(1));
    };
  }, [dispatch, error]);
  const navigate = useNavigate();

  const formatFilterOptions = (data) => {
    let formattedData = data?.map((item) => {
      return { value: item.id, label: item.name };
    });
    return formattedData;
  };

  const onFilterChange = (key, value) => {
    let data = { country, examboard, subject, grade };
    data[key] = value;
    dispatch(setFilter(data));
  };

  const renderViewLessonContainer = (props) => (
    // <Tooltip id="button-tooltip" {...props}>
    <StyledViewLessonLink id={props.id} {...props}>
      <Link
        tabIndex={1}
        to={`/chapters/${props?.id}/lessons`}
        state={{ chapter: props.chapter }}
      >
        View lesson
      </Link>
    </StyledViewLessonLink>
    // </Tooltip>
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Chapter name",
        accessor: "name",
        customWidth: 30,
        Cell: ({ row }) => (
          <StyledChapterNameContainer>
            <StyledRoundIcon />
            <StyledChapterName> {row?.original?.name}</StyledChapterName>
          </StyledChapterNameContainer>
        ),
      },
      {
        Header: "Subject",
        accessor: "subject",
        Cell: ({ row }) => (
          <StyledBadge
            color={
              row?.original?.subject?.color && row?.original?.subject?.color
            }
            text="dark"
          >
            {row?.original?.subject?.name && row.original.subject.name}
          </StyledBadge>
        ),
      },
      {
        Header: "Grade",
        accessor: "year",
        Cell: ({ row }) => (
          <span>
            {row?.original?.years_data &&
              row?.original?.years_data
                .map(function (item) {
                  return item.name;
                })
                .join(",")}
          </span>
        ),
      },
      {
        Header: "Exam board",
        accessor: "exam_board",
        Cell: ({ row }) => <span>{row?.original?.exam_board?.name}</span>,
      },
      {
        Header: "Action",
        Cell: ({ row }) => (
          <StyledButton
            text="View"
            onClick={() => navigate(`${row?.original?.id}/show`)}
            bgColor={theme.colors.NEBULA_BLUE}
          />
        ),
      },
      {
        Header: "Create Lesson",
        customWidth: 17,
        Cell: ({ row }) => (
          <StyledCreateLessonDiv>
            <StyledCreateLessonButton
              text={
                parseInt(row?.original?.lessons_count) > 0
                  ? "Created"
                  : "Create Lesson"
              }
              className="create-lesson-btn"
              onClick={() => {
                if (parseInt(row?.original?.lessons_count) === 0) {
                  navigate(`${row?.original?.id}/lessons/create`, {
                    state: { chapter: row.original, isFromChapter: true },
                  });
                }
              }}
              bgColor={
                parseInt(row?.original?.lessons_count) > 0
                  ? theme.colors.VIVID_MALACHITE
                  : theme.colors.NEBULA_BLUE
              }
            />
            <OverlayTrigger
              placement={"bottom-end"}
              trigger={"click"}
              rootClose={true}
              // containerPadding={100}
              overlay={(props) =>
                renderViewLessonContainer({
                  ...props,
                  id: row?.original?.id,
                  chapter: row.original,
                })
              }
            >
              <StyledPopoverIcon
                tabIndex={0}
                alt="view lessons"
                src={Constant.Icons.eclipes}
                show={parseInt(row?.original?.lessons_count) > 0}
              />
            </OverlayTrigger>
          </StyledCreateLessonDiv>
        ),
      },
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <Spinner
        isLoading={
          isFetching ||
          countriesLoading ||
          examLoading ||
          subjectsLoading ||
          gradesLoading
        }
      />
      <PageHeader
        title="Chapters"
        control={
          <Button
            text="Create Chapter"
            icon={Constant.Icons.add_circle_outline_white}
            bgColor={theme.colors.NEBULA_BLUE}
            onClick={() => {
              navigate("create");
            }}
          />
        }
      ></PageHeader>

      <ContainerComponent>
        <Row>
          <Col>
            <Autocomplete
              options={formatFilterOptions(countriesData)}
              value={country}
              onChange={(data) => {
                // setCountry(data);
                onFilterChange("country", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Country"
              className="m-2 auto-complete"
            />
          </Col>
          <Col>
            <Autocomplete
              options={formatFilterOptions(examboardData)}
              value={examboard}
              onChange={(data) => {
                // setExamboard(data);
                onFilterChange("examboard", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Examboard"
              className="m-2 auto-complete"
            />
          </Col>
          <Col>
            <Autocomplete
              options={formatFilterOptions(subjectsData)}
              value={subject}
              onChange={(data) => {
                // setSubject(data);
                onFilterChange("subject", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Subject"
              className="m-2 auto-complete"
            />
          </Col>
          <Col>
            <Autocomplete
              options={formatFilterOptions(gradesData)}
              value={grade}
              onChange={(data) => {
                // setGrade(data);
                onFilterChange("grade", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Grade"
              className="m-2 auto-complete"
            />
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col className="mt-3">
            {chapters?.data?.length > 0 ? (
              <Table
                data={chapters?.data}
                columns={columns}
                defaultPageSize={chapters?.per_page}
                totalPages={chapters?.last_page}
                currentPage={chapters?.current_page}
              ></Table>
            ) : (
              !isFetching && (
                <Empty text={Constant.tableErrorMessages.chapters} />
              )
            )}
          </Col>
        </Row>
      </ContainerComponent>
    </>
  );
}

export default Chapters;
