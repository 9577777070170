import styled from "styled-components";
import { pxtoRem } from "../../../lib/helper";
import { Modal } from "react-bootstrap";
import { Constant } from "../../../lib/Constant";

const StyledTitle = styled(Modal.Title)`
  color: ${(props) => props.theme.colors.WHITE};
  font-size: ${pxtoRem(24)};
`;

const StyledModal = styled(Modal)``;
const Header = styled(Modal.Header)`
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.NEBULA_BLUE};
  height: ${pxtoRem(74)};
  .btn-close {
    background: transparent url(${Constant.Icons.close_white}) center/
      ${pxtoRem(22)} auto no-repeat;
    margin-right: ${pxtoRem(20)};
  }
`;

const StyledBody = styled(Modal.Body)`
  // width: ${pxtoRem(1681)};
  display: felx;
  align-items: center;
`;

const EditModal = ({ title, onCancel, show, children }) => {
  return (
    <StyledModal
      centered
      onHide={onCancel}
      show={show}
      centerd={"true"}
      size="xl"
    >
      <Header closeButton>
        <StyledTitle>{title}</StyledTitle>
      </Header>
      <StyledBody>{children}</StyledBody>
    </StyledModal>
  );
};

export default EditModal;
