import { useQuery } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useViewAllChallenges = (
  country_id,
  challenge_board_id,
  subject_id,
  grade_id,
  year,
  page
) => {
  const formData = {};
  if (country_id) formData.country_id = country_id;
  if (challenge_board_id) formData.challenge_board_id = challenge_board_id;
  if (subject_id) formData.subject_id = subject_id;
  if (grade_id) formData.grade_id = grade_id;
  if (year) formData.year_id = year;
  if (page) {
    formData.pagination = true;
    formData.page = page;
  }
  return useQuery(
    [
      "challenges",
      country_id,
      challenge_board_id,
      subject_id,
      grade_id,
      year,
      page,
    ],
    async () => {
      const { data } = await axiosPost("/api/cw/viewChallenge", formData);
      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

export default useViewAllChallenges;
