import React, { useState, useEffect } from "react";
import WorkSheetForm from "../../components/form/WorksheetForm";
import QuestionContainer from "../../components/shared/question/QuestionContainer";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { convertBase64 } from "../../lib/helper";
import { useDispatch, useSelector } from "react-redux";
import { setFormData } from "../../redux-toolkit/worksheet";
import {
  useAddWorksheet,
  useViewWorksheetDemo,
  useUpdateWorksheetQuestions,
  useDeleteQuestion,
} from "../../hooks/worksheet";
import { resetQuestions } from "../../redux-toolkit/questions";
import { useQueryClient } from "react-query";
import { Spinner } from "../../components/shared/Spinner";

function AddWorksheet() {
  const formPages = 2;
  const [formStep, setFormStep] = useState(1);
  const [pageJump, setPageJump] = useState(1);
  const [newStep, setNewStep] = useState(false);
  const [worksheetId, setWorksheetId] = useState(null);
  const [breadCrumData, setBreadCrumData] = useState([]);
  const { formData } = useSelector((state) => {
    return state.worksheet;
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitted, isDirty, isValid },
    watch,
    reset,
    setValue,
    getValues,
  } = useForm({ mode: "onChange" });

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { data: worksheetQuestions, isFetching } =
    useViewWorksheetDemo(worksheetId);
  const createMutation = useAddWorksheet();
  const updateMutation = useUpdateWorksheetQuestions();
  const deleteMutation = useDeleteQuestion();

  const onSubmit = async (data) => {
    dispatch(setFormData({ ...formData, ...data }));
    if (formStep < formPages) {
      const thisStep = formStep + pageJump;
      setFormStep(thisStep);
      setNewStep(true);
      return false;
    }
    return false;
  };

  function backPage(e, val) {
    const thisStep = formStep - val;
    setPageJump(val);
    setFormStep(thisStep);
    setNewStep(true);
  }

  useEffect(() => {
    return () => {
      dispatch(resetQuestions());
      dispatch(setFormData(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset(formData, {
      keepValues: true,
      keepDirty: true,
    });
    setNewStep(false);
    // eslint-disable-next-line
  }, [isSubmitted, reset, newStep]);

  const onQuestionSubmit = async (data, onFormSuccess) => {
    // let formData = new FormData();
    let form_data = getValues();
    // for (const obj in questionData) {
    //   formData.append(obj, questionData[obj]);
    // }
    data.append("duration_hours", form_data.duration_hours);
    data.append("duration_minutes", form_data.duration_minutes);
    data.append("chapter_id", form_data.chapter_id);
    data.append("question_type", form_data.question_type);
    if (
      typeof form_data?.cover_image !== "string" &&
      form_data?.cover_image?.length > 0
    ) {
      const imageBase64 = await convertBase64(form_data?.cover_image?.[0]);
      data.append("cover_image", imageBase64);
    }
    form_data.question_type === "sa" &&
      !data.get("sa_question_answer") &&
      data.append("sa_question_answer", "123");

    if (data.get("id")) {
      updateMutation.mutate(data, {
        onError: (res) => {
          toast.error(res?.response?.data?.message);
          onFormSuccess(false);
        },
        onSuccess: (res) => {
          queryClient.invalidateQueries("viewWorksheetDemo");
          onFormSuccess(true);
          toast.success("Question updated successfuly");
        },
      });
    } else {
      createMutation.mutate(data, {
        onError: (res) => {
          toast.error(res?.response?.data?.message);
          onFormSuccess(false);
        },
        onSuccess: (res) => {
          queryClient.invalidateQueries("viewWorksheetDemo");
          onFormSuccess(true);
          if (!worksheetId) setWorksheetId(res?.data?.data?.work_sheet?.id);
          toast.success("Question added successfuly");
        },
      });
    }
  };

  const onQuestionDelete = (formData, onDelete) => {
    deleteMutation.mutate(formData, {
      onError: (res) => {
        toast.error(res?.response?.data?.message);
      },
      onSuccess: (res) => {
        queryClient.invalidateQueries("viewWorksheetDemo");
        toast.success("Question deleted successfuly");
        onDelete();
      },
    });
  };

  const methods = {
    control,
    register,
    errors,
    handleSubmit,
    watch,
    backPage,
    getValues,
    setValue,
    reset,
    isDirty,
    isValid,
  };

  return (
    <>
      <Spinner
        isLoading={
          isFetching ||
          createMutation?.isLoading ||
          updateMutation?.isLoading ||
          deleteMutation?.isLoading
        }
      />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {formStep === 1 && (
            <WorkSheetForm setBreadCrumData={setBreadCrumData} />
          )}
        </form>
        {formStep === 2 && (
          <QuestionContainer
            title="Worksheet"
            onSubmit={onSubmit}
            Questions={worksheetQuestions || []}
            onQuestionSubmit={onQuestionSubmit}
            onQuestionDelete={onQuestionDelete}
            type={formData?.question_type}
            breadCrumData={breadCrumData}
          ></QuestionContainer>
        )}
      </FormProvider>
    </>
  );
}

export default AddWorksheet;
