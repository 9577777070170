import { useEffect, useState } from "react";
import { Constant } from "../../lib/Constant";
import { theme } from "../../lib/themeConstants";
import BoxImage from "./question/BoxImage";
import AlertPopUp from "./popups/AlertPopUp";
import styled from "styled-components";
import { pxtoRem } from "../../lib/helper";

const StyledDelete = styled.div`
  color: ${(props) => props?.theme?.colors?.DEL_RED};
  cursor: pointer;
  img {
    height: ${pxtoRem(26)};
    margin-bottom: ${pxtoRem(5)};
  }
`;

const DeleteButton = ({
  header = "Are you sure you want to delete the question details?",
  body,
  onDelete,
  isedit,
}) => {
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  return (
    <>
      <AlertPopUp
        confirmText="Delete"
        cancelText="Cancel"
        onCancel={() => {
          setIsDeleteModal(false);
        }}
        show={isDeleteModal}
        isdelete={true}
        onConfirm={() => {
          onDelete && onDelete();
          setIsDeleteModal(false);
        }}
        header={header}
        body={body}
      />

      {isedit ? (
        <StyledDelete
          onClick={() => {
            setIsDeleteModal(true);
          }}
        >
          <img src={Constant.Icons.delete_bg_red} /> delete question
        </StyledDelete>
      ) : (
        <BoxImage
          bgColor={theme.colors.PINK}
          imgSrc={Constant.Icons.delete_bg_white}
          onClick={() => {
            setIsDeleteModal(true);
          }}
        />
      )}
    </>
  );
};

export default DeleteButton;
