import React, { useState, useEffect } from "react";
import PastPaperForm from "../../components/form/PastPaperForm";
import QuestionContainer from "../../components/shared/question/QuestionContainer";
import { FormProvider, useForm } from "react-hook-form";
import { Questions } from "../../lib/dummyData";
import { toast } from "react-toastify";
import { convertBase64, removeBlank } from "../../lib/helper";
import { useDispatch, useSelector } from "react-redux";
import { setFormData } from "../../redux-toolkit/pastPaper";
import {
  useAddPastPaper,
  useDeleteQuestion,
  useUpdatePastPaper,
  useViewPastPaperDemo,
} from "../../hooks/pastPapers";
import { resetQuestions } from "../../redux-toolkit/questions";
import moment from "moment";
import { useChapterFilters } from "../../hooks/worksheet";

function AddPastPaper() {
  const formPages = 2;
  const [formStep, setFormStep] = useState(1);
  const [pageJump, setPageJump] = useState(1);
  const [newStep, setNewStep] = useState(false);
  const [pastPaperId, setPastPaperId] = useState(null);
  const { formData } = useSelector((state) => {
    return state.pastPaper;
  });

  const { data: questions, isFetching } = useViewPastPaperDemo(pastPaperId);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitted, isDirty, isValid },
    watch,
    reset,
    setValue,
    getValues,
  } = useForm({ mode: "onChange" });

  const { data: chapters } = useChapterFilters(
    watch("country_id"),
    watch("exam_board_id"),
    watch("subject_id"),
    null,
    watch("level_id")
  );

  const createMutation = useAddPastPaper();
  const updateMutation = useUpdatePastPaper();
  const deleteMutation = useDeleteQuestion();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    dispatch(setFormData({ ...formData, ...data }));
    if (formStep < formPages) {
      const thisStep = formStep + pageJump;
      setFormStep(thisStep);
      setNewStep(true);
      return false;
    }
    return false;
  };

  function backPage(e, val) {
    const thisStep = formStep - val;
    setPageJump(val);
    setFormStep(thisStep);
    setNewStep(true);
  }

  useEffect(() => {
    return () => {
      dispatch(resetQuestions());
      dispatch(setFormData(null));
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    reset(formData, {
      keepValues: true,
      keepDirty: true,
    });
    setNewStep(false);
    // eslint-disable-next-line
  }, [isSubmitted, reset, newStep]);

  const onQuestionSubmit = async (formData, onFormSuccess) => {
    let form_data = getValues();

    formData.append("question_type", form_data.question_type);
    formData.paper_level &&
      formData.append("paper_level", form_data.paper_level);
    formData.paper_no && formData.append("paper_no", form_data.paper_no);
    formData.append("year", form_data?.year);
    formData.append("duration_hours", form_data?.duration_hours);
    formData.append("duration_minutes", form_data?.duration_minutes);

    if (
      typeof form_data?.instruction !== "string" &&
      form_data?.instruction?.length > 0
    ) {
      const imageBase64 = await convertBase64(form_data?.instruction?.[0]);
      formData.append("instruction", imageBase64);
    }
    if (
      typeof form_data?.cover_image !== "string" &&
      form_data?.cover_image?.length > 0
    ) {
      const imageBase64 = await convertBase64(form_data?.cover_image?.[0]);
      formData.append("cover_image", imageBase64);
    }
    !formData.sa_question_answer &&
      formData.append("sa_question_answer", "123");

    if (formData.get("id")) {
      updateMutation.mutate(formData, {
        onError: (res) => {
          toast.error(res?.response?.data?.message);
          onFormSuccess(false);
        },
        onSuccess: (res) => {
          // queryClient.invalidateQueries("viewWorksheet");
          onFormSuccess(true);
        },
      });
    } else {
      createMutation.mutate(formData, {
        onError: (res) => {
          toast.error(res?.response?.data?.message);
          onFormSuccess(false);
        },
        onSuccess: (res) => {
          // queryClient.invalidateQueries("viewWorksheet");
          console.log(res);
          onFormSuccess(true);
        },
      });
    }
  };

  const onQuestionDelete = (formData, onDelete) => {
    deleteMutation.mutate(formData, {
      onError: (res) => {
        toast.error(res?.response?.data?.message);
      },
      onSuccess: (res) => {
        toast.success("Question deleted successfuly");
        onDelete();
      },
    });
  };

  const methods = {
    control,
    register,
    errors,
    handleSubmit,
    watch,
    backPage,
    getValues,
    setValue,
    isDirty,
    isValid,
    reset,
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {formStep === 1 && <PastPaperForm defaultValues={formData} />}
        </form>
        {formStep === 2 && (
          <QuestionContainer
            title="Past paper"
            onSubmit={onSubmit}
            Questions={questions || []}
            onQuestionSubmit={onQuestionSubmit}
            onQuestionDelete={onQuestionDelete}
            type={formData?.question_type}
            chapters={chapters}
          ></QuestionContainer>
        )}
      </FormProvider>
    </>
  );
}

export default AddPastPaper;
