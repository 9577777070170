import React, { useState } from "react";
import { Spinner } from "../../components/shared/Spinner";
import { Badge, Col, Row } from "react-bootstrap";
import Autocomplete from "../../components/shared/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { setPageChange } from "../../redux-toolkit/table";
import { setFilter } from "../../redux-toolkit/curriculum";
import styled from "styled-components";
import {
  useChapters,
  useCountries,
  useExamBoards,
  useSubjects,
  useYears,
} from "../../hooks/chapter";
import Button from "../../components/shared/Button";
import { Constant } from "../../lib/Constant";
import { theme } from "../../lib/themeConstants";
import { useNavigate } from "react-router-dom";
import { pxtoRem } from "../../lib/helper";
import Table from "../../components/shared/Table";
import Empty from "../../components/shared/Empty";
import Dropdown from "../../components/shared/Dropdown";

const StyledBadge = styled(Badge)`
  background-color: ${(props) => props.color} !important;
  width: ${pxtoRem(146)};
  height: ${pxtoRem(46)};
  padding-top: ${pxtoRem(13)};
  font-size: ${pxtoRem(20)};
  border-radius: ${pxtoRem(10)};
`;

const StyledButton = styled(Button)`
  height: ${pxtoRem(46)};
  width: ${pxtoRem(110)};
  font-size: ${pxtoRem(20)};
  padding: 0;
`;

const StyledName = styled.div`
  text-align: start;
  margin-left: ${pxtoRem(100)};
`;

const StyledDropDownContainer = styled.div`
  width: ${pxtoRem(320)};
  margin-top: ${pxtoRem(40)};
  margin-bottom: ${pxtoRem(20)};
`;

const StyledHeaderTd = styled.p`
  font-weight: 500;
  font-size: ${20};
`;

const Curriculum = () => {
  const [content, setContent] = useState(2);
  const contentData = [
    { value: 1, label: "Chapter" },
    { value: 2, label: "Unit" },
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { country, examboard, subject, grade } = useSelector(
    (state) => state.curriculum?.filter
  );

  const { data: countriesData, isLoading: countryLoading } = useCountries();
  const { data: examboardData, isLoading: examBoardLoading } = useExamBoards();
  const { data: subjectsData, isLoading: subjectsLoading } = useSubjects();
  const { data: gradesData, isLoading: gradeLoading } = useYears();

  const { data: chapters, isLoading } = useChapters(
    country?.value,
    examboard?.value,
    subject?.value,
    grade?.value
  );

  const formatFilterOptions = (data) => {
    let formattedData = data?.map((item) => {
      return { value: item.id, label: item.name };
    });
    return formattedData;
  };

  const onFilterChange = (key, value) => {
    let data = { country, examboard, subject, grade };
    data[key] = value;
    dispatch(setFilter(data));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Chapter No",
        accessor: "chapter_no",
        className: "text-center align-middle",
        headerClassName: "text-center pt-3 pb-3 fw-bolder",
        Cell: ({ row }) => <span>{row?.original?.number}</span>,
      },
      {
        Header: "Chapter name",
        accessor: "name",
        customWidth: 30,
        className: "text-center align-middle",
        headerClassName: "text-center pt-3 pb-3 fw-bolder",
        Cell: ({ row }) => <StyledName> {row?.original?.name}</StyledName>,
      },
      {
        Header: "Lesson plan",
        accessor: "lesson_plan",
        className: "text-center align-middle fw-bolder",
        headerClassName: "text-center pt-3 pb-3",
        Cell: ({ row }) => (
          <img
            height={pxtoRem(600)}
            width={pxtoRem(600)}
            src={Constant.Icons.green_file}
            style={{ color: "green" }}
            alt="file_icon"
          />
        ),
      },
      {
        Header: " ",
        className: "text-end align-middle pe-3",
        headerClassName: "text-center pt-3 pb-3",
        Cell: ({ row }) => (
          <StyledButton
            text="View"
            onClick={() => navigate(`${row?.original?.id}/show`)}
            bgColor={theme.colors.NEBULA_BLUE}
          />
        ),
      },
    ],
    // eslint-disable-next-line
    []
  );

  const unitColumn = React.useMemo(() => [
    {
      Header: "Unit Name",
      accessor: "unit_name",
      className: "align-middle ps-3",
      headerClassName: "text-start ps-3 pt-3 pb-3",
      Cell: ({ row }) => <span>{row?.original?.unit_name}</span>,
    },
  ]);

  const unitData = [
    { unit_name: "Unit 1: principle of chemistry" },
    { unit_name: "Unit 2: Inorganic chemistry" },
    { unit_name: "Unit 3: physical chemistry" },
    { unit_name: "Unit 4: inorganic chemistry" },
  ];

  return (
    <>
      <Spinner
        isLoading={
          countryLoading || examBoardLoading || subjectsLoading || gradeLoading
        }
      />

      <Row style={{ display: "flex", alignItems: "center" }}>
        <Col>
          <Autocomplete
            options={formatFilterOptions(countriesData)}
            value={country}
            onChange={(data) => {
              // setSubject(data);
              onFilterChange("country", data);
              dispatch(setPageChange(1));
            }}
            placeholder="Country"
            className="m-2 auto-complete"
            backgroundColor={theme.colors.WHITE}
          />
        </Col>
        <Col>
          <Autocomplete
            options={formatFilterOptions(examboardData)}
            value={examboard}
            onChange={(data) => {
              // setSubject(data);
              onFilterChange("examboard", data);
              dispatch(setPageChange(1));
            }}
            placeholder="Examboard"
            className="m-2 auto-complete"
            backgroundColor={theme.colors.WHITE}
          />
        </Col>
        <Col>
          <Autocomplete
            options={formatFilterOptions(subjectsData)}
            value={subject}
            onChange={(data) => {
              // setSubject(data);
              onFilterChange("subject", data);
              dispatch(setPageChange(1));
            }}
            placeholder="Subject"
            className="m-2 auto-complete"
            backgroundColor={theme.colors.WHITE}
          />
        </Col>

        <Col>
          <Autocomplete
            options={formatFilterOptions(gradesData)}
            value={grade}
            onChange={(data) => {
              // setSubject(data);
              onFilterChange("grade", data);
              dispatch(setPageChange(1));
            }}
            placeholder="Grade"
            className="m-2 auto-complete"
            backgroundColor={theme.colors.WHITE}
          />
        </Col>
        <Col style={{ textAlign: "end" }}>
          <Button
            text="Create syllabus"
            icon={Constant.Icons.add_circle_outline_white}
            bgColor={theme.colors.NEBULA_BLUE}
            style={{
              borderRadius: pxtoRem(4),
            }}
            onClick={() => {
              navigate("create");
            }}
          />
        </Col>
      </Row>
      <Row>
        <StyledDropDownContainer>
          <Dropdown
            options={contentData}
            value={content}
            onChange={(data) => {
              setContent(data);
            }}
            placeholder="Unit"
            className="m-2 auto-complete"
            borderColor={theme.DropDownColors.NEBULA_BLUE}
          />
        </StyledDropDownContainer>
      </Row>

      <Row>
        {content === 1 ? (
          <Col style={{ height: pxtoRem() }}>
            {chapters?.length > 0 ? (
              <Table
                data={chapters?.slice(0, 10)}
                columns={columns}
                headerBgColor={theme.colors.LAVENDER_MIST}
                BodyBgColor={theme.colors.WHITE}
                pagination={false}
              ></Table>
            ) : (
              !isLoading && (
                <Empty text={Constant.tableErrorMessages.chapters} />
              )
            )}
          </Col>
        ) : (
          <>
            <Col xs={4}>
              <Table
                data={unitData}
                columns={unitColumn}
                headerBgColor={theme.colors.LAVENDER_MIST}
                BodyBgColor={theme.colors.WHITE}
                pagination={false}
              ></Table>
            </Col>
            <Col xs={8}>
              {chapters?.length > 0 ? (
                <Table
                  data={chapters?.slice(0, 10)}
                  columns={columns}
                  headerBgColor={theme.colors.LAVENDER_MIST}
                  BodyBgColor={theme.colors.WHITE}
                  pagination={false}
                ></Table>
              ) : (
                !isLoading && (
                  <Empty text={Constant.tableErrorMessages.chapters} />
                )
              )}
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default Curriculum;
