import React from "react";
import styled from "styled-components";
import { pxtoRem } from "../../../lib/helper";
import { Col, Row } from "react-bootstrap";
import { Constant, Qtype } from "../../../lib/Constant";
import SaQuestionNumbers from "./SaQuestionNumbers";
import ViewQuestionNumbers from "./ViewQuestionNumbers";

const ContainerRow = styled(Row)`
  margin-top: ${pxtoRem(60)};
  justify-content: center;
`;

const StyledContentRow = styled(Row)`
  padding: ${pxtoRem()};
  margin-top: ${pxtoRem(60)};
  height: ${pxtoRem(30)};
`;

const StyledText = styled.div`
  font-size: ${pxtoRem(30)};
  color: ${(props) => props.theme.colors.NAVY_BLUE};
  font-weight: 600;
  text-align: left;
  margin-left: ${pxtoRem(70)};
`;
const StyledMarkingScheme = styled.div`
  padding-bottom: ${pxtoRem(30)};
  display: flex;
  flex-direction: column;
  align-items: start;
`;
const StyledHr = styled.hr`
  color: gray;
  width: 93%;
`;

const StyledQuesCol = styled(Col)`
  padding-top: ${pxtoRem(20)};
  padding-bottom: ${pxtoRem(20)};
  padding-left: ${pxtoRem(70)};
  text-align: left;
`;

const SASolution = ({ markingScheme = null, theory = null }) => {
  return (
    <>
      <ContainerRow>
        {markingScheme && (
          <>
            <StyledHr />
            <StyledMarkingScheme>
              <StyledText>Marking Scheme:</StyledText>
              <StyledQuesCol
                dangerouslySetInnerHTML={{ __html: markingScheme }}
              />
            </StyledMarkingScheme>
          </>
        )}
        {theory && (
          <>
            <StyledHr />
            <StyledMarkingScheme>
              <StyledText>Theory:</StyledText>
              <StyledQuesCol dangerouslySetInnerHTML={{ __html: theory }} />
            </StyledMarkingScheme>
          </>
        )}
      </ContainerRow>
    </>
  );
};

export default SASolution;
