import { useMutation } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useUpdateChapter = () => {
  return useMutation((chapter) => {
    return axiosPost("/api/cw/updateChapter", chapter);
  });
};

export default useUpdateChapter;
