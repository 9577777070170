import { useMutation } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useUpdateSelftestQuestion = () => {
  return useMutation((test) => {
    return axiosPost("/api/cw/updateStQuestion", test);
  });
};

export default useUpdateSelftestQuestion;
