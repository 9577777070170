import { useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import ChapterForm from "../../components/form/ChapterForm";
import Button from "../../components/shared/Button";
import SuccessModal from "../../components/shared/popups/SuccessModal";
import { useCreateChapter } from "../../hooks/chapter";
import { Constant } from "../../lib/Constant";
import { theme } from "../../lib/themeConstants";
import { useQueryClient } from "react-query";

const AddChapter = () => {
  const queryClient = useQueryClient();
  const [isModal, setIsModal] = useState(false);
  const createMutation = useCreateChapter();
  const navigate = useNavigate();
  const [chapter, setChapter] = useState();

  const handleFormSubmit = (formData) => {
    createMutation.mutate(formData, {
      onError: (res) => {
        toast.error(res?.response?.data?.message);
      },
      onSuccess: (res) => {
        queryClient.invalidateQueries("chapters");
        setChapter(res?.data?.data);
        setIsModal(true);
      },
    });
  };
  return (
    <>
      <SuccessModal
        onCancel={() => {
          setIsModal(false);
          navigate(`/chapters/${createMutation?.data?.data?.data?.id}/show`, {
            state: { id: createMutation?.data?.data?.data?.id },
          });
        }}
        show={isModal}
        body="Chapter has been successfully created"
        button={
          <Button
            bgColor={theme.colors.NEBULA_BLUE}
            icon={Constant.Icons.add_circle_outline_white}
            text="Create New Lesson"
            onClick={() => {
              navigate(`/chapters/${chapter?.id}/lessons/create`, {
                state: { chapter: chapter, isFromChapter: true },
              });
            }}
          />
        }
      />

      <ChapterForm
        handleFormSubmit={handleFormSubmit}
        title="Create new chapter"
        isLoading={createMutation.isLoading}
      />
    </>
  );
};

export default AddChapter;
