import styled from "styled-components";
import { Constant } from "../../../lib/Constant";
import { pxtoRem } from "../../../lib/helper";

const StyledBox = styled.div`
  width: ${pxtoRem(60)};
  height: ${pxtoRem(55)};
  // background-color: #fc5c56cb;
  background-color: ${(props) => props.theme.colors.PINK};
  align-items: center;
  display: inline-flex;
  justify-content: center;
  margin-left: ${pxtoRem(20)};
  cursor: pointer;
`;

const DeleteButton = () => {
  return (
    <StyledBox>
      <img height={20} alt="delete" src={Constant.Icons.delete_bg_white} />
    </StyledBox>
  );
};

export default DeleteButton;
