import React from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { pxtoRem } from "../../lib/helper";

const StyledContainer = styled.div``;

const InputTimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: inherit;
`;

const HourInput = styled(Form.Control)`
  border: 1px solid ${(props) => props.theme.colors.LIGHT_GREY};
  border-radius: ${pxtoRem(10)};
  color: ${(props) => props.theme.colors.MEDIUM_GREY};
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  text-align: center;
  font-size: ${pxtoRem(20)};

  &:focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    box-shadow: none;
    border: 1px solid ${(props) => props.theme.colors.LIGHT_GREY};
    color: ${(props) => props.theme.colors.MEDIUM_GREY};
  }

  &:disabled {
    background-color: ${(props) => props.theme.colors.GHOST_WHITE};
    border-color: ${(props) => props.theme.colors.BORDER_GREY};
  }
`;

const MinInput = styled(Form.Control)`
  border: 1px solid ${(props) => props.theme.colors.LIGHT_GREY};
  border-radius: ${pxtoRem(10)};
  color: ${(props) => props.theme.colors.MEDIUM_GREY};
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  text-align: center;
  border-left-style: none;
  font-size: ${pxtoRem(20)};

  &:focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    box-shadow: none;
    border: 1px solid ${(props) => props.theme.colors.LIGHT_GREY};
    border-left-style: none;
    color: ${(props) => props.theme.colors.MEDIUM_GREY};
  }
  &:disabled {
    background-color: ${(props) => props.theme.colors.GHOST_WHITE};
    border-color: ${(props) => props.theme.colors.BORDER_GREY};
  }
`;

const StyledError = styled(Form.Control.Feedback)`
  display: block;
  color: ${(props) => props.theme.colors.SHADE_OF_PINK_RED};
`;

function InputTime({ register, errMessage, disabled = false }) {
  return (
    <StyledContainer>
      <InputTimeContainer>
        <HourInput
          placeholder="Hours"
          name="duration_hours"
          {...register("duration_hours", {
            pattern: {
              value: /^(?:[01][0-9]|2[0-3])$/,
              message: "Please add Time in 24 Hours Format",
            },
            required: { value: true, message: "Please add total duration" },
          })}
          disabled={disabled}
        ></HourInput>
        <MinInput
          {...register("duration_minutes", {
            pattern: {
              value: /^[0-5][0-9]$/,
              message: "Please add Time in 24 Hours Format",
            },
            required: { value: true, message: "Please add total duration" },
          })}
          placeholder="Min"
          name="duration_minutes"
          disabled={disabled}
        ></MinInput>
      </InputTimeContainer>
      {errMessage && <StyledError>{errMessage}</StyledError>}
    </StyledContainer>
  );
}

export default InputTime;
