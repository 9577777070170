import React from "react";
import { Col, Row } from "react-bootstrap";
import { useFieldArray } from "react-hook-form";
import styled from "styled-components";
import { Constant } from "../../lib/Constant";
import GlossaryItem from "./GlossaryItem";
import { pxtoRem } from "../../lib/helper";

const StyledContainer = styled.div`
  border: 1px solid ${(props) => props.theme.colors.DARK_GREY};
  border-radius: 10px;
  padding: ${pxtoRem(10)};
  max-height: ${(props) =>
    props.isFormComponent ? pxtoRem(255) : pxtoRem(347)};
  overflow-y: scroll;
  overflow-x: hidden;
  /* width */
  ::-webkit-scrollbar {
    width: ${pxtoRem(20)};
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    border: ${pxtoRem(6)} solid ${(props) => props.theme.colors.WHITE};
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: ${(props) => props.theme.colors.DARK_GREY};
  }
`;

const StyledLable = styled.div`
  height: ${(props) => (props.isFormComponent ? "52px" : "68px")};
  background-color: ${(props) =>
    props.theme.colors.MEDIUM_SLATE_BLUE} !important;
  width: 100%;
  margin-bottom: ${pxtoRem(10)};
  border-radius: 4px;
  font-size: ${pxtoRem(20)};
  color: ${(props) => props.theme.colors.WHITE};
  /* font-family: Segoe UI; */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledGlossaryHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${pxtoRem(5)};
`;

const StyledHeaderLabel = styled.div`
  font-size: ${pxtoRem(24)};
  color: ${(props) => props.theme.NOTIFICATIONLIST.DARK_BLACK};
`;

const StyledAddButton = styled.img`
  cursor: pointer;
  height: ${pxtoRem(40)};
  width: ${pxtoRem(40)};
  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 0.25rem
      rgba(${(props) => props.theme.colors.BOX_SHADOW_BG}, 0.5);
  }
`;
function Glossary({
  isFormComponent = true,
  control,
  register,
  name,
  readOnly = false,
  errors,
  showHeading = false,
}) {
  const { append, fields } = useFieldArray({
    control,
    name: name,
  });

  return (
    <>
      {(isFormComponent || showHeading) && (
        <StyledGlossaryHeader>
          <StyledHeaderLabel>Glossary</StyledHeaderLabel>
          {!readOnly && (
            <StyledAddButton
              onClick={() => {
                append();
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  append();
                }
              }}
              tabIndex={0}
              src={Constant.Icons.add_circle_outline}
              width="35"
              height="35"
              alt="add gloassary"
            />
          )}
        </StyledGlossaryHeader>
      )}
      <StyledContainer isFormComponent={isFormComponent}>
        <Row>
          <Col md={2}>
            <StyledLable isFormComponent={isFormComponent}>Word</StyledLable>
          </Col>
          <Col>
            <StyledLable isFormComponent={isFormComponent}>
              Description
            </StyledLable>
          </Col>
          <Col>
            <StyledLable isFormComponent={isFormComponent}>
              Examples
            </StyledLable>
          </Col>
        </Row>

        {fields.map((field, index) => (
          <GlossaryItem
            disabled={readOnly}
            name={name}
            register={register}
            field={field}
            key={index}
            index={index}
            isFormComponent={isFormComponent}
            errors={errors}
          />
        ))}
      </StyledContainer>
    </>
  );
}

export default Glossary;
