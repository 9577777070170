import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { pxtoRem } from "../../../lib/helper";

const ContainerRow = styled(Row)`
  margin-top: ${pxtoRem(60)};
`;

const ContainerCol = styled(Col)``;

const StyledText = styled.div`
  font-size: ${pxtoRem(30)};
  color: ${(props) => props.theme.colors.NAVY_BLUE};
  font-weight: 600;
  text-align: left;
  margin-left: ${pxtoRem(70)};
`;

const StyledBox = styled.div`
  margin-top: ${pxtoRem(40)};
  width: 100%;
  height: ${pxtoRem(770)};
  max-height: ${pxtoRem(770)};
  overflow: auto;
  background-color: ${(props) => props.theme.colors.SHADE_CYAN_BLUE};
  padding: ${pxtoRem(60)} ${pxtoRem(40)};
  text-align: left;
  border-radius: 4px;
  img {
    width: 100%;
  }
`;

const Solution = ({ leftContnet, rightContent }) => {
  return (
    <ContainerRow>
      <ContainerCol>
        {leftContnet && (
          <>
            <StyledText>Solution:</StyledText>{" "}
            <StyledBox dangerouslySetInnerHTML={{ __html: leftContnet }} />
          </>
        )}
      </ContainerCol>
      <ContainerCol md={{ offset: 1 }}>
        {rightContent && (
          <>
            <StyledText>Theory:</StyledText>
            <StyledBox dangerouslySetInnerHTML={{ __html: rightContent }} />
          </>
        )}
      </ContainerCol>
    </ContainerRow>
  );
};

export default Solution;
