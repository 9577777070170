import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { pxtoRem } from "../../lib/helper";
import { useNavigate } from "react-router";

const StyledWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.SHADE_ALICE_BLUE};
  padding: ${pxtoRem(60)} ${pxtoRem(120)};
  height: 100vh;
`;

const StyledHeaderRow = styled(Row)`
  margin-bottom: ${pxtoRem(40)};
  align-items: center;
  display: flex;
`;

const StyleButtonCol = styled(Col)`
  display: flex;
  flex-direction: row;
  text-align: end;
  justify-content: flex-end;
`;

const CommonBox = styled.div`
  background-color: ${(props) => props.theme.colors.DARK_CYAN};
  color: ${(props) => props.theme.colors.WHITE};
  font-size: ${pxtoRem(20)};
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  text-align: center;
`;

const StyledButtonBox = styled(CommonBox)`
  height: ${pxtoRem(61)};
  width: ${pxtoRem(155)};
  border-radius: ${(props) => props.radius};
`;

const StyledBanner = styled.div`
  position: relative;
  right: ${pxtoRem(120)};
  height: ${pxtoRem(228)};
  width: ${pxtoRem(800)};
`;

const StyledBannerHeading = styled.div`
  background-color: #231f20;
  height: ${pxtoRem(94)};
  width: ${pxtoRem(800)};
  border-radius: 0px ${pxtoRem(30)} 0px 0px;
`;

const StyledBannerTitle = styled.div`
  background-color: ${(props) => props.theme.colors.DARK_CYAN};
  height: ${pxtoRem(134)};
  width: ${pxtoRem(800)};
  border-radius: 0px 0px ${pxtoRem(30)} 0px;
`;
const StyledBannerContent = styled.div`
  position: relative;
  left: ${pxtoRem(51)};
  color: white;
  font-size: ${(props) => pxtoRem(props.size)};
  font-weight: bolder;
`;

const StyledContentRow = styled(Row)`
  margin-top: -45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
`;

const StyledContentHeading = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: ${pxtoRem(50)};
  margin-left: 30px;
`;

const StyledContent = styled.div`
  width: ${pxtoRem(1076)};
  height: ${pxtoRem(508)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.WHITE};
`;

const StyledInnerHeading = styled.p`
  font-size: ${pxtoRem(30)};
  font-weight: 700;
`;

const StyledFooterRow = styled(Row)`
  margin-bottom: ${pxtoRem(60)};
  position: fixed;
  bottom: 0;
  right: ${pxtoRem(120)};
  display: flex;
  align-items: center;
`;

const StyledUl = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledLi = styled.li`
  width: ${pxtoRem(867)};
  height: ${30};
  font-size: ${pxtoRem(20)};
  color: #666666;
  &::marker {
    color: ${(props) => props.theme.colors.DARK_CYAN};
    font-size: ${pxtoRem(40)};
    margin-right: 12px;
  }
`;

const InitalView = ({ setStart }) => {
  const navigate = useNavigate();
  return (
    <StyledWrapper>
      <StyledHeaderRow>
        <Col></Col>
        <StyleButtonCol xs={2}>
          <StyledButtonBox
            radius={"20px"}
            onClick={() => {
              navigate(-1);
            }}
          >
            Close
          </StyledButtonBox>
        </StyleButtonCol>
      </StyledHeaderRow>

      <StyledBanner>
        <StyledBannerHeading>
          <StyledBannerContent size={60}>Mathematics</StyledBannerContent>
        </StyledBannerHeading>
        <StyledBannerTitle>
          <StyledBannerContent size={80}>Key stage 2</StyledBannerContent>
        </StyledBannerTitle>
      </StyledBanner>

      <StyledContentRow>
        <StyledContentHeading>Integers and fraction</StyledContentHeading>
        <StyledContent>
          <StyledInnerHeading>
            Read all the instruction before start the chapter paper test
          </StyledInnerHeading>
          <StyledUl>
            <StyledLi>
              Tortor posuere ac ut consequat semper. Cursus vitae congue mauris
              rhoncus. Leo a
            </StyledLi>
            <StyledLi>
              Tortor posuere ac ut consequat semper. Cursus vitae congue mauris
              rhoncus. Leo a
            </StyledLi>

            <StyledLi>
              Tortor posuere ac ut consequat semper. Cursus vitae congue mauris
              rhoncus. Leo a
            </StyledLi>

            <StyledLi>
              Tortor posuere ac ut consequat semper. Cursus vitae congue mauris
              rhoncus. Leo a
            </StyledLi>

            <StyledLi>
              Tortor posuere ac ut consequat semper. Cursus vitae congue mauris
              rhoncus. Leo a
            </StyledLi>
          </StyledUl>
        </StyledContent>
      </StyledContentRow>

      <StyledFooterRow>
        <StyleButtonCol>
          <StyledButtonBox radius={"6px"} onClick={() => setStart(true)}>
            Start
          </StyledButtonBox>
        </StyleButtonCol>
      </StyledFooterRow>
    </StyledWrapper>
  );
};

export default InitalView;
