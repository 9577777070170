import React, { useEffect } from "react";
import PageHeader from "../../components/shared/PageHeader";
import ContainerComponent from "../../components/shared/ContainerComponent";
import { Badge, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import Autocomplete from "../../components/shared/Autocomplete";
import {
  useCountries,
  useExamBoards,
  useSubjects,
  useYears,
} from "../../hooks/chapter";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "../../components/shared/Spinner";
import { setFilter } from "../../redux-toolkit/mockTest";
import { setPageChange } from "../../redux-toolkit/table";
import Table from "../../components/shared/Table";
import Button from "../../components/shared/Button";
import { Constant } from "../../lib/Constant";
import { theme } from "../../lib/themeConstants";
import { useNavigate } from "react-router-dom";
import useMockTest from "../../hooks/mockTest/useMockTest";
import { toast } from "react-toastify";
import { pxtoRem } from "../../lib/helper";
import Empty from "../../components/shared/Empty";
import Toggle from "../../components/shared/Toggle";

const StyledBadge = styled(Badge)`
  background-color: ${(props) => props.color} !important;
  width: ${pxtoRem(146)};
  height: ${pxtoRem(46)};
  padding-top: ${pxtoRem(13)};
  font-size: ${pxtoRem(20)};
  border-radius: ${pxtoRem(10)};
  font-weight: 100;
`;

const StyledButton = styled(Button)`
  height: ${pxtoRem(46)};
  width: ${pxtoRem(67)};
  font-size: ${pxtoRem(20)};
  /* line-height: ${pxtoRem(18)}; */
  padding: 0;
`;

const Mocktest = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const currentPage = useSelector((state) => {
    return state.table.page;
  });

  const { country, examboard, subject, grade, chapter } = useSelector(
    (state) => state.mockTest?.filter
  );

  const {
    data: mockTests,
    isFetching,
    error,
  } = useMockTest(
    country?.value,
    examboard?.value,
    subject?.value,
    grade?.value,
    chapter?.value,
    currentPage
  );

  const { data: countriesData, isFetching: countriesLoading } = useCountries();
  const { data: examboardData, isFetching: examLoading } = useExamBoards();
  const { data: subjectsData, isFetching: subjectsLoading } = useSubjects();
  const { data: gradesData, isFetching: gradesLoading } = useYears();

  useEffect(() => {
    if (error) {
      toast.error(error?.response?.data?.message);
    }
    return () => {
      dispatch(setPageChange(1));
    };
  }, [dispatch, error]);

  const formatFilterOptions = (data) => {
    return data?.map((item) => ({ value: item.id, label: item.name }));
  };

  const onFilterChange = (key, value) => {
    let data = { country, examboard, subject, grade, chapter };
    data[key] = value;
    dispatch(setFilter(data));
  };

  const handleStatusChange = (key, value) => {};

  const columns = React.useMemo(
    () => [
      {
        Header: "Subject",
        accessor: "subject",
        Cell: ({ row }) => (
          <StyledBadge
            color={
              row?.original?.subject?.color && row?.original?.subject?.color
            }
            text="light"
          >
            {row?.original?.mock_test?.subject?.name &&
              row.original?.mock_test.subject.name}
          </StyledBadge>
        ),
      },
      {
        Header: "Exam board",
        accessor: "exam_board",
        Cell: ({ row }) => (
          <span>{row?.original?.mock_test.exam_board?.name}</span>
        ),
      },
      {
        Header: "Paper type",
        accessor: "paper_type",
        Cell: ({ row }) => <span>{row?.original?.test_type}</span>,
      },

      {
        Header: "Grade",
        accessor: "grade",
        Cell: ({ row }) => <span>{row?.original?.mock_test?.grade?.name}</span>,
      },
      {
        Header: "Action",
        Cell: ({ row }) => (
          <StyledButton
            text="View"
            onClick={() => navigate(`view/${row?.original?.id}`)}
            bgColor={theme.colors.NEBULA_BLUE}
          />
        ),
      },
      {
        Header: "",
        accessor: "status",
        customWidth: 17,
        Cell: ({ row }) => (
          <Toggle
            checked={
              row.original?.mock_test?.status === "publish" ? true : false
            }
            onChange={(value) => {
              handleStatusChange(row.original.id, value);
            }}
            onLable={"Publish"}
            offLable={"Draft"}
          />
        ),
      },
    ],
    []
  );

  return (
    <>
      <Spinner
        isLoading={
          isFetching ||
          countriesLoading ||
          examLoading ||
          subjectsLoading ||
          gradesLoading
        }
      />
      <PageHeader
        title={"Mock Test"}
        control={
          <Button
            text="Create"
            icon={Constant.Icons.add_circle_outline_white}
            bgColor={theme.colors.NEBULA_BLUE}
            onClick={() => {
              navigate("create");
            }}
          />
        }
      ></PageHeader>
      <ContainerComponent>
        <Row>
          <Col>
            <Autocomplete
              options={formatFilterOptions(countriesData)}
              value={country}
              onChange={(data) => {
                // setSubject(data);
                onFilterChange("country", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Country"
              className="m-2 auto-complete"
            />
          </Col>
          <Col>
            <Autocomplete
              options={formatFilterOptions(subjectsData)}
              value={subject}
              onChange={(data) => {
                // setSubject(data);
                onFilterChange("subject", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Subject"
              className="m-2 auto-complete"
            />
          </Col>

          <Col>
            <Autocomplete
              options={formatFilterOptions(examboardData)}
              value={examboard}
              onChange={(data) => {
                // setSubject(data);
                onFilterChange("examboard", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Examboard"
              className="m-2 auto-complete"
            />
          </Col>
          <Col>
            <Autocomplete
              options={formatFilterOptions(gradesData)}
              value={grade}
              onChange={(data) => {
                // setSubject(data);
                onFilterChange("grade", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Grade"
              className="m-2 auto-complete"
            />
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col>
            {mockTests?.data?.length > 0 ? (
              <Table
                data={mockTests?.data}
                columns={columns}
                defaultPageSize={mockTests?.per_page}
                totalPages={mockTests?.last_page}
                currentPage={mockTests?.current_page}
              ></Table>
            ) : (
              !isFetching && (
                <Empty text={Constant.tableErrorMessages.chapters} />
              )
            )}
          </Col>
        </Row>
      </ContainerComponent>
    </>
  );
};

export default Mocktest;
