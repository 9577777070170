import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import Autocomplete from "../../components/shared/Autocomplete";
import Button from "../../components/shared/Button";
import ContainerComponent from "../../components/shared/ContainerComponent";
import Empty from "../../components/shared/Empty";
import PageHeader from "../../components/shared/PageHeader";
import { Spinner } from "../../components/shared/Spinner";
import Table from "../../components/shared/Table";
import Toggle from "../../components/shared/Toggle";
import {
  useCountries,
  useExamBoards,
  useSubjects,
  useYears,
} from "../../hooks/chapter";
import { useSelfTestLessons, useStatusChange } from "../../hooks/selftest";
import { Constant } from "../../lib/Constant";
import { pxtoRem } from "../../lib/helper";
import { theme } from "../../lib/themeConstants";
import { setFilter, setFormData } from "../../redux-toolkit/selftest";
import { setPageChange } from "../../redux-toolkit/table";
import { resetQuestions } from "../../redux-toolkit/questions";
import { useChapterFilter } from "../../hooks/filters";

const StyledButton = styled(Button)`
  padding: ${pxtoRem(4)} ${pxtoRem(8)};
  &:disabled {
    background-color: ${(props) => props.theme.colors.BRIGHT_GREY};
    border-color: ${(props) => props.theme.colors.BRIGHT_GREY};
    color: ${(props) => props.theme.colors.METALIC_SILVER};
  }
`;

function Selftest() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const statusMutation = useStatusChange();

  const currentPage = useSelector((state) => {
    return state.table.page;
  });

  const { country, examboard, subject, grade, chapter } = useSelector(
    (state) => {
      return state.selftest.filter;
    }
  );

  const { data: countriesData, isFetching: countriesLoading } = useCountries();
  const { data: examboardData, isFetching: examLoading } = useExamBoards();
  const { data: subjectsData, isFetching: subjectsLoading } = useSubjects();
  const { data: gradesData, isFetching: gradesLoading } = useYears();
  const { data: chaptersData, isFetching: chapterLoading } = useChapterFilter();

  const {
    data: selftests,
    error,
    isFetching,
  } = useSelfTestLessons(
    country?.value,
    examboard?.value,
    subject?.value,
    grade?.value,
    chapter?.value,
    currentPage
  );

  useEffect(() => {
    dispatch(resetQuestions());
    dispatch(setFormData(null));
    //remove add form data from redux
    if (error) {
      toast.error(error?.response?.data?.message);
    }
    return () => {
      dispatch(setPageChange(1));
    };
  }, [dispatch, error]);

  const formatFilterOptions = (data) => {
    let formattedData = data?.map((item) => {
      return { value: item.id, label: item.name };
    });
    return formattedData;
  };

  const onFilterChange = (key, value) => {
    let data = { country, examboard, subject, grade, chapter };
    data[key] = value;
    dispatch(setFilter(data));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Number",
        accessor: "number",
        customWidth: "2",
        Cell: ({ row }) => <span>{row?.original?.number}</span>,
      },
      {
        Header: "Lesson name",
        accessor: "name",
        customWidth: "40",
        Cell: ({ row }) => <span>{row?.original?.name}</span>,
      },
      {
        Header: "View",
        Cell: ({ row }) => {
          const isDisabled = row?.original?.self_test_count > 0 ? false : true;
          return (
            <StyledButton
              disabled={isDisabled}
              text="View"
              color={theme.colors.WHITE}
              onClick={() => {
                navigate(`view/${row?.original?.id}`);
              }}
              bgColor={theme.colors.NEBULA_BLUE}
            />
          );
        },
      },
      {
        Header: "Self test",
        Cell: ({ row }) => (
          <StyledButton
            text="Create"
            onClick={() => {
              navigate(`create/${row?.original?.id}`, {
                state: { self_test_count: row?.original?.self_test_count },
              });
            }}
            bgColor={
              row?.original?.self_test_count > 0
                ? theme.colors.VIVID_MALACHITE
                : theme.colors.NEBULA_BLUE
            }
          />
        ),
      },
      {
        Header: "",
        accessor: "status",
        customWidth: "20",
        Cell: ({ row }) => (
          <Toggle
            disabled={row?.original?.self_test_count > 0 ? false : true}
            checked={
              row?.original?.self_test?.status === "publish" ? true : false
            }
            onChange={(status) => {
              const formData = new FormData();
              formData.set("status", status ? "publish" : "draft");
              formData.set("id", row?.original?.id);
              statusMutation.mutate(formData, {
                onError: (res) => {
                  toast.error(res?.response?.data?.message);
                },
                onSuccess: (res) => {
                  toast.success("Status changed successfuly");
                  queryClient.invalidateQueries("selfTestLessons", {
                    country_id: country?.value,
                    exam_board_id: examboard?.value,
                    subject_id: subject?.value,
                    grade_id: grade?.value,
                    chapter_id: chapter?.value,
                    page: currentPage,
                  });
                },
              });
            }}
            offLable={"Draft"}
            onLable={"Publish"}
          ></Toggle>
        ),
      },
    ],
    // eslint-disable-next-line
    []
  );
  return (
    <>
      <Spinner
        isLoading={
          isFetching ||
          countriesLoading ||
          examLoading ||
          subjectsLoading ||
          gradesLoading ||
          chapterLoading ||
          statusMutation.isLoading
        }
      />
      <PageHeader title="Selftest"></PageHeader>
      <ContainerComponent>
        <Row>
          <Col>
            <Autocomplete
              options={formatFilterOptions(countriesData)}
              value={country}
              onChange={(data) => {
                onFilterChange("country", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Country"
              className="m-2 auto-complete"
            />
          </Col>
          <Col>
            <Autocomplete
              options={formatFilterOptions(examboardData)}
              value={examboard}
              onChange={(data) => {
                onFilterChange("examboard", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Examboard"
              className="m-2 auto-complete"
            />
          </Col>
          <Col>
            <Autocomplete
              options={formatFilterOptions(subjectsData)}
              value={subject}
              onChange={(data) => {
                onFilterChange("subject", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Subject"
              className="m-2 auto-complete"
            />
          </Col>
          <Col>
            <Autocomplete
              options={formatFilterOptions(gradesData)}
              value={grade}
              onChange={(data) => {
                onFilterChange("grade", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Grade"
              className="m-2 auto-complete"
            />
          </Col>
          <Col>
            <Autocomplete
              options={formatFilterOptions(chaptersData)}
              value={chapter}
              onChange={(data) => {
                onFilterChange("chapter", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Chapter"
              className="m-2 auto-complete"
            />
          </Col>
        </Row>
        <Row>
          <Col className="mt-3">
            {selftests?.data?.length > 0 ? (
              <Table
                data={selftests?.data}
                columns={columns}
                defaultPageSize={selftests?.per_page}
                totalPages={selftests?.last_page}
                currentPage={selftests?.current_page}
              ></Table>
            ) : (
              !isFetching && (
                <Empty text={Constant.tableErrorMessages.selftest} />
              )
            )}
          </Col>
        </Row>
      </ContainerComponent>
    </>
  );
}

export default Selftest;
