import React, { useState } from "react";
import { Button, Col, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import Navbar from "../components/shared/Navbar";
import Checkbox from "../components/shared/Checkbox";
import Toggle from "../components/shared/Toggle";
import { BreadcrumbsData, countries, userProfile } from "../lib/dummyData";
import Autocomplete from "../components/shared/Autocomplete";
import Dropdown from "../components/shared/Dropdown";
import Breadcrumb from "../components/shared/Breadcrumb";
import ColorPicker from "../components/shared/ColorPicker";
import InputTime from "../components/shared/InputTime";
import { FormProvider, useForm } from "react-hook-form";
import Glossary from "../components/shared/Glossary";
import Accordion from "../components/shared/Accordion";
import QuestionsNumberList from "../components/shared/QuestionsNumberList";
import Editor from "../components/shared/Editor";
import QuestionContainer from "../components/shared/question/QuestionContainer";
import AddWorksheet from "./worksheet/AddWorksheet";
import { useEffect } from "react";

function Nisha() {
  const [checked, setChecked] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(null);
  const [autoComplete, setAutoComplete] = useState(null);
  const [description, setDescription] = useState(null);
  const [sdescription, setSDescription] = useState(null);
  const [editor, getEditor] = useState(null);
  const [editorState, setEditorState] = useState(null);
  // const {
  //   register,
  //   handleSubmit,
  //   getValues,
  //   setValue,
  //   formState: { errors },
  // } = useForm({ defaultValues: { color: "#FFFFFF" } });

  const formPages = 2;
  const [formStep, setFormStep] = useState(1);
  const [pageJump, setPageJump] = useState(1);
  const [newStep, setNewStep] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitted, isDirty, isValid },
    watch,
    reset,
    setValue,
    getValues,
  } = useForm({ mode: "onChange" });

  const onSubmit = async (data) => {
    if (formStep < formPages) {
      const thisStep = formStep + pageJump;
      setFormStep(thisStep);
      setNewStep(true);
      return false;
    }
    return false;
  };

  function backPage(e, val) {
    const thisStep = formStep - val;
    setPageJump(val);
    setFormStep(thisStep);
    setNewStep(true);
  }

  useEffect(() => {
    if (newStep) {
      setNewStep(false);
      reset(
        {},
        {
          keepValues: true,
          keepDirty: true,
        }
      );
    }
  }, [isSubmitted, reset, newStep]);

  const methods = {
    control,
    register,
    errors,
    handleSubmit,
    watch,
    backPage,
    getValues,
    setValue,
    isDirty,
    isValid,
  };

  // const onSubmit = (data) => console.log(data);
  const createGap = () => {
    editor.model.change((writer) => {
      const range = editor.model.document.selection.getFirstRange();
      for (const item of range.getItems()) {
        let text = writer.createText(item.data, {
          fontBackgroundColor: "#CCCDFA",
        });
        editor.model.insertContent(text, range);
      }
    });
  };

  const removeGap = () => {
    editor.model.change((writer) => {
      const range = editor.model.document.selection.getFirstRange();
      for (const item of range.getItems()) {
        let text = writer.createText(item.data);
        editor.model.insertContent(text, range);
      }
    });
  };

  return (
    <ListGroup>
      <ListGroupItem>
        <h2>1. Navbar</h2>
        <Navbar data={userProfile} />
      </ListGroupItem>
      <ListGroupItem className="h-100">
        <h2>2. Checkbox</h2>
        <div className="checkbox-container">
          <Checkbox checked={checked} onChange={setChecked}></Checkbox>
        </div>
      </ListGroupItem>
      <ListGroupItem>
        <h3>3.Switch</h3>
        <div style={{ height: "30px" }}>
          <Toggle
            checked={switchChecked}
            onChange={setSwitchChecked}
            offLable={"Draft"}
            onLable={"Publish"}
          ></Toggle>
        </div>
      </ListGroupItem>
      <ListGroupItem>
        <h3>4. Autocomplete</h3>
        <Autocomplete
          options={countries}
          value={autoComplete}
          onChange={setAutoComplete}
          placeholder="Country"
        />
      </ListGroupItem>
      <ListGroupItem>
        <h2>5. Dropdown</h2>
        <Dropdown
          options={countries}
          value={dropdownValue}
          onChange={setDropdownValue}
          placeholder="Select country"
        />
      </ListGroupItem>
      <ListGroupItem>
        <h2>6. Breadcrumb</h2>
        <Breadcrumb data={BreadcrumbsData} />
      </ListGroupItem>
      <ListGroupItem>
        <h2>7. ColorPicker</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ColorPicker
            color={getValues("color")}
            onChange={(data) => {
              setValue("color", data, { shouldValidate: true });
            }}
            validation={{ pattern: /^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/ }}
            id="color"
            register={register}
            colorError={errors.color}
          />

          <input type="submit" />
        </form>
      </ListGroupItem>
      <ListGroupItem>
        <h2>8. InputTime </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputTime
            register={register}
            hoursError={errors.hours}
            minError={errors.min}
          ></InputTime>
          <input type="submit" />
        </form>
      </ListGroupItem>
      <ListGroupItem>
        <h2>9. Glossary</h2>
        {/* <Glossary numberOfDefaultItems={2} /> */}
      </ListGroupItem>
      <ListGroupItem>
        <h2>10. Accordion</h2>
        <Accordion eventKey="0" heading="Add Solution">
          Some Accordion body content
        </Accordion>
      </ListGroupItem>
      <ListGroupItem>
        <h2>11. QuestionsNumberList</h2>
        <QuestionsNumberList></QuestionsNumberList>
      </ListGroupItem>
      <ListGroupItem>
        <div style={{ height: "180px" }}>
          <Editor
            editorState={description}
            onEditorStateChange={setDescription}
            id="toolbar"
          ></Editor>
        </div>
      </ListGroupItem>
      <ListGroupItem>
        <div style={{ height: "180px" }}>
          <Editor
            editorState={sdescription}
            onEditorStateChange={setSDescription}
            id="toolbar1"
          ></Editor>
        </div>
      </ListGroupItem>
      <ListGroupItem>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <QuestionContainer />
          </form>
        </FormProvider>
      </ListGroupItem>
      <ListGroupItem>
        <h2>12. CK editor</h2>
        <Row>
          <Col>
            <Editor
              getEditor={getEditor}
              editorState={editorState}
              onEditorStateChange={setEditorState}
            />
            <Button
              className="mt-2"
              style={{ marginRight: 10 }}
              onClick={createGap}
            >
              {" "}
              create gap
            </Button>
            <Button className="mt-2 ml-2" onClick={removeGap}>
              Remove gap
            </Button>
          </Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
  );
}

export default Nisha;
