// import React, { useEffect, useState, forwardRef } from "react";
// import ReactQuill, { Quill } from "react-quill";
// import styled from "styled-components";
// import { pxtoRem } from "../../lib/helper";
// import EditorToolbar, { formats, modules } from "./EditorToolbar";
// import { Form } from "react-bootstrap";
// var Image = Quill.import("formats/image");
// Image.className = "quill-image";
// Quill.register(Image, true);

// const QuillError = styled(Form.Control.Feedback)`
//   display: block;
//   color: ${(props) => props.theme.colors.SHADE_OF_PINK_RED};
// `;

// const StyledContainer = styled.div`
//   border: 1px solid ${(props) => props.theme.colors.LIGHT_GREY};
//   border-radius: ${pxtoRem(10)};
//   border-top-right-radius: ${(props) => props.issortNotes && 0};
//   border-top-left-radius: ${(props) => props.issortNotes && 0};
//   border-top: ${(props) => props.issortNotes && "none"};
// `;

// const StyledQuillEditor = styled(ReactQuill)`
//   height: ${pxtoRem(150)};
//   background-color: #ffffff;
//   border-radius: ${pxtoRem(10)};
//   border-top-left-radius: 0;
//   border-top-right-radius: 0;
// `;

// const Editor = forwardRef(
//   (
//     {
//       editorState,
//       onEditorStateChange,
//       defaultValue,
//       onBlur,
//       autoFocus,
//       readOnly,
//       onFocus,
//       issortNotes = false,
//       id = "toolbar",
//       errMessage,
//       ...rest
//     },
//     ref
//   ) => {
//     const [error, setError] = useState("");
//     const [isImageSelectorOpen, setIsImageSelectorOpen] = useState(false);
//     let mod = modules(id);
//     useEffect(() => {
//       if (autoFocus) {
//         ref.current.focus();
//       }
//     }, [ref, autoFocus]);

//     useEffect(() => {
//       if (errMessage) {
//         setError(errMessage);
//       } else {
//         setError("");
//       }
//     }, [ref, errMessage]);

//     const onEditorBlur = (event, source, quill) => {
//       if (
//         event.relatedTarget === null &&
//         event.target.nodeName !== "INPUT" &&
//         !isImageSelectorOpen
//       ) {
//         if (onBlur) {
//           onBlur();
//         }
//       }
//     };

//     return (
//       <div>
//         <StyledContainer onBlur={onEditorBlur} issortNotes={issortNotes}>
//           <EditorToolbar id={id} />
//           <StyledQuillEditor
//             {...rest}
//             id={id}
//             theme={"snow"}
//             value={editorState}
//             defaultValue={defaultValue}
//             onChange={(data) => {
//               onEditorStateChange(data);
//               setError("");
//               setIsImageSelectorOpen(false);
//             }}
//             autoFocus={autoFocus}
//             modules={mod}
//             formats={formats}
//             ref={ref}
//             readOnly={readOnly}
//             onFocus={onFocus}
//             bounds={".quill"}
//           ></StyledQuillEditor>
//         </StyledContainer>
//         <QuillError id="quillError">{error}</QuillError>
//       </div>
//     );
//   }
// );

// export default Editor;

import React, { forwardRef } from "react";
import styled from "styled-components";
import { pxtoRem } from "../../lib/helper";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { default as CustomEditor } from "ckeditor5-custom-build/build/ckeditor";
import { Form, Row } from "react-bootstrap";

const Error = styled(Form.Control.Feedback)`
  display: block;
  color: ${(props) => props.theme.colors.SHADE_OF_PINK_RED};
`;

const StyledContainer = styled.div`
  border: 1px solid ${(props) => props.theme.colors.LIGHT_GREY};
  border-radius: ${pxtoRem(10)};
  border-top-right-radius: ${(props) =>
    (props.issortNotes || !props.istopradius) && 0};
  border-top-left-radius: ${(props) =>
    (props.issortNotes || !props.istopradius) && 0};
  border-top: ${(props) => props.issortNotes && "none"};
  height: 100%;

  .ck.ck-toolbar:not(
      .ck-dropdown__panel-visible .ck.ck-toolbar,
      .ck-balloon-rotator__content .ck.ck-toolbar
    ) {
    border-radius: ${(props) => props.issortNotes && 0} !important;
  }
`;

const StyledRow = styled(Row)`
  height: ${pxtoRem(276)} !important;
`;

const Editor = forwardRef(
  (
    {
      editorState,
      onEditorStateChange,
      onBlur,
      autoFocus,
      readOnly,
      onFocus,
      issortNotes = false,
      id = "toolbar",
      errMessage,
      getEditor,
      isTopRadius = true,
      ...rest
    },
    ref
  ) => {
    return (
      <StyledRow>
        <div>
          <StyledContainer istopradius={isTopRadius} issortNotes={issortNotes}>
            <CKEditor
              {...rest}
              key={id}
              editor={CustomEditor}
              data={editorState ?? ""}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                getEditor && getEditor(editor);
              }}
              config={{
                toolbar: [
                  "bold",
                  "|",
                  "italic",
                  "|",
                  "underline",
                  "|",
                  "fontColor",
                  "|",
                  {
                    label: "basic styles",
                    icon: "text",
                    items: ["fontfamily", "fontsize", "fontBackgroundColor"],
                  },
                  "|",
                  "alignment:left",
                  "|",
                  "alignment:center",
                  "|",
                  "alignment:right",
                  "|",
                  "alignment:justify",
                  "|",
                  "bulletedList",
                  "|",
                  "numberedList",
                  "|",
                  "subscript",
                  "|",
                  "superscript",
                  "|",
                  "cut",
                  "|",
                  "insertTable",
                  "|",
                  "link",
                  "|",
                  "imageUpload",
                ],
                removePlugins: ["ImageToolbar"],
              }}
              onChange={(event, editor) => {
                const data = editor?.getData();
                onEditorStateChange(data);
              }}
              onBlur={onBlur}
              ref={ref}
            />
          </StyledContainer>
        </div>
        {errMessage && <Error id="quillError">{errMessage}</Error>}
      </StyledRow>
    );
  }
);
export default Editor;
