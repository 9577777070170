import { useMutation } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useAddWorksheet = () => {
  return useMutation((newTest) => {
    return axiosPost("/api/cw/createWorkSheet", newTest);
  });
};

export default useAddWorksheet;
