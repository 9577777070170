import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { pxtoRem } from "../../../lib/helper";
import { Constant, Qtype } from "../../../lib/Constant";
import { type } from "@testing-library/user-event/dist/type";

const StyledQuestion = styled.div`
  text-align: left;

  img {
    width: 100%;
  }
`;

const CommonBox = styled.div`
  background-color: ${(props) => props.theme.colors.DARK_CYAN};
  color: ${(props) => props.theme.colors.WHITE};
  font-size: ${pxtoRem(20)};
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  text-align: center;
`;
const StyledCloseBox = styled(CommonBox)`
  height: ${pxtoRem(56)};
  width: ${pxtoRem(56)};
  border-radius: 10px;
`;

const StyledColRight = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
  min-height: ${(props) => !props.viewSolution && pxtoRem(300)};
`;
const StyledAnswerContainer = styled.div`
  text-align: left;
  display: flex;
  margin: ${pxtoRem(30)} ${pxtoRem(0)};
`;
const StyledMarks = styled.div`
  font-size: ${pxtoRem(24)};
`;

const StyledBulb = styled.img`
  width: ${pxtoRem(40)};
  cursor: pointer;
`;

const StyledAnswer = styled(StyledQuestion)`
  border-bottom: 2px solid black;
  width: ${pxtoRem(300)};
  padding-left: ${pxtoRem(10)};
`;

const ViewOneWord = ({
  viewSolution,
  setViewSolution,
  activeItem,
  activeIndex,
}) => {
  return (
    <Row>
      <Col>
        <strong isquestion={true}>{`Q.${activeIndex + 1}`}</strong>
      </Col>
      <Col xs={9}>
        <StyledQuestion
          dangerouslySetInnerHTML={{ __html: activeItem?.question }}
        />
        <StyledAnswerContainer>
          {viewSolution && (
            <>
              <div style={{ width: pxtoRem(150), fontWeight: "bold" }}>
                Answer =
              </div>
              <StyledAnswer
                dangerouslySetInnerHTML={{
                  __html: activeItem?.one_word_answer,
                }}
              ></StyledAnswer>
            </>
          )}
        </StyledAnswerContainer>
      </Col>
      <StyledColRight xs={2} viewSolution={viewSolution}>
        <StyledMarks>
          <Row style={{ display: "flex", alignItems: "center" }}>
            {`(${2} marks)`}
            <Col></Col>
            {viewSolution && (
              <StyledCloseBox onClick={() => setViewSolution(!viewSolution)}>
                <img
                  style={{
                    width: pxtoRem(23.76),
                    height: pxtoRem(26.27),
                  }}
                  src={Constant.Icons.close_white}
                  alt="close"
                />
              </StyledCloseBox>
            )}
          </Row>
        </StyledMarks>

        {!viewSolution && (
          <StyledBulb onClick={() => {}} src={Constant.Icons.bulb} alt="12" />
        )}
      </StyledColRight>
      <Row></Row>
    </Row>
  );
};

export default ViewOneWord;
