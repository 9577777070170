import { Pagination } from "react-bootstrap";
import BTable from "react-bootstrap/Table";
import { usePagination, useTable } from "react-table";
import styled from "styled-components";
import { pxtoRem } from "../../lib/helper";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPageChange } from "../../redux-toolkit/table/index";
import { theme } from "../../lib/themeConstants";

const StyledPagination = styled(Pagination)`
  justify-content: center;
  .page-item.disabled .page-link {
    border: 1px solid ${(props) => props.theme.colors.DARK_GREY};
    border-radius: 50%;
  }

  .page-item.active {
    .page-link {
      border: 1px solid ${(props) => props.theme.colors.PAGE_ACTIVE};
      background-color: ${(props) => props.theme.colors.PAGE_ACTIVE};
      height: ${pxtoRem(46)};
      width: ${pxtoRem(46)};
      font-size: ${pxtoRem(20)};
      line-height: ${pxtoRem(24)};
      border-radius: 50%;
      text-align: center;
      color: white;
    }
  }
  .page-link {
    border: 1px solid ${(props) => props.theme.colors.DARK_GREY};
    color: ${(props) => props.theme.colors.DARK_GREY};
    margin: ${pxtoRem(10)};
    height: ${pxtoRem(46)};
    width: ${pxtoRem(46)};
    font-size: ${pxtoRem(20)};
    line-height: ${pxtoRem(20)};
    border-radius: 50%;
    padding-left: ${pxtoRem(15)};
    text-align: center;
  }

  .page-item:last-child .page-link,
  .page-item:first-child .page-link {
    border-radius: 50%;
  }

  .page-item:not(:first-child) .page-link {
    margin-left: unset;
  }
`;

const StyledHeader = styled.thead`
  font-size: ${pxtoRem(20)};
  background-color: ${(props) => props.headerBgColor};
`;

const StyledBody = styled.tbody`
  font-size: ${pxtoRem(20)};
  font-weight: 600;
  tr {
    &:last-child {
      border-color: ${(props) => props.theme.colors.WHITE};
    }
  }
  background-color: ${(props) => props.BodyBgColor};
`;

const StyledRow = styled.tr`
  border-bottom: 2px solid ${(props) => props.theme.colors.BODY_BG};
`;

const StyledTable = styled(BTable)`
  color: ${(props) => props.theme.colors.GUNMENTAL};
  /* height: ${pxtoRem(200)}; */
  /* height:30vh; */
`;
const Table = ({
  columns,
  data,
  pagination = true,
  headerBgColor = null,
  BodyBgColor = null,
  defaultPageSize = 5,
  totalPages = 10,
  currentPage = 1,
}) => {
  const dispatch = useDispatch();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: totalPages,
      autoResetPageIndex: false,
      initialState: { pageIndex: 0, pageSize: defaultPageSize },
    },
    usePagination
  );

  useEffect(() => {
    dispatch(setPageChange(pageIndex + 1));
  }, [pageIndex, dispatch]);

  useEffect(() => {
    if (currentPage - 1 !== pageIndex) {
      gotoPage(currentPage - 1);
    }
    // eslint-disable-next-line
  }, [currentPage]);

  const PaginationList = () => {
    const pList =
      pageIndex + 4 > pageCount
        ? pageOptions.slice(-4)
        : pageOptions.slice(pageIndex, pageIndex + 4);

    return (
      <StyledPagination>
        <Pagination.First
          onClick={() => {
            gotoPage(0);
          }}
          disabled={!canPreviousPage}
        />
        <Pagination.Prev
          onClick={() => {
            previousPage();
          }}
          disabled={!canPreviousPage}
        />
        {pList?.map((item, index) => {
          return (
            <Pagination.Item
              onClick={() => {
                gotoPage(item);
              }}
              active={pageIndex === item}
              key={index}
            >
              {item + 1}
            </Pagination.Item>
          );
        })}

        <Pagination.Next
          onClick={() => {
            nextPage();
          }}
          disabled={!canNextPage}
        />
        <Pagination.Last
          onClick={() => {
            gotoPage(pageCount - 1);
          }}
          disabled={!canNextPage}
        />
      </StyledPagination>
    );
  };
  return (
    <>
      <StyledTable hover size="sm" {...getTableProps()}>
        <StyledHeader headerBgColor={headerBgColor}>
          {headerGroups.map((headerGroup) => (
            <StyledRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className={
                    column.headerClassName
                      ? `${column.headerClassName}`
                      : "text-center align-middle pb-3"
                  }
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                </th>
              ))}
            </StyledRow>
          ))}
        </StyledHeader>
        <StyledBody BodyBgColor={BodyBgColor} {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <StyledRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      className={
                        cell.column.className
                          ? cell.column.className
                          : `text-center align-middle pt-3 pb-3`
                      }
                      width={
                        cell.column.customWidth
                          ? `${cell.column.customWidth}%`
                          : cell.column.width
                      }
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </StyledRow>
            );
          })}
        </StyledBody>
      </StyledTable>
      {pagination && (
        <PaginationList pageIndex={pageIndex} pageOptions={pageOptions} />
      )}
    </>
  );
};

export default Table;
