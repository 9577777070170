import React from "react";
import { Accordion as BAccordion, Form } from "react-bootstrap";
import { Constant } from "../../lib/Constant";
import styled from "styled-components";
import { pxtoRem } from "../../lib/helper";

const StyledAccordianHeader = styled(BAccordion.Header)`
  & > button {
    height: ${pxtoRem(55)};
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.BRIGHT_GREY};
    color: ${(props) => props.theme.NOTIFICATIONLIST.DARK_BLACK};
    font-size: ${pxtoRem(24)};
    opacity: 1;
    line-height: 1.5;
    &:focus,
    &:focus-visible {
      box-shadow: none;
      border: none;
    }
    :not(.collapsed) {
      background-color: ${(props) => props.theme.colors.BRIGHT_GREY};
      color: ${(props) => props.theme.NOTIFICATIONLIST.DARK_BLACK};
      ::after {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
      }
    }

    img {
      margin-left: 20px;
      margin-right: 5px;
    }
  }
`;

const StyledErrMessage = styled(Form.Control.Feedback)`
  display: block;
  font-size: 14px;
  color: #dc3545;
`;

const StyledContainer = styled.div`
  margin-bottom: ${(props) => (props.errMessage ? pxtoRem(3) : pxtoRem(40.5))};
`;

function Accordion({
  eventKey = "0",
  heading = "Add",
  children,
  errMessage = null,
  defaultActiveKey,
}) {
  return (
    <StyledContainer errMessage={errMessage}>
      <BAccordion defaultActiveKey={defaultActiveKey}>
        <BAccordion.Item eventKey={eventKey}>
          <StyledAccordianHeader>
            <img
              src={Constant.Icons.add_circle_outline}
              width={20}
              height={20}
              alt={`${heading}`}
            />
            {heading}
          </StyledAccordianHeader>
          <BAccordion.Body>{children}</BAccordion.Body>
        </BAccordion.Item>
      </BAccordion>
      {errMessage && <StyledErrMessage>{errMessage}</StyledErrMessage>}
    </StyledContainer>
  );
}

export default Accordion;
