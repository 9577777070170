import React, { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import MockTestFrom from "../../components/form/MockTestFrom";
import QuestionContainer from "../../components/shared/question/QuestionContainer";
import { Questions } from "../../lib/dummyData";
import { useDispatch, useSelector } from "react-redux";
import { setFormData } from "../../redux-toolkit/mockTest";
import useCreateMockTest from "../../hooks/mockTest/useCreateMockTest";
import useUpdateMockTest from "../../hooks/mockTest/useUpdateMockTest";
import { Toast } from "react-bootstrap";
import { useQueryClient } from "react-query";

import questions from "../../redux-toolkit/questions";

const AddMockTest = () => {
  const createMutation = useCreateMockTest();
  const updateMutation = useUpdateMockTest();
  const queryClient = useQueryClient();
  const formPages = 2;
  const [formStep, setFormStep] = useState(1);
  const [pageJump, setPageJump] = useState(1);
  const [newStep, setNewStep] = useState(false);

  const { formData } = useSelector((state) => {
    return state.mockTest;
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitted, isDirty, isValid },
    watch,
    reset,
    setValue,
    getValues,
  } = useForm({ mode: "onChange" });

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(setFormData(data));
    if (formStep < formPages) {
      const thisStep = formStep + pageJump;
      setFormStep(thisStep);
      setNewStep(true);
      return false;
    }

    return false;
  };

  function backPage(e, val) {
    const thisStep = formStep - val;
    setPageJump(val);
    setFormStep(thisStep);
    setNewStep(true);
  }

  useEffect(() => {
    reset(formData, {
      keepValues: true,
      keepDirty: true,
    });
    setNewStep(false);
    // eslint-disable-next-line
  }, [isSubmitted, reset, newStep]);

  const onQuestionSubmit = (formData) => {
    let form_data = getValues();
    formData.append("duration_hours", form_data.duration_hours);
    formData.append("duration_minutes", form_data.duration_minutes);
    formData.append("answer_type", form_data.answer_type);
    formData.append("chapter_id", "118");
    formData.append("status", "draft");
    formData.append("test_type", "mock_test");

    if (formData.get("id")) {
      updateMutation.mutate(formData, {
        onError: (res) => {
          Toast.errors(res?.response?.data?.message);
        },
        onSuccess: () => {
          queryClient.invalidateQueries("viewMockTest");
          Toast.success("question updated successfuly");
        },
      });
    } else {
      createMutation.mutate(formData, {
        onError: (res) => {
          Toast.errors(res?.response?.data?.message);
        },
        onSuccess: () => {
          queryClient.invalidateQueries("viewMockTest");
          Toast.success("question added successfuly");
        },
      });
    }
  };

  const methods = {
    control,
    register,
    errors,
    handleSubmit,
    watch,
    backPage,
    getValues,
    setValue,
    isDirty,
    isValid,
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {formStep === 1 && <MockTestFrom />}
        </form>
        {formStep === 2 && (
          <QuestionContainer
            title="Mock Test"
            onSubmit={onSubmit}
            Questions={Questions}
            onQuestionSubmit={onQuestionSubmit}
            type={watch("answer_type")}
          />
        )}
      </FormProvider>
    </>
  );
};

export default AddMockTest;
