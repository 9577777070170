import { Modal } from "react-bootstrap";
import styled from "styled-components";
import Button from "../Button";
import { theme } from "../../../lib/themeConstants";
import { pxtoRem } from "../../../lib/helper";

const StyledLayer = styled.div`
  border: none;
  justify-content: ${(props) => (props.issignout ? "start" : "center")};
  color: ${(props) => props.theme.colors.SIDEMENU_GREY};
  margin-left: ${(props) => props.issignout && "110px"};
`;

const StyledHeader = styled(StyledLayer)`
  font-weight: 600;
  padding-top: ${pxtoRem(60)};
  color: ${(props) =>
    props.issignout && props.theme.colors.NEBULA_BLUE} !important;
`;

const StyledBody = styled(StyledLayer)`
  padding-top: 0px;
  font-weight: 400;
  font-size: ${pxtoRem(22)};
  padding: ${pxtoRem(20)};
  /* width: ${pxtoRem(564)}; */
  /* width: 100%; */
  justify-content: ${(props) => (props.issignout ? "start" : "center")};
  margin-left: ${(props) => (props.isdelete ? "100px" : "")};
`;

const StyledFooter = styled(StyledLayer)`
  padding-bottom: ${pxtoRem(60)};

  button {
    width: ${pxtoRem(130)};
    height: ${pxtoRem(45)};
    margin-right: ${pxtoRem(20)};
  }
`;
const StyledBodyContainer = styled.div`
  /* display: flex;
justify-content:center;
align-items: center; */
`;

const StyledButton = styled(Button)`
  width: ${pxtoRem(179)} !important;
  height: ${pxtoRem(48)};
`;

const AlertPopUp = ({
  confirmText = "Yes",
  cancelText = "No",
  header,
  body,
  onConfirm,
  onCancel,
  show = false,
  isdelete = false,
  issignout = false,
}) => {
  return (
    <Modal
      contentClassName="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onCancel}
    >
      <StyledHeader
        issignout={issignout ? issignout : undefined}
        as={Modal.Header}
      >
        {header}
      </StyledHeader>
      {body && (
        <StyledBodyContainer>
          <StyledBody
            as={Modal.Header}
            issignout={issignout ? issignout : undefined}
            isdelete={isdelete ? isdelete : undefined}
          >
            {body}
          </StyledBody>
        </StyledBodyContainer>
      )}

      <StyledFooter
        as={Modal.Footer}
        issignout={issignout ? issignout : undefined}
      >
        {isdelete ? (
          <>
            <Button
              bgColor={theme.colors.RED}
              text={confirmText}
              onClick={onConfirm}
            ></Button>
            <Button
              bgColor={theme.colors.NEBULA_BLUE}
              text={cancelText}
              onClick={onCancel}
            ></Button>
          </>
        ) : issignout ? (
          <>
            <StyledButton
              bgColor={theme.colors.NEBULA_BLUE}
              text={confirmText}
              onClick={onConfirm}
            ></StyledButton>
            <StyledButton
              bgColor={theme.colors.SPANISH_GREY}
              text={cancelText}
              onClick={onCancel}
            ></StyledButton>
          </>
        ) : (
          <>
            <Button
              bgColor={theme.colors.RED}
              text={cancelText}
              onClick={onCancel}
            ></Button>
            <Button
              bgColor={theme.colors.NEBULA_BLUE}
              text={confirmText}
              onClick={onConfirm}
            ></Button>
          </>
        )}
      </StyledFooter>
    </Modal>
  );
};

export default AlertPopUp;
