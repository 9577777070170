export const pxtoRem = (value) => {
  let remVal = value > 0 && value / 24;
  return remVal ? `${remVal}rem` : `0rem`;
};

export const isQuillEmpty = (value) => {
  if (
    (value.replace(/<(.|\n)*?>/g, "").trim().length === 0 &&
      !value.includes("<img")) ||
    value === ""
  ) {
    return true;
  }
  return false;
};

export const removeBlank = (data) => {
  let obj = { ...data };
  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined || obj[key] === "" || obj[key] === null) {
      delete obj[key];
    }
  });
  return obj;
};

export function isValidImgSrc(inputValue) {
  var doc = new DOMParser().parseFromString(inputValue, "text/html");
  let availableTags = ["html", "head", "body", "figure", "img"];
  let currentImageTags = Array.from(doc.all);
  let allTags = currentImageTags.map((item, index) => {
    let available = availableTags.find((tag) => tag === item.localName);
    if (item.localName === "p" && item.innerHTML === "&nbsp;") {
      return true;
    }
    if (!available) {
      return false;
    }
    return true;
  });

  let checker = (arr) => arr.every((v) => v === true);
  return checker(allTags);

  // var result = doc.evaluate(
  //   "//figure/img/@src",
  //   doc,
  //   null,
  //   XPathResult.STRING_TYPE,
  //   null
  // );
  // var Result = doc.evaluate(
  //   !"//figure/img/@src",
  //   doc,
  //   null,
  //   XPathResult.STRING_TYPE,
  //   null
  // );

  // // console.log(result.stringValue);
  // console.log(Result.stringValue);
  // if (result && result.stringValue && result.stringValue !== "") {
  //   //maybe you want to do further checks to test if the url is actually valid...
  //   return true;
  // }
  // return false;
}

export function isValidTextORImage(inputValue) {
  var doc = new DOMParser().parseFromString(inputValue, "text/html");
  let availableTags = [
    "html",
    "head",
    "body",
    "p",
    "span",
    "strong",
    "i",
    "u",
    "ol",
    "li",
    "ul",
    "sub",
    "sup",
    "img",
    "figure",
    "br",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
  ];
  let currentTags = Array.from(doc.all);
  let allTags = currentTags.map((item, index) => {
    let available = availableTags.find((tag) => tag === item.localName);

    if (!available) {
      return false;
    }
    return true;
  });

  let checker = (arr) => arr.every((v) => v === true);
  return checker(allTags);
}

export function convertBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}
