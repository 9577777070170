import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { pxtoRem } from "../../lib/helper";

const SectionHeader = ({ title, control }) => {
  const StyledRow = styled(Row)`
    margin: 0px;
    background: ${(props) => props.theme.colors.ANTIFLASH_WHITE};
    height: ${pxtoRem(74)};
  `;

  const StyledText = styled.h5`
    color: ${(props) => props.theme.NOTIFICATIONLIST.DARK_BLACK};
    /* padding: ${pxtoRem(6)}; */
    font-size: ${pxtoRem(24)};
    background: ${(props) => props.theme.colors.ANTIFLASH_WHITE};
    height: ${pxtoRem(74)};
    vertical-align: middle;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
  `;

  const StyledCol = styled(Col)`
    margin: auto;
  `;

  const StyledTextCol = styled(Col)`
    box-shadow: 0px 3px 6px #00000029;
  `;
  return (
    <StyledRow>
      <StyledTextCol>
        <Row>
          <Col>
            <StyledText>{title}</StyledText>
          </Col>
          {control && <StyledCol className="text-end">{control}</StyledCol>}
        </Row>
      </StyledTextCol>
    </StyledRow>
  );
};

export default SectionHeader;
