import styled from "styled-components";
import { pxtoRem } from "../../../lib/helper";

const StyledBox = styled.div`
  width: ${pxtoRem(60)};
  height: ${pxtoRem(55)};
  background-color: ${(props) => props?.bgcolor};
  align-items: center;
  display: inline-flex;
  justify-content: center;
  margin-left: ${pxtoRem(20)};
  border-radius: 4px;
  cursor: pointer;
`;

const BoxImage = ({ imgSrc, bgColor, onClick, size = 18 }) => {
  return (
    <StyledBox onClick={onClick} bgcolor={bgColor}>
      <img height={size} alt="icon" src={imgSrc} />
    </StyledBox>
  );
};

export default BoxImage;
