import { Col, Row } from "react-bootstrap";
import { Controller, useForm, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Button from "../../components/shared/Button";
import Dropdown from "../../components/shared/Dropdown";
import FormControl from "../../components/shared/FormControl";
import MultipleDropdown from "../../components/shared/MultipleDropdown";
import { Spinner } from "../../components/shared/Spinner";
import {
  useCountries,
  useExamBoards,
  useSubjects,
  useYears,
  useChapters,
} from "../../hooks/chapter";
import ContainerComponent from "../shared/ContainerComponent";
import styled from "styled-components";
import { pxtoRem, removeBlank } from "../../lib/helper";
import { theme } from "../../lib/themeConstants";
import InputTime from "../shared/InputTime";
import PageHeader from "../shared/PageHeader";
import SectionHeader from "../shared/SectionHeader";
import {
  Constant,
  PaperLevel,
  PaperNo,
  QuestionTypes,
} from "../../lib/Constant";
import { countries } from "../../lib/dummyData";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetQuestions } from "../../redux-toolkit/questions";
import TextInput from "../shared/TextInput";
import FileUpload from "../shared/FileUpload";
import DateInput from "../shared/DateInput";
import useLevels from "../../hooks/chapter/useLevels";

const StyledBox = styled(Row)`
  width: ${(props) => (!props.isedit ? "95%" : "99%")};
  padding-left: ${pxtoRem(60)};
  margin-top: ${pxtoRem(40)};
`;

const StyledButton = styled(Button)`
  margin-right: -${pxtoRem(30)};
`;

const PastPaperForm = ({ isLoading, defaultValues = null, isedit = false }) => {
  const dispatch = useDispatch();
  const {
    control,
    register,
    errors,
    isDirty,
    isValid,
    setValue,
    getValues,
    reset,
  } = useFormContext();

  const navigate = useNavigate();
  const { data: lstCountries } = useCountries();
  const { data: lstLevels } = useLevels();
  const { data: lstExamBoards } = useExamBoards();
  const { data: lstSubjects } = useSubjects();

  useEffect(() => {
    let data = {
      country_id: defaultValues?.chapter?.country_id
        ? parseInt(defaultValues?.chapter?.country_id)
        : null,
      exam_board_id: defaultValues?.chapter?.exam_board?.id
        ? parseInt(defaultValues?.chapter?.exam_board?.id)
        : null,
      level_id: defaultValues?.chapter?.year_id
        ? defaultValues.chapter.years_data[0].key_stage[0].id
        : null,
      subject_id: defaultValues?.chapter?.subject_id
        ? parseInt(defaultValues?.chapter?.subject_id)
        : null,
      question_type: defaultValues?.question_type
        ? defaultValues?.question_type
        : null,
      duration_hours: defaultValues?.duration_hours
        ? defaultValues?.duration_hours
        : null,
      duration_minutes: defaultValues?.duration_minutes
        ? defaultValues?.duration_minutes
        : null,
      coverImageFile: isedit && defaultValues?.cover_image,
      instructionFile: isedit && defaultValues?.instruction,
      cover_image: defaultValues?.cover_image
        ? defaultValues?.cover_image
        : null,
      instruction: defaultValues?.instruction
        ? defaultValues?.instruction
        : null,
      year: defaultValues?.year ? defaultValues?.year : null,
      paper_no: defaultValues?.paper_no ? defaultValues?.paper_no : null,
      paper_level: defaultValues?.paper_level
        ? defaultValues?.paper_level
        : null,
    };
    reset(data, { keepDirty: true });
    // eslint-disable-next-line
  }, [defaultValues, reset]);

  // let instruction = getValues("instruction");
  // let year = getValues("year");
  // let answer_type = getValues("answer_type");
  // let country_id = getValues("country_id");
  // let exam_board_id = getValues("exam_board_id");
  // let subject_id = getValues("subject_id");
  // let level_id = getValues("level_id");
  // let allValues =
  //   country_id &&
  //   exam_board_id &&
  //   level_id &&
  //   subject_id &&
  //   instruction &&
  //   year &&
  //   answer_type;

  return (
    <>
      <Spinner isLoading={isLoading} />
      {!isedit && (
        <>
          <PageHeader
            title="Past paper"
            control={
              <Button
                text="Back"
                bgColor={theme.colors.NEBULA_BLUE}
                onClick={() => {
                  navigate("/past-paper");
                }}
              />
            }
          ></PageHeader>
          <SectionHeader title="Create new past paper"></SectionHeader>
        </>
      )}

      <ContainerComponent>
        {/* <form onSubmit={handleSubmit(handleFormSubmit)}> */}
        <StyledBox style={{ marginBottom: "2rem" }} isedit={isedit}>
          <Col>
            <FormControl
              label="country *"
              errMessage={errors?.country_id?.message}
            >
              <Controller
                isClearable
                rules={{ required: "Please select a country" }}
                name="country_id"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <Dropdown
                    autoFocus
                    placeholder="Select country"
                    {...rest}
                    options={
                      lstCountries?.map((item) => {
                        return {
                          label: item.name,
                          value: item.id,
                        };
                      }) || []
                    }
                  />
                )}
              />
            </FormControl>

            <FormControl
              label="exam board *"
              errMessage={errors?.exam_board_id?.message}
            >
              <Controller
                isClearable
                name="exam_board_id"
                control={control}
                rules={{ required: "Please select a exam board" }}
                defaultValue={defaultValues?.chapter?.exam_board?.id}
                render={({ field: { ref, ...rest } }) => (
                  <Dropdown
                    placeholder="Select exam board"
                    {...rest}
                    options={
                      lstExamBoards?.map((item) => {
                        return {
                          label: item.name,
                          value: item.id,
                        };
                      }) || []
                    }
                  />
                )}
              />
            </FormControl>

            <FormControl label="level *" errMessage={errors?.level_id?.message}>
              <Controller
                isClearable
                name="level_id"
                rules={{ required: "Please select a level" }}
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <Dropdown
                    placeholder="Select level"
                    {...rest}
                    options={
                      lstLevels?.map((item) => {
                        return {
                          label: item.name,
                          value: item.id,
                        };
                      }) || []
                    }
                  />
                )}
              />
            </FormControl>

            <FormControl label="subject *">
              <Controller
                isClearable
                name="subject_id"
                rules={{ required: "Please select a subject" }}
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <Dropdown
                    placeholder="Select subject"
                    {...rest}
                    options={
                      lstSubjects?.map((item) => {
                        return {
                          label: item.name,
                          value: item.id,
                        };
                      }) || []
                    }
                  />
                )}
              />
            </FormControl>

            <FormControl
              label="Cover image *"
              errMessage={errors?.cover_image?.message}
            >
              <TextInput
                style={{ display: "none" }}
                id="coverImageFile"
                register={register}
                validation={{ required: "Please select cover image" }}
              />
              <FileUpload
                isForm={true}
                iscustom={true}
                file={
                  typeof defaultValues?.cover_image === "string"
                    ? defaultValues?.cover_image
                    : defaultValues?.coverImageFile
                }
                accept=".JPG,.JPEG,.PNG"
                id="cover_image"
                register={register}
                handleChange={(e) => {
                  setValue("coverImageFile", e?.target?.files?.[0]?.name, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
              />
            </FormControl>

            <FormControl
              label="Instruction *"
              errMessage={errors?.instruction?.message}
            >
              <TextInput
                style={{ display: "none" }}
                id="instructionFile"
                register={register}
                validation={{ required: "Please select instruction" }}
              />
              <FileUpload
                isForm={true}
                iscustom={true}
                file={
                  typeof defaultValues?.instruction === "string"
                    ? defaultValues?.instruction
                    : defaultValues?.instructionFile
                }
                accept=".JPG,.JPEG,.PNG"
                id="instruction"
                register={register}
                handleChange={(e) => {
                  setValue("instructionFile", e?.target?.files?.[0]?.name, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
              />
            </FormControl>
          </Col>
          <Col md={{ offset: 1 }}>
            <FormControl
              label="year *"
              errMessage={errors?.past_paper_year?.message}
            >
              <Controller
                isClearable
                name="year"
                control={control}
                rules={{ required: "Please add a year" }}
                render={({ field: { ref, ...rest } }) => (
                  <DateInput
                    className="react-calender"
                    autoFocus
                    placeholder="Select question"
                    {...rest}
                  />
                )}
              />
            </FormControl>
            <FormControl
              label="Paper no"
              // errMessage={errors?.paper_no?.message}
            >
              <Controller
                isClearable
                name="paper_no"
                control={control}
                // rules={{ required: "Please select a paper no" }}
                render={({ field: { ref, ...rest } }) => (
                  <Dropdown
                    autoFocus
                    placeholder="Select paper no"
                    {...rest}
                    options={PaperNo}
                    isClearable
                  />
                )}
              />
            </FormControl>
            <FormControl
              label="Paper level"
              // errMessage={errors?.paper_level?.message}
            >
              <Controller
                isClearable
                name="paper_level"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <Dropdown
                    value={defaultValues?.paper_level}
                    autoFocus
                    placeholder="Select paper level"
                    {...rest}
                    options={PaperLevel}
                    isClearable
                  />
                )}
              />
            </FormControl>
            <FormControl
              label="Question type *"
              errMessage={errors?.question_type?.message}
            >
              <Controller
                isClearable
                name="question_type"
                control={control}
                rules={{ required: "Please select a question type" }}
                render={({ field: { ref, ...rest } }) => (
                  <Dropdown
                    autoFocus
                    placeholder="Select question"
                    {...rest}
                    options={QuestionTypes}
                  />
                )}
              />
            </FormControl>
            <FormControl label="Total duration *">
              <InputTime
                errMessage={
                  errors?.duration_hours?.message ||
                  errors?.duration_minutes?.message
                }
                register={register}
              />
            </FormControl>
          </Col>
        </StyledBox>

        <Row>
          <Col style={{ textAlign: "end" }}>
            <StyledButton
              type="submit"
              bgColor={theme.colors.NEBULA_BLUE}
              disabled={isDirty && isValid ? false : true}
              text={isedit ? "Update" : "Next"}
            />
          </Col>
        </Row>
        {/* </form> */}
      </ContainerComponent>
    </>
  );
};

export default PastPaperForm;
