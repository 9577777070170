import React, { useState, useEffect } from "react";
import PastPaperForm from "../../components/form/PastPaperForm";
import QuestionContainer from "../../components/shared/question/QuestionContainer";
import { FormProvider, useForm } from "react-hook-form";
import { Questions } from "../../lib/dummyData";
import { toast } from "react-toastify";
import { convertBase64, removeBlank } from "../../lib/helper";
import { useAddChallenge, useUpdateChallenge } from "../../hooks/challenges";
import { useDispatch, useSelector } from "react-redux";
import { setFormData } from "../../redux-toolkit/challenges";
import ChallengeForm from "../../components/form/ChallengeForm";
import moment from "moment";
import { resetQuestions } from "../../redux-toolkit/questions";

function AddChallenge() {
  const formPages = 2;
  const [formStep, setFormStep] = useState(1);
  const [pageJump, setPageJump] = useState(1);
  const [newStep, setNewStep] = useState(false);
  const { formData } = useSelector((state) => {
    return state.challenges;
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitted, isDirty, isValid },
    watch,
    reset,
    setValue,
    getValues,
  } = useForm({ mode: "onChange", defaultValues: formData });

  const createMutation = useAddChallenge();
  const updateMutation = useUpdateChallenge();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    dispatch(setFormData({ ...formData, ...data }));
    if (formStep < formPages) {
      const thisStep = formStep + pageJump;
      setFormStep(thisStep);
      setNewStep(true);
      return false;
    }

    return false;
  };

  function backPage(e, val) {
    const thisStep = formStep - val;
    setPageJump(val);
    setFormStep(thisStep);
    setNewStep(true);
  }

  useEffect(() => {
    reset(formData, {
      keepValues: true,
      keepDirty: true,
    });
    setNewStep(false);
    // eslint-disable-next-line
  }, [isSubmitted, reset, newStep]);

  useEffect(() => {
    return () => {
      dispatch(resetQuestions());
      dispatch(setFormData(null));
    };
    // eslint-disable-next-line
  }, []);

  const onQuestionSubmit = async (data, onFormSuccess) => {
    let formData = new FormData();
    let questionData = removeBlank(data);
    let form_data = getValues();
    for (const obj in questionData) {
      formData.append(obj, questionData[obj]);
    }

    formData.append("country_id", form_data?.country_id);

    formData.append("challenge_board_id", form_data?.challenge_board_id);
    formData.append("level_id", form_data?.level_id);
    formData.append("section_id", form_data?.section_id);
    formData.append("grade_id", form_data?.grade_id);
    formData.append("subject_id", form_data?.subject_id);
    formData.append("answer_type", form_data.answer_type);
    formData.append(
      "past_paper_year",
      moment(form_data.past_paper_year).format("YYYY")
    );

    if (
      typeof form_data?.instruction !== "string" &&
      form_data?.instruction?.length > 0
    ) {
      const imageBase64 = await convertBase64(form_data?.instruction?.[0]);
      formData.append("instruction", imageBase64);
    }
    !data.sa_question_answer && formData.append("sa_question_answer", "123");

    if (formData.get("id")) {
      updateMutation.mutate(formData, {
        onError: (res) => {
          toast.error(res?.response?.data?.message);
          onFormSuccess(false);
        },
        onSuccess: (res) => {
          // queryClient.invalidateQueries("viewWorksheet");
          onFormSuccess(true);
        },
      });
    } else {
      createMutation.mutate(formData, {
        onError: (res) => {
          toast.error(res?.response?.data?.message);
          onFormSuccess(false);
        },
        onSuccess: (res) => {
          // queryClient.invalidateQueries("viewWorksheet");
          onFormSuccess(true);
        },
      });
    }
  };

  const methods = {
    control,
    register,
    errors,
    handleSubmit,
    watch,
    backPage,
    getValues,
    setValue,
    isDirty,
    isValid,
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {formStep === 1 && <ChallengeForm defaultValues={formData} />}
        </form>
        {formStep === 2 && (
          <QuestionContainer
            title="Challenges"
            onSubmit={onSubmit}
            Questions={Questions}
            onQuestionSubmit={onQuestionSubmit}
            type={formData?.answer_type}
          ></QuestionContainer>
        )}
      </FormProvider>
    </>
  );
}

export default AddChallenge;
