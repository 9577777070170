import { Form } from "react-bootstrap";
import styled from "styled-components";

const StyledCheckbox = styled(Form.Check)`
  color: #212121;
  input {
    cursor: pointer;
  }
`;

const StyledErrMessage = styled(Form.Control.Feedback)`
  display: block;
  font-size: 14px;
  color: #dc3545;
`;

const FormCheckBox = ({
  label,
  id,
  errMessage,
  register,
  validation,
  ...rest
}) => {
  return (
    <div className="mb-3">
      <StyledCheckbox {...register(id, validation)} {...rest} label={label} />
      {errMessage && <StyledErrMessage>{errMessage}</StyledErrMessage>}
    </div>
  );
};

export default FormCheckBox;
