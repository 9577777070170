import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "react-router";
import ViewDemo from "../../layout/ViewDemo";
import InitialPage from "../worksheet/InitialPage";
import QuestionContainer from "../../components/shared/question/QuestionContainer";
import { Qtype } from "../../lib/Constant";
import { Spinner } from "../../components/shared/Spinner";
import QuestionModal from "../../components/shared/question/QuestionModal";
import MockTestFrom from "../../components/form/MockTestFrom";
import EditModal from "../../components/shared/popups/EditModal";

const ViewMockTest = () => {
  const dummyData = [
    {
      question_number: 1,
      isCompleted: false,
      isCurrent: true,
      isDisabled: false,
      showSubQuestion: true,
      question: `<p> A student investigated the reactivity of metals with hydrochloric acid. 1</p>
          
          A student investigated the reactivity of metals with hydrochloric acid.
          A student investigated the reactivity of metals with hydrochloric acid.
          A student investigated the reactivity of metals with hydrochloric acid.
          A student investigated the reactivity of metals with hydrochloric acid.
          A student investigated the reactivity of metals with hydrochloric acid.
          A student investigated the reactivity of metals with hydrochloric acid.
      
          A student investigated the reactivity of metals with hydrochloric acid.
          A student investigated the reactivity of metals with hydrochloric acid.
          A student investigated the reactivity of metals with hydrochloric acid.
          A student investigated the reactivity of metals with hydrochloric acid.
          A student investigated the reactivity of metals with hydrochloric acid.
          A student investigated the reactivity of metals with hydrochloric acid.
      
          A student investigated the reactivity of metals with hydrochloric acid.
          A student investigated the reactivity of metals with hydrochloric acid.
          A student investigated the reactivity of metals with hydrochloric acid.
          A student investigated the reactivity of metals with hydrochloric acid.
          A student investigated the reactivity of metals with hydrochloric acid.
          A student investigated the reactivity of metals with hydrochloric acid.
          <b>please read carefully </b>
          <p>What is your favourit sport</p>
          <div>
          <b>cricket </b>
          </div>
      
          A student investigated the reactivity of metals with hydrochloric acid.
          A student investigated the reactivity of metals with hydrochloric acid.
          A student investigated the reactivity of metals with hydrochloric acid.
          A student investigated the reactivity of metals with hydrochloric acid.
          A student investigated the reactivity of metals with hydrochloric acid.
          A student investigated the reactivity of metals with hydrochloric acid.
      
          A student investigated the reactivity of metals with hydrochloric acid.
          A student investigated the reactivity of metals with hydrochloric acid.
          A student investigated the reactivity of metals with hydrochloric acid.
          A student investigated the reactivity of metals with hydrochloric acid.
          A student investigated the reactivity of metals with hydrochloric acid.
          A student investigated the reactivity of metals with hydrochloric acid.
      
          A student investigated the reactivity of metals with hydrochloric acid.
          A student investigated the reactivity of metals with hydrochloric acid.
          A student investigated the reactivity of metals with hydrochloric acid.
          A student investigated the reactivity of metals with hydrochloric acid.
          A student investigated the reactivity of metals with hydrochloric acid.
          A student investigated the reactivity of metals with hydrochloric acid.
          <b>please read carefully </b>
          <p>What is your favourit sport</p>
          <div>
          <b>cricket </b>
          </div>
          `,
      children: [
        {
          question_number: "1.1",
          isCompleted: true,
          isCurrent: false,
          isDisabled: false,
          marks: "10",
          question: "<p>sub question 1</p>",
          answer: "<p>sub question answer 1</p>",
        },
        {
          question_number: "1.2",
          isCompleted: true,
          isCurrent: false,
          isDisabled: false,
          marks: "5",
          question: "<p>sub question 2</p>",
          answer: "<p>answer 2</p>",
        },
        {
          question: "<p>sub question 3</p>",
          question_number: "1.3",
          isCompleted: false,
          isCurrent: true,
          isDisabled: false,
        },
      ],
    },
    {
      question: "<p> question 2</p>",
      question_number: 2,
      isCompleted: false,
      isCurrent: false,
      isDisabled: true,
    },
    {
      question: "<p> question 3</p>",
      question_number: 3,
      isCompleted: false,
      isCurrent: false,
      isDisabled: true,
    },
    {
      question: "<p> question 4</p>",
      question_number: 4,
      isCompleted: false,
      isCurrent: false,
      isDisabled: true,
    },
    {
      question: "<p> question 5</p>",
      question_number: 5,
      isCompleted: false,
      isCurrent: false,
      isDisabled: true,
    },
    {
      question: "<p> question 6</p>",
      question_number: 6,
      isCompleted: false,
      isCurrent: false,
      isDisabled: true,
      children: [
        {
          question_number: "1.1",
          isCompleted: true,
          isCurrent: false,
          isDisabled: false,
          marks: "10",
          question: "<p>sub question 1</p>",
          answer: "<p>sub question answer 1</p>",
        },
        {
          question_number: "1.2",
          isCompleted: true,
          isCurrent: false,
          isDisabled: false,
          marks: "5",
          question: "<p>sub question 2</p>",
          answer: "<p>answer 2</p>",
        },
        {
          question: "<p>sub question 3</p>",
          question_number: "1.3",
          isCompleted: false,
          isCurrent: true,
          isDisabled: false,
        },
      ],
    },
    {
      question: "<p> question 7</p>",
      question_number: 7,
      isCompleted: false,
      isCurrent: false,
      isDisabled: true,
    },
    {
      question: "<p> question 8</p>",
      question_number: 8,
      isCompleted: false,
      isCurrent: false,
      isDisabled: true,
    },
    {
      question: "<p> question 9</p>",
      question_number: 9,
      isCompleted: false,
      isCurrent: false,
      isDisabled: true,
    },
    {
      question: "<p> question 10</p>",
      question_number: 10,
      isCompleted: false,
      isCurrent: false,
      isDisabled: true,
      children: [
        {
          question_number: "1.1",
          isCompleted: true,
          isCurrent: false,
          isDisabled: false,
          marks: "10",
          question: "<p>sub question 1</p>",
          answer: "<p>sub question answer 1</p>",
        },
        {
          question_number: "1.2",
          isCompleted: true,
          isCurrent: false,
          isDisabled: false,
          marks: "5",
          question: "<p>sub question 2</p>",
          answer: "<p>answer 2</p>",
        },
      ],
    },
  ];

  let location = useLocation();
  const lesson_id = location.pathname.split("/")[3];
  const [initialPage, setInitialPage] = useState(true);
  //   const selftestQuery = useViewSelftest(lesson_id);
  const [show, setShow] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitted, isDirty, isValid },
    watch,
    reset,
    setValue,
    getValues,
  } = useForm({ mode: "onChange" });

  const methods = {
    control,
    register,
    errors,
    handleSubmit,
    watch,
    getValues,
    setValue,
    isDirty,
    isValid,
  };

  const onSubmit = (data) => {};

  return (
    <>
      <EditModal
        onCancel={() => {
          setShowForm(false);
        }}
        show={showForm}
        title={"Edit Mock test"}
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
            <MockTestFrom isEdit={true} />
          </form>
        </FormProvider>
      </EditModal>
      <QuestionModal
        onCancel={() => {
          setShow(false);
        }}
        show={show}
      >
        <Spinner isLoading={false} />
        <FormProvider {...methods}>
          <QuestionContainer
            Questions={dummyData}
            onQuestionSubmit={() => {}}
            type={Qtype.structured_question}
            isEdit={true}
          ></QuestionContainer>
        </FormProvider>
      </QuestionModal>
      {initialPage ? (
        <InitialPage
          handleEdit={() => {
            setShowForm(true);
          }}
          setInitialPage={setInitialPage}
        ></InitialPage>
      ) : (
        <ViewDemo
          type={Qtype.structured_question}
          handleEdit={() => {
            setShow(true);
          }}
          list={dummyData}
          onClose={() => {
            setInitialPage(true);
          }}
        />
      )}
    </>
  );
};

export default ViewMockTest;
