import { useQuery } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useViewChapterPaper = (chapterId) => {
  const formData = {};
  if (chapterId) formData.chapter_id = chapterId;

  return useQuery(
    ["viewSelfTest", chapterId],
    async () => {
      const { data } = await axiosPost("/api/cw/viewChapterPaper", formData);
      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

export default useViewChapterPaper;
