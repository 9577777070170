import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import LessonForm from "../../components/form/LessonForm";
import { Spinner } from "../../components/shared/Spinner";
import AlertPopUp from "../../components/shared/popups/AlertPopUp";
import { useDeleteLesson, useUpdateLesson } from "../../hooks/lesson";
import useLesson from "../../hooks/lesson/useLesson";
import { useQueryClient } from "react-query";
import { Constant } from "../../lib/Constant";

const EditLesson = () => {
  const location = useLocation();
  const lessonId = location.pathname.split("/")[4];
  const lessonQuery = useLesson(lessonId);
  const [isModal, setIsModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const updateMutation = useUpdateLesson();
  const deleteMutation = useDeleteLesson();
  const [formData, setFormData] = useState();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const chapter = location?.state?.chapter;
  if (lessonQuery.isLoading) {
    return <Spinner isLoading={true} />;
  }

  const handleFormSubmit = (data) => {
    setFormData(data);
    setIsModal(true);
  };

  return (
    <>
      <AlertPopUp
        confirmText="Delete"
        cancelText="Cancel"
        onCancel={() => {
          setIsDeleteModal(false);
        }}
        show={isDeleteModal}
        isdelete={true}
        onConfirm={() => {
          setIsDeleteModal(false);
          const fData = new FormData();
          fData.set("id", lessonId);
          deleteMutation.mutate(fData, {
            onError: (res) => {
              toast.error(res?.response?.data?.message);
            },
            onSuccess: () => {
              queryClient.invalidateQueries("lessons");
              toast.success(Constant.SuccessMessages.deleteLesson);
              navigate(`/chapters/${chapter?.id}/lessons`, {
                state: {
                  chapter: chapter,
                },
              });
            },
          });
        }}
        header="Are you sure you want to delete the lesson details ?"
      />
      <AlertPopUp
        onCancel={() => {
          setIsModal(false);
        }}
        onConfirm={() => {
          setIsModal(false);
          const data = formData;
          data.set("id", parseInt(lessonId));
          // data.set("country_id", parseInt(chapter?.country_id));
          // data.set("exam_board_id", parseInt(chapter?.exam_board_id));
          // data.set("subject_id", parseInt(chapter?.subject_id));
          // data.set("year_id", parseInt(chapter?.year_id));

          updateMutation.mutate(formData, {
            onError: (res) => {
              toast.error(res?.response?.data?.message);
            },
            onSuccess: () => {
              queryClient.invalidateQueries("lessons");
              toast.success(Constant.SuccessMessages.editLesson);
              navigate(`/chapters/${chapter?.id}/lessons/${lessonId}/show`, {
                state: {
                  chapter: chapter,
                },
              });
            },
          });
        }}
        show={isModal}
        header="Do you want to save the changes ?"
      />
      <LessonForm
        handleFormSubmit={handleFormSubmit}
        defaultValue={lessonQuery?.data?.data?.[0]}
        title="Edit lesson details"
        isLoading={updateMutation.isLoading || deleteMutation.isLoading}
        chapter={chapter}
        handleDelete={() => {
          setIsDeleteModal(true);
        }}
      />
    </>
  );
};

export default EditLesson;
