import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
import Button from "../../components/shared/Button";
import ContainerComponent from "../../components/shared/ContainerComponent";
import Dropdown from "../../components/shared/Dropdown";
import FormControl from "../../components/shared/FormControl";
import InputTime from "../../components/shared/InputTime";
import PageHeader from "../../components/shared/PageHeader";
import SectionHeader from "../../components/shared/SectionHeader";
import { Spinner } from "../../components/shared/Spinner";
import TextInput from "../../components/shared/TextInput";
import QuestionContainer from "../../components/shared/question/QuestionContainer";
import {
  useParentActivityLessons,
  useParentActivityLesson,
  useCreateParentActivity,
  useUpdateParentActivity,
  useDeleteParentActivity,
  useViewParentActivity,
  useStatusChange

} from "../../hooks/parentActivity";
import { QuestionTypes } from "../../lib/Constant";
import { pxtoRem } from "../../lib/helper";
import { theme } from "../../lib/themeConstants";
import {
  resetQuestions,
  setQuestionsData,
} from "../../redux-toolkit/questions";
import { setFormData } from "../../redux-toolkit/parentActivity";

const StyledBox = styled(Row)`
  width: 95%;
  padding-left: ${pxtoRem(60)};
  margin-top: ${pxtoRem(40)};
`;

const StyledButton = styled(Button)`
  margin-right: -${pxtoRem(30)};
`;

const CreateParentActivity = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const formPages = 2;
  const self_test_count = location.state?.self_test_count;
  const [formStep, setFormStep] = useState(self_test_count > 0 ? 2 : 1);
  const [pageJump, setPageJump] = useState(1);
  const [newStep, setNewStep] = useState(false);
  const createMutation = useCreateParentActivity();
  const updateMutation = useUpdateParentActivity();
  const deleteMutation = useDeleteParentActivity();
  const id = location.pathname.split("/")[3];
  const parentActivityLessonQuery = useParentActivityLesson(id);
  const questionsQuery = useViewParentActivity(id);
  const navigate = useNavigate();

  const parentActivity = parentActivityLessonQuery?.data?.[0];
  const PageControl = ({
    name,
    value,
    labelColClass = "col-4 activity-form",
  }) => {
    return (
      <FormControl labelColClass={labelColClass} label={name}>
        <TextInput disabled value={value} />
      </FormControl>
    );
  };

  const { formData } = useSelector((state) => {
    return state.parentActivity;
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitted, isDirty, isValid },
    watch,
    reset,
    setValue,
    getValues,
  } = useForm({
    defaultValues: formData,
    mode: "onChange",
  });

  const methods = {
    control,
    register,
    errors,
    handleSubmit,
    watch,
    backPage,
    getValues,
    setValue,
    isDirty,
    isValid,
  };

  function backPage(e, val) {
    const thisStep = formStep - val;
    setPageJump(val);
    setFormStep(thisStep);
    setNewStep(true);
  }

  useEffect(() => {
    if (newStep) {
      setNewStep(false);
      reset(
        {},
        {
          keepValues: true,
          keepDirty: true,
        }
      );
    }
  }, [isSubmitted, reset, newStep]);

  useEffect(() => {
    return () => {
      dispatch(resetQuestions());
      dispatch(setFormData(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!formData && questionsQuery?.data?.length > 0) {
      const obj = questionsQuery?.data?.[0];
      setValue("answer_type", obj?.answer_type);
      setValue("duration_hours", obj?.duration_hours || 10);
      setValue("duration_minutes", obj?.duration_minutes || 20);
    }
  }, [questionsQuery, formData, setValue]);

  const onSubmit = async (data) => {
    dispatch(setFormData({ ...formData, ...data }));
    if (formStep < formPages) {
      const thisStep = formStep + pageJump;
      setValue("formStep", thisStep.toString(), { shouldDirty: true });
      setFormStep(thisStep);
      setNewStep(true);
      return false;
    }
    return false;
  };

  const onQuestionSubmit = (formData) => {
    let form_data = getValues();
    formData.append("duration_hours", form_data.duration_hours);
    formData.append("duration_minutes", form_data.duration_minutes);
    formData.append("answer_type", form_data.answer_type);
    formData.append("lesson_id", id);
    formData.append("status", "draft");

    if (formData.get("id")) {
      updateMutation.mutate(formData, {
        onError: (res) => {
          toast.error(res?.response?.data?.message);
        },
        onSuccess: (res) => {
          queryClient.invalidateQueries("viewparentActivity");
          toast.success("question updated successfuly");
        },
      });
    } else {
      createMutation.mutate(formData, {
        onError: (res) => {
          toast.error(res?.response?.data?.message);
        },
        onSuccess: (res) => {
          queryClient.invalidateQueries("viewparentActivity");
          toast.success("question added successfuly");
        },
      });
    }
  };

  const onQuestionDelete = (formData,onDelete) => {
    deleteMutation.mutate(formData, {
      onError: (res) => {
        toast.error(res?.response?.data?.message);
      },
      onSuccess: (res) => {
        queryClient.invalidateQueries("viewparentActivity");
        toast.success("question deleted successfuly");
        onDelete()
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {formStep === 1 && (
          <>
            <Spinner
              isLoading={
                parentActivityLessonQuery.isLoading || createMutation.isLoading
              }
            />
            <PageHeader
              title="Parent activity"
              control={
                <Button
                  onClick={() => {
                    navigate("/parent-activity");
                  }}
                  bgColor={theme.colors.NEBULA_BLUE}
                  text="Back"
                />
              }
            />
            <SectionHeader title="Create new parent activity" />
            <ContainerComponent>
              <StyledBox style={{ marginBottom: "2rem" }}>
                <Col>
                  <PageControl name="Country" value={parentActivity?.country?.name} />
                  <PageControl
                    name="Exam board"
                    value={parentActivity?.exam_board?.name}
                  />
                  <PageControl name="Level" value="kb" />
                  <PageControl name="Grade" value={parentActivity?.grade?.name} />
                </Col>
                <Col md={{ offset: 1 }}>
                  <PageControl
                    labelColClass="col-4"
                    name="Subject"
                    value={parentActivity?.subject?.name}
                  />

                  <PageControl
                    name="Chapter name"
                    value={parentActivity?.chapter?.name}
                    labelColClass="col-4"
                  />
                  <PageControl
                    labelColClass="col-4"
                    name="Lesson name"
                    value={parentActivity?.name}
                  />
                  <FormControl
                    labelColClass="col-4"
                    label="Question type *"
                    errMessage={errors?.answer_type?.message}
                  >
                    <Controller
                      isClearable
                      name="answer_type"
                      control={control}
                      rules={{ required: "Please select a question type" }}
                      render={({ field: { ref, ...rest } }) => (
                        <Dropdown
                          autoFocus
                          placeholder="Select question"
                          {...rest}
                          onChange={(value) => {
                            setValue("answer_type", value);
                            // dispatch(resetQuestions());
                          }}
                          options={QuestionTypes}
                          isDisabled={questionsQuery?.data?.length > 0}
                        />
                      )}
                    />
                  </FormControl>

                  <FormControl
                    labelColClass="col-4"
                    errMessage={
                      errors?.duration_hours?.message ||
                      errors?.duration_minutes?.message
                    }
                    label="Total duration *"
                  >
                    <InputTime register={register} />
                  </FormControl>
                </Col>
              </StyledBox>
              <Row>
                <Col style={{ textAlign: "end" }}>
                  <StyledButton
                    type="submit"
                    bgColor={theme.colors.NEBULA_BLUE}
                    text="Next"
                  />
                </Col>
              </Row>
            </ContainerComponent>
          </>
        )}
      </form>
      {formStep === 2 && (
        <>
          <Spinner
            isLoading={
              createMutation.isLoading ||
              updateMutation.isLoading ||
              deleteMutation.isLoading
            }
          />
          {self_test_count > 0 ? (
            <>
              {questionsQuery?.data?.length > 0 ? (
                <QuestionContainer
                  Questions={questionsQuery?.data}
                  onQuestionSubmit={onQuestionSubmit}
                  onQuestionDelete={onQuestionDelete}
                  title="parentActivity"
                  type={formData?.answer_type}
                ></QuestionContainer>
              ) : (
                <Spinner isLoading={true} />
              )}
            </>
          ) : (
            <QuestionContainer
              Questions={questionsQuery?.data}
              onQuestionSubmit={onQuestionSubmit}
              onQuestionDelete={onQuestionDelete}
              title="parentActivity"
              type={formData?.answer_type}
            ></QuestionContainer>
          )}
        </>
      )}
    </FormProvider>
  );
};

export default CreateParentActivity;
