import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filter: {
    country: null,
    examboard: null,
    subject: null,
    grade: null,
  },
  formData: null,
};

export const mockTest = createSlice({
  name: "mockTest",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
  },
});

export const { setFilter, setFormData } = mockTest.actions;

export default mockTest.reducer;
