export const theme = {
  colors: {
    COLUMBIA_BLUE: "#CEE9FA",
    NEBULA_BLUE: "#5A5EEE",
    DEEP_SKY_BLUE: "#008DED",
    WHITE: "#FFFFFF",
    BLACK: "#000000",
    GREY: "#808391",
    PURPLE: "#8A8DF2",
    NAVY_BLUE: "#020723",
    LIGHT_GREY: "#666666",
    DARK_GREY: "#707070",
    MEDIUM_GREY: "#777777",
    GHOST_WHITE: "#F9FAFF",
    BORDER_GREY: "#B2B2B2",
    MEDIUM_SLATE_BLUE: "#6D71EF",
    BRIGHT_GREY: "#ECECEC",
    MEDIUM_PURPLE: "#8E8BF3",
    YELLOW: "#FFD800",
    GREEN: "#00EB6E",
    BRANDEIS_BLUE: "#0072FA",
    SIDEMENU_GREY: "#2a2f44",
    MALACHITE: "#00E34B",
    METALIC_SILVER: "#ABAAAC",
    BLUE_COLA: "#0B92E8",
    APPLE_GREEN: "#7DC500",
    ANTIFLASH_WHITE: "#EEF1F5",
    DISABLED_INPUT_BG: "#F9FAFF",
    DISABLED_BORDER: "#66666680",
    TAUPE_GREY: "#888888",
    PAGE_ACTIVE: "#0075FF",
    VIVID_MALACHITE: "#00D22B",
    SHADE_OF_PINK_RED: "#dc3545",
    PINK: "#FC5C56CB",
    LAVENDER_BLUE: "#C5C7F9",
    RED: "#FF0000",
    GUNMENTAL: "#2A2F44",
    BODY_BG: "#F8F8FB",
    SPANISH_GREY: "#999999",
    BOX_SHADOW_BG: "49,132,253",
    QUESTION_BG: "rgba(248,248,251,0.29)",
    SHADE_ALICE_BLUE: "#F7FAFF",
    DARK_CYAN: "#007b85",
    LIGHT_SHADE_BLUE: "#3A8B8C",
    LIGHT_SHADE_GREY: "#EEEEEE",
    MEDIUM_LIGHT_SHADE_CYAN: "#6ecacd",
    GREEN_CYAN_SHADE: "#344356",
    SHADOW_GREY: "#3c80d116",
    SHADE_CYAN_BLUE: "#f2f7ff",
    SILVER_ARGENT: "#c0c0c0",
    Gray19: "rgba(248,248,251,0)",
    SHADE_OF_BLUE: "#CCCDFA",
    PLACEHOLDER_HINT: "rgb(110, 202, 205,0.09)",
    LAVENDER_MIST: "#E9ECFF",
    DEL_RED: "#FF0001",
    VIVID_CERULEAN: "#00A5EC",
  },
  AvatarColors: {
    0: "#0B92E8",
    1: "#F7C700",
  },
  SwitchColors: {
    onColor: "#FFFFFF",
    offColor: "#E5E7EA",
    onHandleColor: "#8E8BF3",
    offHandleColor: "#DBDBDB",
  },
  DropDownColors: {
    Grey: "#F3F3FA",
    SKY_BLUE: "rgb(222, 235, 255)",
    LIGHT_GREY: "#666666",
    MEDIUM_GREY: "#777777",
    LIGHT_BLUE: "#CCE8FB",
    NEBULA_BLUE: "#5A5EEE",
  },
  FontSize: {
    MEDIUM: "20px",
    SEMI_BOLD: "9px",
  },
  NOTIFICATIONLIST: {
    DARK_BLACK: "#111111",
    LIGHT_BLACK: "#333333",
  },
  COLORPICKER: {
    DEEP_BLUE: "#0071F88E",
    SKY_BLUE: "#F5F7FE",
    GREY: "#D0D0D0",
  },
};
