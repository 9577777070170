import React, { useEffect } from "react";
import { useGreetings } from "../hooks/dashboard";
import { toast } from "react-toastify";
import { Spinner } from "../components/shared/Spinner";
import PageHeader from "../components/shared/PageHeader";
import styled from "styled-components";
import { pxtoRem } from "../lib/helper";
import moment from "moment";

const StyledDateContainer = styled.div`
  padding: 0;
  background-color: ${(props) => props.theme.colors.NEBULA_BLUE};
  /* width: ${pxtoRem(172)}; */
  height: ${pxtoRem(76)};
  color: ${(props) => props.theme.colors.WHITE};
  border-radius: ${pxtoRem(10)};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: ${pxtoRem(20)};
  padding-right: ${pxtoRem(20)};
  padding-top: ${pxtoRem(5)};
`;

const StyledTitle = styled.span`
  font-size: ${pxtoRem(40)};
`;

const StyledTimeContainer = styled.div`
  margin-left: ${pxtoRem(20)};
  color: ${(props) => props.theme.colors.DARK_GREY};
`;

const StyledTime = styled.div`
  font-size: ${pxtoRem(22)};
  text-align: start;
`;

const StyledCountry = styled.div`
  font-size: ${pxtoRem(18)};
  text-align: start;
`;

const StyledCity = styled.div`
  font-size: ${pxtoRem(14)};
  text-align: start;
`;

const StyledSup = styled.sup`
  vertical-align: top;
  position: relative;
  top: ${pxtoRem(22)};
`;

function Dashboard() {
  const { data: greetings, error, isFetching } = useGreetings();
  let date = moment().format("Do");
  useEffect(() => {
    if (error) {
      toast.error(error?.response?.data?.message);
    }
  }, [error]);

  return (
    <>
      <Spinner isLoading={isFetching} />
      <PageHeader
        title={
          <StyledTitle>
            {greetings?.greeting} {greetings?.name}
          </StyledTitle>
        }
        control={
          <div className="d-flex justify-content-end">
            <StyledDateContainer>
              <StyledTitle>
                {date.slice(0, date.length - 2)}
                <StyledSup>
                  {date.slice(date.length - 2, date.length)}
                </StyledSup>
              </StyledTitle>
              &nbsp;{moment().format("MMMM")}
            </StyledDateContainer>
            <StyledTimeContainer>
              <StyledTime> {moment().format("hh:mm A")}</StyledTime>
              <StyledCountry>Sri Lanka</StyledCountry>
              <StyledCity>Matara</StyledCity>
            </StyledTimeContainer>
          </div>
        }
      ></PageHeader>
    </>
  );
}

export default Dashboard;
