import React from "react";
import { Spinner } from "../shared/Spinner";
import PageHeader from "../shared/PageHeader";
import { Col, Row } from "react-bootstrap";
import ContainerComponent from "../shared/ContainerComponent";
import { Controller, useFormContext } from "react-hook-form";

import {
  useCountries,
  useExamBoards,
  useSubjects,
  useYears,
} from "../../hooks/chapter";
import MultipleDropdown from "../shared/MultipleDropdown";
import Dropdown from "../shared/Dropdown";
import FormControl from "../shared/FormControl";
import TextInput from "../shared/TextInput";
import { theme } from "../../lib/themeConstants";
import { useNavigate } from "react-router-dom";
import Button from "../shared/Button";
import { pxtoRem } from "../../lib/helper";
import { useState } from "react";
import styled from "styled-components";
import FileUpload from "../shared/FileUpload";

const StyledHeadingRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${pxtoRem(30)};
`;
const StyledHeadingCol = styled(Col)`
  display: flex;
  justify-content: end;
`;
const StyledDropdownCol = styled.div`
  margin: 0px;
  padding: 0px;
  width: ${pxtoRem(250)};
`;

const StyledRow = styled(Row)`
  margin-top: ${pxtoRem(50)};
  margin-bottom: ${pxtoRem(50)};
`;

const CurriculumForm = () => {
  const navigate = useNavigate();
  const [content, setContent] = useState(2);
  const { register, control, errors, setValue } = useFormContext();
  const { data: lstCountries } = useCountries();
  const { data: lstGrades } = useYears();
  const { data: lstExamBoards } = useExamBoards();
  const { data: lstSubjects } = useSubjects();
  const contentData = [
    { value: 1, label: "Chapter" },
    { value: 2, label: "Unit" },
  ];

  return (
    <>
      <Spinner isLoading={false} />

      <StyledHeadingRow>
        <StyledDropdownCol>
          <Dropdown
            options={contentData}
            value={content}
            onChange={(data) => {
              setContent(data);
            }}
            placeholder="Unit"
            className="m-2 auto-complete"
            borderColor={theme.DropDownColors.NEBULA_BLUE}
          />
        </StyledDropdownCol>
        <StyledHeadingCol xs={3}>
          <Button
            text={"Back"}
            bgColor={theme.colors.NEBULA_BLUE}
            onClick={() => {
              navigate("/curriculum");
            }}
            style={{
              height: pxtoRem(50),
              width: pxtoRem(154),
              borderRadius: pxtoRem(4),
            }}
          />
        </StyledHeadingCol>
      </StyledHeadingRow>

      {content === 1 ? (
        <ContainerComponent>
          <StyledRow>
            <Col>
              <FormControl
                isInline={false}
                label="Select country *"
                errMessage={errors?.country_id?.message}
                labelColClass="col-3 activity-form"
              >
                <Controller
                  isClearable
                  name="country_id"
                  control={control}
                  rules={{ required: "Please select a country" }}
                  render={({ field: { ref, ...rest } }) => (
                    <Dropdown
                      autoFocus
                      placeholder="Select country"
                      {...rest}
                      options={
                        lstCountries?.map((item) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        }) || []
                      }
                    />
                  )}
                />
              </FormControl>
            </Col>
            <Col>
              <FormControl
                isInline={false}
                label="Select exam board *"
                errMessage={errors?.exam_board_id?.message}
                labelColClass="col-3 activity-form"
              >
                <Controller
                  isClearable
                  name="exam_board_id"
                  control={control}
                  rules={{ required: "Please Select exam board" }}
                  render={({ field: { ref, ...rest } }) => (
                    <Dropdown
                      placeholder="Select exam board"
                      {...rest}
                      options={
                        lstExamBoards?.map((item) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        }) || []
                      }
                    />
                  )}
                />
              </FormControl>
            </Col>
            <Col>
              <FormControl
                isInline={false}
                label="Select level *"
                errMessage={errors?.level_id?.message}
                labelColClass="col-3 activity-form"
              >
                <Controller
                  isClearable
                  name="level_id"
                  rules={{ required: "Please Select level" }}
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <Dropdown
                      placeholder="Select level"
                      {...rest}
                      options={
                        lstGrades?.map((item) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        }) || []
                      }
                    />
                  )}
                />
              </FormControl>
            </Col>
            <Col>
              <FormControl
                isInline={false}
                label="Select grade *"
                errMessage={errors?.year_id?.message}
                labelColClass="col-3 activity-form"
              >
                <Controller
                  isClearable
                  name="year_id"
                  rules={{ required: "Please Select grade" }}
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <MultipleDropdown
                      placeholder="Select grade"
                      {...rest}
                      options={
                        lstGrades?.map((item) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        }) || []
                      }
                    />
                  )}
                />
              </FormControl>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col xs={3}>
              <FormControl
                isInline={false}
                label="Select subject *"
                errMessage={errors?.subject_id?.message}
                labelColClass="col-3 activity-form"
              >
                <Controller
                  isClearable
                  name="subject_id"
                  rules={{ required: "Please Select subject" }}
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <Dropdown
                      placeholder="Select subject"
                      {...rest}
                      options={
                        lstSubjects?.map((item) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        }) || []
                      }
                    />
                  )}
                />
              </FormControl>
            </Col>
            <Col xs={6}>
              <FormControl
                isInline={false}
                label="Enter Unit name"
                errMessage={errors?.unit_name?.message}
                labelColClass="col-3 activity-form"
              >
                <TextInput
                  id="unit_name"
                  placeholder="Unit name"
                  register={register}
                  validation={{ required: "Please enter unit name" }}
                />
              </FormControl>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <FormControl
                isInline={false}
                label="Enter Chapter no & name"
                errMessage={errors?.subject_id?.message}
                labelColClass="col-3 activity-form"
              >
                <Controller
                  isClearable
                  name="subject_id"
                  rules={{ required: "Please Select subject" }}
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <Dropdown
                      placeholder="Select subject"
                      {...rest}
                      options={
                        lstSubjects?.map((item) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        }) || []
                      }
                    />
                  )}
                />
              </FormControl>
            </Col>
            <Col>
              <FormControl
                label="Lesson plan"
                errMessage={errors?.instruction?.message}
                isInline={false}
              >
                <TextInput
                  style={{ display: "none" }}
                  id="instructionFile"
                  register={register}
                  validation={{ required: "Please select instruction" }}
                />
                <FileUpload
                  isForm={true}
                  iscustom={true}
                  file={null}
                  accept=".JPG,.JPEG,.PNG"
                  id="instruction"
                  register={register}
                  handleChange={(e) => {
                    setValue("instructionFile", e?.target?.files?.[0]?.name, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                  }}
                />
              </FormControl>
            </Col>
          </StyledRow>

          <Row style={{ marginTop: "200px" }}>
            <Col style={{ textAlign: "end" }}>
              <Button
                text={"Save"}
                bgColor={theme.colors.NEBULA_BLUE}
                onClick={() => {}}
                style={{
                  height: pxtoRem(40),
                  width: pxtoRem(108),
                  borderRadius: pxtoRem(10),
                }}
              />
            </Col>
          </Row>
        </ContainerComponent>
      ) : (
        <ContainerComponent>
          <StyledRow>
            <Col>
              <FormControl
                isInline={false}
                label="Select country *"
                errMessage={errors?.country_id?.message}
                labelColClass="col-3 activity-form"
              >
                <Controller
                  isClearable
                  name="country_id"
                  control={control}
                  rules={{ required: "Please select a country" }}
                  render={({ field: { ref, ...rest } }) => (
                    <Dropdown
                      autoFocus
                      placeholder="Select country"
                      {...rest}
                      options={
                        lstCountries?.map((item) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        }) || []
                      }
                    />
                  )}
                />
              </FormControl>
            </Col>
            <Col>
              <FormControl
                isInline={false}
                label="Select exam board *"
                errMessage={errors?.exam_board_id?.message}
                labelColClass="col-3 activity-form"
              >
                <Controller
                  isClearable
                  name="exam_board_id"
                  control={control}
                  rules={{ required: "Please Select exam board" }}
                  render={({ field: { ref, ...rest } }) => (
                    <Dropdown
                      placeholder="Select exam board"
                      {...rest}
                      options={
                        lstExamBoards?.map((item) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        }) || []
                      }
                    />
                  )}
                />
              </FormControl>
            </Col>
            <Col>
              <FormControl
                isInline={false}
                label="Select level *"
                errMessage={errors?.level_id?.message}
                labelColClass="col-3 activity-form"
              >
                <Controller
                  isClearable
                  name="level_id"
                  rules={{ required: "Please Select level" }}
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <Dropdown
                      placeholder="Select level"
                      {...rest}
                      options={
                        lstGrades?.map((item) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        }) || []
                      }
                    />
                  )}
                />
              </FormControl>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col xs={4}>
              <FormControl
                isInline={false}
                label="Select grade *"
                errMessage={errors?.year_id?.message}
                labelColClass="col-3 activity-form"
              >
                <Controller
                  isClearable
                  name="year_id"
                  rules={{ required: "Please Select grade" }}
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <MultipleDropdown
                      placeholder="Select grade"
                      {...rest}
                      options={
                        lstGrades?.map((item) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        }) || []
                      }
                    />
                  )}
                />
              </FormControl>
            </Col>
            <Col xs={4}>
              <FormControl
                isInline={false}
                label="Select subject *"
                errMessage={errors?.subject_id?.message}
                labelColClass="col-3 activity-form"
              >
                <Controller
                  isClearable
                  name="subject_id"
                  rules={{ required: "Please Select subject" }}
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <Dropdown
                      placeholder="Select subject"
                      {...rest}
                      options={
                        lstSubjects?.map((item) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        }) || []
                      }
                    />
                  )}
                />
              </FormControl>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <FormControl
                isInline={false}
                label="Select Chapter no & name"
                errMessage={errors?.subject_id?.message}
                labelColClass="col-3 activity-form"
              >
                <Controller
                  isClearable
                  name="subject_id"
                  rules={{ required: "Please Select subject" }}
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <Dropdown
                      placeholder="Select subject"
                      {...rest}
                      options={
                        lstSubjects?.map((item) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        }) || []
                      }
                    />
                  )}
                />
              </FormControl>
            </Col>
            <Col>
              <FormControl
                label="Lesson plan"
                errMessage={errors?.instruction?.message}
                isInline={false}
              >
                <TextInput
                  style={{ display: "none" }}
                  id="instructionFile"
                  register={register}
                  validation={{ required: "Please select instruction" }}
                />
                <FileUpload
                  isForm={true}
                  iscustom={true}
                  file={null}
                  accept=".JPG,.JPEG,.PNG"
                  id="instruction"
                  register={register}
                  handleChange={(e) => {
                    setValue("instructionFile", e?.target?.files?.[0]?.name, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                  }}
                />
              </FormControl>
            </Col>
          </StyledRow>

          <Row style={{ marginTop: "200px" }}>
            <Col style={{ textAlign: "end" }}>
              <Button
                text={"Save"}
                bgColor={theme.colors.NEBULA_BLUE}
                onClick={() => {}}
                style={{
                  height: pxtoRem(40),
                  width: pxtoRem(108),
                  borderRadius: pxtoRem(10),
                }}
              />
            </Col>
          </Row>
        </ContainerComponent>
      )}
    </>
  );
};

export default CurriculumForm;
