import React, { useEffect, useState } from "react";
import PageHeader from "../../components/shared/PageHeader";
import { theme } from "../../lib/themeConstants";
import Button from "../../components/shared/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ContainerComponent from "../../components/shared/ContainerComponent";
import { Constant } from "../../lib/Constant";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { pxtoRem } from "../../lib/helper";
import Table from "../../components/shared/Table";
import { useLessons } from "../../hooks/lesson";
import { useDispatch, useSelector } from "react-redux";
import Empty from "../../components/shared/Empty";
import { toast } from "react-toastify";
import { setPageChange } from "../../redux-toolkit/table";
import ViewLessonData from "../../components/shared/popups/ViewLessonData";
import { Spinner } from "../../components/shared/Spinner";
import useChapter from "../../hooks/chapter/useChapter";

const StyledIconContainer = styled.div`
  height: ${pxtoRem(52)};
  width: ${pxtoRem(52)};
  border-radius: 50%;
  background-color: ${(props) =>
    props.isFilled
      ? props.theme.colors.MALACHITE
      : props.theme.colors.METALIC_SILVER};
  display: flex;
  justify-content: center;
  margin: auto;
  padding-top: ${(props) => pxtoRem(props.paddingTop)};
  padding-left: ${(props) => props.paddingLeft && pxtoRem(props.paddingLeft)};
  cursor: pointer;
  cursor: ${(props) => (props.isFilled ? "cursor" : "auto")};
`;

const StyledIcon = styled.img`
  height: ${(props) => pxtoRem(props.height)};
  width: ${(props) => pxtoRem(props.width)};
`;

const StyledButton = styled(Button)`
  height: ${pxtoRem(46)};
  width: ${pxtoRem(67)};
  font-size: ${pxtoRem(20)};
  padding: 0;
`;

function Lessons() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentPage = useSelector((state) => {
    return state.table.page;
  });
  let { data: chapter, isFetching: chapterFetching } = useChapter(
    params?.chapterId
  );
  const {
    data: lessons,
    error,
    isFetching,
  } = useLessons(params?.chapterId, currentPage);
  const [showDataModal, setShowDataModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [modalHeading, setModalHeading] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (error) {
      toast.error(error?.response?.data?.message);
    }
    return () => {
      dispatch(setPageChange(1));
    };
  }, [error, dispatch]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Number",
        accessor: "number",
        className: "text-center align-middle pl-2",
        hedaderClassName: "text-start align-middle pl-2",
        customWidth: 5,
      },
      {
        Header: "Lesson Name",
        accessor: "name",
      },
      {
        Header: "Des",
        accessor: "description",
        customWidth: 7,
        Cell: ({ row }) => (
          <StyledIconContainer
            paddingTop={12}
            isFilled={row.original.description ? true : false}
            onClick={() => {
              if (row.original.description) {
                setModalHeading("Description");
                setModalData(row.original.description);
                setShowDataModal(true);
              }
            }}
          >
            <StyledIcon
              src={Constant.Icons.description}
              height={28}
              width={23}
            />
          </StyledIconContainer>
        ),
      },
      {
        Header: "Video",
        accessor: "video_url",
        customWidth: 7,
        Cell: ({ row }) => (
          <StyledIconContainer
            paddingTop={16}
            isFilled={row.original.video_url ? true : false}
            onClick={() => {
              if (row.original.video_url) {
                setModalHeading("Video");
                setModalData(row.original.video_url);
                setShowDataModal(true);
              }
            }}
          >
            <StyledIcon
              src={Constant.Icons.videoPlayer}
              height={20}
              width={29}
            />
          </StyledIconContainer>
        ),
      },
      {
        Header: "Ppt",
        accessor: "is_ppt",
        customWidth: 7,
        Cell: ({ row }) => (
          <StyledIconContainer
            paddingTop={12}
            isFilled={row.original.presentation ? true : false}
            onClick={() => {
              if (row.original.presentation) {
                setModalHeading("PPT");
                setModalData(row.original.presentation);
                setShowDataModal(true);
              }
            }}
          >
            <StyledIcon src={Constant.Icons.ppt} height={28} width={25} />
          </StyledIconContainer>
        ),
      },
      {
        Header: "Notes",
        accessor: "is_notes",
        customWidth: 7,
        Cell: ({ row }) => (
          <StyledIconContainer
            paddingTop={12}
            isFilled={row.original.short_notes ? true : false}
            onClick={() => {
              if (row.original.short_notes) {
                setModalHeading("Notes");
                setModalData(row.original.short_notes);
                setShowDataModal(true);
              }
            }}
          >
            <StyledIcon src={Constant.Icons.notes} height={28} width={23} />
          </StyledIconContainer>
        ),
      },
      {
        Header: "Glossary",
        accessor: "is_glossary",
        customWidth: 7,
        Cell: ({ row }) => (
          <StyledIconContainer
            paddingTop={12}
            paddingLeft={10}
            isFilled={row.original.glossary?.length > 0 ? true : false}
            onClick={() => {
              if (row.original.glossary?.length > 0) {
                setModalHeading("Glossary");
                setModalData(row.original.glossary);
                setShowDataModal(true);
              }
            }}
          >
            <StyledIcon src={Constant.Icons.glossary} height={29} width={29} />
          </StyledIconContainer>
        ),
      },

      {
        Header: "Action",
        className: "text-end align-middle pr-1",
        headerClassName: "text-end align-middle pr-1 pb-3",
        Cell: ({ row }) => (
          <StyledButton
            text="View"
            onClick={() =>
              navigate(`${row.original.id}/show`, {
                state: { chapter: location?.state?.chapter },
              })
            }
            bgColor={theme.colors.NEBULA_BLUE}
          />
        ),
      },
    ],
    // eslint-disable-next-line
    []
  );
  return (
    <>
      <Spinner isLoading={isFetching || chapterFetching} />
      <PageHeader
        title={`Chapter ${chapter && chapter[0]?.number} > Lesson`}
        control={
          <Button
            text="Back"
            bgColor={theme.colors.NEBULA_BLUE}
            onClick={() => navigate(`/chapters`)}
          />
        }
      ></PageHeader>
      <ContainerComponent>
        <Row>
          <Col>
            <div className="d-flex w-100 justify-content-end mb-3">
              <Button
                text="Create Lesson"
                icon={Constant.Icons.add_circle_outline_white}
                bgColor={theme.colors.NEBULA_BLUE}
                onClick={() =>
                  navigate(`create`, {
                    state: {
                      chapter: {
                        ...location.state.chapter,
                        lesson_count: lessons?.data?.total,
                      },
                    },
                  })
                }
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {lessons?.data?.data?.length > 0 ? (
              <Table
                data={lessons?.data?.data}
                columns={columns}
                defaultPageSize={lessons?.data?.per_page}
                totalPages={lessons?.data?.last_page}
                currentPage={lessons?.data?.current_page}
              ></Table>
            ) : (
              !isFetching && (
                <Empty text={Constant.tableErrorMessages.lessons} />
              )
            )}
          </Col>
          <ViewLessonData
            show={showDataModal}
            onCancel={setShowDataModal}
            data={modalData}
            heading={modalHeading}
            content={modalData}
          ></ViewLessonData>
        </Row>
      </ContainerComponent>
    </>
  );
}

export default Lessons;
