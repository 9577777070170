import { useMutation } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useDeleteQuestion = () => {
  return useMutation((data) => {
    return axiosPost("/api/cw/deletePastPaper", data);
  });
};

export default useDeleteQuestion;
