import { useQuery } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useViewSelftest = (lesson_id) => {
  const formData = {};
  if (lesson_id) formData.lesson_id = lesson_id;

  return useQuery(
    ["viewSelfTest", lesson_id],
    async () => {
      const { data } = await axiosPost("/api/cw/viewSelfTest", formData);
      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

export default useViewSelftest;
