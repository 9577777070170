import React from "react";
import { Breadcrumb as BBreadcrumb } from "react-bootstrap";
import styled from "styled-components";
import { pxtoRem } from "../../lib/helper";

const StyledBreadcrumbLink = styled.li`
  margin-bottom: ${pxtoRem(36)};
  &:not(:first-child):before {
    content: ">" !important;
    font-size: ${pxtoRem(24)};
    font-weight: medium;
    color: ${(props) => props.theme.NOTIFICATIONLIST.DARK_BLACK};
  }

  a {
    cursor: context-menu !important;
    font-size: ${pxtoRem(24)};
    font-weight: bold;
    color: ${(props) => props.theme.NOTIFICATIONLIST.DARK_BLACK};
    text-decoration: none;
  }
`;

function Breadcrumb({ data }) {
  return (
    <>
      <BBreadcrumb>
        {data?.map((item, index) => (
          <StyledBreadcrumbLink className="breadcrumb-item" key={index}>
            {item}
          </StyledBreadcrumbLink>
        ))}
      </BBreadcrumb>
    </>
  );
}

export default Breadcrumb;
