import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import Button from "../components/shared/Button";
import SaQuestionNumbers from "../components/shared/view/SaQuestionNumbers";
import Solution from "../components/shared/view/Solution";
import ViewMcq from "../components/shared/view/ViewMcq";
import ViewPair from "../components/shared/view/ViewPair";
import ViewQuestionNumbers from "../components/shared/view/ViewQuestionNumbers";
import ViewSa from "../components/shared/view/ViewSa";
import { Constant, Qtype } from "../lib/Constant";
import { pxtoRem } from "../lib/helper";
import { theme } from "../lib/themeConstants";
import { useDispatch } from "react-redux";
import history from "../lib/history";
import SASolution from "../components/shared/view/SASolution";
import {
  ViewLayoutCloseBox,
  ViewLayoutCommonBox,
  ViewLayoutContentRow,
  ViewLayoutEditBox,
  ViewLayoutHeaderRow,
} from "../Style";
import {
  resetQuestions,
  setCurrentQuestionIndex,
  setCurrentSubQuestionIndex,
} from "../redux-toolkit/questions";
import ViewOneWord from "../components/shared/view/ViewOneWord";
import OneWordSolution from "../components/shared/view/OneWordSolution";

const StyledWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.SHADE_ALICE_BLUE};
  padding: ${pxtoRem(60)} ${pxtoRem(120)};
  height: 100vh;
`;

const StyledPaginationCol = styled(Col)`
  display: flex;
  justify-content: center;
`;

const StyledTimer = styled(ViewLayoutCommonBox)`
  height: ${pxtoRem(41)};
  width: ${pxtoRem(87)};
  border-radius: 21px;
`;

const StyleButtonCol = styled(Col)`
  display: flex;
  flex-direction: row;
  text-align: end;
  justify-content: flex-end;
`;

const StyledButtons = styled(Col)`
  margin-top: ${pxtoRem(30)};
  text-align: center;
`;

const StyledButton = styled(Button)`
  margin-right: ${pxtoRem(40)};
  &:disabled {
    background-color: ${(props) => props.theme.colors.MEDIUM_LIGHT_SHADE_CYAN};
    border: 1px solid ${(props) => props.theme.colors.MEDIUM_LIGHT_SHADE_CYAN};
  }

  :not(.btn-check) + .btn:active {
    background-color: ${(props) =>
      props.theme.colors.LIGHT_SHADE_BLUE}!important;
    border-color: ${(props) => props.theme.colors.LIGHT_SHADE_BLUE}!important;
    color: ${(props) => props.theme.colors.WHITE};
  }
`;

const StyledViewButton = styled(StyledButton)`
  :not(.btn-check) + .btn:active {
    background-color: ${(props) =>
      props.viewsolution === "true"
        ? props.theme.colors.LIGHT_SHADE_BLUE
        : props.theme.colors.LIGHT_SHADE_GREY}!important;
    border-color: ${(props) =>
      props.viewsolution === "true"
        ? props.theme.colors.LIGHT_SHADE_BLUE
        : props.theme.colors.LIGHT_SHADE_GREY}!important;
    color: ${(props) =>
      props.viewsolution === "true"
        ? props.theme.colors.WHITE
        : props.theme.colors.BLACK};
  }
`;

const ViewDemo = ({ list, type, handleEdit, onClose, defaultValues }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [viewSolution, setViewSolution] = useState(false);
  const [activeSubIndex, setActiveSubIndex] = useState(null);
  const [activeItem, setActiveItem] = useState(list?.[0]);
  const [startEndIndex, setStartEndIndex] = useState({ start: 0, end: 9 });
  const [startEndSubQuestionIndex, setStartEndSubQuestionIndex] = useState({
    start: 0,
    end: 9,
  });
  const dispatch = useDispatch();

  const handlePrevNext = (flag = "prev") => {
    const newIndex = flag === "prev" ? activeIndex - 1 : activeIndex + 1;
    setActiveIndex(newIndex);
    setActiveItem(list[newIndex]);
    if (flag === "prev") {
      if (activeIndex - 1 < startEndIndex?.start) {
        setStartEndIndex({
          start: activeIndex - 1,
          end: startEndIndex?.end - 1,
        });
      }
    } else {
      if (activeIndex + 1 > startEndIndex?.end) {
        setStartEndIndex({
          start: startEndIndex?.start + 1,
          end: activeIndex + 1,
        });
      }
    }
  };

  useEffect(() => {
    if (list?.length > 0) {
      if (list?.length - 1 < activeIndex) {
        handlePrevNext("prev");
      } else {
        setActiveItem(list[activeIndex]);
      }
    }

    if (list?.length > 0 && list[activeIndex]?.children?.length > 0) {
      if (list[activeIndex].children?.length <= activeSubIndex) {
        setActiveSubIndex(activeSubIndex - 1);
        if (list[activeIndex].children?.length > 10) {
          setStartEndSubQuestionIndex({
            start: startEndSubQuestionIndex?.end - 10,
            end: startEndSubQuestionIndex?.end - 1,
          });
        } else {
          // setStartEndSubQuestionIndex({ start: 0, end: 9 });
        }
      } else if (
        list[activeIndex].children?.length > 10 &&
        activeSubIndex > 9
      ) {
        !list[activeIndex].children[activeSubIndex] &&
          setActiveSubIndex(activeSubIndex - 1);
        setStartEndSubQuestionIndex({
          start: activeSubIndex - 9,
          end: activeSubIndex,
        });
      } else {
        setStartEndSubQuestionIndex({ start: 0, end: 9 });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    setViewSolution(false);
  }, [activeIndex, activeSubIndex]);

  const renderContentView = () => {
    switch (type) {
      case Qtype.mcq:
        return (
          <ViewMcq
            key={activeItem?.id}
            activeIndex={activeIndex}
            activeItem={activeItem}
          />
        );
      case Qtype.matching_pair:
        return <ViewPair activeItem={activeItem} />;
      case Qtype.structured_question:
        return (
          <ViewSa
            activeItem={activeItem}
            activeSubIndex={activeSubIndex}
            viewSolution={viewSolution}
            setViewSolution={setViewSolution}
            type={type}
          />
        );
      case Qtype.one_word:
        return (
          <ViewOneWord
            viewSolution={viewSolution}
            setViewSolution={setViewSolution}
            activeItem={activeItem}
            activeIndex={activeIndex}
          />
        );
      default:
        return <div> no view available </div>;
    }
  };

  const FooterButtons = ({
    onView,
    onNext,
    onPrev,
    showViewSolutionBtn = true,
    disablePrevBtn = false,
    disableNextBtn = false,
    onClose,
  }) => {
    const solutionText =
      type === Qtype.structured_question || type === Qtype.one_word
        ? viewSolution
          ? "Close Auto check"
          : "Auto check"
        : viewSolution
        ? "Close solution"
        : "View solution";

    return (
      <StyledButtons>
        <StyledButton
          disabled={disablePrevBtn}
          bgColor={theme.colors.LIGHT_SHADE_BLUE}
          text="Previous"
          onClick={onPrev}
        />
        {showViewSolutionBtn && (
          <StyledViewButton
            bgColor={
              viewSolution
                ? theme.colors.LIGHT_SHADE_BLUE
                : theme.colors.LIGHT_SHADE_GREY
            }
            color={viewSolution ? theme.colors.WHITE : theme.colors.BLACK}
            text={solutionText}
            onClick={onView}
            viewsolution={viewSolution.toString()}
          />
        )}

        <StyledButton
          // disabled={list?.length - 1 === activeIndex}
          disabled={disableNextBtn}
          onClick={onNext}
          bgColor={theme.colors.LIGHT_SHADE_BLUE}
          text="Next"
        />
      </StyledButtons>
    );
  };

  const onChangeLeft = () => {
    if (startEndIndex?.start > 0) {
      setStartEndIndex({
        start: startEndIndex?.start - 1,
        end: startEndIndex?.end - 1,
      });
    }
  };

  const onChangeRight = () => {
    if (
      startEndIndex?.end < list?.length &&
      startEndIndex?.end + 1 !== list?.length
    ) {
      setStartEndIndex({
        start: startEndIndex?.start + 1,
        end: startEndIndex?.end + 1,
      });
    }
  };

  const onSubChangeLeft = () => {
    if (startEndSubQuestionIndex?.start > 0) {
      setStartEndSubQuestionIndex({
        start: startEndSubQuestionIndex?.start - 1,
        end: startEndSubQuestionIndex?.end - 1,
      });
    }
  };

  const onSubChangeRight = () => {
    if (
      startEndSubQuestionIndex?.end < list[activeIndex]?.children?.length &&
      startEndSubQuestionIndex?.end + 1 !== list[activeIndex]?.children?.length
    ) {
      setStartEndSubQuestionIndex({
        start: startEndSubQuestionIndex?.start + 1,
        end: startEndSubQuestionIndex?.end + 1,
      });
    }
  };

  const renderPagination = () => {
    switch (type) {
      case Qtype.structured_question:
        return (
          <SaQuestionNumbers
            activeIndex={activeIndex}
            activeSubIndex={activeSubIndex}
            list={list}
            onSubQuesClick={(subIndex) => {
              setActiveSubIndex(subIndex);
            }}
            onPageClick={(index) => {
              setActiveIndex(index);
              setActiveSubIndex(null);
              setActiveItem(list[index]);
            }}
            startEndIndex={startEndIndex}
            startEndSubQuestionIndex={startEndSubQuestionIndex}
            onChangeLeft={onChangeLeft}
            onChangeRight={onChangeRight}
            onSubChangeLeft={onSubChangeLeft}
            onSubChangeRight={onSubChangeRight}
          />
        );

      default:
        return (
          <ViewQuestionNumbers
            activeIndex={activeIndex}
            list={list}
            onPageClick={(index) => {
              setActiveIndex(index);
              setActiveItem(list[index]);
            }}
            startEndIndex={startEndIndex}
            onChangeLeft={onChangeLeft}
            onChangeRight={onChangeRight}
          />
        );
    }
  };

  if (list?.length < 1) {
    return (
      <StyledWrapper className="text-center">
        <h3>No records found</h3>
      </StyledWrapper>
    );
  }

  return (
    <StyledWrapper>
      <ViewLayoutHeaderRow>
        <Col xs={1}>
          <StyledTimer>
            {defaultValues?.duration_hours}:{defaultValues?.duration_minutes}
          </StyledTimer>
        </Col>
        <StyledPaginationCol xs={9}>{renderPagination()}</StyledPaginationCol>
        <StyleButtonCol xs={2}>
          <ViewLayoutEditBox
            onClick={() => {
              dispatch(resetQuestions());
              dispatch(setCurrentQuestionIndex(activeIndex));
              if (activeSubIndex !== null) {
                handleEdit && handleEdit(true);
                dispatch(setCurrentSubQuestionIndex(activeSubIndex));
              } else {
                handleEdit && handleEdit(false);
              }
            }}
          >
            <img height={20} src={Constant.Icons.edit} alt="edit" />
          </ViewLayoutEditBox>
          <ViewLayoutCloseBox
            onClick={() => {
              onClose ? onClose() : history.back();
            }}
          >
            Close
          </ViewLayoutCloseBox>
        </StyleButtonCol>
      </ViewLayoutHeaderRow>
      <ViewLayoutContentRow>
        <Col>
          {renderContentView()}

          {type === Qtype.structured_question ? (
            !viewSolution && (
              <FooterButtons
                showViewSolutionBtn={activeSubIndex !== null}
                disablePrevBtn={activeIndex === 0 && activeSubIndex === null}
                disableNextBtn={
                  list?.length - 1 === activeIndex &&
                  ((activeItem?.children?.length || 0) === 0 ||
                    activeItem?.children.length - 1 === activeSubIndex)
                }
                onNext={() => {
                  // check if current question has sub questions, next will apply to sub questions
                  if (activeItem?.children?.length > 0) {
                    // if has not visited any sub questions it will default next to first sub question
                    if (activeSubIndex === null) {
                      setActiveSubIndex(0);
                      setStartEndSubQuestionIndex({ start: 0, end: 9 });
                      // if user is on last sub question, then next to new main question
                    } else if (
                      activeItem?.children?.length - 1 ===
                      activeSubIndex
                    ) {
                      setStartEndSubQuestionIndex({ start: 0, end: 9 });
                      setActiveSubIndex(null);
                      setActiveIndex(activeIndex + 1);
                      setActiveItem(list[activeIndex + 1]);
                    } else {
                      // move to next sub question
                      setActiveSubIndex(activeSubIndex + 1);
                      if (activeSubIndex + 1 > startEndSubQuestionIndex?.end) {
                        setStartEndSubQuestionIndex({
                          start: startEndSubQuestionIndex?.start + 1,
                          end: activeSubIndex + 1,
                        });
                      }
                    }
                  } else {
                    // if current question does not have any sub questions and click on next
                    setActiveIndex(activeIndex + 1);
                    setActiveItem(list[activeIndex + 1]);
                    if (activeIndex + 1 > startEndIndex?.end) {
                      setStartEndIndex({
                        start: startEndIndex?.start + 1,
                        end: activeIndex + 1,
                      });
                    }
                  }
                }}
                onView={() => {
                  setViewSolution(!viewSolution);
                }}
                onPrev={() => {
                  if (
                    activeItem?.children?.length > 0 &&
                    activeSubIndex !== null
                  ) {
                    if (activeSubIndex === null) {
                      setActiveSubIndex(activeItem?.children?.length - 1);
                      setStartEndSubQuestionIndex({
                        start: activeItem?.children?.length - 8,
                        end: activeItem?.children?.length - 1,
                      });
                    } else if (activeSubIndex !== 0) {
                      setActiveSubIndex(activeSubIndex - 1);
                      if (
                        activeSubIndex - 1 <
                        startEndSubQuestionIndex?.start
                      ) {
                        setStartEndSubQuestionIndex({
                          start: activeSubIndex - 1,
                          end: startEndSubQuestionIndex?.end - 1,
                        });
                      }
                    } else {
                      setActiveSubIndex(null);
                      setStartEndSubQuestionIndex({ start: 0, end: 9 });
                    }
                  } else {
                    const tempItem = list[activeIndex - 1];
                    setActiveIndex(activeIndex - 1);
                    setActiveItem(tempItem);
                    if (activeIndex - 1 < startEndIndex?.start) {
                      setStartEndIndex({
                        start: activeIndex - 1,
                        end: startEndIndex?.end - 1,
                      });
                    }
                    if (tempItem?.children?.length > 0) {
                      setActiveSubIndex(tempItem?.children?.length - 1);
                      setStartEndSubQuestionIndex({
                        start: tempItem?.children?.length - 8,
                        end: tempItem?.children?.length - 1,
                      });
                    }
                  }
                }}
              />
            )
          ) : type === Qtype.one_word ? (
            !viewSolution && (
              <FooterButtons
                disablePrevBtn={activeIndex === 0}
                disableNextBtn={list.length - 1 === activeIndex}
                showPrevView={true}
                onNext={() => {
                  handlePrevNext("next");
                }}
                onView={() => {
                  setViewSolution(!viewSolution);
                }}
                onPrev={() => {
                  handlePrevNext("prev");
                }}
              />
            )
          ) : (
            <FooterButtons
              disablePrevBtn={activeIndex === 0}
              disableNextBtn={list.length - 1 === activeIndex}
              showPrevView={true}
              onNext={() => {
                handlePrevNext("next");
              }}
              onView={() => {
                setViewSolution(!viewSolution);
              }}
              onPrev={() => {
                handlePrevNext("prev");
              }}
            />
          )}
          {viewSolution &&
            (type === Qtype.structured_question ? (
              <>
                <SASolution
                  markingScheme={
                    activeItem?.children[activeSubIndex]?.marking_scheme
                  }
                  theory={activeItem?.children[activeSubIndex]?.knowledge}
                />
              </>
            ) : type === Qtype.one_word ? (
              <>
                <OneWordSolution
                  markingScheme={activeItem.marking_scheme}
                  theory={activeItem.knowledge}
                />
              </>
            ) : (
              <Solution
                leftContnet={activeItem?.solution}
                rightContent={activeItem?.knowledge}
              />
            ))}
        </Col>
      </ViewLayoutContentRow>
    </StyledWrapper>
  );
};

export default ViewDemo;
