import { useMemo, useState } from "react";
import { Badge, ListGroup, ListGroupItem } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Button from "../components/shared/Button";
import FileUpload from "../components/shared/FileUpload";
import FileUploadModal from "../components/shared/FileUploadModal";
import PageHeader from "../components/shared/PageHeader";
import SectionHeader from "../components/shared/SectionHeader";
import SideBar from "../components/shared/SideBar";
import Table from "../components/shared/Table";
import TextInput from "../components/shared/TextInput";
import Toggle from "../components/shared/Toggle";
import AlertPopUp from "../components/shared/popups/AlertPopUp";
import SuccessModal from "../components/shared/popups/SuccessModal";

const BharatComponents = () => {
  const [isAlert, setIsAlert] = useState();
  const [isSuccess, setIsSuccess] = useState(true);
  const [checked, setChecked] = useState(false);

  const list = [
    {
      firstName: "bharat",
      board: "GSCE",
      year: 2017,
      papertype: "paper1",
    },
    {
      firstName: "mahesh",
      board: "GSCE",
      year: 2017,
      papertype: "paper2",
    },
    {
      firstName: "mukesh",
      board: "GSCE",
      year: 2017,
      papertype: "paper3",
    },
    {
      firstName: "rohan",
      board: "GSCE",
      year: 2017,
      papertype: "paper4",
    },
    {
      firstName: "mayank",
      board: "GSCE",
      year: 2017,
      papertype: "paper5",
    },
    {
      firstName: "mayank",
      board: "GSCE",
      year: 2017,
      papertype: "paper1",
    },
    {
      firstName: "mayank",
      board: "GSCE",
      year: 2017,
      papertype: "paper6",
    },
    {
      firstName: "mayank",
      board: "GSCE",
      year: 2017,
      papertype: "paper7",
    },
    {
      firstName: "mayank",
      board: "GSCE",
      year: 2017,
      papertype: "paper8",
    },
    {
      firstName: "mayank",
      board: "GSCE",
      year: 2017,
      papertype: "paper9",
    },
    {
      firstName: "mayank",
      board: "GSCE",
      year: 2017,
      papertype: "paper10",
    },
    {
      firstName: "mayank",
      board: "GSCE",
      year: 2017,
      papertype: "paper11",
    },
    {
      firstName: "mayank",
      board: "GSCE",
      year: 2017,
      papertype: "paper12",
    },
    {
      firstName: "mayank",
      board: "GSCE",
      year: 2017,
      papertype: "paper13",
    },
    {
      firstName: "mayank",
      board: "GSCE",
      year: 2017,
      papertype: "paper14",
    },
  ];

  const columns = useMemo(
    () => [
      {
        Header: "Subject",
        accessor: "firstName",
        Cell: ({ cell }) => <Badge bg="primary">Phycics</Badge>,
      },
      {
        Header: "Exam board",
        accessor: "board",
      },
      {
        Header: "Year",
        accessor: "year",
      },
      {
        Header: "Paper Type",
        accessor: "papertype",
      },
      {
        Header: "Upload MS",
        accessor: "uploadms",
        Cell: ({ cell }) => <FileUploadModal />,
      },
      {
        Header: "Upload PP",
        accessor: "uploadpp",
        Cell: ({ cell }) => <FileUploadModal />,
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ cell }) => <Button text="View" />,
      },
      {
        Header: "",
        accessor: "ff",
        Cell: ({ cell }) => (
          <Toggle
            checked={checked}
            onChange={setChecked}
            offLable={"Draft"}
            onLable={"Publish"}
          ></Toggle>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {};
  return (
    <ListGroup>
      <ListGroupItem>
        <h2>1. Side bar</h2>
        <SideBar />
      </ListGroupItem>

      <ListGroupItem>
        <h2>2. Page header</h2>
        <PageHeader
          title="Activity Management"
          control={<Button icon="./logo192.png" text="Create new chepter" />}
        />
      </ListGroupItem>

      <ListGroupItem>
        <h2>3. Section header</h2>
        <SectionHeader title="Create new chepter" />
      </ListGroupItem>
      <ListGroupItem>
        <h2>4. Button</h2>
        <Button bgColor="gold" text="button with BG" />
      </ListGroupItem>
      <ListGroupItem>
        <Button bgColor="red" text="button with BG2" />
      </ListGroupItem>
      <ListGroupItem>
        <Button icon="./logo192.png" reverse text="with image" />
      </ListGroupItem>
      <ListGroupItem>
        <Button
          color="green"
          bgHover="yellow"
          variant="inverse"
          reverse
          text="inverse"
        />
      </ListGroupItem>
      <ListGroupItem>
        <Button
          icon="./logo192.png"
          color="green"
          bgHover="yellow"
          variant="inverse"
          text="inverse with image"
        />
      </ListGroupItem>
      <ListGroupItem>
        <h2>5. Alert Popup</h2>
        <Button
          text="Alert Popup"
          onClick={() => {
            setIsAlert(true);
          }}
        />
        <AlertPopUp
          onCancel={() => {
            setIsAlert(false);
          }}
          show={isAlert}
          header="Are you sure you want to delete the question details"
          body="If you will delete the question details, all sub questions also will be deleted"
        />
      </ListGroupItem>
      <ListGroupItem>
        <h2>6. Success Popup</h2>
        <Button
          text="Success Popup"
          onClick={() => {
            setIsSuccess(true);
          }}
        />
        <SuccessModal
          onCancel={() => {
            setIsSuccess(false);
          }}
          show={isSuccess}
          body="Chepter has been successfully created"
          button={<Button icon="./logo192.png" text="Create new lession" />}
        />
      </ListGroupItem>
      <ListGroupItem>
        <h2>7. Text input</h2>
        <TextInput placeholder="free text input" />
      </ListGroupItem>
      <ListGroupItem>
        <TextInput type="number" placeholder="enter number" />
      </ListGroupItem>
      <ListGroupItem>
        <h5>With validation</h5>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            id="userName"
            register={register}
            validation={{ required: "please enter user name" }}
            placeholder="user name"
          />

          {errors?.userName && errors?.userName?.message}
          <br />
          <TextInput
            id="password"
            register={register}
            validation={{ required: "please enter password" }}
            type="password"
            placeholder="password"
          />
          {errors?.password && errors?.password?.message}
          <br />
          <Button type="submit" text="Submit" />
        </form>
      </ListGroupItem>
      <ListGroupItem>
        <h2>8. File Upload</h2>
        <FileUpload />
      </ListGroupItem>
      <ListGroupItem>
        <h2>File Upload with icon</h2>
        <FileUploadModal />
      </ListGroupItem>

      <ListGroupItem>
        <h2>9. Table</h2>
        <Table columns={columns} data={list} />
      </ListGroupItem>
    </ListGroup>
  );
};

export default BharatComponents;
