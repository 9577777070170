import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { alphabets } from "../../../lib/Constant";
import { pxtoRem } from "../../../lib/helper";

const StyledQuestion = styled.div`
  text-align: left;
  font-size: ${pxtoRem(29)};
  color: ${(props) => props.theme.colors.GUNMENTAL};
  font-weight: bold;
  margin-bottom: ${pxtoRem(20)};
  width: ${pxtoRem(890)};
  width: ${(props) => (props.isquestion ? pxtoRem(80) : pxtoRem(890))};
  max-width: ${pxtoRem(890)};
  img {
    width: 100%;
  }
`;

const StyledQuestionContainer = styled(Col)`
  display: flex;
`;

const StyledOptionContainer = styled(Row)`
  display: flex;
  flex-direction: row;
  margin-top: ${pxtoRem(30)};
`;

const StyledOption = styled.div`
  height: ${pxtoRem(55)};
  width: ${pxtoRem(540)};
  box-shadow: 0px 12px 19px ${(props) => props.theme.colors.SHADOW_GREY};
  border-radius: 40px;
  display: flex;
  font-size: ${pxtoRem(20)};
  color: ${(props) => props.theme.colors.GREEN_CYAN_SHADE};
  align-items: center;
  padding: 0px ${pxtoRem(40)};
  margin-right: ${pxtoRem(40)};
  margin-bottom: ${pxtoRem(60)};
`;

const StyledMcqOption = styled.span`
  margin-right: ${pxtoRem(20)};
`;

const StyledContainer = styled.div`
  width: ${pxtoRem(1150)};
  margin: auto;
`;

const StyledImage = styled.img`
  width: ${pxtoRem(40)};
  height: ${pxtoRem(40)};
`;

const ViewMcq = ({ activeItem, activeIndex }) => {
  return (
    <Row>
      <Col>
        <StyledContainer>
          <StyledQuestionContainer>
            <StyledQuestion isquestion={true}>{`Q.${
              activeIndex + 1
            }`}</StyledQuestion>
            <StyledQuestion
              dangerouslySetInnerHTML={{ __html: activeItem.question }}
            />
          </StyledQuestionContainer>

          <StyledOptionContainer>
            {activeItem?.answers?.map((item, index) => {
              return (
                <StyledOption>
                  <StyledMcqOption>{`${alphabets[
                    index
                  ]?.toUpperCase()}.`}</StyledMcqOption>
                  {item?.type === "image" ? (
                    <StyledImage src={item?.answer} alt="answer" />
                  ) : (
                    item?.answer
                  )}
                </StyledOption>
              );
            })}
          </StyledOptionContainer>
        </StyledContainer>
      </Col>
    </Row>
  );
};

export default ViewMcq;
