import { useEffect } from "react";
import { Col, Form, Nav, Row, Tab } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/shared/Button";
import SectionHeader from "../../components/shared/SectionHeader";
import { Spinner } from "../../components/shared/Spinner";
import { Constant } from "../../lib/Constant";
import { pxtoRem } from "../../lib/helper";
import { theme } from "../../lib/themeConstants";
import Breadcrumb from "../shared/Breadcrumb";
import ContainerComponent from "../shared/ContainerComponent";
import Editor from "../shared/Editor";
import FileUpload from "../shared/FileUpload";
import FormControl from "../shared/FormControl";
import Glossary from "../shared/Glossary";
import { TabItem, TabList } from "../shared/TabList";
import TextInput from "../shared/TextInput";

const StyledLabel = styled(Form.Label)`
  margin-bottom: 0px;
  color: ${(props) => props.theme.colors.NAVY_BLUE};
`;

const StyledDeleteIcon = styled.img`
  cursor: pointer;
  width: ${pxtoRem(26)};
  height: ${pxtoRem(34)};
`;

const LessonForm = ({
  title,
  handleFormSubmit,
  isLoading,
  isFormReset = false,
  defaultValue = null,
  handleDelete,
  chapter,
}) => {
  const {
    control,
    setValue,
    reset,
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues: defaultValue,
    mode: "onChange",
  });
  const location = useLocation();
  const chapterId = location.pathname.split("/")[2];
  // const chapter = location?.state?.chapter;
  const isEdit = defaultValue?.id ? true : false;
  const navigate = useNavigate();

  const name = chapter?.name;
  const number = chapter?.number;
  const countryName = chapter?.country?.name;
  const subjectName = chapter?.subject?.name;
  const exam_boardName = chapter?.exam_board?.name;
  const gradeName =
    chapter?.years_data &&
    chapter?.years_data
      .map(function (item) {
        return item.name;
      })
      .join(",");

  const onSubmit = async (data) => {
    const formData = new FormData();
    const setField = (name) => {
      formData.set(name, data?.[name] ? data?.[name] : "");
    };
    formData.set("chapter_id", chapterId);
    formData.set("name", data.name);
    formData.set("number", data.number);

    setField("description");
    setField("video_url");
    setField("short_notes");
    if (typeof data?.presentation !== "string" && data.presentation?.[0]) {
      formData.set("presentation", data.presentation?.[0]);
    }

    if (
      typeof data?.short_notes_file !== "string" &&
      data.short_notes_file?.[0]
    ) {
      formData.append("short_notes_file", data.short_notes_file?.[0]);
    }

    data?.glossary?.forEach((item, index) => {
      formData.append(`glossary[${index}][id]`, index + 1);
      formData.append(`glossary[${index}][word]`, item.word);
      formData.append(`glossary[${index}][description]`, item.description);
      formData.append(`glossary[${index}][example]`, item.example);
    });

    handleFormSubmit(formData);
  };
  useEffect(() => {
    reset();
    setValue("number", defaultValue?.number);
  }, [defaultValue, reset, setValue]);

  if (isFormReset) {
    return <Spinner isLoading={isFormReset} />;
  }

  return (
    <>
      <Spinner isLoading={isLoading} />
      {!isEdit && (
        <div className="mb-2" style={{ textAlign: "end" }}>
          <Button
            onClick={() => {
              navigate(`/chapters/${chapterId}/lessons`, {
                state: { chapter: chapter },
              });
            }}
            bgColor={theme.colors.NEBULA_BLUE}
            text="Back"
          />
        </div>
      )}
      <SectionHeader
        title={title}
        control={
          isEdit && (
            <StyledDeleteIcon
              onClick={handleDelete}
              src={Constant.Icons.delete}
              alt="delete"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleDelete();
                }
              }}
            />
          )
        }
      />

      <ContainerComponent>
        <Breadcrumb
          data={[
            countryName,
            exam_boardName,
            subjectName,
            gradeName,
            `${number}. ${name}`,
          ]}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col className="col-4">
              <FormControl
                isInline={false}
                label="Lesson number"
                errMessage={errors?.number?.message}
              >
                <TextInput
                  autoFocus
                  id="number"
                  type="number"
                  step="any"
                  placeholder="Lesson number"
                  register={register}
                  validation={{
                    required: "Please enter lesson number",
                    min: {
                      value: 1,
                      message: "Lesson number can not be less than 1",
                    },
                  }}
                />
              </FormControl>
            </Col>

            <Col className="col-8">
              <FormControl
                isInline={false}
                label="Lesson name"
                errMessage={errors?.name?.message}
              >
                <TextInput
                  id="name"
                  placeholder="Lesson name"
                  register={register}
                  validation={{ required: "Please enter lesson name" }}
                />
              </FormControl>
            </Col>
          </Row>
          <Row className="description-container">
            <Col>
              <StyledLabel>Description</StyledLabel>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <Editor
                    id="description"
                    {...field}
                    editorState={field.value}
                    onEditorStateChange={(text) => {
                      field.onChange(text);
                    }}
                  />
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TabList
                defaultActiveKey="video_url-tab"
                tabItems={
                  <>
                    <TabItem className="me-5">
                      <Nav.Link eventKey="video_url-tab">Videos</Nav.Link>
                    </TabItem>
                    <TabItem className="me-5">
                      <Nav.Link eventKey="presentation-tab">PPT</Nav.Link>
                    </TabItem>
                    <TabItem>
                      <Nav.Link eventKey="notes-tab">Sort notes</Nav.Link>
                    </TabItem>
                  </>
                }
              >
                <Tab.Pane eventKey="video_url-tab">
                  <FormControl errMessage={errors?.video_url?.message}>
                    <TextInput
                      id="video_url"
                      validation={{
                        pattern: {
                          value:
                            /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g,
                          message: "Please enter valid url",
                        },
                      }}
                      register={register}
                      placeholder="Paste the URL"
                    />
                  </FormControl>
                </Tab.Pane>
                <Tab.Pane eventKey="presentation-tab">
                  <FileUpload
                    file={defaultValue?.presentation}
                    id="presentation"
                    register={register}
                    accept=".PPT,.PPTX"
                    iscustom={true}
                    handleChange={(e) => {
                      setValue(
                        "presentation_fileName",
                        e?.target?.files?.[0]?.name,
                        {
                          shouldDirty: true,
                        }
                      );
                    }}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="notes-tab">
                  <FileUpload
                    file={defaultValue?.short_notes_file}
                    id="short_notes_file"
                    register={register}
                    iscustom={true}
                    issortNotes={true}
                    handleChange={(e) => {
                      setValue(
                        "short_notes_fileName",
                        e?.target?.files?.[0]?.name,
                        {
                          shouldDirty: true,
                        }
                      );
                    }}
                  />

                  <Controller
                    name="short_notes"
                    control={control}
                    render={({ field }) => (
                      <Editor
                        id="short_notes"
                        {...field}
                        issortNotes={true}
                        editorState={field.value}
                        onEditorStateChange={(text) => {
                          field.onChange(text);
                        }}
                      />
                    )}
                  />
                </Tab.Pane>
              </TabList>
            </Col>
          </Row>
          <Row>
            <Col>
              <Glossary
                register={register}
                control={control}
                name={"glossary"}
                numberOfDefaultItems={0}
                errors={errors}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col style={{ textAlign: "end" }}>
              <Button
                onClick={() => {
                  if (isEdit) {
                    navigate(
                      `/chapters/${chapterId}/lessons/${defaultValue.id}/show`,
                      {
                        state: { chapter: chapter },
                      }
                    );
                  } else {
                    if (location?.state?.isFromChapter) {
                      navigate(`/chapters`);
                    } else {
                      navigate(`/chapters/${chapterId}/lessons`, {
                        state: { chapter: chapter },
                      });
                    }
                  }
                }}
                className="me-3"
                color={theme.colors.LIGHT_GREY}
                bgColor={theme.colors.WHITE}
                variant="inverse"
                text="Cancel"
              />
              <Button
                type="submit"
                bgColor={theme.colors.NEBULA_BLUE}
                disabled={isDirty && isValid ? false : true}
                text={isEdit ? "Update" : "Save"}
              />
            </Col>
          </Row>
        </form>
      </ContainerComponent>
    </>
  );
};

export default LessonForm;
