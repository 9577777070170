import { useQuery } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useLevels = (country_id) => {
  const formData = {};
  if (country_id) formData.country_id = country_id;

  return useQuery(
    ["levels", country_id],
    async () => {
      const { data } = await axiosPost("/api/cw/viewLevelFilter", formData);
      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

export default useLevels;
