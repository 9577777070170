import { useMutation } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useUpdatePastpaper = () => {
  return useMutation((newTest) => {
    return axiosPost("/api/cw/updatePastPaper", newTest);
  });
};

export default useUpdatePastpaper;
