import { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import styled from "styled-components";
import { Constant, alphabets } from "../../../lib/Constant";
import { convertBase64, pxtoRem } from "../../../lib/helper";
import { theme } from "../../../lib/themeConstants";
import Accordion from "../Accordion";
import Button from "../Button";
import Editor from "../Editor";
import FormControl from "../FormControl";
import TextInput from "../TextInput";
import BoxImage from "./BoxImage";
import MatchingPairOption from "./MatchingPairOption";
import _ from "lodash";

const StyledContainer = styled.div`
  margin-top: ${pxtoRem(20)};
  background-color: ${(props) => props.theme.colors.BODY_BG};
  border: 1px solid ${(props) => props.theme.colors.DARK_GREY};
  border-radius: 4px;
  width: 100%;
  padding: ${pxtoRem(40)};
`;

const StyledLabel = styled(Form.Label)`
  margin-bottom: 0px;
  color: ${(props) => props.theme.colors.NAVY_BLUE};
`;

const StyledAddOther = styled.div`
  display: inline-flex;
  cursor: pointer;
  font-size: ${pxtoRem(25)};
  color: ${(props) => props.theme.colors.NEBULA_BLUE};
  margin-bottom: ${pxtoRem(14)};
`;

const StyledAddButton = styled.img`
  height: ${pxtoRem(40)};
  width: ${pxtoRem(40)};
  margin-right: ${pxtoRem(14)};
`;

const StyledCaptionRow = styled(Row)`
  height: ${pxtoRem(150)};
`;

const MatchingPairForm = ({
  defaultValues,
  handleFormSubmit,
  nextQuestion,
  onQuestionDelete,
  onSubmit,
  formStateValues,
  setFormState,
}) => {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues: {
      ...defaultValues,
      test_type: "self_test",
      answers:
        defaultValues?.answers?.length > 0
          ? defaultValues?.answers
          : [
              {
                type: "text",
                answer: "",
              },
              {
                type: "text",
                answer: "",
              },
              {
                type: "text",
                answer: "",
              },
              {
                type: "image",
                answer: "",
              },
            ],
    },
    mode: "onChange",
  });
  const allValues = getValues();
  const {
    append,
    remove,
    fields: matching_pair,
  } = useFieldArray({
    control,
    name: "matching_pair",
  });

  useEffect(() => {
    if (
      formStateValues?.isValid !== isValid ||
      formStateValues?.isDirty !== isDirty ||
      !_.isEqual(formStateValues?.allValues, allValues)
    ) {
      setFormState({ isValid, isDirty, allValues });
    }
  }, [isValid, isDirty, allValues]);

  const onFormSubmit = async (data) => {
    debugger;
    let formData = new FormData();
    formData.append("question", data?.question);
    formData.append("solution", data?.solution);
    formData.append("knowledge", data?.knowledge);
    formData.append("mark", data?.mark);
    formData.append("matching_pair", data?.matching_pair);
    if (defaultValues?.id) {
      formData.append("id", defaultValues?.id);
    }

    Promise.all(
      await data?.matching_pair?.map(async (item, index) => {
        // let answerValue = item.answer;

        const left = item["left"][0];
        const right = item["right"][0];

        formData.append(`matching_pair[${index}][left].type`, left.type);
        formData.append(`matching_pair[${index}][left].no`, index + 1);
        formData.append(`matching_pair[${index}][left].answer`, left.answer);

        formData.append(`matching_pair[${index}][right].type`, right.type);
        formData.append(`matching_pair[${index}][right].no`, index + 1);
        formData.append(`matching_pair[${index}][right].answer`, right.answer);

        formData.append(`matching_pair[${index}][order]`, alphabets[index]);
      })
    ).then(() => {
      onSubmit(formData);
    });

    onSubmit && onSubmit(formData);
  };

  return (
    <form key={2} onSubmit={handleSubmit(onFormSubmit)}>
      <StyledContainer>
        <Row>
          <Col xs={3}>
            <FormControl errMessage={errors?.mark?.message}>
              <TextInput
                placeholder="Mark"
                type="number"
                register={register}
                id="mark"
                validation={{
                  required: "This field is required",
                  min: {
                    value: 1,
                    message: "Value can not be less than 1",
                  },
                }}
              />
            </FormControl>
          </Col>
          {defaultValues?.id && (
            <Col style={{ textAlign: "right" }}>
              <BoxImage
                onClick={() => {
                  if (onQuestionDelete) {
                    let formData = new FormData();
                    formData.append("id", defaultValues?.id);
                    onQuestionDelete(formData);
                  }
                }}
                bgColor={theme.colors.PINK}
                imgSrc={Constant.Icons.delete_bg_white}
              />
            </Col>
          )}
        </Row>
        <Row className="description-container">
          <Col>
            <StyledLabel>question?</StyledLabel>
            <Controller
              name="question"
              control={control}
              rules={{ required: "This field is required" }}
              render={({ field }) => (
                <Editor
                  errMessage={errors?.question?.message}
                  id="question"
                  {...field}
                  editorState={field.value}
                  onEditorStateChange={(text) => {
                    field.onChange(text);
                  }}
                />
              )}
            />
          </Col>
        </Row>

        <StyledCaptionRow>
          <Col>
            <FormControl
              isInline={false}
              label="Left column"
              errMessage={errors?.mark?.message}
            >
              <TextInput
                register={register}
                id="leftColumn"
                validation={{ required: "This field is required" }}
              />
            </FormControl>
          </Col>
          <Col>
            <FormControl
              isInline={false}
              label="Right column"
              errMessage={errors?.mark?.message}
            >
              <TextInput
                register={register}
                id="rightColumn"
                validation={{ required: "This field is required" }}
              />
            </FormControl>
          </Col>
          <Col xs={1}></Col>
        </StyledCaptionRow>

        {matching_pair.map((field, index) => (
          <MatchingPairOption
            errors={errors}
            control={control}
            name="matching_pair"
            register={register}
            field={field}
            key={field.id}
            index={index}
            setValue={setValue}
            onDelete={() => {
              remove(index);
            }}
          />
        ))}

        <StyledAddOther
          onClick={() => {
            append();
          }}
        >
          <StyledAddButton
            src={Constant.Icons.add_circle_outline_blue}
            width="35"
            height="35"
            alt="add option"
          />
          Add card pair
        </StyledAddOther>

        <Accordion eventKey="0" heading="Add Solution">
          <Controller
            name="solution"
            control={control}
            render={({ field }) => (
              <Editor
                id="solution"
                {...field}
                editorState={field.value}
                onEditorStateChange={(text) => {
                  field.onChange(text);
                }}
              />
            )}
          />
        </Accordion>

        <Accordion eventKey="0" heading="Add Theory">
          <Controller
            name="knowledge"
            control={control}
            render={({ field }) => (
              <Editor
                id="knowledge"
                {...field}
                editorState={field.value}
                onEditorStateChange={(text) => {
                  field.onChange(text);
                }}
              />
            )}
          />
        </Accordion>
        <Row className="mt-4">
          <Col className="text-end">
            <Button
              variant="inverse"
              bgColor={theme.colors.WHITE}
              color={theme.colors.NEBULA_BLUE}
              text="Next question"
              disabled={defaultValues?.id ? false : true}
              onClick={nextQuestion}
              className="me-4"
            />
            <Button
              type="submit"
              bgColor={theme.colors.NEBULA_BLUE}
              text={defaultValues?.id ? "Update" : "Save"}
            />
          </Col>
        </Row>
      </StyledContainer>
    </form>
  );
};

export default MatchingPairForm;
