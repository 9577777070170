import { useQuery } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useWorksheets = (
  country_id,
  exam_board_id,
  subject_id,
  year_id,
  chapter_id,
  page
) => {
  const formData = {};
  if (country_id) formData.country_id = country_id;
  if (exam_board_id) formData.exam_board_id = exam_board_id;
  if (subject_id) formData.subject_id = subject_id;
  if (year_id) formData.year_id = year_id;
  if (chapter_id) formData.chapter_id = chapter_id;

  if (page) {
    formData.pagination = true;
    formData.page = page;
  }

  return useQuery(
    [
      "worksheets",
      country_id,
      exam_board_id,
      subject_id,
      year_id,
      chapter_id,
      page,
    ],
    async () => {
      const { data } = await axiosPost("/api/cw/viewWorkSheet", formData);
      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

export default useWorksheets;
