import React from "react";
import styled from "styled-components";
import { pxtoRem } from "../../lib/helper";

export const StyledCheckbox = styled.span`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    height: ${pxtoRem(60)};
    width: ${pxtoRem(55)};
    z-index: 1;
  }

  input:checked ~ .checkmark {
    background-color: green;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: ${pxtoRem(12)};
    top: ${pxtoRem(5)};
    width: ${pxtoRem(6)};
    height: ${pxtoRem(13)};
    border: solid white;
    border-width: 0 ${pxtoRem(3.8)} ${pxtoRem(3.8)} 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const StyledCheckMark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  border: 10px solid ${(props) => props.theme.colors.PURPLE};
  height: ${pxtoRem(55)};
  width: ${pxtoRem(60)};
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.WHITE};
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;

function Checkbox({ checked = false, onChange, ...props }) {
  return (
    <StyledCheckbox>
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => {
          onChange(e.target.checked);
        }}
        {...props}
      />
      <StyledCheckMark className="checkmark"></StyledCheckMark>
    </StyledCheckbox>
  );
}

export default Checkbox;
