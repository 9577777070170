import React from "react";
import styled from "styled-components";
import { pxtoRem } from "../../lib/helper";
import { useSelector } from "react-redux";

const StyledQuestionNumberContainer = styled.div`
  height: ${pxtoRem(45)};
  width: ${pxtoRem(45)};
  border-radius: ${pxtoRem(4)};
  margin: 0px ${pxtoRem(15)};
  align-items: center;
  cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};
  border: 1px solid ${(props) => props.theme.colors.DARK_GREY};
  border-color: ${(props) =>
    props.isCurrent || props.isCompleted
      ? props.theme.colors.NEBULA_BLUE
      : props.theme.colors.DARK_GREY};
  color: ${(props) =>
    props.isCurrent
      ? props.theme.colors.NEBULA_BLUE
      : props.isCompleted
      ? props.theme.colors.WHITE
      : props.theme.colors.DARK_GREY};
  background-color: ${(props) =>
    props.isCurrent
      ? props.theme.colors.WHITE
      : props.isCompleted
      ? props.theme.colors.MEDIUM_SLATE_BLUE
      : props.theme.colors.WHITE};
  font-size: ${pxtoRem(18)};
  display: flex;
  justify-content: center;
  text-align: center;
`;

function QuestionNumber({
  children,
  isCurrent = false,
  isCompleted = true,
  onChange,
  index,
  isDisabled = false,
  setShowSubQuestion,
}) {
  const { currentQuestionIndex } = useSelector((state) => state.questions);
  return (
    <StyledQuestionNumberContainer
      isCurrent={isCurrent}
      isCompleted={isCompleted}
      isDisabled={isDisabled}
      onClick={() => {
        setTimeout(() => {
          currentQuestionIndex !== index && !isDisabled && onChange(index);
        }, 0);
      }}
      onDoubleClick={() => {
        setShowSubQuestion(true);
      }}
      key={index}
    >
      {children}
    </StyledQuestionNumberContainer>
  );
}

export default QuestionNumber;
