import React from "react";
import PageHeader from "../shared/PageHeader";
import Button from "../../components/shared/Button";
import { theme } from "../../lib/themeConstants";
import { useNavigate } from "react-router-dom";
import SectionHeader from "../shared/SectionHeader";
import ContainerComponent from "../shared/ContainerComponent";
import { pxtoRem } from "../../lib/helper";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import FormControl from "../shared/FormControl";
import { Controller, useFormContext } from "react-hook-form";
import Dropdown from "../shared/Dropdown";
import {
  useCountries,
  useExamBoards,
  useSubjects,
  useYears,
} from "../../hooks/chapter";
import MultipleDropdown from "../shared/MultipleDropdown";
import { QuestionTypes } from "../../lib/Constant";
import { useDispatch } from "react-redux";
import InputTime from "../shared/InputTime";
import { Spinner } from "../shared/Spinner";

const StyledBox = styled(Row)`
  width: ${(props) => (!props.isEdit ? "95%" : "99%")};
  padding-left: ${pxtoRem(60)};
  margin-top: ${pxtoRem(40)};
`;

const StyledButton = styled(Button)`
  margin-right: -${pxtoRem(30)};
`;
const MockTestFrom = ({ isLoading, isEdit = false }) => {
  const dispatch = useDispatch();
  const { control, register, errors, setValue, isDirty, isValid } =
    useFormContext();
  const navigate = useNavigate();

  const { data: lstCountries } = useCountries();
  const { data: lstGrades } = useYears();
  const { data: lstExamBoards } = useExamBoards();
  const { data: lstSubjects } = useSubjects();

  const MockNumbers = [
    { label: "Mock 1", value: 1 },
    { label: "Mock 2", value: 2 },
    { label: "Mock 3", value: 3 },
    { label: "Mock 4", value: 4 },
    { label: "Mock 5", value: 5 },
  ];
  return (
    <>
      <Spinner isLoading={isLoading} />
      {!isEdit && (
        <>
          <PageHeader
            title={"Mock Test"}
            control={
              <Button
                text={"Back"}
                bgColor={theme.colors.NEBULA_BLUE}
                onClick={() => {
                  navigate("/mock-test");
                }}
              />
            }
          ></PageHeader>
          <SectionHeader title="Create new Mock test"></SectionHeader>
        </>
      )}
      <ContainerComponent>
        <StyledBox style={{ marginBottom: "2rem" }} isEdit={isEdit}>
          <Col>
            <FormControl
              label="country *"
              errMessage={errors?.country_id?.message}
              labelColClass="col-3 activity-form"
            >
              <Controller
                isClearable
                name="country_id"
                control={control}
                rules={{ required: "Please select a country" }}
                render={({ field: { ref, ...rest } }) => (
                  <Dropdown
                    autoFocus
                    placeholder="Select country"
                    {...rest}
                    options={
                      lstCountries?.map((item) => {
                        return {
                          label: item.name,
                          value: item.id,
                        };
                      }) || []
                    }
                  />
                )}
              />
            </FormControl>
            <FormControl
              label="exam board *"
              errMessage={errors?.exam_board_id?.message}
              labelColClass="col-3 activity-form"
            >
              <Controller
                isClearable
                name="exam_board_id"
                control={control}
                rules={{ required: "Please Select exam board" }}
                render={({ field: { ref, ...rest } }) => (
                  <Dropdown
                    placeholder="Select exam board"
                    {...rest}
                    options={
                      lstExamBoards?.map((item) => {
                        return {
                          label: item.name,
                          value: item.id,
                        };
                      }) || []
                    }
                  />
                )}
              />
            </FormControl>
            <FormControl
              label="level *"
              errMessage={errors?.level_id?.message}
              labelColClass="col-3 activity-form"
            >
              <Controller
                isClearable
                name="level_id"
                rules={{ required: "Please Select level" }}
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <Dropdown
                    placeholder="Select level"
                    {...rest}
                    options={
                      lstGrades?.map((item) => {
                        return {
                          label: item.name,
                          value: item.id,
                        };
                      }) || []
                    }
                  />
                )}
              />
            </FormControl>

            <FormControl
              label="grade *"
              errMessage={errors?.year_id?.message}
              labelColClass="col-3 activity-form"
            >
              <Controller
                isClearable
                name="year_id"
                rules={{ required: "Please Select grade" }}
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <MultipleDropdown
                    placeholder="Select grade"
                    {...rest}
                    options={
                      lstGrades?.map((item) => {
                        return {
                          label: item.name,
                          value: item.id,
                        };
                      }) || []
                    }
                  />
                )}
              />
            </FormControl>
          </Col>
          <Col md={{ offset: 1 }}>
            <FormControl
              label="subject *"
              errMessage={errors?.subject_id?.message}
              labelColClass="col-3 activity-form"
            >
              <Controller
                isClearable
                name="subject_id"
                rules={{ required: "Please Select subject" }}
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <Dropdown
                    placeholder="Select subject"
                    {...rest}
                    options={
                      lstSubjects?.map((item) => {
                        return {
                          label: item.name,
                          value: item.id,
                        };
                      }) || []
                    }
                  />
                )}
              />
            </FormControl>
            <FormControl
              label="Mock No *"
              errMessage={errors?.subject_id?.message}
              labelColClass="col-3 activity-form"
            >
              <Controller
                isClearable
                name="mock_no"
                rules={{ required: "Please Select subject" }}
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <Dropdown
                    placeholder="Select mock number"
                    {...rest}
                    options={MockNumbers}
                  />
                )}
              />
            </FormControl>
            <FormControl
              label="Question type *"
              errMessage={errors?.answer_type?.message}
              labelColClass="col-3 activity-form"
            >
              <Controller
                isClearable
                name="answer_type"
                control={control}
                rules={{ required: "Please select a question type" }}
                render={({ field: { ref, ...rest } }) => (
                  <Dropdown
                    autoFocus
                    placeholder="Select question"
                    {...rest}
                    options={QuestionTypes}
                    onChange={(value) => {
                      setValue("answer_type", value);
                      // dispatch(resetQuestions());
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl
              label="Total duration *"
              labelColClass="col-3 activity-form"
            >
              <InputTime
                errMessage={
                  errors?.duration_hours?.message ||
                  errors?.duration_minutes?.message
                }
                register={register}
              />
            </FormControl>
          </Col>
        </StyledBox>
        <Row>
          <Col style={{ textAlign: "end" }}>
            <StyledButton
              type={"submit"}
              bgColor={theme.colors.NEBULA_BLUE}
              disabled={isDirty & isValid ? false : true}
              text={isEdit ? "Update" : "Next"}
            />
          </Col>
        </Row>
      </ContainerComponent>
    </>
  );
};

export default MockTestFrom;
