import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
import Button from "../../components/shared/Button";
import ContainerComponent from "../../components/shared/ContainerComponent";
import Dropdown from "../../components/shared/Dropdown";
import FormControl from "../../components/shared/FormControl";
import InputTime from "../../components/shared/InputTime";
import PageHeader from "../../components/shared/PageHeader";
import SectionHeader from "../../components/shared/SectionHeader";
import { Spinner } from "../../components/shared/Spinner";
import TextInput from "../../components/shared/TextInput";
import QuestionContainer from "../../components/shared/question/QuestionContainer";
import {
  useCountries,
  useCreateChapter,
  useExamBoards,
  useSubjects,
  useYears,
} from "../../hooks/chapter";
import { pxtoRem } from "../../lib/helper";
import { theme } from "../../lib/themeConstants";
import { Level, Month, QuestionTypes, Season, Week } from "../../lib/Constant";
import MultipleDropdown from "../../components/shared/MultipleDropdown";
import DateInput from "../../components/shared/DateInput";

const StyledBox = styled(Row)`
  width: 95%;
  padding-left: ${pxtoRem(60)};
  margin-top: ${pxtoRem(40)};
`;

const StyledButton = styled(Button)`
  margin-right: -${pxtoRem(30)};
`;

const CreateSeasonActivity = () => {
  const formPages = 2;
  const [formStep, setFormStep] = useState(1);
  const [pageJump, setPageJump] = useState(1);
  const [newStep, setNewStep] = useState(false);

  const queryClient = useQueryClient();
  const [isModal, setIsModal] = useState(false);
  const createMutation = useCreateChapter();
  const navigate = useNavigate();
  const [chapter, setChapter] = useState();

  const { data: lstCountries } = useCountries();
  const { data: lstGrades } = useYears();
  const { data: lstExamBoards } = useExamBoards();
  const { data: lstSubjects } = useSubjects();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitted, isDirty, isValid },
    watch,
    reset,
    setValue,
    getValues,
  } = useForm({ mode: "onChange" });

  const methods = {
    control,
    register,
    errors,
    handleSubmit,
    watch,
    backPage,
    getValues,
    setValue,
    isDirty,
    isValid,
  };

  function backPage(e, val) {
    const thisStep = formStep - val;
    setPageJump(val);
    setFormStep(thisStep);
    setNewStep(true);
  }

  useEffect(() => {
    if (newStep) {
      setNewStep(false);
      reset(
        {},
        {
          keepValues: true,
          keepDirty: true,
        }
      );
    }
  }, [isSubmitted, reset, newStep]);


  const onSubmit = async (data) => {
    if (formStep < formPages) {
      const thisStep = formStep + pageJump;
      setValue("formStep", thisStep.toString(), { shouldDirty: true });
      setFormStep(thisStep);
      setNewStep(true);
      return false;
    }
    return false;
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {formStep === 2 ? (
          <QuestionContainer
            onCancel={() => {
            }}
            title="Season activity"
          ></QuestionContainer>
        ) : (
          <>
            <Spinner isLoading={false} />
            <PageHeader
              title="Season activity"
              control={
                <Button
                  onClick={() => {
                    navigate("/season-activity");
                  }}
                  bgColor={theme.colors.NEBULA_BLUE}
                  text="Back"
                />
              }
            />
            <SectionHeader title="Create new season activity" />
            <ContainerComponent>
              <StyledBox style={{ marginBottom: "2rem" }}>
                <Col>
                  <FormControl
                    label="Country *"
                    errMessage={errors?.country_id?.message}
                  >
                    <Controller
                      isClearable
                      name="country_id"
                      control={control}
                      rules={{ required: "Please select a country" }}
                      render={({ field: { ref, ...rest } }) => (
                        <Dropdown
                          autoFocus
                          placeholder="Select country"
                          {...rest}
                          options={
                            lstCountries?.map((item) => {
                              return {
                                label: item.name,
                                value: item.id,
                              };
                            }) || []
                          }
                        />
                      )}
                    />
                  </FormControl>

                  <FormControl
                    label="Exam board *"
                    errMessage={errors?.exam_board_id?.message}
                  >
                    <Controller
                      isClearable
                      name="exam_board_id"
                      control={control}
                      rules={{ required: "Please Select exam board" }}
                      render={({ field: { ref, ...rest } }) => (
                        <Dropdown
                          placeholder="Select exam board"
                          {...rest}
                          options={
                            lstExamBoards?.map((item) => {
                              return {
                                label: item.name,
                                value: item.id,
                              };
                            }) || []
                          }
                        />
                      )}
                    />
                  </FormControl>

                  <FormControl
                    label="Level *"
                    errMessage={errors?.level_id?.message}
                  >
                    <Controller
                      isClearable
                      name="level_id"
                      control={control}
                      rules={{ required: "Please Select level" }}
                      render={({ field: { ref, ...rest } }) => (
                        <Dropdown
                          placeholder="Select level"
                          {...rest}
                          options={Level}
                        />
                      )}
                    />
                  </FormControl>

                  <FormControl
                    label="Grade"
                    errMessage={errors?.year_id?.message}
                  >
                    <Controller
                      isClearable
                      name="year_id"
                      rules={{ required: "Please Select grade" }}
                      control={control}
                      render={({ field: { ref, ...rest } }) => (
                        <MultipleDropdown
                          placeholder="Select grade"
                          {...rest}
                          options={
                            lstGrades?.map((item) => {
                              return {
                                label: item.name,
                                value: item.id,
                              };
                            }) || []
                          }
                        />
                      )}
                    />
                  </FormControl>

                  <FormControl
                    label="Subject *"
                    errMessage={errors?.subject_id?.message}
                  >
                    <Controller
                      isClearable
                      name="subject_id"
                      rules={{ required: "Please Select subject" }}
                      control={control}
                      render={({ field: { ref, ...rest } }) => (
                        <Dropdown
                          placeholder="Select subject"
                          {...rest}
                          options={
                            lstSubjects?.map((item) => {
                              return {
                                label: item.name,
                                value: item.id,
                              };
                            }) || []
                          }
                        />
                      )}
                    />
                  </FormControl>
                </Col>
                <Col md={{ offset: 1 }}>
                  <FormControl
                    label="Select season *"
                    errMessage={errors?.season?.message}
                  >
                    <Controller
                      isClearable
                      name="season"
                      rules={{ required: "Please Select season" }}
                      control={control}
                      render={({ field: { ref, ...rest } }) => (
                        <Dropdown
                          placeholder="Select season"
                          {...rest}
                          options={Season}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl
                    label="Select month *"
                    errMessage={errors?.month?.message}
                  >
                    <Controller
                      isClearable
                      name="month"
                      rules={{ required: "Please Select month" }}
                      control={control}
                      render={({ field: { ref, ...rest } }) => (
                        <Dropdown
                          placeholder="Select month"
                          {...rest}
                          options={Month}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl
                    label="Select week *"
                    errMessage={errors?.week?.message}
                  >
                    <Controller
                      isClearable
                      name="week"
                      rules={{ required: "Please Select week" }}
                      control={control}
                      render={({ field: { ref, ...rest } }) => (
                        <Dropdown
                          placeholder="Select week"
                          {...rest}
                          options={Week}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl
                    label="Select date *"
                    errMessage={errors?.date?.message}
                  >
                    <Controller
                      isClearable
                      name="date"
                      control={control}
                      rules={{ required: "Please select a question type" }}
                      render={({ field: { ref, ...rest } }) => (
                        <DateInput
                          className="react-calender"
                          autoFocus
                          placeholder="Select question"
                          {...rest}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl
                    label="Question type *"
                    errMessage={errors?.answer_type?.message}
                  >
                    <Controller
                      isClearable
                      name="answer_type"
                      control={control}
                      rules={{ required: "Please select a question type" }}
                      render={({ field: { ref, ...rest } }) => (
                        <Dropdown
                          autoFocus
                          placeholder="Select question"
                          {...rest}
                          options={QuestionTypes}
                        />
                      )}
                    />
                  </FormControl>
                </Col>
              </StyledBox>
              <Row>
                <Col style={{ textAlign: "end" }}>
                  <StyledButton
                    type="submit"
                    bgColor={theme.colors.NEBULA_BLUE}
                    text="Next"
                  />
                </Col>
              </Row>
            </ContainerComponent>
          </>
        )}
      </form>
    </FormProvider>
  );
};

export default CreateSeasonActivity;
