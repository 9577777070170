import axios from "axios";
import history from "../lib/history";
import { resetStore } from "../store";

const axiosClient = axios.create({
  baseURL: "https://cw.api.smartht.co.uk",
});

const axiosGet = (url) => {
  return axiosClient.get(url, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
};

const axiosPost = (url, data) => {
  return axiosClient.post(url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      accept: "*/*",
    },
  });
};

const axiosDelete = (url) => {
  return axiosClient.delete(url, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
};

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      let path = window.location.pathname;
      resetStore();
      localStorage.clear();
      if (!(path === "/" || path === "/login")) {
        history.replace("/");
        history.go(0);
      }
    }
    return Promise.reject(error);
  }
);

export { axiosClient, axiosGet, axiosPost, axiosDelete };
