import { useQuery } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useViewWorksheet = (chapter_id) => {
  const formData = {};
  if (chapter_id) formData.chapter_id = chapter_id;

  return useQuery(
    ["viewWorksheet", chapter_id],
    async () => {
      const { data } = await axiosPost("/api/cw/viewWorkSheet", formData);
      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

export default useViewWorksheet;
