import React from "react";
import ContainerComponent from "./ContainerComponent";
import styled from "styled-components";
import { pxtoRem } from "../../lib/helper";

const StyledLabel = styled.div`
  display: flex;
  font-weight: 800;
  align-items: center;
  justify-content: center;
  /* height: ${pxtoRem(600)}; */
  width: 100%;
`;
function Empty({ text = "No Data" }) {
  return (
    <ContainerComponent>
      <StyledLabel>{text}</StyledLabel>
    </ContainerComponent>
  );
}

export default Empty;
