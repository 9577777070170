import { Col, Form, Row } from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import styled from "styled-components";
import { Constant } from "../../../lib/Constant";
import { convertBase64, pxtoRem } from "../../../lib/helper";
import { theme } from "../../../lib/themeConstants";
import Accordion from "../Accordion";
import Button from "../Button";
import Editor from "../Editor";
import FormControl from "../FormControl";
import TextInput from "../TextInput";
import QuestionOption from "./QuestionOption";
import DeleteButton from "../DeleteButton";
import { forwardRef, useEffect } from "react";
import { useImperativeHandle } from "react";
import { useRef } from "react";
import Dropdown from "../Dropdown";

const ListErrMessage = styled(Form.Control.Feedback)`
  display: block;
  color: ${(props) => props.theme.colors.SHADE_OF_PINK_RED};
`;

const StyledContainer = styled.div`
  margin-top: ${pxtoRem(20)};
  background-color: ${(props) => props.theme.colors.QUESTION_BG};
  border: 1px solid ${(props) => props.theme.colors.DARK_GREY};
  border-radius: 4px;
  width: 100%;
  padding: ${pxtoRem(40)};
`;

const StyledLabel = styled(Form.Label)`
  font-size: ${pxtoRem(24)};
  color: ${(props) => props.theme.colors.LIGHT_GREY};
`;

const StyledAddOther = styled.div`
  display: inline-flex;
  cursor: pointer;
  font-size: ${pxtoRem(25)};
  color: ${(props) => props.theme.colors.NEBULA_BLUE};
  margin-bottom: ${pxtoRem(14)};
  margin-left: -8px;
`;

const StyledAddButton = styled.img`
  height: ${pxtoRem(40)};
  width: ${pxtoRem(40)};
  margin-right: ${pxtoRem(12)};
`;

const McqForm = forwardRef(
  (
    {
      defaultValues,
      onSubmit,
      nextQuestion,
      onQuestionDelete,
      isedit,
      setFormState,
      formStateValues,
      chapters,
    },
    ref
  ) => {
    const formRef = useRef();
    const intiateValue = () => {
      return {
        mark: defaultValues?.mark || "",
        question: defaultValues?.question || "",
        solution: defaultValues?.solution || "",
        knowledge: defaultValues?.knowledge || "",
        corrAnsLen: defaultValues?.correct_answers?.length || 0,
        answers:
          defaultValues?.answers?.length > 0
            ? defaultValues?.answers?.map((item) => {
                return {
                  ...item,
                  answerImage: item.type === "image" ? item?.answer : "",
                  checked: defaultValues?.correct_answers?.some(
                    (x) => x.id === item.id
                  ),
                };
              })
            : Array.from({ length: 4 }).map(() => {
                return {
                  type: "text",
                  answer: "",
                };
              }),
      };
    };

    useImperativeHandle(ref, () => ({
      canParentSubmit: () => {
        return isValid && isDirty;
      },
      onParentSubmit: () => {
        formRef.current.dispatchEvent(
          new Event("submit", { bubbles: true, cancelable: true })
        );
      },
    }));

    const {
      reset,
      setValue,
      getValues,
      control,
      register,
      formState: { errors, isValid, isDirty },
      handleSubmit,
    } = useForm({
      defaultValues: intiateValue(),
      mode: "onChange",
    });

    useEffect(() => {
      reset(intiateValue());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValues, reset]);

    const {
      append,
      remove,
      fields: answers,
    } = useFieldArray({
      control,
      name: "answers",
    });

    const onFormSubmit = async (data) => {
      let formData = new FormData();
      // formData.append("question_number", 1);
      formData.append("question", data?.question);
      formData.append("solution", data?.solution);
      formData.append("knowledge", data?.knowledge);
      formData.append("mark", data?.mark);
      data?.chapter_id && formData.append("chapter_id", data?.chapter_id);
      if (defaultValues?.id) {
        formData.append("id", defaultValues?.id);
        formData.append("status", defaultValues?.status);
      }

      Promise.all(
        await data?.answers?.map(async (item) => {
          let answerValue = item.answer;
          if (item.type === "image") {
            if (typeof item?.answer !== "string" && item?.answer?.length > 0) {
              answerValue = await convertBase64(item?.answer?.[0]);
            }
          }
          return { ...item, answerValue: answerValue };
        })
      ).then((data) => {
        let answerIndex = 0;
        data.forEach((item, index) => {
          formData.append(`answers[${index}][id]`, index + 1);
          formData.append(`answers[${index}][type]`, item.type);
          formData.append(`answers[${index}][answer]`, item.answerValue);

          if (item?.checked) {
            formData.append(`correct_answers[${answerIndex}][id]`, index + 1);
            formData.append(`correct_answers[${answerIndex}][type]`, item.type);
            formData.append(
              `correct_answers[${answerIndex}][answer]`,
              item.answerValue
            );
            answerIndex = answerIndex + 1;
          }
        });
        onSubmit(formData);
      });
    };

    const setAnswerLeg = (len) => {
      setValue("answerLength", answers.length + len, {
        shouldValidate: true,
        shouldDirty: true,
      });
    };

    return (
      <form ref={formRef} key={2} onSubmit={handleSubmit(onFormSubmit)}>
        <StyledContainer>
          <Row>
            <Col xs={3} style={{ marginTop: pxtoRem(33) }}>
              <FormControl errMessage={errors?.mark?.message}>
                <TextInput
                  onWheel={(e) => e.target.blur()}
                  placeholder="Mark"
                  type="number"
                  register={register}
                  id="mark"
                  validation={{
                    required: "This field is required",
                    min: {
                      value: 1,
                      message: "Value can not be less than 1",
                    },
                  }}
                />
              </FormControl>
            </Col>
            {chapters && (
              <Col xs={3}>
                <FormControl
                  label="chapter name"
                  errMessage={errors?.chapter_id?.message}
                  isInline={false}
                >
                  <Controller
                    isClearable
                    name="chapter_id"
                    rules={{ required: "Please Select chapter" }}
                    control={control}
                    render={({ field: { ref, ...rest } }) => (
                      <Dropdown
                        placeholder="Select chapter"
                        {...rest}
                        options={
                          chapters?.map((item) => {
                            return {
                              label: item.name,
                              value: item.id,
                            };
                          }) || []
                        }
                      />
                    )}
                  />
                </FormControl>
              </Col>
            )}
            {defaultValues?.id && (
              <Col style={{ textAlign: "right" }}>
                <DeleteButton
                  isedit={isedit}
                  onDelete={() => {
                    if (onQuestionDelete) {
                      let formData = new FormData();
                      formData.append("id", defaultValues?.id);
                      onQuestionDelete(formData);
                    }
                  }}
                />
              </Col>
            )}
          </Row>

          <Row className="description-container">
            <Col>
              <StyledLabel>Question?</StyledLabel>
              <Controller
                name="question"
                control={control}
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <Editor
                    errMessage={errors?.question?.message}
                    id="question"
                    {...field}
                    editorState={field.value}
                    onEditorStateChange={(text) => {
                      field.onChange(text);
                    }}
                  />
                )}
              />
            </Col>
          </Row>

          {answers.map((field, index) => (
            <QuestionOption
              errors={errors}
              control={control}
              name="answers"
              register={register}
              field={field}
              key={field.id}
              index={index}
              setValue={setValue}
              getValues={getValues}
              onDelete={() => {
                setAnswerLeg(-1);
                remove(index);
              }}
              handleCheck={() => {
                const ansList = getValues("answers");
                setValue(
                  "corrAnsLen",
                  ansList?.filter((x) => x?.checked).length,
                  {
                    shouldValidate: true,
                    shouldDirty: false,
                  }
                );
              }}
            />
          ))}

          {errors.answerLength ? (
            <ListErrMessage>Please add minimum two options</ListErrMessage>
          ) : (
            errors.corrAnsLen && (
              <ListErrMessage>Please check correct answer</ListErrMessage>
            )
          )}

          <input
            defaultValue={answers?.length}
            style={{ display: "none" }}
            type="text"
            {...register("answerLength", {
              validate: (value) => {
                return value < 2 ? false : true;
              },
            })}
          />
          <input
            defaultValue={answers?.filter((x) => x?.checked).length || 0}
            style={{ display: "none" }}
            type="text"
            {...register("corrAnsLen", {
              validate: (value) => value !== 0,
            })}
          />

          <StyledAddOther
            onClick={() => {
              append({ type: "text" });
              setAnswerLeg(1);
            }}
          >
            <StyledAddButton
              src={Constant.Icons.add_circle_outline_blue}
              width="35"
              height="35"
              alt="add option"
            />
            Add more option
          </StyledAddOther>

          <Accordion
            eventKey="0"
            heading="Add Solution"
            defaultActiveKey={defaultValues?.solution ? "solution" : ""}
          >
            <Controller
              name="solution"
              control={control}
              render={({ field }) => (
                <Editor
                  id="solution"
                  {...field}
                  editorState={field.value}
                  onEditorStateChange={(text) => {
                    field.onChange(text);
                  }}
                />
              )}
            />
          </Accordion>

          <Accordion
            eventKey="0"
            heading="Add Theory"
            defaultActiveKey={defaultValues?.knowledge ? "knowledge" : ""}
          >
            <Controller
              name="knowledge"
              control={control}
              render={({ field }) => (
                <Editor
                  id="knowledge"
                  {...field}
                  editorState={field.value}
                  onEditorStateChange={(text) => {
                    field.onChange(text);
                  }}
                />
              )}
            />
          </Accordion>
          <Row className="mt-4">
            <Col className="text-end">
              <Button
                variant="inverse"
                bgColor={theme.colors.WHITE}
                color={theme.colors.NEBULA_BLUE}
                text="Next question"
                disabled={defaultValues?.id ? false : true}
                onClick={() => {
                  setTimeout(() => {
                    nextQuestion();
                  }, 0);
                }}
                className="me-4"
              />
              <Button
                type="submit"
                disabled={isValid && isDirty ? false : true}
                bgColor={theme.colors.NEBULA_BLUE}
                text={defaultValues?.id ? "Update" : "Save"}
              />
            </Col>
          </Row>
        </StyledContainer>
      </form>
    );
  }
);

export default McqForm;
