import { Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { pxtoRem } from "../../lib/helper";

const StyledErrMessage = styled(Form.Control.Feedback)`
  display: block;
  color: ${(props) => props.theme.colors.SHADE_OF_PINK_RED};
`;

const StyledLabel = styled(Form.Label)`
  margin-bottom: 0px;
  color: ${(props) => props.theme.colors.NAVY_BLUE};
`;

const StyledRow = styled(Row)`
  height: ${pxtoRem(120)};
`;

const StyledLabelColumn = styled(Col)`
  padding-top: ${pxtoRem(10)};
`;

const FormControl = ({
  isInline = true,
  label,
  errMessage,
  labelColClass = "col-5",
  ...rest
}) => {
  return (
    <>
      {isInline ? (
        <>
          <StyledRow>
            {label && (
              <StyledLabelColumn className={labelColClass}>
                <StyledLabel>{label}</StyledLabel>
              </StyledLabelColumn>
            )}

            <Col className={label ? "col-7" : "col-12"}>
              {rest.children}
              {errMessage && <StyledErrMessage>{errMessage}</StyledErrMessage>}
            </Col>
          </StyledRow>
        </>
      ) : (
        <>
          <StyledRow>
            <div className="mb-3">
              <StyledLabel>{label}</StyledLabel>
              {rest.children}
              {errMessage && <StyledErrMessage>{errMessage}</StyledErrMessage>}
            </div>
          </StyledRow>
        </>
      )}
    </>
  );
};

export default FormControl;
