import { useMutation } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useDeleteParentActivity = () => {
  return useMutation((id) => {
    return axiosPost("/api/cw/deleteSelfTest", id);
  });
};

export default useDeleteParentActivity;
