import { useMutation } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useUpdateWorksheetQuestions = () => {
  return useMutation((test) => {
    return axiosPost("/api/cw/updateWsQuestion", test);
  });
};

export default useUpdateWorksheetQuestions;
