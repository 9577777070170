import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filter: {
    country: null,
    examboard: null,
    subject: null,
    level: null,
    year: null,
  },
  formData: null,
};

export const pastPaperSlice = createSlice({
  name: "pastPaper",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFilter, setFormData } = pastPaperSlice.actions;

export default pastPaperSlice.reducer;
