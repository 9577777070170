import React, { useEffect } from "react";
import { Badge, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Autocomplete from "../../components/shared/Autocomplete";
import Button from "../../components/shared/Button";
import PageHeader from "../../components/shared/PageHeader";
import Table from "../../components/shared/Table";
import { Constant } from "../../lib/Constant";
import ContainerComponent from "../../components/shared/ContainerComponent";
import { pxtoRem } from "../../lib/helper";
import { theme } from "../../lib/themeConstants";
import {
  useChangePastPaperStatus,
  useViewPastPaper,
  useUploadPastPaperFile,
} from "../../hooks/pastPapers";
import {
  useCountries,
  useExamBoards,
  useSubjects,
  useYears,
} from "../../hooks/chapter";
import { Spinner } from "../../components/shared/Spinner";
import Empty from "../../components/shared/Empty";
import { useDispatch, useSelector } from "react-redux";
import { setPageChange } from "../../redux-toolkit/table";
import { setFilter, setFormData } from "../../redux-toolkit/pastPaper";
import Toggle from "../../components/shared/Toggle";
import { useState } from "react";
import UploadFile from "../../components/shared/popups/UploadFile";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import useLevels from "../../hooks/chapter/useLevels";
import { setQuestionsData } from "../../redux-toolkit/questions";

const StyledBadge = styled(Badge)`
  background-color: ${(props) => props.color} !important;
  width: ${pxtoRem(146)};
  height: ${pxtoRem(46)};
  padding-top: ${pxtoRem(13)};
  font-size: ${pxtoRem(20)};
  border-radius: ${pxtoRem(10)};
  color: ${(props) => props.theme.colors.WHITE};
  font-weight: normal;
`;

const StyledButton = styled(Button)`
  height: ${pxtoRem(46)};
  width: ${pxtoRem(67)};
  font-size: ${pxtoRem(20)};
  /* line-height: ${pxtoRem(18)}; */
  padding: 0;
`;

const StyledUploadFile = styled.img`
  height: ${(props) => (props?.hasFile ? pxtoRem(32) : pxtoRem(21))};
  width: ${(props) => (props?.hasFile ? pxtoRem(32) : pxtoRem(21))};
  cursor: pointer;
`;
function PastPaper() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [uploadFileModal, setUploadFileModal] = useState(false);
  const [ppUploadFileModal, setPpUploadFileModal] = useState(false);
  const [msFileData, setMsFileData] = useState(null);
  const [ppFileData, setPpFileData] = useState(null);

  const currentPage = useSelector((state) => {
    return state.table.page;
  });

  const { country, examboard, subject, level, year } = useSelector((state) => {
    return state.pastPaper.filter;
  });

  const {
    data: pastPapers,
    error,
    isFetching,
  } = useViewPastPaper(
    country?.value,
    examboard?.value,
    subject?.value,
    level?.value,
    year?.value,
    currentPage
  );

  const statusMutation = useChangePastPaperStatus();
  const uploadFileMutation = useUploadPastPaperFile();
  const navigate = useNavigate();

  const { data: countriesData, isFetching: countriesLoading } = useCountries();
  const { data: examboardData, isFetching: examLoading } = useExamBoards();
  const { data: subjectsData, isFetching: subjectsLoading } = useSubjects();
  const { data: levelData, isFetching: levelLoading } = useLevels();
  const { data: gradesData, isFetching: gradesLoading } = useYears();

  useEffect(() => {
    if (error) {
      toast.error(error?.response?.data?.message);
    }
    return () => {
      dispatch(setPageChange(1));
    };
  }, [dispatch, error]);

  useEffect(() => {
    dispatch(setFormData(null));
    dispatch(setQuestionsData(null));
  }, [dispatch]);

  const formatFilterOptions = (data) => {
    let formattedData = data?.map((item) => {
      return { value: item.id, label: item.name };
    });
    return formattedData;
  };

  const onFilterChange = (key, value) => {
    let data = { country, examboard, subject, level, year };
    data[key] = value;
    dispatch(setFilter(data));
  };

  const confirmUploadFile = async (data) => {
    let formData = new FormData();

    if (typeof data?.image !== "string" && data?.file?.length > 0) {
      formData.append("ms_file", data?.file?.[0]);
      formData.append("id", msFileData.id);
    } else {
      formData.append("delete", true);
      formData.append("id", msFileData.id);
    }
    uploadFileMutation.mutate(formData, {
      onError: (res) => {
        toast.error(res?.response?.data?.message);
        setUploadFileModal(false);
      },
      onSuccess: (res) => {
        queryClient.invalidateQueries("pastPapers", {
          country_id: country?.value,
          exam_board_id: examboard?.value,
          subject_id: subject?.value,
          level: level?.value,
          year: year?.value,
          page: currentPage,
        });
        setUploadFileModal(false);
      },
    });
  };

  const confirmPpUploadFile = async (data) => {
    let formData = new FormData();
    if (typeof data?.image !== "string" && data?.file?.length > 0) {
      formData.append("pp_file", data?.file?.[0]);
      formData.append("id", ppFileData.id);
    } else {
      formData.append("delete", true);
      formData.append("id", ppFileData.id);
    }
    uploadFileMutation.mutate(formData, {
      onError: (res) => {
        toast.error(res?.response?.data?.message);
        setUploadFileModal(false);
      },
      onSuccess: (res) => {
        queryClient.invalidateQueries("pastPapers", {
          country_id: country?.value,
          exam_board_id: examboard?.value,
          subject_id: subject?.value,
          level: level?.value,
          year: year?.value,
          page: currentPage,
        });
        setPpUploadFileModal(false);
      },
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Subject",
        accessor: "subject",
        Cell: ({ row }) => (
          <StyledBadge
            color={
              row?.original?.chapter?.subject?.color &&
              row?.original?.chapter?.subject?.color
            }
          >
            {row?.original?.chapter?.subject?.name &&
              row.original?.chapter?.subject.name}
          </StyledBadge>
        ),
      },
      {
        Header: "Exam board",
        accessor: "exam_board",
        Cell: ({ row }) => (
          <span>
            {row?.original?.chapter?.exam_board?.name &&
              row.original?.chapter?.exam_board.name}
          </span>
        ),
      },
      {
        Header: "Year",
        accessor: "year",
      },
      {
        Header: "Paper type",
        accessor: "paper_type",
      },
      {
        Header: "Upload MS",
        accessor: "ms_file",
        Cell: ({ row }) => (
          <StyledUploadFile
            src={
              row?.original?.ms_file
                ? Constant.Icons.file
                : Constant?.Icons.upload_outline
            }
            onClick={() => {
              setMsFileData({
                id: row?.original?.id,
                file: row?.original?.ms_file ? row?.original?.ms_file : null,
              });
              setUploadFileModal(true);
            }}
            hasFile={row?.original?.ms_file}
            alt="upload file"
          />
        ),
      },
      {
        Header: "Upload PP",
        accessor: "pp_file",
        Cell: ({ row }) => (
          <StyledUploadFile
            src={
              row?.original?.pp_file
                ? Constant.Icons.file
                : Constant?.Icons.upload_outline
            }
            onClick={() => {
              setPpUploadFileModal(true);
              setPpFileData({
                id: row?.original?.id,
                file: row?.original?.pp_file ? row?.original?.pp_file : null,
              });
            }}
            hasFile={row?.original?.pp_file}
            alt="upload file"
          />
        ),
      },
      {
        Header: "Action",
        Cell: ({ row }) => (
          <StyledButton
            text="View"
            onClick={() => navigate(`view/${row?.original?.id}`)}
            bgColor={theme.colors.NEBULA_BLUE}
          />
        ),
      },
      {
        Header: "",
        accessor: "status",
        customWidth: 17,
        Cell: ({ row }) => (
          <Toggle
            checked={row.original?.status === "publish" ? true : false}
            onChange={(status) => {
              const formData = new FormData();
              formData.set("status", status ? "publish" : "draft");
              formData.set("id", row?.original?.id);
              statusMutation.mutate(formData, {
                onError: (res) => {
                  toast.error(res?.response?.data?.message);
                },
                onSuccess: (res) => {
                  queryClient.invalidateQueries("pastPapers", {
                    country_id: country?.value,
                    exam_board_id: examboard?.value,
                    subject_id: subject?.value,
                    level: level?.value,
                    year: year?.value,
                    page: currentPage,
                  });
                },
              });
            }}
            onLable={"Publish"}
            offLable={"Draft"}
          />
        ),
      },
    ],
    // eslint-disable-next-line
    []
  );
  return (
    <>
      <Spinner
        isLoading={
          countriesLoading ||
          examLoading ||
          subjectsLoading ||
          gradesLoading ||
          levelLoading
        }
      />
      <PageHeader
        title="Past paper"
        control={
          <Button
            text="Create"
            icon={Constant.Icons.add_circle_outline_white}
            bgColor={theme.colors.NEBULA_BLUE}
            onClick={() => {
              navigate("create");
            }}
          />
        }
      ></PageHeader>

      <ContainerComponent>
        <Row>
          <Col>
            <Autocomplete
              options={formatFilterOptions(countriesData)}
              value={country}
              onChange={(data) => {
                // setCountry(data);
                onFilterChange("country", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Country"
              className="m-2 auto-complete"
            />
          </Col>
          <Col>
            <Autocomplete
              options={formatFilterOptions(examboardData)}
              value={examboard}
              onChange={(data) => {
                // setExamboard(data);
                onFilterChange("examboard", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Examboard"
              className="m-2 auto-complete"
            />
          </Col>
          <Col>
            <Autocomplete
              options={formatFilterOptions(subjectsData)}
              value={subject}
              onChange={(data) => {
                // setSubject(data);
                onFilterChange("subject", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Subject"
              className="m-2 auto-complete"
            />
          </Col>
          <Col>
            <Autocomplete
              options={formatFilterOptions(levelData)}
              value={level}
              onChange={(data) => {
                // setGrade(data);
                onFilterChange("level", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Level"
              className="m-2 auto-complete"
            />
          </Col>
          <Col>
            <Autocomplete
              options={formatFilterOptions(gradesData)}
              value={year}
              onChange={(data) => {
                // setGrade(data);
                onFilterChange("year", data);
                dispatch(setPageChange(1));
              }}
              placeholder="Year"
              className="m-2 auto-complete"
            />
          </Col>
        </Row>
        <Row>
          <Col className="mt-3">
            {pastPapers?.data?.length > 0 ? (
              <>
                <Table
                  data={pastPapers?.data}
                  columns={columns}
                  defaultPageSize={pastPapers?.per_page}
                  totalPages={pastPapers?.last_page}
                  currentPage={pastPapers?.current_page}
                ></Table>
              </>
            ) : (
              !isFetching && (
                <Empty text={Constant.tableErrorMessages.pastPaper} />
              )
            )}
          </Col>
        </Row>

        <UploadFile
          file={msFileData?.file}
          show={uploadFileModal}
          onConfirm={confirmUploadFile}
          onCancel={() => setUploadFileModal(false)}
        />

        <UploadFile
          file={ppFileData?.file}
          show={ppUploadFileModal}
          onConfirm={confirmPpUploadFile}
          onCancel={() => setPpUploadFileModal(false)}
        />
      </ContainerComponent>
    </>
  );
}

export default PastPaper;
