import styled from "styled-components";
import { pxtoRem } from "../../../lib/helper";
import { Constant } from "../../../lib/Constant";

const StyledContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
`;

const StyledRoundItem = styled.p`
  width: ${pxtoRem(45)};
  height: ${pxtoRem(45)};
  border: 3px solid ${(props) => props.theme.colors.MEDIUM_LIGHT_SHADE_CYAN};
  color: ${(props) => props.theme.colors.MEDIUM_LIGHT_SHADE_CYAN};
  border-radius: 20px;
  margin: ${pxtoRem(4)};
  margin-right: ${pxtoRem(10)};
  cursor: pointer;

  ${(props) =>
    props.isactive &&
    `
      color: white;
      background-color:#6ecacd;
    `};
`;

const StyledLeftIndicator = styled.img`
  transform: rotate(90deg);
  margin-top: ${pxtoRem(20)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  width: ${pxtoRem(28)};
  height: ${pxtoRem(11)};
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`;

const StyledRightIndicator = styled.img`
  transform: rotate(-90deg);
  margin-top: ${pxtoRem(20)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  width: ${pxtoRem(25)};
  height: ${pxtoRem(11)};
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`;

const ViewQuestionNumbers = ({
  activeIndex,
  onPageClick,
  list,
  startEndIndex,
  onChangeLeft,
  onChangeRight,
}) => {
  return (
    <StyledContainer>
      <StyledLeftIndicator
        onClick={() => {
          onChangeLeft();
        }}
        disabled={startEndIndex?.start === 0}
        src={Constant.Icons.view_down_arrow}
        visible={list && list?.length > 10}
        alt="add question"
      />
      {list?.map(
        (item, index) =>
          index >= startEndIndex?.start &&
          index <= startEndIndex?.end && (
            <StyledRoundItem
              onClick={() => {
                onPageClick(index);
              }}
              isactive={activeIndex === index}
              key={index}
            >
              {index + 1}
            </StyledRoundItem>
          )
      )}
      <StyledRightIndicator
        onClick={() => {
          onChangeRight();
        }}
        disabled={startEndIndex?.end === list?.length - 1}
        src={Constant.Icons.view_down_arrow}
        visible={list && list?.length > 10}
        width="19"
        height="11"
        alt="add question"
      />
    </StyledContainer>
  );
};

export default ViewQuestionNumbers;
