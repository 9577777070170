import { useMutation } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useCreateChapter = () => {
  return useMutation((newChapter) => {
    return axiosPost("/api/cw/createChapter", newChapter);
  });
};

export default useCreateChapter;
