import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { Constant } from "../../../lib/Constant";
import { pxtoRem } from "../../../lib/helper";

const StyledLayer = styled.div`
  padding-top: 0px;
  border: none;
  justify-content: center;
`;

const StyledHeader = styled(StyledLayer)`
  font-weight: 600;
  flex-direction: column;
  padding-top: ${pxtoRem(40)};
`;

const StyledBody = styled(StyledLayer)`
  padding-top: 0px;
  text-align: center;
`;

const StyledFooter = styled(StyledLayer)`
  padding-bottom: ${pxtoRem(40)};
`;

const StyledCloseIcon = styled.img`
  width: ${pxtoRem(42)};
  height: ${pxtoRem(42)};
  border-radius: 50%;
  padding: ${pxtoRem(8)};
  background: ${(props) => props.theme.colors.LAVENDER_BLUE};
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
`;

const StyledSuccessIcon = styled.img`
  height: ${pxtoRem(120)};
  width: ${pxtoRem(120)};
`;

const SuccessModal = ({ body, button, show = false, onCancel }) => {
  return (
    <Modal
      contentClassName="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onCancel}
    >
      <StyledCloseIcon
        onClick={onCancel}
        src={Constant.Icons.close}
        alt="close"
      />
      <StyledHeader as={Modal.Header}>
        <StyledSuccessIcon src={Constant.Icons.success} alt="success" />
      </StyledHeader>
      {body && <StyledBody as={Modal.Body}>{body}</StyledBody>}
      <StyledFooter as={Modal.Footer}>{button && button}</StyledFooter>
    </Modal>
  );
};

export default SuccessModal;
