import { useQuery } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useYears = (country_id, subject_id) => {
  const formData = new FormData();
  if (country_id) formData.set("country_id", country_id);
  if (subject_id) formData.set("subject_id", subject_id);
  return useQuery(
    ["years", country_id, subject_id],
    async () => {
      const { data } = await axiosPost("/api/cw/viewGradeFilter", formData);
      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

export default useYears;
