import { useQuery } from "react-query";
import { axiosPost } from "../../lib/axiosClient";

const useChapterFilters = (
  country_id,
  exam_board_id,
  subject_id,
  year_id,
  level_id,
  isFormFilter = false
) => {
  const formData = {};
  if (country_id) formData.country_id = country_id;
  if (exam_board_id) formData.exam_board_id = exam_board_id;
  if (subject_id) formData.subject_id = subject_id;
  if (level_id) formData.key_stage_id = level_id;
  if (year_id) {
    if (isFormFilter) {
      year_id.forEach((element, index) => {
        formData[`year_id[${index}]`] = element;
      });
    } else {
      formData.year_id = year_id;
    }
  }

  return useQuery(
    ["chaptersFilter", country_id, exam_board_id, subject_id, year_id],
    async () => {
      const { data } = await axiosPost("/api/cw/viewChapter", formData);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: isFormFilter
        ? !!country_id && !!exam_board_id && !!year_id && !!subject_id
        : true,
    }
  );
};

export default useChapterFilters;
