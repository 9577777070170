import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { Constant } from "../../../lib/Constant";
import {
  isQuillEmpty,
  isValidImgSrc,
  isValidTextORImage,
  pxtoRem,
  removeBlank,
} from "../../../lib/helper";
import { theme } from "../../../lib/themeConstants";
import Accordion from "../Accordion";
import Button from "../Button";
import Editor from "../Editor";
import FormControl from "../FormControl";
import TextInput from "../TextInput";
import BoxImage from "./BoxImage";
import { useEffect, useState } from "react";
import _ from "lodash";
import { forwardRef } from "react";
import { useRef } from "react";
import { useImperativeHandle } from "react";
import Dropdown from "../Dropdown";
import DeleteButton from "../DeleteButton";

const StyledContainer = styled.div`
  background-color: ${(props) => props.theme.colors.QUESTION_BG};
  border: 1px solid ${(props) => props.theme.colors.DARK_GREY};
  border-radius: 5px;
  width: 100%;
  padding: ${pxtoRem(40)};
`;

const StyledLabel = styled(Form.Label)`
  margin-bottom: 0px;
  color: ${(props) => props.theme.colors.NAVY_BLUE};
`;

const StyledNextQuestionButton = styled(Button)`
  margin-right: ${pxtoRem(20)};
`;
const OneWordForm = forwardRef(
  (
    {
      onSubmit,
      nextQuestion,
      defaultValues,
      onQuestionDelete,
      chapters,
      isedit,
    },
    ref
  ) => {
    const formRef = useRef();
    const {
      control,
      register,
      formState: { errors, isValid, isDirty },
      handleSubmit,
      setValue,
      getValues,
      reset,
      watch,
    } = useForm({
      mode: "onChange",
      defaultValues: defaultValues,
    });

    let [showErrors, setShowErrors] = useState({});

    useEffect(() => {
      reset({
        ...defaultValues,
        mark: defaultValues?.mark ? defaultValues?.mark : null,
        question: defaultValues?.question ? defaultValues?.question : null,
        one_word_answer: defaultValues?.one_word_answer
          ? defaultValues?.one_word_answer
          : null,
        marking_scheme: defaultValues?.marking_scheme
          ? defaultValues?.marking_scheme
          : null,
        knowledge: defaultValues?.knowledge ? defaultValues?.knowledge : null,
      });
      setShowErrors({});
    }, [defaultValues, reset]);

    useImperativeHandle(ref, () => ({
      canParentSubmit: () => {
        return isValid && isDirty;
      },
      onParentSubmit: () => {
        formRef.current.dispatchEvent(
          new Event("submit", { bubbles: true, cancelable: true })
        );
      },
    }));

    const onQuestionSubmit = (data) => {
      setShowErrors({});
      const formData = new FormData();
      let filteredData = removeBlank(data);

      for (let key of Object.keys(filteredData)) {
        formData.append(key, data[key]);
      }

      formData.append("one_word_correct_answer", data?.one_word_answer);
      formData.append(
        "status",
        defaultValues?.status ? defaultValues?.status : "draft"
      );
      onSubmit(formData);
    };

    return (
      <form ref={formRef} key={2} onSubmit={handleSubmit(onQuestionSubmit)}>
        <StyledContainer>
          <Row>
            <Col xs={3}>
              <FormControl
                label="mark"
                isInline={false}
                errMessage={errors?.marks?.message}
              >
                <TextInput
                  register={register}
                  id="mark"
                  type="number"
                  validation={{
                    required: "Please enter mark",
                    min: {
                      value: 1,
                      message: "Value can not be less than 1",
                    },
                  }}
                />
              </FormControl>
            </Col>
            {chapters && (
              <Col xs={3}>
                <FormControl
                  label="chapter name"
                  errMessage={errors?.chapter_id?.message}
                  isInline={false}
                >
                  <Controller
                    isClearable
                    name="chapter_id"
                    rules={{ required: "Please Select chapter" }}
                    control={control}
                    render={({ field: { ref, ...rest } }) => (
                      <Dropdown
                        placeholder="Select chapter"
                        {...rest}
                        options={
                          chapters?.map((item) => {
                            return {
                              label: item.name,
                              value: item.id,
                            };
                          }) || []
                        }
                      />
                    )}
                  />
                </FormControl>
              </Col>
            )}
            <Col style={{ textAlign: "right" }}>
              {defaultValues?.id && (
                <DeleteButton
                  isedit={isedit}
                  onDelete={() => {
                    if (onQuestionDelete) {
                      let formData = new FormData();
                      formData.append("id", defaultValues?.id);
                      onQuestionDelete(formData);
                    }
                  }}
                />
              )}
            </Col>
          </Row>
          <Row className="description-container mt-2">
            <Col>
              <StyledLabel>Question?</StyledLabel>
              <Controller
                name="question"
                control={control}
                rules={{
                  required: { value: true, message: "Please enter question" },
                }}
                render={({ field }) => (
                  <>
                    <Editor
                      id="question"
                      {...field}
                      editorState={field.value === "" ? undefined : field.value}
                      onEditorStateChange={(text) => {
                        field.onChange(text);
                        text &&
                          text !== "" &&
                          setShowErrors({ ...showErrors, question: true });
                      }}
                      ref={field.ref}
                      errMessage={
                        showErrors?.question && errors?.question?.message
                      }
                    />
                  </>
                )}
              />
            </Col>
          </Row>
          <Row className="description-container">
            <Col>
              <StyledLabel>Answer</StyledLabel>
              <TextInput
                id="one_word_answer"
                register={register}
                validation={{ required: "Please enter answer" }}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <Accordion
                eventKey="marking-scheme"
                heading="Add Marking Scheme"
                errMessage={
                  showErrors?.marking_scheme && errors?.marking_scheme?.message
                }
                defaultActiveKey={
                  defaultValues?.marking_scheme ? "marking-scheme" : ""
                }
              >
                <Controller
                  name="marking_scheme"
                  control={control}
                  rules={{
                    validate: {
                      validImage: (v) =>
                        isValidImgSrc(v) || "Please enter image only",
                    },
                  }}
                  render={({ field }) => (
                    <Editor
                      issortNotes={true}
                      id="marking_scheme"
                      {...field}
                      editorState={field.value === "" ? undefined : field.value}
                      onEditorStateChange={(text) => {
                        field.onChange(text);
                        text &&
                          text !== "" &&
                          setShowErrors({
                            ...showErrors,
                            marking_scheme: true,
                          });
                      }}
                      ref={field.ref}
                    />
                  )}
                />
              </Accordion>
            </Col>
          </Row>
          <Row>
            <Col>
              <Accordion
                eventKey="knowledge"
                heading="Add Theory"
                defaultActiveKey={defaultValues?.knowledge ? "knowledge" : ""}
              >
                <Controller
                  name="knowledge"
                  control={control}
                  render={({ field }) => (
                    <Editor
                      issortNotes={true}
                      id="knowledge"
                      {...field}
                      editorState={field.value}
                      onEditorStateChange={(text) => {
                        field.onChange(text);
                      }}
                      ref={field.ref}
                    />
                  )}
                />
              </Accordion>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col className="text-end">
              <StyledNextQuestionButton
                onClick={() => {
                  setTimeout(() => {
                    nextQuestion();
                  }, 0);
                }}
                disabled={!defaultValues?.id}
                variant="inverse"
                bgColor={theme.colors.WHITE}
                color={theme.colors.NEBULA_BLUE}
                text="Next question"
              />
              <Button
                type="submit"
                disabled={isDirty && isValid ? false : true}
                bgColor={theme.colors.NEBULA_BLUE}
                text={defaultValues?.id ? "Update" : "Save"}
              />
            </Col>
          </Row>
        </StyledContainer>
      </form>
    );
  }
);

export default OneWordForm;
